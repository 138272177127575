import React, { useRef, useEffect, useState } from 'react';

import classNames from 'classnames';

import InfoIcon from '../InfoIcon';
import classes from './styles.module.scss';

export default function Switch({
  label,
  isEnabled,
  onClick,
  backgroundColor,
  info,
  infoWidth,
  tooltipDirection,
  readOnly,
  labelStyle,
}) {
  const switchRef = useRef();
  const [swithToggled, setSwitchToggled] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    const color = backgroundColor || '#00E4AC';

    switchRef.current.style.setProperty('--backgroundColor', color);
  }, [backgroundColor]);

  useEffect(() => {
    setSwitchToggled(isEnabled);
  }, [isEnabled]);

  return (
    <div
      role="switch"
      tabIndex={0}
      ref={switchRef}
      aria-checked={isEnabled}
      className={classNames(classes.Switch, {
        [classes.enabled]: isEnabled,
      })}
      onKeyDown={(event) => {
        if (readOnly) {
          return;
        }
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      onClick={() => {
        if (readOnly) {
          return;
        }
        onClick();
        setIsTouched(true);
      }}
    >
      <span className={classes.label} style={labelStyle}>
        {label}{' '}
        {info && (
          <InfoIcon
            info={info}
            infoWidth={infoWidth}
            tooltipDirection={tooltipDirection}
          />
        )}
      </span>
      <div className={classes.switch}>
        <div
          className={classNames(classes.toggle, {
            [classes.jumpLeft]: !swithToggled && isTouched,
            [classes.jumpRight]: swithToggled && isTouched,
          })}
        />
      </div>
    </div>
  );
}
