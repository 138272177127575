import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import FormDropdown from '../../components/Form/FormDropdown';
import Textarea from '../../components/Form/Textarea';
import Button from '../../components/Button';

import { UiContext } from '../../context/UiContext';
import { hasAuthData } from '../../helpers/authStorage';
import UserService from '../../services/UserService';
import AdminService from '../../services/AdminService';
import classes from './styles.module.scss';

export default function InviteToJobModal({
  show,
  handleClose,
  currentCandidate,
  refetch,
}) {
  const [message, setMessage] = useState('');
  const [jobPost, setJobPost] = useState('');
  const [defaultMessage, setDefaultMessage] = useState('');
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  const { setIsFetching, showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  const isAuthorized = hasAuthData();

  const messageError =
    message.length <= 25
      ? t('modals.InviteToJobModal.message.maxLengthError')
      : null;

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isAuthorized,
  });

  const { data: jobPages } = useQuery({
    queryKey: ['jobs'],
    queryFn: () => AdminService.getMyJobs({ pageSize: 99999 }),
    enabled: isAuthorized,
  });

  const candidateApplicationsIds = currentCandidate?.jobApplications?.map(
    (application) => application?.jobPostId
  );

  const jobs = jobPages?.data?.filter(
    (job) =>
      !candidateApplicationsIds?.includes(job?.id) &&
      job?.status !== 'UnPublished'
  );

  const jobOptions = jobs?.map((job) => ({
    label: job.jobTitle,
    value: job.id,
  }));

  const resetMessage = () => {
    setMessage(defaultMessage);
  };

  const submit = async () => {
    setHasTriedToSubmit(true);

    if (!messageError) {
      try {
        setIsFetching(true);
        await AdminService.inviteCandidateToJob({
          candidateId: currentCandidate.id,
          jobPostId: jobPost.value,
          message,
        });
        if (refetch) {
          await refetch();
        }
        handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        showNotification({
          text: `${t('modals.InviteToJobModal.successMessage')} ${
            currentCandidate?.userProfile?.firstName
          } ${currentCandidate?.userProfile?.lastName}.`,
        });
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      setMessage('');
      setJobPost('');
      setMessage(defaultMessage);
      setHasTriedToSubmit(false);
    };
  }, [defaultMessage, show]);

  useEffect(() => {
    setDefaultMessage(`${t('modals.InviteToJobModal.hello')} ${
      currentCandidate?.userProfile?.firstName
    }

${t('modals.InviteToJobModal.inviteMessage')}

${me?.userProfile?.firstName} ${me?.userProfile?.lastName}`);
  }, [me, currentCandidate, t]);

  useEffect(() => {
    setMessage(defaultMessage);
  }, [defaultMessage]);

  return (
    <Modal
      show={show}
      centered
      className={classes.InviteToJobModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h2>{t('modals.InviteToJobModal.modalTitle')}</h2>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.candidateInfo}>
          <h3>{t('modals.InviteToJobModal.candidate')}</h3>
          <span className={classes.candidateName}>
            {currentCandidate?.userProfile?.firstName}{' '}
            {currentCandidate?.userProfile?.lastName}
          </span>
          <span className={classes.location}>
            {currentCandidate?.userProfile?.city ||
              currentCandidate?.userProfile?.customLocation ||
              currentCandidate?.jobApplications?.find?.(
                (application) => application?.applicantLocation?.length
              )?.applicantLocation}
          </span>
        </div>
        <div className={classes.textareaContainer}>
          <div className={classes.label}>
            <h4>{t('modals.InviteToJobModal.message.title')}</h4>
            <div className={classes.sublabel}>
              <span>
                {t('modals.InviteToJobModal.message.anythingYouLike')}
              </span>
              <button
                onClick={resetMessage}
                type="button"
                style={{
                  visibility: message !== defaultMessage ? 'visible' : 'hidden',
                }}
              >
                {t('modals.InviteToJobModal.message.revertButton')}
              </button>
            </div>
          </div>
          <Textarea
            style={{ lineHeight: '16px' }}
            note="Optional"
            width={746}
            height={141}
            placeholder={t('modals.InviteToJobModal.message.placeholder')}
            maxLength={5000}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            touched={hasTriedToSubmit}
            error={messageError}
          />
        </div>
        <div className={classes.jobsDropdownContainer}>
          <FormDropdown
            label={t('modals.InviteToJobModal.jobDropdown.label')}
            options={jobOptions}
            width={374}
            value={jobPost}
            placeholder={t('modals.InviteToJobModal.jobDropdown.placeholder')}
            height={60}
            setFieldValue={(_name, option) => setJobPost(option)}
          />
        </div>
        <div className={classes.buttons}>
          <Button
            width={213}
            height={50}
            disabled={!message || !jobPost}
            onClick={submit}
          >
            {t('modals.InviteToJobModal.sendInvitation')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
