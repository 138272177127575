import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Truncate from 'react-text-truncate';
import classNames from 'classnames';

import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

export default function NewPostOptions({
  currentTemplate,
  setCurrentTemplate,
}) {
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ['Job Template'],
    queryFn: () =>
      AdminService.getJobPostTemplates({
        pageSize: 9999,
        orderBy: 'templateSortOrder',
        sortOrder: 'asc',
        filters: 'templateStatus:eq:Published',
      }),
    keepPreviousData: true,
  });

  return (
    <div className={classes.NewPostOptions}>
      <div
        onClick={() => setCurrentTemplate('')}
        className={classNames(classes.button, {
          [classes.active]: currentTemplate === '',
        })}
      >
        <div className={classes.buttonHoverBackground} />
        <p>
          {t(
            'dashboardComponents.Jobs.PostAJob.PostOptions.NewPostOptions.startFromScratch'
          )}
        </p>
      </div>
      {data?.data?.map?.((template) => (
        <div
          onClick={() => setCurrentTemplate(template)}
          className={classNames(classes.button, {
            [classes.active]: currentTemplate?.id === template.id,
          })}
          key={template.id}
        >
          <div className={classes.buttonHoverBackground} />
          <div className={classes.visiable}>
            <Truncate
              line={2}
              text={template.templateName}
              truncateText="…"
              element="p"
            />
            <p className={classes.prefilled}>
              (
              {t(
                'dashboardComponents.Jobs.PostAJob.PostOptions.NewPostOptions.prefilled'
              )}
              )
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
