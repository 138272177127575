/* eslint-disable jsx-a11y/media-has-caption */

import React, { useRef, useState, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import screenfull from 'screenfull';

import SwipeControl from './SwipeControl';
import Loader from './Loader';
import Pagination from './Pagination';

import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

const createQuestion = (question) => {
  if (!question) {
    return '';
  }

  if (question.length <= 75) {
    return question;
  }
  return `${question.slice(0, 75)}...`;
};

export default function VideoResume({
  candidate,
  currentForm,
  sidePopupIsOpened,
  isModal,
}) {
  const [currentVideo, setCurrentVideo] = useState('');
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [isFullscreanOpened, setIsFullscreanOpened] = useState(false);
  const [totalDuration, setTotalDuration] = useState(0);
  const [isControlsVisible, setIsControlsVisilbe] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  const { data: jobPost } = useQuery({
    queryKey: ['jobPost', candidate?.jobPostId],
    queryFn: () => AdminService.getJobPost({ id: candidate?.jobPostId }),
    enabled: !!candidate?.jobPostId,
  });

  const sortedVrQuestions = jobPost?.videoResumeQuestions?.sort(
    (prevQuestion, nextQuestion) =>
      prevQuestion?.sortOrder - nextQuestion?.sortOrder
  );

  const candidateVideos = useMemo(() => {
    const sortedJobApplicationVideos = sortedVrQuestions
      ?.map((question) => {
        return candidate?.jobApplicationVideos.find(
          (video) => video.videoResumeQuestionId === question.id
        );
      })
      .filter((vid) => vid);

    if (sortedJobApplicationVideos?.length) {
      return sortedJobApplicationVideos;
    }

    if (candidate?.userProfile?.userProfileVideoResume) {
      return [candidate?.userProfile?.userProfileVideoResume];
    }

    if (candidate?.user?.userProfile?.userProfileVideoResume) {
      return [candidate?.user?.userProfile?.userProfileVideoResume];
    }

    if (!candidate?.jobApplicationVideos?.length) {
      return [];
    }

    return [];
  }, [candidate, sortedVrQuestions]);

  const videoRef = useRef(null);
  const playerContainerRef = useRef(null);

  useEffect(() => {
    setCurrentVideo(candidateVideos?.[0]);
  }, [candidateVideos]);

  const resetSettings = () => {
    setIsControlsVisilbe(false);
    setCurrentTime(0);
    setTotalDuration(0);
    setVideoProgress(0);
  };

  useEffect(() => {
    resetSettings();
  }, []);

  const loadVideo = () => {
    setIsLoadingVideo(true);
    if (videoRef.current.duration === Infinity) {
      videoRef.current.currentTime = 1e101;
      videoRef.current.ontimeupdate = () => {
        if (videoRef?.current?.duration) {
          setTotalDuration(videoRef?.current?.duration);
          setIsControlsVisilbe(true);
          setIsLoadingVideo(false);
        }
      };
    } else {
      setTotalDuration(videoRef?.current?.duration);
      setIsControlsVisilbe(true);
      setIsLoadingVideo(false);
    }
  };

  useEffect(() => {
    setIsVideoPaused(true);
    videoRef?.current?.pause();
  }, [candidate, currentForm, sidePopupIsOpened, currentVideo]);

  useEffect(() => {
    if (currentTime === totalDuration) {
      setIsVideoPaused(true);
    }
  }, [currentTime, totalDuration]);

  useEffect(() => {
    if (totalDuration === 0) {
      return null;
    }

    const interval = setInterval(() => {
      setCurrentTime(videoRef.current?.currentTime);
      setVideoProgress((videoRef.current?.currentTime / totalDuration) * 100);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [currentVideo, totalDuration]);

  const handlePlayPause = () => {
    if (!isVideoPaused) {
      setIsVideoPaused(true);
      videoRef.current.pause();

      return;
    }

    setIsVideoPaused(false);
    videoRef.current.play();
  };

  const handleClickOnSeekbar = (event) => {
    videoRef.current.currentTime =
      ((event.nativeEvent.offsetX + 1) * totalDuration) / 205;
  };

  const handleFullscreen = () => {
    if (isFullscreanOpened) {
      screenfull.exit();
      setIsFullscreanOpened(false);

      return;
    }

    setIsFullscreanOpened(true);
    screenfull.request(playerContainerRef.current);
  };

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setIsFullscreanOpened(false);
    }
  }
  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);

  const currentVideoIndex = candidateVideos?.indexOf(currentVideo);

  useEffect(() => {
    resetSettings();
  }, [currentVideo]);

  const showNextVideo = () => {
    if (!candidateVideos[currentVideoIndex + 1]) {
      return;
    }
    setCurrentVideo(candidateVideos[currentVideoIndex + 1]);
    resetSettings();
  };

  const showPrevVideo = () => {
    if (!candidateVideos[currentVideoIndex - 1]) {
      return;
    }
    setCurrentVideo(candidateVideos[currentVideoIndex - 1]);
    resetSettings();
  };

  let restTime = 0;

  if (totalDuration === 0) {
    restTime = '-0:00';
  } else if (totalDuration <= currentTime) {
    restTime = '-0:00';
  } else if (Number.isFinite(totalDuration) && Number.isFinite(currentTime)) {
    restTime = `-${Math.floor((totalDuration - currentTime) / 60)}:${'0'
      .concat(Math.floor((totalDuration - currentTime) % 60))
      .slice(-2)}`;
  } else {
    restTime = '-0:00';
  }

  return (
    <div
      className={classNames(classes.video, {
        [classes.isOpened]: isFullscreanOpened,
      })}
      ref={playerContainerRef}
      style={{ height: isModal && '513px', marginTop: isModal && '-15px' }}
    >
      {candidateVideos?.length ? (
        <>
          {candidateVideos.length > 1 && (
            <div className={classes.question}>
              {createQuestion(sortedVrQuestions[currentVideoIndex]?.question)}
            </div>
          )}
          {currentVideo ? (
            <div className={classes.videoContainer}>
              <SwipeControl
                onSwipeLeft={showNextVideo}
                onSwipeRight={showPrevVideo}
              >
                <video
                  ref={videoRef}
                  src={currentVideo?.url}
                  controls={false}
                  onLoadedData={loadVideo}
                  onEnded={() => {
                    videoRef.current.currentTime = 0;
                    setIsVideoPaused(true);
                  }}
                />
              </SwipeControl>
              {isLoadingVideo && <Loader />}
              {isControlsVisible && (
                <div className={classes.controls}>
                  <div
                    className={classes.seekbar}
                    onClick={(event) => handleClickOnSeekbar(event)}
                  >
                    <div
                      className={classes.progress}
                      style={{ width: `${videoProgress}%` }}
                    />
                  </div>
                  <div className={classes.buttons}>
                    <span className={classes.timer}>{restTime}</span>
                    <div
                      className={classNames(classes.play, {
                        [classes.pause]: !isVideoPaused,
                      })}
                      onClick={handlePlayPause}
                    />
                    <div
                      className={classes.fullscrean}
                      onClick={handleFullscreen}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={classes.videoText} style={{ marginBottom: 0 }}>
              N/A
            </div>
          )}
          <Pagination
            pageCount={candidateVideos.length}
            currentPage={currentVideoIndex + 1}
            setPage={(page) => setCurrentVideo(candidateVideos[page - 1])}
          />
          {/*  {candidateVideos.length > 1 && (
            <>
              <button
                type="button"
                className={classes.prevVideo}
                onClick={showPrevVideo}
                disabled={!candidateVideos?.[currentVideoIndex - 1]}
              />
              <button
                type="button"
                className={classes.nextVideo}
                onClick={showNextVideo}
                disabled={!candidateVideos?.[currentVideoIndex + 1]}
              />
            </>
          )} */}
        </>
      ) : (
        <div className={classes.videoText}>
          This candidate does not have a video resume.
        </div>
      )}
    </div>
  );
}
