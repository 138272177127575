import React, { useState, useEffect, useRef, useMemo } from 'react';

import { DonutChart } from 'react-circle-chart';
import { useTranslation } from 'react-i18next';

import GraphHoverInfo from './GraphHoverInfo';

import classes from './styles.module.scss';

export default function JobSummary({ stats = {} }) {
  const [graphTooltipData, setGraphTooltipData] = useState(null);
  const [hoverCoords, setHoverCoords] = useState(null);

  const graphRef = useRef();

  const { t } = useTranslation();

  const total =
    stats.new + stats.screen + stats.interview + stats.offer + stats.onboard;

  const graphItems = useMemo(() => {
    const items = [];
    const newShare = (100 / total) * stats.new;
    if (newShare) {
      items.push({
        label: t('dashboardComponents.Jobs.MyJobs.JobSummary.new'),
        value: newShare,
        color: 'rgb(16, 197, 233)',
        integerValue: stats.new,
      });
    }
    const screenShare = (100 / total) * stats.screen;
    if (screenShare) {
      items.push({
        label: t('dashboardComponents.Jobs.MyJobs.JobSummary.screen'),
        value: screenShare,
        color: 'rgb(0, 227, 171)',
        integerValue: stats.screen,
      });
    }

    const interviewShare = (100 / total) * stats.interview;
    if (interviewShare) {
      items.push({
        label: t('dashboardComponents.Jobs.MyJobs.JobSummary.interview'),
        value: interviewShare,
        color: 'rgb(255, 184, 0)',
        integerValue: stats.interview,
      });
    }

    const offerShare = (100 / total) * stats.offer;
    if (offerShare) {
      items.push({
        label: t('dashboardComponents.Jobs.MyJobs.JobSummary.offer'),
        value: offerShare,
        color: 'rgb(255, 45, 85)',
        integerValue: stats.offer,
      });
    }

    const onboardShare = (100 / total) * stats.onboard;
    if (onboardShare) {
      items.push({
        label: t('dashboardComponents.Jobs.MyJobs.JobSummary.onboard'),
        value: onboardShare,
        color: 'rgb(103, 34, 210)',
        integerValue: stats.onboard,
      });
    }

    return items;
  }, [stats, total, t]);

  useEffect(() => {
    const detectGraphElementUnderCursor = (event) => {
      const hoveredGraphPart = document.elementFromPoint(
        event.clientX,
        event.clientY
      );

      setHoverCoords({
        x: event.clientX,
        y: event.clientY,
      });

      const hoveredGraphPartColor =
        getComputedStyle(hoveredGraphPart).getPropertyValue('stroke');

      if (hoveredGraphPart.tagName === 'circle') {
        const hoveredGraphPartData = graphItems.find(
          (item) => item.color === hoveredGraphPartColor
        );

        if (hoveredGraphPartData) {
          setGraphTooltipData(hoveredGraphPartData);
        }
      } else {
        setGraphTooltipData(null);
      }
    };

    graphRef.current.addEventListener(
      'mousemove',
      detectGraphElementUnderCursor
    );

    return () => {
      graphRef.current?.removeEventListener(
        'mousemove',
        detectGraphElementUnderCursor
      );
    };
  }, [graphItems]);

  return (
    <div className={classes.JobSummary}>
      <h2>
        {t('dashboardComponents.Jobs.MyJobs.JobSummary.totalHiringPipeline')}
      </h2>
      <div className={classes.data}>
        <div className={classes.graphBorder}>
          <div className={classes.total}>{total}</div>
        </div>
        <div
          className={classes.graph}
          ref={graphRef}
          onMouseLeave={() => setGraphTooltipData(null)}
        >
          <DonutChart
            size={162}
            tooltipSx={{ display: 'none' }}
            trackWidth="lg"
            showTotal={false}
            items={graphItems}
          />
        </div>
        <ul className={classes.stats}>
          <li>
            <div>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="10" height="10" rx="5" fill="#10C5E9" />
              </svg>
              {t('dashboardComponents.Jobs.MyJobs.JobSummary.new')}
            </div>
            <span className={classes.value}>{stats.new}</span>
          </li>
          <li>
            <div>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="10" height="10" rx="5" fill="#00E3AB" />
              </svg>
              {t('dashboardComponents.Jobs.MyJobs.JobSummary.screen')}
            </div>
            <span className={classes.value}>{stats.screen}</span>
          </li>
          <li>
            <div>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="10" height="10" rx="5" fill="#FFB800" />
              </svg>
              {t('dashboardComponents.Jobs.MyJobs.JobSummary.interview')}
            </div>
            <span className={classes.value}>{stats.interview}</span>
          </li>
          <li>
            <div>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="10" height="10" rx="5" fill="#FF2D55" />
              </svg>
              {t('dashboardComponents.Jobs.MyJobs.JobSummary.offer')}
            </div>
            <span className={classes.value}>{stats.offer}</span>
          </li>
          <li>
            <div>
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="10" height="10" rx="5" fill="#6722D2" />
              </svg>
              {t('dashboardComponents.Jobs.MyJobs.JobSummary.onboard')}
            </div>
            <span className={classes.value}>{stats.onboard}</span>
          </li>
        </ul>
      </div>
      {graphTooltipData && (
        <GraphHoverInfo
          label={graphTooltipData.label}
          value={graphTooltipData.integerValue}
          coords={hoverCoords}
        />
      )}
    </div>
  );
}
