import React from 'react';

import classes from './styles.module.scss';

import useDropdown from '../../../../../../hooks/useDropdown';

export default function TitleDropdown({
  dashboardMode,
  setDashboardMode,
  dashboardModeOptions,
}) {
  const dropdown = useDropdown({
    currentOption: dashboardMode,
    setCurrentOption: setDashboardMode,
    options: dashboardModeOptions,
    classes,
    placeholder: 'My Dashboard',
    useValuesAsLabels: true,
  });

  return <div className={classes.TitleDropdown}>{dropdown}</div>;
}
