/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormDropdown from '../../../../../Form/FormDropdown';
import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';

import getResizedImageUrl from '../../../../../../helpers/getResizedImageUrl';
import yupSchemaToFields from '../../../../../../helpers/yupSchemaToFields';
import { COUNTRY_OPTIONS } from '../../../../../../constants/main';
import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Cannot be empty')
    .matches(/^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]+$/, 'Numbers not allowed'),
  postCode: yup
    .string()
    .trim()
    .matches(/^\d+$/, 'Alphabets not allowed')
    .min(5, 'Min 5 characters')
    .max(12, 'Max 12 characters'),
  country: yup.object().required('Cannot be empty'),
});

export default function AddOrEditCity({
  isVisible,
  handleClose,
  refetch,
  showAgain,
  isAdd,
  cities,
  selectedRows,
  hasNoAnimation,
  isEdit,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageError, setCoverImageError] = useState(null);
  const [isEditingInitialCoverImage, setIsEditingInitialCoverImage] =
    useState(false);
  // const [isSaving, setIsSaving] = useState(false);

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(cities, selectedRows);

  const fileInputRef = useRef();

  const { t } = useTranslation();

  const {
    showUnknownErrorModal,
    showModal,
    showDuplicateDataModal,
    setIsFetching,
    // isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const handleHttpError = (error) => {
    if (error?.response?.data?.message === 'Duplicity') {
      showDuplicateDataModal(
        t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.city'
        )
      );
    } else {
      showUnknownErrorModal();
    }
  };

  const addCity = async (values) => {
    try {
      setIsFetching(true);
      // setIsSaving(true);
      setIsCreatingOrUpdating(true);
      const response = await AdminService.uploadImage(coverImage, 'city');
      await AdminService.createCity({
        coverImageName: response.fileName,
        name: values.name,
        country: values.country.label,
        postCode: values.postCode,
      });
      showModal({
        title: t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.modals.success'
        ),
        text: t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.modals.addCitySuccessText'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.modals.addMore'
        ),
        confirmButtonLabel: t('common.gotIt'),
        onConfirm: () => {},
        onCancel: showAgain,
      });
      await refetch();
      // setIsSaving(false);
      handleClose();
    } catch (error) {
      console.log(error);
      handleHttpError(error);
      // setIsSaving(false);
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const updateCity = async (values) => {
    try {
      setIsFetching(true);
      // setIsSaving(true);
      setIsCreatingOrUpdating(true);

      let image;

      if (coverImage) {
        image = await AdminService.uploadImage(coverImage, 'city');
      }

      await AdminService.updateCity({
        coverImageName: image
          ? image.fileName
          : cities[currentDataIndex].coverImageName,
        name: values.name,
        id: cities[currentDataIndex].id,
        country: values.country.label,
        postCode: values.postCode,
      });
      showModal({
        title: t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.modals.success'
        ),
        text: t(
          'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.modals.editCitySuccessText'
        ),
        dismissButtonLabel: t('common.gotIt'),
        dismissButtonVariant: '',
      });
      await refetch();
      // setIsSaving(false);
    } catch (error) {
      console.log(error);
      handleHttpError(error);
      //  setIsSaving(false);
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: isAdd ? '' : cities?.[currentDataIndex]?.name,
      postCode: isAdd ? '' : cities?.[currentDataIndex]?.postCode,
      country: isAdd
        ? ''
        : {
            label: cities?.[currentDataIndex]?.country,
            value: cities?.[currentDataIndex]?.country,
          },
    },
    validationSchema,
    onSubmit: isAdd ? addCity : updateCity,
    enableReinitialize: true,
  });

  const resetForm = () => {
    formik.resetForm();
    setCoverImage(null);
    setIsTriedToSubmit(false);
    setIsEditingInitialCoverImage(true);
  };

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      setIsTriedToSubmit(false);
      setIsEditingInitialCoverImage(true);
    }

    if (isAdd) {
      setIsEditingInitialCoverImage(false);
      setCoverImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (isEdit && isVisible) {
      setIsEditingInitialCoverImage(true);
    }
  }, [isEdit, isVisible]);

  let imageBlock;

  if (coverImage || isEditingInitialCoverImage) {
    imageBlock = (
      <div className={classes.imageContainer}>
        <i
          className={classes.deleteIcon}
          onClick={() => {
            if (isEditingInitialCoverImage) {
              setIsEditingInitialCoverImage(false);
            } else {
              setCoverImage(null);
            }
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.00024 3.99582L8.78828 0.207778C9.06532 -0.0692594 9.51449 -0.0692595 9.79153 0.207778C10.0686 0.484816 10.0686 0.933983 9.79153 1.21102L6.00348 4.99906L9.79282 8.7884C10.0699 9.06544 10.0699 9.5146 9.79282 9.79164C9.51578 10.0687 9.06661 10.0687 8.78957 9.79164L5.00024 6.00231L1.21151 9.79104C0.934471 10.0681 0.485304 10.0681 0.208267 9.79104C-0.0687712 9.514 -0.0687711 9.06483 0.208267 8.7878L3.997 4.99906L0.209556 1.21162C-0.067482 0.934586 -0.0674817 0.485419 0.209556 0.208381C0.486594 -0.0686567 0.93576 -0.068657 1.2128 0.208381L5.00024 3.99582Z"
              fill="white"
            />
          </svg>
        </i>
        <img
          src={
            isEditingInitialCoverImage
              ? getResizedImageUrl({
                  url: `city/${cities?.[currentDataIndex]?.coverImageName}`,
                  width: 450,
                })
              : URL.createObjectURL(coverImage)
          }
          alt={formik.values.name}
        />
      </div>
    );
  } else {
    imageBlock = (
      <>
        <div
          className={classes.imageUploader}
          onClick={() => fileInputRef.current.click()}
        >
          <div className={classes.plusIcon}>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.998 4.16797V15.8346"
                stroke="white"
                strokeWidth="1.64103"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.16431 10H16.831"
                stroke="white"
                strokeWidth="1.64103"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={classes.uploaderContainer}>
            <p>
              {t(
                'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.addCityPictureHere'
              )}
            </p>
            <p>1800*1800</p>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={(event) => {
                setCoverImage(event.target.files[0]);
                setCoverImageError(null);
              }}
            />
          </div>
        </div>
        {coverImageError && (
          <div className={classes.error}>{coverImageError}</div>
        )}
      </>
    );
  }

  return (
    <SidePopup
      fields={[
        ...yupSchemaToFields(validationSchema),
        {
          field: 'cover',
          validation:
            coverImage || isEditingInitialCoverImage ? [] : ['required'],
        },
      ]}
      formik={{ ...formik, resetForm, dirty: formik.dirty || !!coverImage }}
      mode={isAdd ? 'add' : 'edit'}
      isVisible={isVisible}
      handleClose={handleClose}
      title={
        isAdd
          ? t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.addTitle'
            )
          : t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.editTitle'
            )
      }
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        if (!coverImage) {
          setCoverImageError(
            t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.pictureCannotBeEmpty'
            )
          );
        }
        formik.handleSubmit();
      }}
      leftButtonLabel={isAdd ? t('common.clear') : t('common.reset')}
      rightButtonLabel={isAdd ? t('common.add') : t('common.save')}
      onLeftButtonClick={resetForm}
      switchNext={selectedRows?.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows?.length > 1 && switchToPrevDataElement}
      hasNoAnimation={hasNoAnimation}
    >
      <div className={classes.AddOrEditCity}>
        <div className={classes.col}>
          <TextInput
            height={50}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.cityName'
            )}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            name="name"
            error={formik.errors.name}
            placeholder={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.cityNamePlaceholder'
            )}
          />
          <div style={{ position: 'relative' }}>
            <FormDropdown
              height={50}
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.country'
              )}
              value={formik.values.country}
              onBlur={formik.handleBlur}
              touched={isTriedToSubmit}
              name="country"
              error={formik.errors.country}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.countryPlaceholder'
              )}
              setFieldValue={formik.setFieldValue}
              options={useMemo(() => COUNTRY_OPTIONS, [])}
              maxOptionsHeight={200}
              fixed
            />
          </div>
          <TextInput
            height={50}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.zipcode'
            )}
            value={formik.values.postCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            name="postCode"
            error={formik.errors.postCode}
            placeholder={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.zipcodePlaceholder'
            )}
          />
        </div>
        <div className={classes.col}>
          <div className={classes.coverImage}>
            <h1>
              {t(
                'dashboardComponents.Console.RestaurantManagement.Cities.AddOrEditCity.picture'
              )}
            </h1>
            {imageBlock}
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
