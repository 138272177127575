export const hasLogoSettingsChanged = (initialSettings, newSettings) => {
  return (
    initialSettings.logo !== newSettings.logo ||
    initialSettings.favicon !== newSettings.favicon ||
    initialSettings.brandName !== newSettings.brandName
  );
};

export const hasColorSettingsChanged = (initialSettings, newSettings) => {
  if (!initialSettings || !newSettings) {
    return false;
  }

  return !initialSettings.every((color, index) => {
    const initialColor = typeof color === 'string' ? color : color.value;
    const newColor =
      typeof newSettings[index] === 'string'
        ? newSettings[index]
        : newSettings[index]?.value;

    return initialColor?.toLowerCase() === newColor?.toLowerCase();
  });
};

export const hasFontSettingsChanged = (initialSettings, newSettings) => {
  if (!initialSettings || !newSettings) {
    return false;
  }

  const hasFontStylesChanged = (font, savedFont) => {
    return (
      font?.styles?.sort?.().join('') !== savedFont?.styles?.sort?.().join('')
    );
  };

  return (
    initialSettings.titleFont.fontFamily.value !==
      newSettings.titleFont.fontFamily.value ||
    initialSettings.subTitleFont.fontFamily.value !==
      newSettings.subTitleFont.fontFamily.value ||
    initialSettings.bodyTextFont.fontFamily.value !==
      newSettings.bodyTextFont.fontFamily.value ||
    initialSettings.bodySubTextFont.fontFamily.value !==
      newSettings.bodySubTextFont.fontFamily.value ||
    hasFontStylesChanged(initialSettings.titleFont, newSettings.titleFont) ||
    hasFontStylesChanged(
      initialSettings.subTitleFont,
      newSettings.subTitleFont
    ) ||
    hasFontStylesChanged(
      initialSettings.bodyTextFont,
      newSettings.bodyTextFont
    ) ||
    hasFontStylesChanged(
      initialSettings.bodySubTextFont,
      newSettings.bodySubTextFont
    )
  );
};

export const hasContactsSettingsChanged = (initialSettings, newSettings) => {
  return (
    initialSettings.supportEmail !== newSettings.supportEmail ||
    initialSettings.contactEmail !== newSettings.contactEmail ||
    initialSettings.appName !== newSettings.appName
  );
};
