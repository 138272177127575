import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import classes from './styles.module.scss';

import NonFavoritesIcon from '../../../Dashboard/Jobs/MyJobs/Icons/NonFavoritesIcon';
import FavoritesIcon from '../../../Dashboard/Jobs/MyJobs/Icons/FavoritesIcon';

import { UiContext } from '../../../../context/UiContext';
import AdminService from '../../../../services/AdminService';

export default function CandidateBooleanFavorite({
  isFavorite,
  candidateId,
  refetch,
  isJobApplication,
}) {
  const { setIsFetching } = useContext(UiContext);

  const queryClient = useQueryClient();

  const refetchWithQueryClient = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['candidates'],
    });
  };

  const addCandidateToFavorites = async (event) => {
    event.stopPropagation();
    try {
      setIsFetching(true);

      if (isJobApplication) {
        await AdminService.addMyJobApplicationToFavorites({
          jobApplicationId: candidateId,
        });
      } else {
        await AdminService.addCandidateToFavorites({ id: candidateId });
      }

      if (refetch) {
        await refetch();
      } else {
        await refetchWithQueryClient();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeCandidateFromFavorites = async (event) => {
    event.stopPropagation();
    try {
      setIsFetching(true);

      if (isJobApplication) {
        await AdminService.removeMyJobApplicationFromFavorites({
          jobApplicationId: candidateId,
        });
      } else {
        await AdminService.removeCandidateFromFavorites({ id: candidateId });
      }

      if (refetch) {
        await refetch();
      } else {
        await refetchWithQueryClient();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div
      className={classes.CandidateBooleanFavorite}
      onClick={
        isFavorite ? removeCandidateFromFavorites : addCandidateToFavorites
      }
    >
      {isFavorite ? (
        <FavoritesIcon size={20} />
      ) : (
        <NonFavoritesIcon size={20} />
      )}
    </div>
  );
}
