import React from 'react';

import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../../Tooltip';

export default function ResumeIcon({ resumeType }) {
  const { t } = useTranslation();

  let content = null;

  if (resumeType === 'Video' || resumeType === 'Both') {
    content = (
      <Tooltip
        text={t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.videoResume'
        )}
        width={120}
        direction="top-left"
      >
        <svg
          width="17"
          height="21"
          viewBox="0 0 17 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.05469 0.578125V5.57812L8.05468 5.63253C8.0546 6.0566 8.05452 6.46873 8.10022 6.80864C8.15174 7.19185 8.27726 7.62912 8.64047 7.99234C9.00369 8.35555 9.44097 8.48108 9.82417 8.5326C10.1641 8.5783 10.5762 8.57822 11.0003 8.57813H11.0003L11.0547 8.57812H16.0547V14.5781C16.0547 17.4066 16.0547 18.8208 15.176 19.6994C14.2973 20.5781 12.8831 20.5781 10.0547 20.5781H6.05469C3.22626 20.5781 1.81205 20.5781 0.933367 19.6994C0.0546875 18.8208 0.0546875 17.4066 0.0546875 14.5781V6.57812C0.0546875 3.7497 0.0546875 2.33548 0.933367 1.4568C1.81205 0.578125 3.22626 0.578125 6.05469 0.578125H8.05469ZM10.0547 0.582746V5.57812C10.0547 6.0778 10.0568 6.35196 10.0824 6.54215L10.0834 6.54943L10.0907 6.55043C10.2809 6.576 10.555 6.57812 11.0547 6.57812H16.0501C16.0399 6.16648 16.0072 5.89408 15.9024 5.64119C15.7502 5.27364 15.4612 4.98461 14.8831 4.40655L12.2263 1.7497C11.6482 1.17164 11.3592 0.882607 10.9916 0.730366C10.7387 0.625614 10.4663 0.592938 10.0547 0.582746Z"
            fill="url(#paint0_linear_0_1)"
          />
          <path
            d="M10.5961 13.2645C10.9094 13.0686 10.9094 12.6123 10.5961 12.4165L6.81969 10.0562C6.48666 9.84811 6.05469 10.0875 6.05469 10.4802V15.2007C6.05469 15.5934 6.48666 15.8328 6.81969 15.6247L10.5961 13.2645Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_0_1"
              x1="8.05469"
              y1="-10.6881"
              x2="-13.3701"
              y2="4.52539"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00E4AC" />
              <stop offset="0.504968" stopColor="#10C5E9" />
              <stop offset="1" stopColor="#9240DC" />
            </linearGradient>
          </defs>
        </svg>
      </Tooltip>
    );
  } else if (resumeType === 'Document') {
    content = (
      <Tooltip
        text={t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.wordResume'
        )}
        width={120}
        direction="top-left"
      >
        <svg
          width="17"
          height="21"
          viewBox="0 0 17 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.05469 0.738281V5.73828L8.05468 5.79269C8.0546 6.21676 8.05452 6.62889 8.10022 6.9688C8.15174 7.352 8.27726 7.78928 8.64047 8.15249C9.00369 8.51571 9.44097 8.64123 9.82417 8.69275C10.1641 8.73845 10.5762 8.73837 11.0003 8.73829H11.0003L11.0547 8.73828H16.0547V14.7383C16.0547 17.5667 16.0547 18.9809 15.176 19.8596C14.2973 20.7383 12.8831 20.7383 10.0547 20.7383H6.05469C3.22626 20.7383 1.81205 20.7383 0.933367 19.8596C0.0546875 18.9809 0.0546875 17.5667 0.0546875 14.7383V6.73828C0.0546875 3.90985 0.0546875 2.49564 0.933367 1.61696C1.81205 0.738281 3.22626 0.738281 6.05469 0.738281H8.05469ZM10.0547 0.742902V5.73828C10.0547 6.23796 10.0568 6.51211 10.0824 6.70231L10.0834 6.70959L10.0907 6.71059C10.2809 6.73616 10.555 6.73828 11.0547 6.73828H16.0501C16.0399 6.32664 16.0072 6.05424 15.9024 5.80134C15.7502 5.4338 15.4612 5.14477 14.8831 4.56671L12.2263 1.90985C11.6482 1.33179 11.3592 1.04276 10.9916 0.890522C10.7387 0.78577 10.4663 0.753095 10.0547 0.742902Z"
            fill="#C1C5D6"
          />
          <path
            d="M5.05469 12.7383L11.0547 12.7383"
            stroke="#F2F4F8"
            strokeLinecap="round"
          />
          <path
            d="M5.05469 15.7383L10.0547 15.7383"
            stroke="#F2F4F8"
            strokeLinecap="round"
          />
        </svg>
      </Tooltip>
    );
  }

  return <div>{content}</div>;
}
