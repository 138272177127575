import { useState, useEffect } from 'react';

export default function useHoverAndClickStatus(ref) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // Reset click status after 1000 ms
  useEffect(() => {
    let timeout;
    if (isClicked) {
      timeout = setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [isClicked]);

  useEffect(() => {
    const handleMouseEnter = () => setIsMouseOver(true);
    const handleMouseLeave = () => setIsMouseOver(false);
    const handleClick = () => setIsClicked(true);

    if (ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseEnter);
      ref.current.addEventListener('mouseleave', handleMouseLeave);
      ref.current.addEventListener('mousedown', handleClick);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', handleMouseEnter);
        ref.current.removeEventListener('mouseleave', handleMouseLeave);
        ref.current.removeEventListener('mousedown', handleClick);
      }
    };
  }, [ref]);

  return {
    isMouseOver,
    isClicked,
  };
}
