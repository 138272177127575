/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Candidate({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToCandidate = (candidateId) => {
      return () => {
        navigate(`/tools/candidates?candidate=${candidateId}`);
        hideNotifications();
      };
    };

    const jobPath = `/tools/jobs/my-jobs/${notification.jobPost?.id}`;
    const goToJob = () => {
      navigate(jobPath);
      hideNotifications();
    };

    const content = {
      CandidateRecommended: {
        title: t(
          'dashboardComponents.Notifications.Notification.Candidate.candidateReco.title',
          {
            candidate: notification.eventNote,
            author: notification.author?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Candidate.candidateReco.buttonLabel'
        ),
        onClick: goToCandidate(notification.messageInfo),
      },
      ExternalCandidateAdded: {
        title: t(
          'dashboardComponents.Notifications.Notification.Candidate.externalCandidateAdded.title',
          {
            candidate: notification.eventNote,
            author: notification.author?.userProfile?.fullName,
            title: notification.jobApplication?.jobPost?.jobTitle,
            jobId: notification.jobApplication?.jobPost?.id,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Candidate.candidateReco.buttonLabel'
        ),
        onClick: goToJob,
      },
      ExternalCandidateAddedWithoutJob: {
        title: t(
          'dashboardComponents.Notifications.Notification.Candidate.externalCandidateAddedWithoutJob.title',
          {
            candidate: notification.eventNote,
            author: notification.author?.userProfile?.fullName,
          }
        ),
        buttonLabel: '',
        onClick: () => {},
      },
    };

    return (
      content[notification.name] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
