import React, { useContext } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import NotificationItem from './NotificationItem';
import Escalation from './Escalation';

import { UiContext } from '../../../../context/UiContext';

import UserService from '../../../../services/UserService';
import classes from './styles.module.scss';

export default function Notification({ user, refetch }) {
  const { setIsFetching } = useContext(UiContext);

  const userNotifications = user?.userNotificationSettings?.sort(
    (a, b) => a.type - b.type
  );

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      newApplication: userNotifications?.[0].isEnabled,
      message: userNotifications?.[1].isEnabled,
      insight: userNotifications?.[2].isEnabled,
      recommendation: userNotifications?.[3].isEnabled,
      newInterview: userNotifications?.[4].isEnabled,
      upcomingInterview: userNotifications?.[5].isEnabled,
      event: userNotifications?.[6].isEnabled,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        await UserService.changeNotificationSettings({
          id: user?.id,
          userNotificationSettings: [
            {
              type: 0,
              isEnabled: values.newApplication,
            },
            {
              type: 1,
              isEnabled: values.message,
            },
            {
              type: 2,
              isEnabled: values.insight,
            },
            {
              type: 3,
              isEnabled: values.recommendation,
            },
            {
              type: 4,
              isEnabled: values.newInterview,
            },
            {
              type: 5,
              isEnabled: values.upcomingInterview,
            },
            {
              type: 6,
              isEnabled: values.event,
            },
          ],
        });

        if (refetch) {
          await refetch();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
  });

  return (
    <div className={classes.Notification}>
      <div className={classes.col}>
        <h3 className={classes.title}>
          {t('dashboardComponents.ProfileSettings.Notification.title')}
        </h3>
        <div className={classes.notifications}>
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.newApplication.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.newApplication.description'
            )}
            value={formik.values.newApplication}
            setValue={() => {
              formik.setFieldValue(
                'newApplication',
                !formik.values.newApplication
              );
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.message.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.message.description'
            )}
            value={formik.values.message}
            setValue={() => {
              formik.setFieldValue('message', !formik.values.message);
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.insight.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.insight.description'
            )}
            value={formik.values.insight}
            setValue={() => {
              formik.setFieldValue('insight', !formik.values.insight);
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.recommendation.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.recommendation.description'
            )}
            value={formik.values.recommendation}
            setValue={() => {
              formik.setFieldValue(
                'recommendation',
                !formik.values.recommendation
              );
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.newInterview.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.newInterview.description'
            )}
            value={formik.values.newInterview}
            setValue={() => {
              formik.setFieldValue('newInterview', !formik.values.newInterview);
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.upcomingInterview.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.upcomingInterview.description'
            )}
            value={formik.values.upcomingInterview}
            setValue={() => {
              formik.setFieldValue(
                'upcomingInterview',
                !formik.values.upcomingInterview
              );
              formik.submitForm();
            }}
          />
          <NotificationItem
            label={t(
              'dashboardComponents.ProfileSettings.Notification.event.label'
            )}
            description={t(
              'dashboardComponents.ProfileSettings.Notification.event.description'
            )}
            value={formik.values.event}
            setValue={() => {
              formik.setFieldValue('event', !formik.values.event);
              formik.submitForm();
            }}
          />
        </div>
      </div>
      <div className={classes.col}>
        <Escalation />
      </div>
    </div>
  );
}
