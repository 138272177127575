import React, { useState } from 'react';

import classNames from 'classnames';

import getResizedImageUrl from '../../../../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';
import { ReactComponent as DeleteIcon } from '../../../../../../../../../assets/images/dashboard/brand-kit/delete.svg';

export default function ImagePreview({ file, deleteFile, small }) {
  const [isHovered, setIsHovered] = useState(null);

  const imageUrl =
    file instanceof File
      ? URL.createObjectURL(file)
      : getResizedImageUrl({
          url: `brand/${file.split('/').pop()}`,
          width: 500,
        });

  return (
    <div
      className={classNames(classes.ImagePreview, {
        [classes.small]: small,
        [classes.hovered]: isHovered === true,
        [classes.notHovered]: isHovered === false,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageUrl} alt="Preview" className={classes.preview} />
      <button
        type="button"
        className={classNames(classes.deleteButton)}
        onClick={deleteFile}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}
