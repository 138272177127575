import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

import assessmentStatusIcon from '../../../../../../../../../assets/images/dashboard/jobs/assessment-status.svg';

export default function AssessmentStatus({ application }) {
  const completedAssessments = application?.assignedAssessments
    ?.filter(
      (assessment) => assessment.status !== 'Assigned' && assessment.status
    )
    .map((assessment) => assessment.status);

  const passedAssessments = completedAssessments?.filter(
    (assessment) => assessment === 'Passed'
  );
  const failedAssessments = completedAssessments?.filter(
    (assessment) => assessment === 'Failed'
  );

  const isVisible =
    application?.status !== 'New' && application?.status !== 'Invited';

  const isExpired =
    new Date(application?.flowQForms?.[0]?.expiresAt) < Date.now();

  return (
    isVisible && (
      <div className={classes.AssessmentStatus}>
        <img src={assessmentStatusIcon} alt="Assessment Status" />
        <div
          className={classNames(classes.container, {
            [classes.expired]: isExpired,
            //  [classes.pending]: true,
            [classes.complete]:
              passedAssessments?.length === completedAssessments?.length &&
              completedAssessments?.length > 0,
            // [classes.black]: completedAssessments?.includes('Failed'),
            [classes.fail]: failedAssessments?.length > 0,
            [classes.allCompleted]:
              passedAssessments?.length ===
              application?.assignedAssessments.length,
          })}
        >
          <span className={classes.leftChar}>
            {completedAssessments?.length}
          </span>
          <span className={classes.rightChar}>
            /{application?.assignedAssessments?.length}
          </span>
        </div>
      </div>
    )
  );
}
