import React from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InnerHeader from '../../components/Auth/InnerHeader';
import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';

import classes from './styles.module.scss';

export default function RequestAccessSuccessPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={classes.RequestAccessSuccessPage}>
      <Helmet>
        <title>{t('pages.RequestAccessSuccessPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          label={t('common.signIn')}
          title={t('pages.RequestAccessSuccessPage.requestSentTitle')}
          onButtonClick={() => navigate('/login')}
        />
        <p>
          {t('pages.RequestAccessSuccessPage.requestSentText1')} <br />
          <br />
          {t('pages.RequestAccessSuccessPage.requestSentText2')}
        </p>
        <ButtonWithHelperText
          noHelper
          onClick={() => navigate('/login')}
          buttonLabel={t('pages.RequestAccessSuccessPage.closeButton')}
        />
      </div>
    </div>
  );
}
