import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CurrencyInput from '../../../components/Form/CurrencyInput';
import TextInput from '../../../components/Form/TextInput';

import useOfferStatus from '../hooks/useOfferStatus';
import classes from './styles.module.scss';

export default function PrevOffer({ candidate, counterOfferMode }) {
  const { t } = useTranslation();

  const { isCountered, offer, isAccepted, recruiterOffers, readOnly } =
    useOfferStatus({
      candidate,
      counterOfferMode,
    });

  if (!isCountered && offer?.statusHistory?.length < 3) {
    return null;
  }

  let label = t('modals.OfferModal.offer.previousOffer');

  const reversedRecruiterOffers = Array.isArray(recruiterOffers)
    ? [...recruiterOffers].reverse()
    : [];

  const prevRecruiterOffer = reversedRecruiterOffers?.find(
    (o) => o.amount !== offer.amount
  );
  const prevRecruiterOfferAmount = prevRecruiterOffer?.amount;

  let value = prevRecruiterOfferAmount;
  let date = offer?.statusHistory?.at(-1)?.createdAt;

  // Offer has been countered by the candidate
  if ((isCountered && counterOfferMode === 'default') || !isAccepted) {
    label = t('modals.OfferModal.offer.ourOffer');

    if (counterOfferMode === 'update' || readOnly) {
      label = t('modals.OfferModal.offer.previousOffer');
    }

    value = readOnly ? reversedRecruiterOffers?.[1]?.amount : offer?.amount;
    date = readOnly ? reversedRecruiterOffers?.[1]?.createdAt : date;
    // Offer with counters has been accepted by the candidate
  } else if (
    isAccepted &&
    offer.status === 'Accepted' &&
    offer.statusHistory.length > 2
  ) {
    label = t('modals.OfferModal.offer.previousOffer');

    value = prevRecruiterOfferAmount;
    date = prevRecruiterOffer?.createdAt;
    // Offer with counters has been declined by the candidate
  } else if (
    isAccepted &&
    offer.status === 'Accepted' &&
    offer.statusHistory.length > 2
  ) {
    label = t('modals.OfferModal.offer.previousOffer');
    date = prevRecruiterOffer?.createdAt;
  } else if (isAccepted && offer.status === 'Declined') {
    label = t('modals.OfferModal.offer.previousOffer');
    const prevOffer = recruiterOffers.at(-2);
    value = prevOffer?.amount;
    date = prevOffer?.createdAt;
  }

  if (!value) {
    return null;
  }

  return (
    <div
      className={classNames(classes.PrevOffer, {
        [classes.decline]: counterOfferMode === 'decline',
      })}
    >
      {counterOfferMode !== 'decline' ? (
        <>
          <div className={classes.dropdownContainer}>
            <h4 className={classNames(classes.dropdownTitle, classes.short)}>
              {label}{' '}
              {date && (
                <span className={classes.note}>
                  (On {moment(date).format('DD MMM YYYY')})
                </span>
              )}
            </h4>
            <TextInput
              readonly
              value={offer?.currency}
              height={48}
              width={95}
            />
          </div>
          <CurrencyInput height={48} readonly value={value} />
        </>
      ) : (
        <div className={classes.decline}>
          {t('modals.OfferModal.offer.declineCounterOffer')}
        </div>
      )}
    </div>
  );
}
