import React, { useContext } from 'react';

import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';

import AdminService from '../../../../../../services/AdminService';
import { UiContext } from '../../../../../../context/UiContext';
import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import classes from './styles.module.scss';

const RightButton = (props) => {
  const { t } = useTranslation();
  return (
    <button type="button" className={classes.deactivateButton} {...props}>
      {t(
        'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.DeactivateUserConfirmationTitle'
      )}
    </button>
  );
};

export default function ViewBlocking({
  isVisible,
  handleClose,
  currentDataId,
  data,
  dataName,
}) {
  const queryClient = useQueryClient();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(data, currentDataId);

  const { showModal, setIsFetching, showUnknownErrorModal } =
    useContext(UiContext);

  const { t } = useTranslation();

  const clearIncident = async () => {
    try {
      setIsFetching(true);
      await AdminService.clearUserReport({
        id: currentDataId,
        reportData: { ...data[currentDataIndex] },
      });
      showModal({
        title: t(
          'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.Success'
        ),
        text: t(
          'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.ChangesSavedSuccessfully'
        ),
      });
      queryClient.invalidateQueries({ queryKey: [dataName] });
      handleClose();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const deactivateUser = () => {
    const handler = async () => {
      try {
        setIsFetching(true);

        await AdminService.blockUser({
          userId: data[currentDataIndex]?.user?.id,
        });

        queryClient.invalidateQueries({ queryKey: [dataName] });
        handleClose();
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    };

    showModal({
      title: t(
        'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.DeactivateUserConfirmationTitle'
      ),
      text: t(
        'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.DeactivateUserConfirmationText'
      ),
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
      onConfirm: handler,
      onCancel: () => {},
    });
  };

  return (
    <SidePopup
      mode="reviewIncident"
      isVisible={isVisible}
      handleClose={handleClose}
      title="Review Incident"
      switchNext={data?.length > 1 && switchToNextDataElement}
      switchPrev={data?.length > 1 && switchToPrevDataElement}
      leftButtonLabel={t(
        'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.ClearIncident'
      )}
      rightButtonLabel={t(
        'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.DeactivateUser'
      )}
      isRightButtonDisabled={data?.[currentDataIndex]?.user.isBlocked}
      onRightButtonClick={deactivateUser}
      onLeftButtonClick={clearIncident}
      rightButtonComponent={RightButton}
      isLeftButtonDisabled={data?.[currentDataIndex]?.isCleared}
    >
      <div className={classes.ViewBlocking}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              label={t(
                'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.UserName'
              )}
              readonly
              value={data?.[currentDataIndex]?.user?.userProfile?.fullName}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              height={50}
              label="User ID"
              readonly
              value={data?.[currentDataIndex]?.user?.id}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              label="Type"
              readonly
              value={t(
                'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.Reported'
              )}
            />
          </div>
          <div className={classes.col} />
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              label={t(
                'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.Reason'
              )}
              readonly
              value={data?.[currentDataIndex]?.reason}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              label={t(
                'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.ReportedBy'
              )}
              readonly
              value={data?.[currentDataIndex]?.createdBy?.userProfile?.fullName}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              height={50}
              label={t(
                'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.ReportedOn'
              )}
              readonly
              value={moment(data?.[currentDataIndex]?.createdAt).format(
                'DD-MMM-YYYY'
              )}
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
