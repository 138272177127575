/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { createPortal } from 'react-dom';

import QuoteInput from './TextBox/QuoteInput';
import Addressees from './Addressees';
import Title from './Title';
import TextBox from './TextBox';
import Toolbar from './Toolbar';
import Tags from './Tags';
import Files from './Files';

import { useFeedStore } from '../../../../../store';
import getResizedImageUrl from '../../../../../helpers/getResizedImageUrl';
import fileIcons from '../../../../../constants/fileIcons';
import classes from './styles.module.scss';

const CHAR_LIMIT = 5000;

export default function TextEditor({
  message,
  setMessage,
  title,
  setTitle,
  addressees,
  setAddressees,
  tags,
  setTags,
  sharedClasses,
  textBoxRef,
  files,
  setFiles,
  setUploadedFiles,
  uploadedFiles,
  fileNames,
  setFileNames,
}) {
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [isFileUploaderVisible, setIsFileUploaderVisible] = useState(false);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [isEditable, setIsEditable] = useState(true);

  const addTagButtonRef = useRef();

  const lastTextBoxRange = useFeedStore((state) => state.lastTextBoxRange);
  const setLastTextBoxRange = useFeedStore(
    (state) => state.setLastTextBoxRange
  );
  const quoteContainers = useFeedStore((state) => state.quoteContainers);
  const addQuoteContainer = useFeedStore((state) => state.addQuoteContainer);

  useEffect(() => {
    if (!isTitleVisible) {
      setTitle('');
    }
  }, [isTitleVisible, setTitle]);

  const addQuote = () => {
    setIsEditable(false);

    const quoteC = document.createElement('div');
    quoteC.classList.add('quoteContainer');
    const containerClass = `c${Math.random().toString(36).slice(2, 7)}`;
    quoteC.classList.add(containerClass);

    textBoxRef.current.appendChild(quoteC);

    addQuoteContainer({
      containerClass,
      quote: '',
    });

    const emptySpan = document.createElement('span');
    emptySpan.innerHTML = '&#8203;';

    quoteC.after(emptySpan);
  };

  const removeUserFromAddressessWhenRemovedFromText = useCallback(() => {
    const removedUserIds = [];
    mentionedUserIds.forEach((userId) => {
      const mentionedUserElement = textBoxRef.current.querySelector(
        `[data-userid="${userId}"]`
      );

      if (!mentionedUserElement || !mentionedUserElement.innerText.trim()) {
        removedUserIds.push(userId);
      }
    });

    if (removedUserIds.length) {
      setAddressees((prevState) =>
        prevState.filter((user) => !removedUserIds.includes(user.value))
      );
      setMentionedUserIds((prevState) =>
        prevState.filter((id) => !removedUserIds.includes(id))
      );
    }
  }, [mentionedUserIds, setAddressees, textBoxRef]);

  const mentionUser = useCallback(
    (userName, userId) => {
      const nnode = document.createElement('span');
      nnode.contentEditable = false;

      if (
        !lastTextBoxRange ||
        !textBoxRef.current.contains(lastTextBoxRange.startContainer)
      ) {
        const newRange = new Range();
        newRange.setStart(textBoxRef.current, 0);
        newRange.setEnd(textBoxRef.current, 0);
        newRange.surroundContents(nnode);
      } else {
        lastTextBoxRange.surroundContents(nnode);
      }

      nnode.innerHTML = `<span data-userid="${userId}" 
        style="color:#00E4AC;">${` ${userName}`}</span>`;

      const newRange = new Range();
      newRange.setStartAfter(nnode, 0);
      setLastTextBoxRange(newRange);

      setMentionedUserIds((prevState) => [...prevState, userId]);
    },
    [lastTextBoxRange, setLastTextBoxRange, textBoxRef]
  );

  const createFile = useCallback((attachment, fileName) => {
    const fileExtension = attachment.displayName.split('.').pop();
    const isImage = attachment.mimeType.includes('image');
    const url = isImage
      ? getResizedImageUrl({
          url: `feed/${attachment.fileName}`,
          width: 900,
        })
      : attachment.uri;

    return `<a href=${url} data-type="fileAttachment" target="_blank"
    style="display: inline-flex; align-items: center; text-decoration: none;">
        <img src=${fileIcons[fileExtension]} alt="File" style="width: 21px;
        margin-right: 14px;" />
        <h4 style="
        margin: 0;
        margin-right: 10px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 150%;
        color: #242833;
        ">${fileName}</h4>
        <div style="display: flex;
        align-items: center;
        gap: 10px;">
        </div>
      </a>`;
  }, []);

  const insertFile = useCallback(
    (file, fileName) => {
      const uploadedFile = uploadedFiles.find(
        (fl) => fl.fileSize === file.size
      );

      const fileHtml = createFile(uploadedFile, fileName);

      const fileContainer = document.createElement('span');
      fileContainer.contentEditable = false;
      textBoxRef.current.appendChild(fileContainer);

      const emptySpan = document.createElement('span');
      emptySpan.innerHTML = '&#8203;';
      fileContainer.after(emptySpan);

      fileContainer.innerHTML = fileHtml;
    },
    [createFile, textBoxRef, uploadedFiles]
  );

  return (
    <div className={classes.TextEditor}>
      {isTitleVisible && (
        <Title
          title={title}
          setTitle={setTitle}
          hideTitle={() => setIsTitleVisible(false)}
        />
      )}
      <TextBox
        message={message}
        setMessage={setMessage}
        maxLength={CHAR_LIMIT}
        addressees={addressees}
        setAddressees={setAddressees}
        textBoxRef={textBoxRef}
        mentionUser={mentionUser}
        sharedClasses={sharedClasses}
        mentionedUserIds={mentionedUserIds}
        removeUserFromAddressessWhenRemovedFromText={
          removeUserFromAddressessWhenRemovedFromText
        }
        isEditable={isEditable}
      />
      <Toolbar
        messageLength={textBoxRef.current?.innerText.trim().length || 0}
        setIsTitleVisible={setIsTitleVisible}
        maxLength={CHAR_LIMIT}
        mentionUser={mentionUser}
        addressees={addressees}
        setAddressees={setAddressees}
        setIsAddingTag={setIsAddingTag}
        addTagButtonRef={addTagButtonRef}
        files={files}
        setIsFileUploaderVisible={setIsFileUploaderVisible}
        addQuote={addQuote}
      />
      {isFileUploaderVisible || files?.length ? (
        <Files
          fileNames={fileNames}
          setFileNames={setFileNames}
          files={files}
          setFiles={setFiles}
          setUploadedFiles={setUploadedFiles}
          insertFile={insertFile}
        />
      ) : null}
      {isAddingTag || tags?.length > 0 ? (
        <Tags
          tags={tags}
          setTags={setTags}
          isAddingTag={isAddingTag}
          setIsAddingTag={setIsAddingTag}
          addTagButtonRef={addTagButtonRef}
        />
      ) : null}
      <Addressees addressees={addressees} setAddressees={setAddressees} />
      {quoteContainers.map((quoteContainer) => {
        const container = document.body.querySelector(
          `.${quoteContainer.containerClass}`
        );

        if (!container) {
          return null;
        }

        return createPortal(
          <QuoteInput
            setIsEditable={setIsEditable}
            quote={quoteContainer.quote}
          />,
          document.body.querySelector(`.${quoteContainer.containerClass}`)
        );
      })}
    </div>
  );
}
