import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { getTimezoneDate } from '../../../../../helpers/timezones';

export default function Template({
  deleteNotification,
  isRead,
  notification,
  notificationContent,
  classes,
  userTimezone,
}) {
  return (
    <div
      onClick={() => {
        if (!notificationContent?.buttonLabel && notificationContent?.onClick) {
          notificationContent?.onClick();
        }
      }}
      className={classNames(classes.Notification, {
        [classes.read]: isRead,
      })}
    >
      {isRead ? (
        <i
          className={classNames(classes.icon, classes.delete)}
          onClick={deleteNotification}
        >
          Delete
        </i>
      ) : (
        <i className={classes.icon}>Unread</i>
      )}
      <h4>{notificationContent?.title}</h4>
      <div className={classes.content}>
        {notificationContent?.details && (
          <div className={classes.detailsContainer}>
            <span className={classes.details}>
              {notificationContent?.details}
            </span>
          </div>
        )}
        <span className={classes.text}>{notificationContent?.text}</span>{' '}
        <span className={classes.timeAgo}>
          {moment(
            getTimezoneDate(notification.createdAt, userTimezone)[0]
          ).format('Do MMM YYYY hh:mm A')}
        </span>
      </div>

      {notificationContent?.buttonLabel && (
        <div className={classes.buttons}>
          <button
            className={classes.mainButton}
            type="button"
            onClick={notificationContent?.onClick}
          >
            {notificationContent?.buttonLabel}
          </button>
        </div>
      )}
    </div>
  );
}
