import React from 'react';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

import { useUiContext } from '../../../../../context/UiContext';

export default function VrQuestions({ setItemCount }) {
  const { t } = useTranslation();

  const { showModal } = useUiContext();

  const showLimitExceededModal = (error) => {
    if (error?.response?.data?.message === 'Limit reached') {
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.VrQuestions.limitExceeded'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.VrQuestions.limitExceededMessage'
        ),
        dismissButtonLabel: t('common.OK'),
        dismissButtonVariant: 'modal',
      });
      return true;
    }

    return false;
  };

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestionTitle'
      ),
      field: 'title',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestion'
      ),
      field: 'question',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.VrQuestions.Default'),
      field: 'isDefault',
      type: 'boolean',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.VrQuestions.Duration'),
      field: 'duration',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.VrQuestions.SortOrder'
      ),
      field: 'sortOrder',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.VrQuestions.Notes'),
      field: 'note',
      sortable: false,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      onCreateError={showLimitExceededModal}
      onUpdateError={showLimitExceededModal}
      setItemCount={setItemCount}
      dataName={t(
        'dashboardComponents.Console.JobManagement.VrQuestions.VideoResumeQuestion'
      )}
      getDataHandler={AdminService.getVrQuestions}
      deleteDataHandler={AdminService.deleteVrQuestion}
      createDataHandler={AdminService.createVrQuestion}
      updateDataHandler={AdminService.updateVrQuestion}
      viewLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestionTitle'
          ),
          field: 'title',
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestion'
          ),
          field: 'question',
          fullWidth: true,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Duration'
          ),
          field: 'duration',
          width: 139,
          centerText: true,
          note: 'in seconds',
        },
      ]}
      viewRightColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.SortOrder'
          ),
          field: 'sortOrder',
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Default'
          ),
          type: 'switch',
          field: 'isDefault',
          defaultValue: false,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Notes'
          ),
          field: 'note',
          type: 'textarea',
        },
      ]}
      formLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestionTitle'
          ),
          field: 'title',
          // validation: ['string', 'required'],
          validation: yup.string().required(),
          placeholder: 'VR Question Title here ',
          maxLength: 25,
          warning: (value) =>
            value.length && value.length < 5 ? 'Title too short' : null,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.VRQuestion'
          ),
          field: 'question',
          validation: ['string', 'required'],
          placeholder: 'VR Question here',
          maxLength: 75,
          fullWidth: true,
          warning: (value) =>
            value.length && value.length < 5 ? 'Question too short' : null,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Duration'
          ),
          field: 'duration',
          //  validation: ['string', 'required', 'numbers'],
          validation: yup.number().max(90, 'Duration too high'),
          warning: (value) =>
            value > 90 && value <= 120 ? 'Duration not optimal' : null,
          placeholder: 'Duration here ',
          type: 'number',
          defaultValue: '30',
          note: 'in seconds',
          minValue: '5',
        },
      ]}
      formRightColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.SortOrder'
          ),
          field: 'sortOrder',
          validation: ['string', 'required', 'numbers'],
          //  validation: yup.string(),
          placeholder: 'Sort Order here ',
          maxLength: 5,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Default'
          ),
          type: 'switch',
          field: 'isDefault',
          defaultValue: false,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.VrQuestions.Notes'
          ),
          field: 'note',
          // validation: ['string'],
          validation: yup.string(),
          placeholder: 'Notes here ',
          type: 'textarea',
        },
      ]}
      columns={columns}
    />
  );
}
