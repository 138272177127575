/* eslint-disable no-shadow */
import React from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

import { convertTo12HourFormat, addOneHour } from '../../../../../helpers/time';
import {
  getTimezoneDate,
  getTimezoneTime,
} from '../../../../../helpers/timezones';

const contactRecruiter = (email) => {
  const mailtoLink = `mailto:${email}`;

  const tempLink = document.createElement('a');
  tempLink.href = mailtoLink;
  document.body.appendChild(tempLink);

  tempLink.click();

  document.body.removeChild(tempLink);
};

export default function Onboarding({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createFeedbackNotificationContent = (notification) => {
    const feedbackRating =
      notification.jobApplication?.jobApplicationUserFeedbacks?.[0]
        ?.feedbackRating;

    return feedbackRating > 3
      ? {
          title: `${t(
            'dashboardComponents.Notifications.Notification.Onboarding.feedbackReceived'
          )}: ${notification.jobApplication?.user?.userProfile?.fullName}`,
          text: `${t(
            'dashboardComponents.Notifications.Notification.Onboarding.proposed'
          )} ${moment(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedOnboardingDate,
              userTimezone,
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime
            )[0]
          ).format('Do MMM YYYY')} ${convertTo12HourFormat(
            getTimezoneTime(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime,
              userTimezone
            )
          )} - ${addOneHour(
            getTimezoneTime(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime,
              userTimezone
            )
          )}`,
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Onboarding.review'
          ),
        }
      : {};
  };

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const goToJoining = (date) => {
      navigate(
        `/tools/calendar?date=${date}&event=Joining&id=${notification.jobApplication?.jobApplicationJoinings[0]?.id}`
      );
      hideNotifications();
    };

    const goToOnboarding = (date) => {
      navigate(
        `/tools/calendar?date=${date}&event=Onboarding&id=${notification.jobApplication?.jobApplicationOnboardings[0]?.id}`
      );
      hideNotifications();
    };

    const content = {
      ApplicationPendingOnboarding: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      ApplicationPendingJoining: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      JoiningInvite: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningInvite.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningInvite.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningInvite.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningRescheduled: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningRescheduled.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningRescheduled.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningRescheduled.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningChangeRequested: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningChangeRequested.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.suggestedJoiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.suggestedJoiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.suggestedJoiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningChangeRequested.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningChangeRequested.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.suggestedJoiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      Joined: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joined.title',
          {
            name: notification.jobApplication?.user?.userProfile?.fullName,
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joined.buttonLabel'
        ),
        onClick: goToJob,
      },
      OnboardingInvite: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingInvite.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingInvite.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingInvite.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingRescheduled: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingRescheduled.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingRescheduled.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingRescheduled.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingChangeRequested: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingChangeRequested.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.suggestedOnboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.suggestedOnboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.suggestedOnboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingChangeRequested.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingChangeRequested.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedOnboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedCandidate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedCandidate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedCandidate.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingAcceptedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAcceptedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingDeclined: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedCandidate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedCandidate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedCandidate.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      OnboardingDeclinedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone,
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationOnboardings?.[0]
                  ?.onboardingDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingDeclinedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToOnboarding(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.onboardingDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedCandidate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedCandidate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedCandidate.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningAcceptedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningAcceptedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningDeclined: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedCandidate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedCandidate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedCandidate.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      JoiningDeclinedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone,
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationJoinings?.[0]
                  ?.joiningDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningDeclinedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToJoining(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationJoinings?.[0]
                ?.joiningDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
      'Application pending onboarding more than 5 days': {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.applicationPendingOnboarding'
        ),
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.review'
        ),
        onClick: goToJob,
      },
      NewFeedback: createFeedbackNotificationContent(notification),
      ApplicationJoined: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.candidateJoined'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
      },
      JoiningNoShow: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.joiningNoShow'
        )}: ${notification.jobApplication?.user?.userProfile?.fullName}`,
        text: `${notification.jobApplication?.jobPost?.jobTitle} (${notification.jobApplication?.jobPost?.id})`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.Contact Recruiter'
        ),
        onClick: () =>
          contactRecruiter(
            notification.jobApplication?.jobPost?.user?.userProfile?.email || ''
          ),
      },
    };

    return (
      content[notification.eventNote] ||
      content[notification.name] || {
        title: notification.eventNote,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: 'Review',
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
