/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

export default function Button({
  children,
  variant,
  width,
  height,
  onClick,
  disabled,
  type,
  onMouseEnter,
  onMouseLeave,
  style,
  className,
  isAnimation,
}) {
  const [mouseOver, setIsMouseOver] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // Reset click state after animation
  useEffect(() => {
    if (isClicked) {
      const timeout = setTimeout(() => {
        setIsClicked(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [isClicked]);

  return (
    <button
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={(event) => {
        onClick?.(event);
        setIsClicked(true);
      }}
      style={{ ...style, width, height, minHeight: height }}
      className={classNames(classes.Button, className, {
        [classes.secondary]: variant === 'secondary',
        [classes.delete]: variant === 'delete',
        [classes.modal]: variant === 'modal',
        [classes.report]: variant === 'report',
        [classes.print]: variant === 'print',
        [classes.black]: variant === 'black',
        [classes.gradient]: variant === 'gradient',
        [classes.setWidth]: !!width,
        [classes.animate]: isAnimation,
        [classes.clicked]: isClicked,
      })}
      type={type || 'button'}
      disabled={disabled}
    >
      <div
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        className={classNames(classes.wrapper, {
          [classes.mouveOverButton]: mouseOver && isAnimation,
          [classes.mouveLeaveButton]: !mouseOver && isAnimation,
        })}
      >
        {children}
      </div>
    </button>
  );
}
