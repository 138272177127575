import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Button({ page, isActive, onClick }) {
  return (
    <button
      onClick={onClick}
      className={classNames(classes.Button, {
        [classes.active]: isActive,
      })}
      type="button"
      disabled={isActive}
    >
      {page}
    </button>
  );
}
