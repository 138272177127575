import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import ChatService from '../../../../../services/ChatService';
import classes from './styles.module.scss';
import getResizedImageUrl from '../../../../../helpers/getResizedImageUrl';

export default function MessagesAndFeedback() {
  const [contacts, setContacts] = useState([]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: chatThreads } = useQuery({
    queryKey: ['chatThreads'],
    queryFn: () =>
      ChatService.getThreads({
        orderBy: 'Recent',
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const contacts = [];
    chatThreads?.every((group) => {
      contacts.push(...group.threads);

      if (contacts.length < 5) {
        return true;
      }
      return false;
    });

    setContacts(
      contacts
        .sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate))
        .slice(0, 5)
    );
  }, [chatThreads]);

  return (
    <div className={classes.MessagesAndFeedback}>
      <div className={classes.header}>
        <h3>
          {t(
            'dashboardComponents.Dashboard.RightPanel.MessagesAndFeedback.header.title'
          )}
        </h3>
        <button
          type="button"
          onClick={() => {
            navigate('/tools/chat');
          }}
        >
          {t(
            'dashboardComponents.Dashboard.RightPanel.MessagesAndFeedback.header.seeAllButtonLabel'
          )}
        </button>
      </div>
      {!chatThreads?.length ? (
        <div className={classes.noData}>
          <p>
            {t(
              'dashboardComponents.Dashboard.RightPanel.MessagesAndFeedback.noDataMessage'
            )}
          </p>
        </div>
      ) : (
        <div className={classes.users}>
          {contacts.map((contact) => (
            <div
              className={classes.user}
              key={contact.jobApplicationId}
              onClick={() =>
                navigate(
                  `/tools/chat?jobApplicationId=${contact.jobApplicationId}`
                )
              }
            >
              {contact.user.userProfile.avatar ? (
                <img
                  src={getResizedImageUrl({
                    url: `avatar/${contact.user.userProfile.avatar}`,
                    width: 200,
                  })}
                  alt="User"
                />
              ) : (
                <div className={classes.noAvatar}>
                  {contact.user.userProfile.firstName[0]}{' '}
                  {contact.user.userProfile.lastName[0]}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
