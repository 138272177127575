/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
import { useReactToPrint } from 'react-to-print';
import Icons from '../Icons';
import classes from './styles.module.scss';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import AddCandidateMagnifier from './AddCandidateMagnifier';

import useResizeObserver from '../../../../../hooks/useResizeObserver';

function highlightPattern(text, pattern) {
  if (!pattern) {
    return text; // Return original text if pattern is null or undefined
  }

  // Create a RegExp object with the 'i' flag for case-insensitive matching
  const regExp = new RegExp(pattern, 'gi');
  return text.replace(regExp, (value) => `<mark>${value}</mark>`);
}

export default function Pdf({
  isModal,

  resume,
  addCandidateModal,
  deleteResume,
  uploadNewResume,
  formik,
  resumeFieldName,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
  openResumeInput,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isIncreased, setIsIncreased] = useState(false);
  // const [position, setPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const inputRef = useRef(null);
  const pdfRef = useRef(null);
  const miniScrollWindow = useRef(null);
  const mainResumeRef = useRef(null);

  const { width: mainResumeWidth } = useResizeObserver(mainResumeRef);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const source = resume?.ur ? resume?.ur : resume;
  const url = resume?.url;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages: pages }) => {
    setNumPages(pages);
  };

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: `@media print {
      @page {
        size: A4;
        margin: 0;
      }

      .react-pdf__Page__canvas {
        margin: 0 auto !important;

        page-break-before: always;
      }
    }`,
  });

  const handleChange = (event) => {
    setIsFocused(true);
    setIsOpened(!isOpened);

    if (event.target.checked) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const textRenderer = useCallback(
    (textItem) => {
      if (!searchText.length) {
        return highlightPattern(textItem.str, null);
      }

      return highlightPattern(textItem.str, searchText);
    },
    [searchText]
  );

  function onChange(event) {
    setSearchText(event.target.value);
  }

  let tempStartValue = 85;

  const pages = Array.from(Array(numPages)?.keys()).map(() => {
    const start = tempStartValue;
    tempStartValue += 233 + 28;
    return {
      start,
      end: start + 233,
    };
  });

  const scrollMainResume = (value) => {
    if (!mainResumeRef?.current) {
      return;
    }

    if (Number.isNaN(value) || value >= 0) {
      mainResumeRef.current.scrollTop = 0;
      return;
    }
    const valuePercent = Math.floor((Math.abs(value) / 233) * 100);
    mainResumeRef.current.scrollTop = (545 / 100) * valuePercent;
  };

  const handleClickOnMagnifier = (magnifierRef, customPos) => {
    const pos =
      customPos ||
      miniScrollWindow?.current?.getBoundingClientRect().y -
        magnifierRef.current?.getBoundingClientRect().y;
    const scrollValue =
      Math.abs(pos) + 85 + miniScrollWindow?.current?.scrollTop;

    if (scrollPosition === scrollValue) {
      setIsIncreased(!isIncreased);
    } else {
      setScrollPosition(scrollValue);
      setIsIncreased(false);
    }

    const pageIndex = pages.findIndex(
      (page) => page?.start - 75 <= scrollValue && page?.end - 46 >= scrollValue
    );

    if (pageIndex !== -1) {
      setPageNumber(pageIndex + 1);
    }

    scrollMainResume(pages[pageIndex]?.start - scrollValue);
  };

  useEffect(() => {
    if (isIncreased) {
      const { scrollTop } = mainResumeRef.current;
      mainResumeRef.current.scrollLeft = 300;

      mainResumeRef.current.scrollBy({
        top: scrollTop * 4,
      });
    }
  }, [isIncreased]);

  const handleMouseScrollDown = (event) => {
    if (!isIncreased) {
      return;
    }

    event.preventDefault();
    const startX = event.clientX + mainResumeRef.current.scrollLeft;
    const startY = event.clientY + mainResumeRef.current.scrollTop;

    const handleMouseScrollMove = (e) => {
      e.preventDefault();
      mainResumeRef.current.scrollLeft = startX - e.clientX;
      mainResumeRef.current.scrollTop = startY - e.clientY;
    };

    const handleMouseScrollUp = () => {
      document.removeEventListener('mousemove', handleMouseScrollMove);
      document.removeEventListener('mouseup', handleMouseScrollUp);
    };

    document.addEventListener('mousemove', handleMouseScrollMove);
    document.addEventListener('mouseup', handleMouseScrollUp);
  };

  const mainWindowScale = isIncreased ? 2.16 : 1.08;
  const mainWindowWidth = isTabletOrMobile ? mainResumeWidth : '';
  const mainContainerWidth = isModal && '670px';
  const mainContainerHeight = isModal && '496px';

  return (
    <div
      className={classNames(classes.Pdf, {
        [classes.addCandidateModal]: addCandidateModal,
        [classes.hasResume]: !!source,
      })}
    >
      {!isTabletOrMobile && (
        <AddCandidateMagnifier
          handleClickOnMagnifier={handleClickOnMagnifier}
        />
      )}
      <div
        className={classes.resumes}
        ref={miniScrollWindow}
        style={{ height: isModal && '496px' }}
      >
        {source && (
          <Document
            ref={pdfRef}
            file={source}
            className={classes.resume}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {[...Array(numPages)].map((u, page) => (
              <Page
                pageNumber={page + 1}
                renderTextLayer={false}
                renderMode="canvas"
                renderAnnotationLayer={false}
                scale={1.8}
                height={600}
                className={classes.page}
                onClick={() => {
                  mainResumeRef.current.scrollTop = 1000;
                }}
                key={page}
              />
            ))}
          </Document>
        )}
      </div>

      {source && (
        <>
          <div
            ref={mainResumeRef}
            className={classes.mainResumeContainer}
            style={{
              height: isTabletOrMobile ? '' : mainContainerHeight,
              width: isTabletOrMobile ? '' : mainContainerWidth,
              cursor: isIncreased ? 'move' : 'auto',
            }}
            onMouseDown={handleMouseScrollDown}
          >
            <Document
              file={source}
              className={classes.mainResume}
              externalLinkTarget="_blank"
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer
                renderMode="svg"
                renderAnnotationLayer
                scale={isTabletOrMobile ? 1 : mainWindowScale}
                width={mainWindowWidth}
                className={classes.page}
                customTextRenderer={textRenderer}
              />
            </Document>
          </div>
          <Icons
            isPossibleToEdit={isPossibleToEdit}
            isOpened={isOpened}
            handleChange={handleChange}
            inputRef={inputRef}
            searchText={searchText}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onChange}
            setIsFocused={setIsFocused}
            setIsOpened={setIsOpened}
            url={url}
            handlePrint={handlePrint}
            setSearchText={setSearchText}
            isFocused={isFocused}
            addCandidateModal={addCandidateModal}
            deleteResume={deleteResume}
            uploadNewResume={uploadNewResume}
            formik={formik}
            resumeFieldName={resumeFieldName}
            isReadOnly={isReadOnly}
            isEdit={isEdit}
            openResumeInput={openResumeInput}
          />
        </>
      )}
    </div>
  );
}
