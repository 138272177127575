/* eslint-disable react/no-array-index-key */
import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Controls({ length, currentIndex, setCurrentIndex }) {
  if (length === 1) {
    return null;
  }

  return (
    <div className={classes.Controls}>
      {Array.from({ length }).map((_, index) => (
        <div
          onClick={() => setCurrentIndex(index)}
          key={index}
          className={classNames(classes.control, {
            [classes.active]: index === currentIndex,
          })}
        />
      ))}
    </div>
  );
}
