import React, { useEffect, useState, useContext } from 'react';

import AdminService from '../../../../../services/AdminService';

import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function FavStatus({
  candidate,
  job,
  calendarEvent,
  isCandidateFavorite,
  refetch,
  noRefetch,
  userId,
  big,
  white,
  width,
}) {
  const [isJobPostSuccessFav, setIsJobSuccessFav] = useState(false);
  const [gradientIds, setGradientIds] = useState([]);

  // Set gradient ids
  useEffect(() => {
    const ids = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 2; i++) {
      ids.push(`favoriteGradientId${i}-${Math.random()}`);
    }

    setGradientIds(ids);
  }, []);

  const { setIsFetching } = useContext(UiContext);

  const isCandidateFav = candidate?.isFavorite || isCandidateFavorite;
  const isJobFav =
    job?.isFavorite ||
    job?.jobPostFavorites?.find((fav) => fav?.userId === +userId);
  const isEventFav = calendarEvent?.event?.eventFavorites?.find(
    (fav) => fav.userId === userId
  );

  const addCandidateToFavorites = async () => {
    try {
      setIsFetching(true);
      await AdminService.addCandidateToFavorites({
        id: candidate?.id || candidate?.userId,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeCandidateFromFavorites = async () => {
    try {
      setIsFetching(true);
      await AdminService.removeCandidateFromFavorites({
        id: candidate?.id || candidate?.userId,
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const addJobToFavorites = async () => {
    try {
      setIsFetching(true);
      await AdminService.addMyJobToFavorites({
        jobId: job.id || job?.jobPostId,
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (noRefetch) {
        setIsJobSuccessFav(true);
      }
      setIsFetching(false);
    }
  };

  const removeJobFromFavorites = async () => {
    try {
      setIsFetching(true);
      await AdminService.removeMyJobFromFavorites({
        jobId: job.id || job?.jobPostId,
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (noRefetch) {
        setIsJobSuccessFav(false);
      }
      setIsFetching(false);
    }
  };

  const addEventToFavorites = async () => {
    try {
      setIsFetching(true);
      if (calendarEvent.type === 'Interview') {
        await AdminService.addJobApplicationInterviewToFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          interviewId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
      if (calendarEvent.type === 'Onboarding') {
        await AdminService.addJobApplicationOnboardingToFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          onboardingId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
      if (calendarEvent.type === 'Joining') {
        await AdminService.addJobApplicationStartDateToFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          joiningId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeEventFromFavorites = async () => {
    try {
      setIsFetching(true);
      if (calendarEvent.type === 'Interview') {
        await AdminService.deleteJobApplicationInterviewFromFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          interviewId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
      if (calendarEvent.type === 'Onboarding') {
        await AdminService.deleteJobApplicationOnboardingFromFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          onboardingId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
      if (calendarEvent.type === 'Joining') {
        await AdminService.deleteJobApplicationStartDateFromFavorites({
          jobApplicationId: calendarEvent.jobApplicationId,
          joiningId: calendarEvent.id,
        });
        if (refetch) {
          await refetch();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const toggleCandidateFav = () => {
    if (isCandidateFav) {
      removeCandidateFromFavorites();

      return;
    }

    addCandidateToFavorites();
  };

  const toggleJobFav = () => {
    if (isJobFav || isJobPostSuccessFav) {
      removeJobFromFavorites();

      return;
    }

    addJobToFavorites();
  };

  const toggleCalendarFav = () => {
    if (isEventFav) {
      removeEventFromFavorites();

      return;
    }

    addEventToFavorites();
  };

  const favoriteIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <circle cx="10" cy="10" r="10" fill={`url(#${gradientIds[0]})`} />
      <path
        d="M7.84479 13.8138C7.63031 13.9266 7.37964 13.7444 7.4206 13.5056L7.8322 11.1058L6.08861 9.40618C5.91509 9.23704 6.01084 8.94235 6.25064 8.9075L8.66023 8.55737L9.73783 6.37391C9.84507 6.15661 10.1549 6.15661 10.2622 6.37391L11.3398 8.55737L13.7494 8.9075C13.9892 8.94235 14.0849 9.23704 13.9114 9.40618L12.1678 11.1058L12.5794 13.5056C12.6204 13.7444 12.3697 13.9266 12.1552 13.8138L10 12.6808L7.84479 13.8138Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="10"
          y1="-11.2662"
          x2="-12.5324"
          y2="8.73379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4AC" />
          <stop offset="0.504968" stopColor="#10C5E9" />
          <stop offset="1" stopColor="#9240DC" />
        </linearGradient>
      </defs>
    </svg>
  );

  const nonFavoriteIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <circle cx="15" cy="15" r="15" fill="#242833" fillOpacity="0.5" />
      <path
        d="M11.7672 20.7207C11.4454 20.8899 11.0694 20.6167 11.1309 20.2584L11.7483 16.6586L9.13288 14.1093C8.8726 13.8556 9.01623 13.4135 9.37592 13.3613L12.9903 12.8361L14.6067 9.56086C14.7676 9.23492 15.2324 9.23492 15.3932 9.56086L17.0096 12.8361L20.624 13.3613C20.9837 13.4135 21.1273 13.8556 20.8671 14.1093L18.2517 16.6586L18.8691 20.2584C18.9305 20.6167 18.5545 20.8899 18.2328 20.7207L15 19.0211L11.7672 20.7207Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );

  const bigFavoriteIcon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <circle cx="10" cy="10" r="10" fill={`url(#${gradientIds[1]})`} />
      <path
        d="M7.84479 13.8138C7.63031 13.9266 7.37964 13.7444 7.4206 13.5056L7.8322 11.1058L6.08861 9.40618C5.91509 9.23704 6.01084 8.94235 6.25064 8.9075L8.66023 8.55737L9.73783 6.37391C9.84507 6.15661 10.1549 6.15661 10.2622 6.37391L11.3398 8.55737L13.7494 8.9075C13.9892 8.94235 14.0849 9.23704 13.9114 9.40618L12.1678 11.1058L12.5794 13.5056C12.6204 13.7444 12.3697 13.9266 12.1552 13.8138L10 12.6808L7.84479 13.8138Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id={gradientIds[1]}
          x1="10"
          y1="-11.2662"
          x2="-12.5324"
          y2="8.73379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4AC" />
          <stop offset="0.504968" stopColor="#10C5E9" />
          <stop offset="1" stopColor="#9240DC" />
        </linearGradient>
      </defs>
    </svg>
  );

  const bigNonFavoriteIcon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <circle cx="15" cy="15" r="15" fill="#242833" fillOpacity="0.5" />
      <path
        d="M11.7672 20.7207C11.4454 20.8899 11.0694 20.6167 11.1309 20.2584L11.7483 16.6586L9.13288 14.1093C8.8726 13.8556 9.01623 13.4135 9.37592 13.3613L12.9903 12.8361L14.6067 9.56086C14.7676 9.23492 15.2324 9.23492 15.3932 9.56086L17.0096 12.8361L20.624 13.3613C20.9837 13.4135 21.1273 13.8556 20.8671 14.1093L18.2517 16.6586L18.8691 20.2584C18.9305 20.6167 18.5545 20.8899 18.2328 20.7207L15 19.0211L11.7672 20.7207Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );

  const bigWhiteNonFavoriteIcon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <g filter="url(#filter0_d_1429_47681)">
        <circle cx="24" cy="20" r="20" fill="#F2F4F8" />
      </g>
      <path
        d="M19.6896 27.6315C19.2606 27.8571 18.7593 27.4928 18.8412 27.0151L19.6644 22.2154L16.1772 18.8163C15.8302 18.478 16.0217 17.8886 16.5013 17.8189L21.3205 17.1186L23.4757 12.7517C23.6901 12.3171 24.3099 12.3171 24.5243 12.7517L26.6795 17.1186L31.4987 17.8189C31.9783 17.8886 32.1698 18.478 31.8228 18.8163L28.3356 22.2154L29.1588 27.0151C29.2407 27.4928 28.7394 27.8571 28.3104 27.6315L24 25.3654L19.6896 27.6315Z"
        stroke="#242833"
        strokeWidth="1.5"
      />
      <defs>
        <filter
          id="filter0_d_1429_47681"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1429_47681"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1429_47681"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const whiteNonFavoriteIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height: width }}
    >
      <circle cx="10.0547" cy="10.7383" r="10" fill="#F2F4F8" />
      <path
        d="M7.89948 14.5521C7.685 14.6649 7.43432 14.4827 7.47529 14.2439L7.88689 11.844L6.1433 10.1445C5.96978 9.97532 6.06553 9.68063 6.30532 9.64578L8.71492 9.29565L9.79252 7.11219C9.89976 6.8949 10.2096 6.8949 10.3169 7.11219L11.3945 9.29565L13.804 9.64578C14.0438 9.68063 14.1396 9.97532 13.9661 10.1445L12.2225 11.844L12.6341 14.2439C12.675 14.4827 12.4244 14.6649 12.2099 14.5521L10.0547 13.419L7.89948 14.5521Z"
        stroke="#242833"
      />
    </svg>
  );

  let favorite = {
    FavIcon: big ? bigFavoriteIcon : favoriteIcon,
    NonFavIcon: big ? bigNonFavoriteIcon : nonFavoriteIcon,
  };

  if (white) {
    favorite.NonFavIcon = big ? bigWhiteNonFavoriteIcon : whiteNonFavoriteIcon;
  }

  if (candidate) {
    favorite = {
      icon: isCandidateFav ? favorite.FavIcon : favorite.NonFavIcon,
      onClick: toggleCandidateFav,
    };
  }

  if (calendarEvent) {
    favorite = {
      icon: isEventFav ? favorite.FavIcon : favorite.NonFavIcon,
      onClick: toggleCalendarFav,
    };
  }

  if (job) {
    favorite = {
      icon:
        isJobFav || isJobPostSuccessFav
          ? favorite.FavIcon
          : favorite.NonFavIcon,
      onClick: toggleJobFav,
    };
  }

  return (
    <div
      className={classes.favIcon}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        favorite.onClick();
      }}
    >
      {favorite.icon}
    </div>
  );
}
