import React from 'react';

import classes from './styles.module.scss';
import getResizedImageUrl from '../../../../../../helpers/getResizedImageUrl';

export default function MemberCard({ member }) {
  const name = member?.fullName;
  const hasPhoto = member?.avatar;

  return (
    <div className={classes.MemberCard}>
      <div className={classes.imageContainer}>
        {hasPhoto ? (
          <img
            src={getResizedImageUrl({
              url: `avatar/${member?.avatar}`,
              width: 150,
            })}
            alt="member"
          />
        ) : (
          <div className={classes.noPhoto}>
            {`${member?.firstName?.[0] || ''}${member?.lastName?.[0] || ''}`}
          </div>
        )}
      </div>
      <span className={classes.name}>{name}</span>
    </div>
  );
}
