import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TextInput from '../../components/Form/TextInput';
import InnerHeader from '../../components/Auth/InnerHeader';
import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';

import { saveAuthData } from '../../helpers/authStorage';
import { AuthContext } from '../../context/AuthContext';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';
import passwordIcon from '../../assets/images/auth/password.svg';

export default function PasswordSignInPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { email } = useContext(AuthContext);
  const { showModal } = useContext(UiContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .trim()
          .required(t('pages.PasswordSignInPage.requiredField')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await UserService.loginByPassword({
          email,
          password: values.password,
        });
        saveAuthData({
          accessToken: response.token,
          userName: response.userName,
          userId: response.id,
        });
        navigate('/tools/discover');
      } catch (error) {
        showModal({
          icon: 'error',
          title: t('pages.PasswordSignInPage.incorrectPasswordTitle'),
          text: t('pages.PasswordSignInPage.incorrectPasswordText'),
          dismissButtonLabel: 'OK',
        });
      }
    },
  });

  return (
    <div className={classes.PasswordSignInPage}>
      <Helmet>
        <title>{t('pages.PasswordSignInPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          withBackButton
          noButton
          title={`${t('pages.PasswordSignInPage.enterYour')} ${t(
            'pages.PasswordSignInPage.password'
          )}`}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.inputContainer}>
            <TextInput
              icon={passwordIcon}
              error={
                formik.errors.password &&
                formik.errors.password !== 'Must be 8 digits or more'
                  ? formik.errors.password
                  : null
              }
              warning={
                formik.errors.password &&
                formik.errors.password === 'Must be 8 digits or more'
                  ? formik.errors.password
                  : null
              }
              touched={isTriedToSubmit}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t('pages.PasswordSignInPage.passwordLabel')}
              name="password"
              placeholder={t('pages.PasswordSignInPage.passwordPlaceholder')}
              type="password"
              height={55}
            />
          </div>
        </form>
        <ButtonWithHelperText
          onClick={() => {
            setIsTriedToSubmit(true);
            formik.handleSubmit();
          }}
          isDisabled={!formik.values.password}
          helperLink="/request-password"
          helperText={t('pages.PasswordSignInPage.forgotPassword')}
          helperLinkText={t('pages.PasswordSignInPage.requestNewPassword')}
          buttonLabel={t('common.verify')}
        />
      </div>
    </div>
  );
}
