import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CloseIcon from './CloseIcon';
import CandidateHeader from './CandidateHeader';
import CandidateChat from './Chat';
import Details from './Details';
import Notes from './Notes';
import AssessmentAndInterview from './AssessmentAndInterview';
import CandidateTimeline from './CandidateTimeline';
import PaginateCandidates from './PaginateCandidates';

import CandidatesService from '../../../services/CandidatesService';
import ChatService from '../../../services/ChatService';
import AdminService from '../../../services/AdminService';
import Applications from './Applications';
import classes from './styles.module.scss';

const WordResume = lazy(() => import('./WordResume'));

export default function Candidate({
  isVisible,
  setIsVisible,
  candidate,
  candidates,
  refetch,
  setCurrentCandidate,
  job,
  isApplication,
  notificationsData,
  hasSingleCandidate,
}) {
  const [currentForm, setCurrentForm] = useState('Details');
  const [isOpened, setIsOpened] = useState(false);
  // const [fadeInOut, setFadeInOut] = useState(false);

  const { t } = useTranslation();

  const { data: candidatesData } = useQuery({
    queryKey: ['candidates'],
    queryFn: () => CandidatesService.getAllCandidates({ pageSize: 99999 }),
  });

  const { data: chatThreads, refetch: refetchChatThreads } = useQuery({
    queryKey: ['chatThreads'],
    queryFn: ChatService.getThreads,
    keepPreviousData: true,
  });

  const jobApplicationThread = useMemo(() => {
    return chatThreads
      ?.find((threadJob) => threadJob.jobPostId === job?.id)
      ?.threads?.find((thread) => thread.jobApplicationId === candidate?.id);
  }, [candidate?.id, chatThreads, job]);

  let forms;
  if (isApplication) {
    forms = [
      { tab: 'Details' },
      { tab: 'Word Resume' },
      { tab: 'Chat', amount: jobApplicationThread?.totalCount || 0 },
      { tab: 'Assessment & Interview' },
      { tab: 'Notes', amount: candidate?.jobApplicationNotes?.length },
      { tab: 'Timeline' },
    ];
  } else {
    forms = [
      { tab: 'Details' },
      { tab: 'Word Resume' },
      { tab: 'Applications', amount: candidate?.jobApplications?.length },
    ];
  }
  const hasUnreadMessage = jobApplicationThread?.unreadCount;

  useEffect(() => {
    if (candidate && candidate?.user?.id && isApplication) {
      AdminService.addCandidateToViewed({ id: candidate?.user?.id });
    }
    // setFadeInOut((prev) => !prev);
    // setTimeout(() => {
    //   setFadeInOut((prev) => !prev);
    // }, 300);
  }, [candidate, isApplication]);

  useEffect(() => {
    if (isVisible) {
      setIsOpened(true);
    }
  }, [isVisible]);

  const handleClose = () => {
    setIsOpened(false);

    setTimeout(() => {
      setIsVisible(false);
      setCurrentCandidate(null);
    }, 300);
  };

  useEffect(() => {
    if (hasSingleCandidate) {
      return;
    }

    setCurrentCandidate(candidates?.find(({ id }) => id === candidate?.id));
  }, [hasSingleCandidate, candidate?.id, candidates, setCurrentCandidate]);

  useEffect(() => {
    return () => setCurrentCandidate(null);
  }, [setCurrentCandidate]);

  const jobApplications = candidatesData?.find(
    (cand) => cand.id === (candidate?.user?.id || candidate?.id)
  )?.jobApplications;

  return (
    <>
      <div
        className={classNames(classes.blur, {
          [classes.blurActivated]: isVisible,
        })}
      >
        <div
          className={classNames(classes.container, {
            [classes.visible]: isOpened,
          })}
        >
          <div
            className={classNames({
              // [classes.fadeInOut]: fadeInOut,
            })}
          >
            <CandidateHeader
              candidate={candidate}
              refetch={refetch}
              isApplication={isApplication}
              job={job}
              isVisible={isVisible}
              handleClose={handleClose}
              notificationsData={notificationsData}
            />
            <div className={classes.candidateInfo}>
              <ul
                className={classNames(classes.links, {
                  [classes.withoutGap]: !isApplication,
                })}
              >
                {forms.map((form) =>
                  ['Notes', 'Applications', 'Chat'].includes(form.tab) ? (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                      className={classNames(classes.link, {
                        [classes.linkActive]: form.tab === currentForm,
                      })}
                      onClick={() => setCurrentForm(form.tab)}
                      key={form.tab}
                    >
                      {t(
                        `dashboardComponents.Candidate.${form.tab.toLowerCase()}`
                      )}
                      <span
                        className={classNames(classes.counter, {
                          [classes.counterActive]:
                            (form.tab === currentForm &&
                              currentForm === 'Notes') ||
                            (form.tab === currentForm &&
                              currentForm === 'Applications') ||
                            (form.tab === 'Chat' && hasUnreadMessage),
                          [classes.noData]: !form.amount,
                        })}
                      >
                        {form.amount}
                      </span>
                    </li>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                      className={classNames(classes.link, {
                        [classes.linkActive]: form.tab === currentForm,
                      })}
                      onClick={() => setCurrentForm(form.tab)}
                      key={form.tab}
                    >
                      {t(
                        `dashboardComponents.Candidate.${form.tab.toLowerCase()}`
                      )}
                    </li>
                  )
                )}
              </ul>

              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Details',
                })}
              >
                <Details
                  candidate={candidate}
                  currentForm={currentForm}
                  setCurrentForm={setCurrentForm}
                  sidePopupIsOpened={isOpened}
                  isApplication={isApplication}
                  candidateApplications={jobApplications}
                />
              </div>
              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Word Resume',
                })}
              >
                <Suspense
                  fallback={
                    <div>
                      {t(
                        'dashboardComponents.Candidate.WordResume.Word.loading'
                      )}
                    </div>
                  }
                >
                  <WordResume candidate={candidate} currentForm={currentForm} />
                </Suspense>
              </div>
              {isApplication && (
                <div
                  className={classNames(classes.hidden, {
                    [classes.open]: currentForm === 'Chat',
                  })}
                >
                  {currentForm === 'Chat' && (
                    <CandidateChat
                      candidate={candidate}
                      chatThreads={chatThreads}
                      refetchChatThreads={refetchChatThreads}
                    />
                  )}
                </div>
              )}
              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Assessment & Interview',
                })}
              >
                <AssessmentAndInterview
                  candidate={candidate}
                  job={job}
                  refetch={refetch}
                />
              </div>
              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Notes',
                })}
              >
                <Notes
                  candidate={candidate}
                  refetch={refetch}
                  isVisible={currentForm === 'Notes'}
                />
              </div>
              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Timeline',
                })}
              >
                {candidate?.id && <CandidateTimeline candidate={candidate} />}
              </div>
              <div
                className={classNames(classes.hidden, {
                  [classes.open]: currentForm === 'Applications',
                })}
              >
                <Applications jobApplications={jobApplications} />
              </div>
            </div>
          </div>
          <PaginateCandidates
            candidate={candidate}
            candidates={candidates}
            setCurrentCandidate={setCurrentCandidate}
          />
          <div className={classes.closeIconContainer}>
            <CloseIcon onClick={handleClose} />
          </div>
        </div>
      </div>
    </>
  );
}
