/* eslint-disable no-plusplus */
import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()}
  `;
}

function formatStr(text) {
  let result = text[0];

  for (let i = 1; i < text.length; i++) {
    // eslint-disable-next-line no-restricted-globals
    if (text[i] === text[i].toUpperCase()) {
      result += ' ';
    }

    result += text[i];
  }

  return result;
}

export default function InterviewDetails({ event }) {
  const { t } = useTranslation();

  return (
    <div className={classes.InterviewDetails}>
      <div className={classes.form}>
        <span className={classes.title}>{t('common.when')}</span>
        <div className={classes.input}>{`${getEventDate(event.eventDate)} ${
          event?.time
        }`}</div>
      </div>

      {/*     <div className={classes.notification}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{t('common.notification')}</span>{' '}
          <div className={classes.switchContainer}>
            <Switch isEnabled={event?.event?.notificationMethod !== 'None'} />
          </div>
        </div>
        <div
          className={classNames(classes.form, {
            [classes.hidden]: event?.event?.notificationMethod === 'None',
          })}
          style={{
            visibility:
              event?.event?.notificationMethod === 'None'
                ? 'hidden'
                : 'visible',
          }}
        >
          <div className={classes.input}>
            {event?.event?.notificationMethod}
          </div>
          <div className={classes.input}>{event?.event?.notificationValue}</div>
          <div className={classes.input}>{event?.event?.notificationType}</div>
        </div>
      </div> */}

      <div className={classes.mode}>
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Interview.InterviewDetails.titleInterviewMode'
            )}
          </span>
          <div className={classes.input}>{formatStr(event?.event?.type)}</div>
        </div>
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Interview.InterviewDetails.titleInterviewLevel'
            )}
          </span>
          <div className={classes.input}>{formatStr(event?.event?.level)}</div>
        </div>
      </div>

      <div className={classes.connection}>
        {(event?.event?.type === 'Video' || event?.event?.type === 'Audio') && (
          <div className={classes.form}>
            <span className={classes.title}>
              {t(
                'dashboardComponents.Calendar.EventDetails.Interview.InterviewDetails.titleConnectionDetails'
              )}
            </span>
            <div className={classes.input}>
              {event?.event?.connectionDetails}
            </div>
          </div>
        )}
        {event?.event?.type === 'InPerson' && (
          <div className={classes.form}>
            <span className={classes.title}>{t('common.location')}</span>
            <div className={classes.input}>{event?.event?.customLocation}</div>
          </div>
        )}
      </div>
    </div>
  );
}
