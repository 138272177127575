import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Dropdown from '../../../Form/Dropdown';
import SearchInput from '../../../Form/SearchInput';
import Note from './Note';

import addIcon from '../../../../assets/images/dashboard/candidate/add.svg';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import classes from './styles.module.scss';

export default function Notes({ candidate, refetch, isVisible }) {
  const [isAdding, setIsAdding] = useState(false);
  const [activeAction, setActiveAction] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNotes, setFilteredNotes] = useState([]);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    setFilteredNotes(
      candidate?.jobApplicationNotes?.filter(({ note }) =>
        note.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    );
  }, [candidate?.jobApplicationNotes, searchTerm]);

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'notesSearchHistory'
  );

  const actions = [
    {
      label: t('dashboardComponents.Candidate.Notes.allNotes'),
      value: 'allNotes',
      onClick: () => {
        console.log('all notes');
      },
    },
    {
      label: t('dashboardComponents.Candidate.Notes.owner'),
      value: 'owner',
      onClick: () => {
        console.log('owner');
      },
    },
    {
      label: t('dashboardComponents.Candidate.Notes.date'),
      value: 'date',
      onClick: () => {
        console.log('date');
      },
    },
  ];

  return (
    <div>
      <div className={classes.header}>
        <button
          className={classes.new}
          onClick={() => setIsAdding(true)}
          type="button"
        >
          {t('dashboardComponents.Candidate.Notes.newNote')}{' '}
          <img src={addIcon} alt="Add" />
        </button>
        <div className={classes.inputs}>
          <span className={classes.itemsAmount}>{`${filteredNotes?.length} ${
            filteredNotes?.length > 1
              ? t('dashboardComponents.Candidate.Notes.items')
              : t('dashboardComponents.Candidate.Notes.item')
          } ${t('dashboardComponents.Candidate.Notes.found')}`}</span>
          <SearchInput
            value={searchTerm}
            setValue={setSearchTerm}
            searchHistory={searchHistory}
            width={isTabletOrMobile ? 155 : ''}
          />
          <Dropdown
            options={actions}
            placeholder={t('dashboardComponents.Candidate.Notes.allNotes')}
            activeOption={activeAction}
            setActiveOption={setActiveAction}
            width={isTabletOrMobile ? 117 : 140}
          />
        </div>
      </div>
      <div className={classes.notes}>
        {isAdding && (
          <Note
            isVisible={isVisible}
            isAdding
            setIsAdding={setIsAdding}
            candidateId={candidate?.id}
            refetch={refetch}
          />
        )}
        {filteredNotes
          ?.map((note) => (
            <Note
              isVisible={isVisible}
              key={note?.id}
              note={note}
              candidateId={candidate?.id}
              refetch={refetch}
            />
          ))
          .reverse()}
      </div>
    </div>
  );
}
