import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import CollapsedPostContent from './CollapsedPostContent';
import Announcement from './Announcement';
import Appreciation from './Appreciation';
import Tags from './Tags';
import Attachments from './Attachments';
import Comments from './Comments';
import PostStats from './PostStats';
import Header from './Header';
import Gallery from './Gallery';
import Text from './Text';

import useIntersectionObserver from '../../../../../hooks/useIntersectionObserver';
import getResizedImageUrl from '../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';
import Poll from './Poll';

const markPostAsRead = (postId, setIsRead) => {
  const readPosts = JSON.parse(localStorage.getItem('readPosts')) || [];
  if (!readPosts.includes(postId)) {
    readPosts.push(postId);
    localStorage.setItem('readPosts', JSON.stringify(readPosts));
    setIsRead(true);
  }
};

const isPostRead = (postId) => {
  const readPosts = JSON.parse(localStorage.getItem('readPosts')) || [];
  return readPosts.includes(postId);
};

export default function Post({
  reference,
  feedPost,
  refetch,
  setEditingPost,
  isAuthor,
  isAdmin,
  isHiddenTabActive,
}) {
  const [isRead, setIsRead] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const postRef = useRef();
  const timer = useRef();

  const [setNode, entry] = useIntersectionObserver({ threshold: 0.25 });

  const collapsePost = () => {
    setIsCollapsed(true);
    try {
      const prevCollapsedPosts = JSON.parse(
        localStorage.getItem('collapsedPostIds') || '[]'
      );
      localStorage.setItem(
        'collapsedPostIds',
        JSON.stringify([...prevCollapsedPosts, feedPost.id])
      );
    } catch (error) {
      console.log(error);
    }
  };

  const expandPost = () => {
    setIsCollapsed(false);
    try {
      const prevCollapsedPosts = JSON.parse(
        localStorage.getItem('collapsedPostIds') || '[]'
      );
      localStorage.setItem(
        'collapsedPostIds',
        JSON.stringify(prevCollapsedPosts.filter((id) => id !== feedPost.id))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsRead(isPostRead(feedPost.id));
  }, [feedPost.id]);

  useEffect(() => {
    if (postRef.current) {
      setNode(postRef.current);
    }
  }, [setNode]);

  useEffect(() => {
    if (entry.isIntersecting) {
      timer.current = setTimeout(() => {
        markPostAsRead(feedPost.id, setIsRead);
      }, 2000);
    } else {
      clearTimeout(timer.current);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [entry, feedPost.id]);

  useEffect(() => {
    const collapsedPosts = JSON.parse(
      localStorage.getItem('collapsedPostIds') || '[]'
    );
    if (collapsedPosts.includes(feedPost.id)) {
      setIsCollapsed(true);
    }
  }, [feedPost.id]);

  let role = 'viewer';

  if (isAuthor) {
    role = 'author';
  }
  if (isAdmin) {
    role = 'admin';
  }

  const media = feedPost?.attachments
    ?.filter(
      (file) =>
        file.mimeType.includes('image') || file.mimeType.includes('video')
    )
    .map((file) => ({
      type: file.mimeType.includes('image') ? 'image' : 'video',
      url: file.mimeType.includes('video')
        ? file.url
        : getResizedImageUrl({
            url: `feed/${file.fileName}`,
            width: 1000,
          }),
    }));

  const attachments = feedPost?.attachments
    /* ?.filter(
      (attachment) =>
        !attachment.mimeType.includes('image') &&
        !attachment.mimeType.includes('video')
    ) */
    ?.sort((a, b) => a.sortOrder - b.sortOrder);

  let mainContent = <Text text={feedPost.content} />;

  if (feedPost.type === 'Appreciation') {
    mainContent = (
      <Appreciation
        text={feedPost.content}
        recipients={feedPost.appreciated}
        feedPost={feedPost}
      />
    );
  }

  if (feedPost.type === 'Announcement') {
    mainContent = <Announcement feedPost={feedPost} />;
  }

  if (isCollapsed) {
    mainContent = (
      <CollapsedPostContent
        content={feedPost.content}
        feedPost={feedPost}
        refetch={refetch}
        setEditingPost={setEditingPost}
        role={role}
        expandPost={expandPost}
      />
    );
  }

  return (
    <article ref={postRef}>
      <div
        className={classNames(classes.Post, {
          [classes.collapsed]: isCollapsed,
        })}
        ref={reference}
      >
        <Header
          feedPost={feedPost}
          refetch={refetch}
          setEditingPost={setEditingPost}
          role={role}
          isRead={isRead}
          collapsePost={collapsePost}
          isCollapsed={isCollapsed}
          postRef={postRef}
          isHiddenTabActive={isHiddenTabActive}
        />
        {!isCollapsed && feedPost.title && (
          <h1 className={classes.title}>{feedPost.title}</h1>
        )}
        {!isCollapsed && (
          <Gallery
            media={media}
            feedPost={feedPost}
            isRead={isRead}
            refetch={refetch}
          />
        )}
        {mainContent}
        {feedPost?.tags?.length && !isCollapsed ? (
          <Tags tags={feedPost.tags} />
        ) : null}
        {attachments?.length && !isCollapsed ? (
          <Attachments attachments={attachments} />
        ) : null}
        {feedPost.polls.length && !isCollapsed ? (
          <Poll
            poll={feedPost.polls[0]}
            feedPostId={feedPost.id}
            refetch={refetch}
            authorId={feedPost?.author.id}
          />
        ) : null}
        {!isCollapsed && <PostStats feedPost={feedPost} refetch={refetch} />}
        {!isCollapsed && feedPost && (
          <div
            className={classes.commentsContainer}
            style={{
              marginTop: feedPost?.comments?.length ? 55 : 15,
            }}
          >
            <Comments
              commnts={feedPost.comments}
              feedPost={feedPost}
              refetch={refetch}
            />
          </div>
        )}
      </div>
    </article>
  );
}
