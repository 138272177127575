import React from 'react';

import { useQuery } from '@tanstack/react-query';
import QRCode from 'qrcode.react';

import getResizedImageUrl from '../../../helpers/getResizedImageUrl';
import logo from '../../../assets/images/dashboard/jobs/KFC.png';
import AdminService from '../../../services/AdminService';

export default function QRCodeGenerator({ value, size, imageSize }) {
  const { data } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const logoUrl = data?.brand?.logo
    ? getResizedImageUrl({
        url: `brand/${data.brand.logo.split('/').pop()}`,
        width: 240,
      })
    : logo;

  return (
    <QRCode
      renderAs="svg"
      value={value}
      size={size || 150}
      level="H"
      imageSettings={{
        src: logoUrl,
        height: imageSize?.height || 39,
        width: imageSize?.width || 86,
        excavate: true,
      }}
    />
  );
}
