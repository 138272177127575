import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../../Form/Dropdown';
import Template from '../Template';
import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';
import Textarea from '../../../../../Form/Textarea';

import yupSchemaToFields from '../../../../../../helpers/yupSchemaToFields';
import createOrUpdateVrQuestions from '../../../../../../helpers/createOrUpdateVrQuestions';
import convertDropdownValues from '../../../../../../helpers/convertDropdownValues';
import useUpdateData from '../../../../../../hooks/useUpdateData';
import { UiContext } from '../../../../../../context/UiContext';
import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

const convertDataFieldToDropdownValue = (field) => {
  if (!field) {
    return '';
  }
  return { label: field.name, value: field.id };
};

export default function AddOrEditJobTemplate({
  isVisible,
  handleClose,
  isAdd,
  data,
  selectedRows,
  createDataHandler,
  refetch,
  setIsAddDataVisible,
  isEdit,
  hasNoAnimation,
  updateDataHandler,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [isTemplateVisible, setIsTemplateVisible] = useState(false);
  const [status, setStatus] = useState({ label: 'Draft', value: 0 });
  const [vrQuestions, setVrQuestions] = useState([]);
  const [useDefaultVrQuestions, setUseDefaultVrQuestions] = useState(false);
  const [assessments, setAssessments] = useState([]);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(data, selectedRows);

  const { updateData } = useUpdateData({
    updateHandler: updateDataHandler,
    refetch,
  });

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    setIsCreatingOrUpdating,
    showDuplicateDataModal,
  } = useContext(UiContext);

  const areVrQuestionsValid = useRef();
  const areAssessmentsValid = useRef();

  useEffect(() => {
    areVrQuestionsValid.current = true;
    areAssessmentsValid.current = true;
  }, []);

  const { data: allVrQuestions } = useQuery({
    queryKey: ['vrQuestions'],
    queryFn: () => AdminService.getVrQuestions({ pageSize: 999999 }),
  });

  const topQuestions = useMemo(
    () =>
      allVrQuestions?.data
        ?.filter((question) => question.isDefault)
        ?.sort((prev, next) => prev.sortOrder - next.sortOrder)
        ?.slice(0, 5),
    [allVrQuestions]
  );

  useEffect(() => {
    if (useDefaultVrQuestions) {
      setVrQuestions?.(
        topQuestions?.map((question) => ({
          id: question.id,
          savedQuestion: {},
          duration: question.duration,
          title: question.title,
          question: question.question,
        }))
      );
    }
  }, [setVrQuestions, useDefaultVrQuestions, topQuestions]);

  // Set template status from the data
  useEffect(() => {
    if (isEdit && data && currentDataIndex !== undefined) {
      setStatus({
        label: data[currentDataIndex]?.templateStatus,
        value: data[currentDataIndex]?.templateStatus,
      });
    }
  }, [data, currentDataIndex, isEdit]);

  const createData = async (values) => {
    try {
      setIsFetching(true);
      const template = await createDataHandler(values);
      await refetch();
      setIsAddDataVisible(false);
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.Success'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.addedSuccessfully'
        ),
        dismissButtonLabel: t('common.no'),
        confirmButtonLabel: t('common.publish'),
        confirmButtonVariant: '',
        onConfirm: async () => {
          try {
            await updateData({
              ...template,
              templateStatus: 'Published',
            });
            refetch();
          } catch (error) {
            console.log(error);
          }
        },
        onCancel: () => {},
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message === 'Duplicity') {
        showDuplicateDataModal('Job Template');
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
    }
  };

  const uploadCoverImage = async (image) => {
    return AdminService.uploadImage(image, 'jobpostcover');
  };

  const uploadJobPostVideo = async (video) => {
    const uploadedVideo = await AdminService.uploadVideo(video);
    return AdminService.createJobPostVideo(uploadedVideo);
  };

  const uploadJobPostAttachment = async (doc) => {
    const uploadedDoc = await AdminService.uploadFile(doc);
    return AdminService.createJobPostAttachment(uploadedDoc);
  };

  const addTemplate = async (values) => {
    if (!areVrQuestionsValid.current && !areAssessmentsValid.current) {
      return;
    }
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const valuesWithImageFilenName = {
        ...values,
      };

      if (values.coverFileName) {
        const image = await uploadCoverImage(values.coverFileName);
        valuesWithImageFilenName.coverFileName = image.fileName;
      }

      if (values.jobPostVideoId) {
        const jobPostVideo = await uploadJobPostVideo(values.jobPostVideoId);
        valuesWithImageFilenName.jobPostVideoId = jobPostVideo?.id;
      }

      if (values.jobPostAttachmentIds) {
        const jobPostAttachment = await uploadJobPostAttachment(
          values.jobPostAttachmentIds
        );
        valuesWithImageFilenName.jobPostAttachmentIds = [jobPostAttachment?.id];
      }

      if (vrQuestions.length) {
        const questions = await createOrUpdateVrQuestions(vrQuestions);
        valuesWithImageFilenName.videoResumeQuestionIds = questions;
      }

      await createData({
        ...convertDropdownValues(valuesWithImageFilenName),
        graduationId: values.graduation?.value,
        assignedAssessments: assessments?.map((assessment, index) => ({
          tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
          assessmentProviderKey: assessment.assessmentProviderKey,
          assessmentId: assessment.assessmentId,
          threshold: `${assessment.threshold}`,
          englishThreshold: `${assessment.englishThreshold}`,
          mentalThreshold: `${assessment.mentalThreshold}`,
          limit: assessment.limit,
          sortOrder: index + 1,
        })),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const updateTemplate = async (values) => {
    if (!areVrQuestionsValid.current || !areAssessmentsValid.current) {
      return;
    }
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const valuesWithImageFilenName = {
        ...values,
      };

      if (values.coverFileName && values.coverFileName instanceof File) {
        const image = await uploadCoverImage(values.coverFileName);
        valuesWithImageFilenName.coverFileName = image.fileName;
      }

      if (values.jobPostVideoId && values.jobPostVideoId instanceof File) {
        const jobPostVideo = await uploadJobPostVideo(values.jobPostVideoId);
        valuesWithImageFilenName.jobPostVideoId = jobPostVideo?.id;
      }

      if (
        values.jobPostAttachmentIds &&
        values.jobPostAttachmentIds instanceof File
      ) {
        const jobPostAttachment = await uploadJobPostAttachment(
          values.jobPostAttachmentIds
        );
        valuesWithImageFilenName.jobPostAttachmentIds = [jobPostAttachment?.id];
      }

      if (vrQuestions.length) {
        const questions = await createOrUpdateVrQuestions(vrQuestions);
        valuesWithImageFilenName.videoResumeQuestionIds = questions;
      }

      await updateData({
        ...convertDropdownValues(valuesWithImageFilenName),
        graduationId: values.graduation?.value,
        id: data[currentDataIndex]?.id,
        templateStatus: status.value,
        assignedAssessments: assessments?.map((assessment, index) => ({
          tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
          assessmentProviderKey: assessment.assessmentProviderKey,
          assessmentId: assessment.assessmentId,
          threshold: `${assessment.threshold}`,
          englishThreshold: `${assessment.englishThreshold}`,
          mentalThreshold: `${assessment.mentalThreshold}`,
          limit: assessment.limit,
          sortOrder: index + 1,
        })),
      });
    } catch (error) {
      console.log(error);

      if (error?.response?.data?.message === 'Duplicity') {
        showDuplicateDataModal('Job Template');
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        templateName: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.EmptyField'
            )
          ),
        templateSortOrder: yup
          .string()
          .trim()
          .matches(
            /^\d+$/,
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.NumbersOnly'
            )
          )
          .required(
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.EmptyField'
            )
          ),
        templateNote: yup.string().trim().nullable(),
        jobTitle: yup.string().trim(),
        locationCityId: yup.object(),
        locationCityDistrictId: yup.object(),
        jobTypeIds: yup.array(),
        education: yup.object(),
        corporateTitle: yup.string().trim().nullable(),
        jobDescription: yup.string().trim().nullable(),
        locationIds: yup.array(),
        workExperienceId: yup.object(),
        salaryTo: yup
          .number()
          .nullable()
          .min(
            1000,
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SalaryTooLow'
            )
          )
          .moreThan(
            yup.ref('salaryFrom'),
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SalaryRangeError'
            )
          ),
        salaryFrom: yup
          .number()
          .nullable()
          .min(
            1000,
            t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SalaryTooLow'
            )
          ),
        note: yup.string().trim().nullable(),
        coverFileName: yup.mixed(),
        jobPostVideoId: yup.mixed(),
        skillIds: yup.array(),
        jobPostAttachmentIds: yup.mixed(),
        isAssessmentNeeded: yup.boolean(),
        applicationType: yup.string().trim(),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      isAssessmentNeeded: isAdd
        ? ''
        : !!data?.[currentDataIndex]?.assignedAssessments?.length,
      templateName: isAdd ? '' : data?.[currentDataIndex]?.templateName,
      templateNote: isAdd ? '' : data?.[currentDataIndex]?.templateNote,
      templateSortOrder: isAdd
        ? ''
        : data?.[currentDataIndex]?.templateSortOrder,
      locationCityId: isAdd
        ? ''
        : convertDataFieldToDropdownValue(
            data?.[currentDataIndex]?.locationCity
          ),
      locationCityDistrictId: isAdd
        ? ''
        : convertDataFieldToDropdownValue(
            data?.[currentDataIndex]?.locationCityDistrictId
          ),
      jobTypeIds: isAdd
        ? ''
        : data?.[currentDataIndex]?.jobTypes?.map((jobType) =>
            convertDataFieldToDropdownValue(jobType)
          ),
      graduation: isAdd
        ? ''
        : convertDataFieldToDropdownValue(data?.[currentDataIndex]?.graduation),
      corporateTitle: isAdd ? '' : data?.[currentDataIndex]?.corporateTitle,
      jobDescription: isAdd ? '' : data?.[currentDataIndex]?.jobDescription,
      locationIds: isAdd
        ? ''
        : data?.[currentDataIndex]?.locations?.map((loc) =>
            convertDataFieldToDropdownValue(loc)
          ),
      workExperienceId: isAdd
        ? ''
        : convertDataFieldToDropdownValue(
            data?.[currentDataIndex]?.workExperience
          ),
      salaryTo: isAdd ? '' : data?.[currentDataIndex]?.salaryTo,
      salaryFrom: isAdd ? '' : data?.[currentDataIndex]?.salaryFrom,
      note: isAdd ? '' : data?.[currentDataIndex]?.note,
      coverFileName: isAdd ? '' : data?.[currentDataIndex]?.coverFileName,
      jobPostVideoId: isAdd ? '' : data?.[currentDataIndex]?.jobPostVideo,
      skillIds: isAdd
        ? ''
        : data?.[currentDataIndex]?.skills?.map((skill) =>
            convertDataFieldToDropdownValue(skill)
          ),
      jobPostAttachmentIds: isAdd
        ? ''
        : data?.[currentDataIndex]?.jobPostAttachmentIds,
      jobTitle: isAdd ? '' : data?.[currentDataIndex]?.jobTitle,
      applicationType: isAdd
        ? 'VideoOnly'
        : data?.[currentDataIndex]?.applicationType,
      assignedAssessments: isAdd
        ? ''
        : data?.[currentDataIndex]?.assignedAssessments,
    },
    validationSchema,
    onSubmit: isAdd ? addTemplate : updateTemplate,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isEdit && data && currentDataIndex !== undefined) {
      setVrQuestions(
        data?.[currentDataIndex]?.videoResumeQuestions.map((qstn) => ({
          id: Math.random(),
          savedQuestion: {
            label: qstn.title,
            value: qstn.id,
            duration: qstn.duration,
            question: qstn.question,
          },
        }))
      );
    }
  }, [isEdit, data, currentDataIndex]);

  const resetForm = () => {
    formik.resetForm();
    if (isEdit && data && currentDataIndex !== undefined) {
      setStatus({
        label: data[currentDataIndex]?.templateStatus,
        value: data[currentDataIndex]?.templateStatus,
      });
    }
  };

  /*   if (
    formik.values.isAssessmentNeeded &&
    assessments.length &&
    assessments.every(
      (assessment) =>
        assessment.limit > 2 &&
        assessment.limit < 10 &&
        assessment.threshold > 49 &&
        assessment.englishThreshold > 49 &&
        assessment.mentalThreshold > 49
    )
  ) {
    areAssessmentsValid.current = true;
  } else {
    areAssessmentsValid.current = false;
  } */

  if (
    formik.values.isAssessmentNeeded &&
    (assessments.length || formik.values.assignedAssessments?.length) &&
    assessments.every(
      (assessment) =>
        assessment.limit > 2 &&
        assessment.limit < 10 &&
        (assessment.threshold === null ||
          assessment.threshold > 49 ||
          assessment.assessment === 'FlowQ')
    )
  ) {
    areAssessmentsValid.current = true;
  } else {
    areAssessmentsValid.current = false;
  }

  if (!assessments.length && !formik.values.isAssessmentNeeded) {
    areAssessmentsValid.current = true;
  }

  const closeWithWarning = () => {
    showModal({
      icon: 'error',
      title: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.Exit'
      ),
      text: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SaveAsDraft'
      ),
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
      onConfirm: () => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      },
      onCancel: handleClose,
    });
  };

  useEffect(() => {
    if (!isVisible) {
      setIsTriedToSubmit(false);
      setIsTemplateVisible(false);
      setVrQuestions([]);
    }
  }, [isVisible]);

  const showTemplate = async () => {
    setIsTriedToSubmit(true);
    formik.validateForm();

    if (!formik.isValid) {
      return;
    }

    if (formik.values.templateName) {
      setIsTemplateVisible(true);
      setIsTriedToSubmit(false);
    }
  };

  const statusOptions = [
    {
      label: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.Draft'
      ),
      value: 0,
    },
    {
      label: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.Published'
      ),
      value: 1,
    },
  ];

  return (
    <SidePopup
      fields={yupSchemaToFields(validationSchema)}
      formik={{
        ...formik,
        dirty:
          formik.dirty ||
          status.label !== data[currentDataIndex]?.templateStatus,
        resetForm,
      }}
      mode={isAdd ? 'add' : 'edit'}
      isVisible={isVisible}
      handleClose={
        isAdd && formik.values.templateName !== ''
          ? closeWithWarning
          : handleClose
      }
      title={
        isAdd
          ? t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.AddTemplate'
            )
          : t(
              'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.EditTemplate'
            )
      }
      switchNext={selectedRows.length > switchToNextDataElement}
      switchPrev={selectedRows.length > switchToPrevDataElement}
      onLeftButtonClick={() => {
        formik.resetForm();
      }}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      hasNoAnimation={hasNoAnimation}
    >
      <div className={classes.AddOrEditJobTemplate}>
        {isEdit && (
          <div className={classes.status}>
            <span>Published Status</span>
            <Dropdown
              width={150}
              activeOption={status}
              setActiveOption={setStatus}
              options={statusOptions}
            />
          </div>
        )}
        {isTemplateVisible ? (
          <Template
            formik={formik}
            isTriedToSubmit={isTriedToSubmit}
            isEdit={isEdit}
            vrQuestions={vrQuestions}
            setVrQuestions={setVrQuestions}
            useDefaultVrQuestions={useDefaultVrQuestions}
            setUseDefaultVrQuestions={setUseDefaultVrQuestions}
            areVrQuestionsValid={areVrQuestionsValid}
            assessments={assessments}
            setAssessments={setAssessments}
          />
        ) : (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.col}>
                <TextInput
                  value={formik.values.templateName}
                  height={50}
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.TemplateName'
                  )}
                  placeholder={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.TemplateNamePlaceholder'
                  )}
                  onChange={formik.handleChange}
                  name="templateName"
                  touched={isTriedToSubmit}
                  onBlur={formik.handleBlur}
                  error={formik.errors.templateName}
                />
              </div>
              <div className={classes.col}>
                <TextInput
                  value={formik.values.templateSortOrder}
                  height={50}
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SortOrder'
                  )}
                  placeholder={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.SortOrderPlaceholder'
                  )}
                  onChange={formik.handleChange}
                  name="templateSortOrder"
                  touched={isTriedToSubmit}
                  onBlur={formik.handleBlur}
                  error={formik.errors.templateSortOrder}
                  maxLength={5}
                />
                <Textarea
                  value={formik.values.note}
                  height={100}
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.Notes'
                  )}
                  placeholder={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.EnterNotes'
                  )}
                  onChange={formik.handleChange}
                  name="note"
                  touched={isTriedToSubmit}
                  onBlur={formik.handleBlur}
                  error={formik.errors.note}
                />
              </div>
            </div>
            <button
              className={classes.fillTemplateButton}
              type="button"
              onClick={showTemplate}
            >
              {isAdd
                ? t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.FillTemplate'
                  )
                : t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.AddOrEditJobTemplate.editTemplate'
                  )}
            </button>
          </>
        )}
      </div>
    </SidePopup>
  );
}
