import React, { useState, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';
import classNames from 'classnames';

// import Button from '../../../Button';
import FormButtonsManager from '../../FormButtonsManager';

import classes from './styles.module.scss';
import CloseIcon from '../../Candidate/CloseIcon';

export default function SidePopup({
  children,
  isVisible,
  handleClose,
  title,
  isOnTop,
  hasNoAnimation,
  isOverlayed,
  // leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  //  isRightButtonDisabled,
  switchPrev,
  switchNext,
  noButtons,
  // rightButtonComponent,
  // leftButtonVariant,
  // isLeftButtonDisabled,
  formik,
  mode,
  fields,
}) {
  // const [fadeInOut, setFadeInOut] = useState(false);
  const [isBackdropVisible, setIsBacdropVisible] = useState(false);
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const sidePopupRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (isVisible) {
      setIsBacdropVisible(true);
      setIsContainerVisible(true);
    } else {
      setIsContainerVisible(false);
      setTimeout(() => {
        setIsBacdropVisible(false);
      }, 200);
    }
  }, [isVisible]);

  useEffect(() => {
    const maxContentHeight =
      sidePopupRef.current.clientHeight - 47 - 58 - 22 - 158;

    sidePopupRef.current.style.setProperty(
      '--maxContentHeight',
      `${maxContentHeight}px`
    );
  }, [isVisible]);

  return createPortal(
    <div
      ref={sidePopupRef}
      className={classNames(classes.SidePopup, {
        [classes.visible]: isBackdropVisible,
        [classes.onTop]: isOnTop,
        [classes.noAnimation]: hasNoAnimation,
        [classes.overlayd]: isOverlayed,
        [classes.noButtons]: noButtons,
      })}
    >
      <div
        className={classNames(classes.container, {
          [classes.visible]: isContainerVisible,
        })}
      >
        <div className={classes.closeIconPosition}>
          <CloseIcon onClick={handleClose} />
        </div>
        <div
          className={classNames(classes.defaultFadeInOut, {
            // [classes.fadeInOut]: fadeInOut,
          })}
        >
          <header className={classes.header}>
            <h1>{title}</h1>
          </header>
          {isVisible && (
            <div className={classes.content} ref={contentRef}>
              {children}
            </div>
          )}
        </div>
        {isVisible && (
          <footer className={classes.footer}>
            <>
              {switchPrev && (
                <i className={classes.prevIcon} onClick={switchPrev}>
                  Previous
                </i>
              )}
              <FormButtonsManager
                rightButtonLabel={rightButtonLabel}
                formik={formik}
                mode={mode}
                onRightButtonClick={onRightButtonClick}
                onLeftButtonClick={onLeftButtonClick}
                fields={fields}
              />
              {switchNext && (
                <i className={classes.nextIcon} onClick={switchNext}>
                  Next
                </i>
              )}
            </>
          </footer>
        )}
      </div>
    </div>,
    document.getElementById('dashboardPageContainer') || document.body
  );
}
