import React, { useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import JobInfo from '../../Favorites/Candidates/CandidateCard/JobInfo';
import classes from './styles.module.scss';
import useIntersectionObserverPagination from '../../../../hooks/useIntersectionObserverPagination';

const PAGE_SIZE = 20;

export default function Applications({ jobApplications, isModal, style }) {
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const jobApplicationsReversed = Array.isArray(jobApplications)
    ? [...jobApplications].reverse()
    : [];

  const fetchNextPage = useCallback(() => {
    setIsFetching(true);
    setPage((prevPage) => prevPage + 1);
    setTimeout(() => {
      setIsFetching(false);
    }, 100);
  }, []);

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  return (
    <div
      className={classes.Applications}
      style={{
        marginTop: isModal && '30px',
        height: isModal && '460px',
        ...style,
      }}
    >
      {!jobApplicationsReversed?.length ? (
        <span className={classes.noData}>
          {t('dashboardComponents.Candidate.Applications.noData')}
        </span>
      ) : (
        jobApplicationsReversed
          ?.slice(0, page * PAGE_SIZE)
          ?.map((application, index) => (
            <JobInfo
              reference={
                index === page * PAGE_SIZE - 1 ? lastListElementRef : null
              }
              application={application}
              key={application?.id}
              width={isTabletOrMobile ? '' : '100%'}
            />
          ))
      )}
    </div>
  );
}
