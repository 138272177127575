import React, { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Stat from './Stat';
import Timeline from './Timeline';
import ApplicantsGraph from './ApplicantsGraph';
import ViewsGraph from './ViewsGraph';

import AdminService from '../../../../../../services/AdminService';
import {
  getDate,
  getTimelineTitle,
} from '../../../../../../helpers/getTimeline';
import classes from './styles.module.scss';

/* function getNumberOfDays(start) {
  const date1 = new Date(start);
  const date2 = new Date();

  const oneDay = 1000 * 60 * 60 * 24;

  const diffInTime = date2.getTime() - date1.getTime();

  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays <= 11;
}
 */
function isThisWeek(date) {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const daysToday = Math.floor(
    (currentDate - startDate) / (24 * 60 * 60 * 1000)
  );
  const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));

  const todayWeekNumber = Math.floor(daysToday / 7);
  const dateWeekNumber = Math.floor(days / 7);

  return todayWeekNumber === dateWeekNumber;
}

function formatMetricDigits(metric, value) {
  if (!value) {
    return null;
  }

  const stringValue = String(value);

  if (metric === 'views') {
    return stringValue.length > 4
      ? `${stringValue.slice(0, stringValue.length - 4)}k`
      : stringValue;
  }

  return stringValue;
}

export default function Insights({ job }) {
  const { t } = useTranslation();

  const { data: jobPostStats } = useQuery({
    queryKey: [`jobPostStats`, job],
    queryFn: () => AdminService.getJobPostStats({ jobId: job?.id }),
  });

  const { data: jobPostTimeline } = useQuery({
    queryKey: [`jobPostTimeline`, job],
    queryFn: () => AdminService.getJobPostTimeline({ jobId: job?.id }),
  });

  const citiesTotalAmount = jobPostStats?.applicantByLocation?.reduce(
    (accum, city) => city.count + accum,
    0
  );
  const educationTotalAmount = jobPostStats?.applicantByEducation?.reduce(
    (accum, education) => education.count + accum,
    0
  );

  console.log(jobPostStats);

  const metrics = [
    {
      title: t('dashboardComponents.Jobs.MyJobs.JobDetails.Insights.jobViews'),
      amount: formatMetricDigits('views', jobPostStats?.totalViews?.count),
      last: formatMetricDigits('views', jobPostStats?.totalViews?.change),
      percentage: false,
      type: 'none',
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.conversionRate'
      ),
      amount: jobPostStats?.conversionRate.count,
      last: jobPostStats?.conversionRate.change.toFixed(0),
      percentage: true,
      type: 'none',
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.averageTimeToApply'
      ),
      amount: jobPostStats?.timeToApply?.count,
      last: jobPostStats?.timeToApply?.change,
      percentage: false,
      type: 'minutes',
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.dropOffRate'
      ),
      amount: jobPostStats?.dropOffRate.count
        ? jobPostStats?.dropOffRate.count.toFixed(1)
        : 0,
      last: jobPostStats?.dropOffRate.change.toFixed(0),
      percentage: true,
      type: 'none',
    },
  ];

  const daysApplicants = [0, 0, 0, 0, 0, 0, 0];
  const daysViews = [0, 0, 0, 0, 0, 0, 0];
  const monthApplicants = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const monthViews = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  jobPostStats?.applicants?.forEach((applicant) => {
    const applicantDate = new Date(applicant?.date);

    if (applicantDate.getFullYear() !== new Date().getFullYear()) {
      return;
    }

    monthApplicants[applicantDate.getMonth()] += applicant?.count;

    if (!isThisWeek(applicantDate)) {
      return;
    }

    if (applicantDate.getDay() === 0) {
      daysApplicants[6] += applicant?.count;
    } else {
      daysApplicants[applicantDate.getDay() - 1] += applicant?.count;
    }
  });

  jobPostStats?.views?.forEach((view) => {
    const viewDate = new Date(view?.date);

    if (viewDate.getFullYear() !== new Date().getFullYear()) {
      return;
    }

    monthViews[viewDate.getMonth()] += view?.count;

    if (!isThisWeek(viewDate)) {
      return;
    }

    if (viewDate.getDay() === 0) {
      daysViews[6] += view?.count;
    } else {
      daysViews[viewDate.getDay() - 1] += view?.count;
    }
  });

  const graphData = {
    daysViews,
    daysApplicants,
    monthViews,
    monthApplicants,
  };

  const events = useMemo(
    () =>
      jobPostTimeline?.map((event) => ({
        title: getTimelineTitle(event),
        date: getDate(new Date(event?.createdAt)),
      })),
    [jobPostTimeline]
  );

  return (
    <div className={classes.Insights}>
      <div className={classes.graphs}>
        <ViewsGraph graphData={graphData} />
        <div className={classes.topGraphs}>
          <ApplicantsGraph
            name={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.location'
            )}
            data={jobPostStats?.applicantByLocation}
            totalAmount={citiesTotalAmount}
          />
          <ApplicantsGraph
            name={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.educationLevel'
            )}
            data={jobPostStats?.applicantByEducation}
            totalAmount={educationTotalAmount}
          />
        </div>
      </div>
      <div className={classes.metrics}>
        {metrics.map((metric) => (
          <Stat metric={metric} key={metric.title} />
        ))}
      </div>
      <div className={classes.timeline}>
        <div className={classes.timelineContainer}>
          <span className={classes.title}>Timeline</span>
          <Timeline data={events} />
        </div>
      </div>
    </div>
  );
}
