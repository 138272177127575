import React, { useMemo } from 'react';

import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Switch from '../../../../Switch';
import MultiDropdown from '../../../../Form/MultiDropdown';
import SelectedUsers from '../../../SelectedUsers';
import FormButton from '../../../../FormButton';

import { useUiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';
import AdminService from '../../../../../services/AdminService';
import FeedService from '../../../../../services/FeedService';

// let timer;

export default function Escalation() {
  const { setIsFetching, showUnknownErrorModal } = useUiContext();

  const { t } = useTranslation();

  const {
    data: tenantSettings,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const { data: users } = useQuery({
    queryKey: ['escalationUsers'],
    queryFn: FeedService.getFeedPostRecipients,
  });

  const userOptions = useMemo(
    () =>
      users?.map?.((user) => ({
        label: `${user.userProfile.firstName} ${user.userProfile.lastName} (${user.userName})`,
        value: user.id,
      })) || [],
    [users]
  );

  const formik = useFormik({
    initialValues: {
      isEnabled: tenantSettings?.escalationReceivers.length > 0,
      userIds:
        tenantSettings && users
          ? tenantSettings.escalationReceivers.map((usr) => ({
              label: userOptions.find((opt) => opt.value === usr.userId).label,
              value: usr.userId,
            }))
          : [],
    },
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        const request = {
          ...tenantSettings,
          escalationReceivers: values.isEnabled
            ? values.userIds.map((usr) => ({ userId: usr.value }))
            : [],
        };

        await AdminService.updateTenantSettings(request);
        refetch();
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const hasAllUsersBeenDeleted =
    !formik.values.userIds.length &&
    tenantSettings?.escalationReceivers.length > 0;

  return (
    <div className={classes.Escalation}>
      <div className={classes.row}>
        <div className={classes.text}>
          <h3 className={classes.title}>
            {t(
              'dashboardComponents.ProfileSettings.Notification.Escalation.title'
            )}
          </h3>
          <p className={classes.info}>
            {t(
              'dashboardComponents.ProfileSettings.Notification.Escalation.info'
            )}
          </p>
        </div>
        <div className={classes.switch}>
          <Switch
            isEnabled={formik.values.isEnabled}
            onClick={() => {
              formik.setFieldValue('isEnabled', !formik.values.isEnabled);
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div
          style={{
            visibility: formik.values.isEnabled ? 'visible' : 'hidden',
          }}
        >
          <MultiDropdown
            hideValue
            labelStyle={{
              display: 'none',
            }}
            options={userOptions}
            width={333}
            value={formik.values.userIds}
            name="userIds"
            setFieldValue={formik.setFieldValue}
            placeholder={t(
              'dashboardComponents.ProfileSettings.Notification.Escalation.placeholder'
            )}
          />
        </div>
        <FormButton
          disabled={
            !formik.dirty ||
            formik.isSubmitting ||
            isFetching ||
            (formik.values.isEnabled &&
              !formik.values.userIds.length &&
              !hasAllUsersBeenDeleted)
          }
          style={{
            width: 180,
            height: 45,
          }}
          variant="submit"
          onClick={formik.handleSubmit}
        >
          {t('common.save')}
        </FormButton>
      </div>
      {formik.values.isEnabled && (
        <div className={classes.row}>
          <SelectedUsers
            style={{ width: '100%' }}
            invitedUsers={formik.values.userIds}
            withFormik
            setInvitedUsers={(value) => formik.setFieldValue('userIds', value)}
          />
        </div>
      )}
    </div>
  );
}
