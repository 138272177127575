import React, { useState } from 'react';

import classNames from 'classnames';

import User from './User';

import classes from './styles.module.scss';

export default function SelectedUsers({
  invitedUsers,
  setInvitedUsers,
  withFormik,
  readOnly,
  style,
}) {
  const [currentUser, setCurrentUser] = useState(null);

  return (
    <div
      className={classNames(classes.SelectedUsers, {
        [classes.readOnly]: readOnly,
      })}
      style={style}
    >
      {invitedUsers &&
        invitedUsers?.map?.((user) => {
          return (
            <User
              key={user.value}
              user={user}
              removeUser={() => {
                if (withFormik) {
                  setInvitedUsers(
                    invitedUsers.filter((usr) => usr.value !== user.value)
                  );
                  return;
                }

                setInvitedUsers((prevUsers) => {
                  return prevUsers.filter((usr) => usr.value !== user.value);
                });
              }}
              setCurrentUser={setCurrentUser}
            />
          );
        })}
      {currentUser && invitedUsers.length ? (
        <div className={classes.currentUser}>
          {currentUser.userProfile?.firstName}{' '}
          {currentUser.userProfile?.lastName} ({currentUser.userName})
        </div>
      ) : null}
    </div>
  );
}
