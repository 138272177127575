import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { get, orderBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import DashboardDropdown from '../../DashboardDropdown';

import useIntersectionObserverPagination from '../../../../../hooks/useIntersectionObserverPagination';
import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';
// import UserService from '../../../../../services/UserService';

const ALERTS_PER_PAGE = 20;

export default function RedAlertJobs() {
  const [data, setData] = useState([]);
  const [elementHeight, setElementHeight] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const defaultCityOption = {
    value: '',
    label: t('dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.All cities'),
  };

  const [city, setCity] = useState(defaultCityOption);

  const defaultRestaruantsOption = {
    value: '',
    label: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.All locations'
    ),
  };

  const [restaurant, setRestaurant] = useState(defaultRestaruantsOption);

  const componentRef = useRef();
  const tableContainerRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: redAlerts } = useQuery({
    queryKey: ['redAlerts', restaurant, city],
    queryFn: () => {
      const params = {
        locationIds: restaurant.value,
        cityIds: city.value,
      };

      if (restaurant.value === '') {
        delete params.locationIds;
      }

      if (city.value === '') {
        delete params.cityIds;
      }

      return AdminService.getRedAlerts(params);
    },
    //   keepPreviousData: true,
  });

  console.log(redAlerts);

  const { data: restaurants } = useQuery({
    queryKey: ['locations'],
    queryFn: () => AdminService.getRestaurants({ pageSize: 9999 }),
  });

  const restaurantOptions = useMemo(() => {
    if (city?.value === '') {
      return [
        defaultRestaruantsOption,
        ...(restaurants?.data ?? []).map((rest) => ({
          value: rest.id,
          label: rest.name,
        })),
      ];
    }

    const options = restaurants?.data
      ?.filter((rest) => {
        if (city) {
          return rest.city.id === city.value;
        }
        return true;
      })
      ?.map((rest) => ({
        value: rest.id,
        label: rest.name,
      }));

    return [defaultRestaruantsOption, ...(options ?? [])];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, restaurants?.data]);

  const { data: cities } = useQuery({
    queryKey: ['cities'],
    queryFn: () => AdminService.getCities({ pageSize: 9999 }),
  });

  const cityOptions = [
    defaultCityOption,
    ...(cities?.data ?? []).map((ct) => ({
      value: ct.id,
      label: ct.name,
    })),
  ];

  const changeSorting = (field) => {
    setSortField(field);
    if (sortField === field) {
      setSortOrder((prevOrder) => {
        if (prevOrder === 'asc') {
          return 'desc';
        }
        return 'asc';
      });
    }
  };

  const switchToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching: false,
    fetchNextPage: switchToNextPage,
  });

  // Set restaurant to default when city changes
  useEffect(() => {
    setRestaurant(defaultRestaruantsOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    const getScrollBarWidth = () => {
      document.body.style.setProperty(
        '--rajScrollBarWidth',
        `${
          tableContainerRef.current?.offsetWidth -
          tableContainerRef.current?.clientWidth
        }px`
      );
    };

    if (tableContainerRef.current) {
      window.addEventListener('resize', () => getScrollBarWidth);
    }

    getScrollBarWidth();

    return () => {
      window.removeEventListener('resize', () => getScrollBarWidth);
    };
  }, [redAlerts]);

  useEffect(() => {
    if (componentRef.current && !isTabletOrMobile) {
      componentRef.current.style.setProperty(
        '--parentHeight',
        `${componentRef.current.parentElement.clientHeight}px`
      );
      setElementHeight(componentRef.current.parentElement.clientHeight);
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (elementHeight && !isTabletOrMobile) {
      componentRef.current.style.setProperty(
        '--maxTableHeight',
        `${elementHeight - 94}px`
      );
    }
  }, [elementHeight, isTabletOrMobile]);

  useEffect(() => {
    if (redAlerts) {
      const redAlertsWithIds = redAlerts?.map((rA, index) => ({
        ...rA,
        id: index,
      }));

      setData(redAlertsWithIds);
    }
  }, [redAlerts]);

  useEffect(() => {
    if (sortField) {
      setData((prevData) => {
        const sortedData = orderBy(
          prevData,
          [
            sortField === 'job.id'
              ? (alert) => get(alert, sortField)
              : (alert) => get(alert, sortField)?.toLowerCase?.(),
          ],
          [sortOrder]
        );

        return sortedData;
      });
    }
  }, [sortField, sortOrder]);

  useEffect(() => {
    setCurrentPage(1);
  }, [city, restaurant]);

  const alertTypes = {
    LongerWait: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.LongerWait'
    ),
    LimitedAppicants: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.LimitedAppicants'
    ),
    NoInterviews: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.NoInterviews'
    ),
    NoOffers: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.NoOffers'
    ),
    Open30Days: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.Open30Days'
    ),
    CandidateIssues: t(
      'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.AlertTypes.CandidateIssues'
    ),
  };

  return (
    <div className={classes.RedAlertJobs} ref={componentRef}>
      {data.length ? null : (
        <div className={classes.noData}>
          <p>
            {t(
              'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.NoRedAlertVacancies'
            )}
          </p>
        </div>
      )}
      <div className={classes.header}>
        <h2>
          {t('dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.Header')}
        </h2>
        <div className={classes.dropdowns}>
          <div className={classes.dropdownContainer}>
            <DashboardDropdown
              currentOption={city}
              setCurrentOption={setCity}
              placeholder={t('common.location')}
              options={cityOptions}
            />
          </div>
          <div className={classes.dropdownContainer}>
            <DashboardDropdown
              options={restaurantOptions}
              currentOption={restaurant}
              setCurrentOption={setRestaurant}
              placeholder={t('common.restaurant')}
            />
          </div>
        </div>
      </div>
      <div className={classes.borderContainer}>
        <div className={classes.border} />
        <div
          className={classNames(classes.tableContainer, {
            [classes.noScroll]: !redAlerts,
          })}
          ref={tableContainerRef}
        >
          <table>
            <thead>
              <tr>
                <th onClick={() => changeSorting('job.id')}>
                  {t('common.jobId')}
                </th>
                <th onClick={() => changeSorting('job.jobTitle')}>
                  {t(
                    'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.JobName'
                  )}
                </th>
                <th onClick={() => changeSorting('job.locations[0].name')}>
                  {t('common.location')}
                </th>
                <th
                  onClick={() =>
                    changeSorting('job.user.userProfile.firstName')
                  }
                >
                  {t('common.recruiter')}
                </th>
                <th onClick={() => changeSorting('type')}>
                  {t(
                    'dashboardComponents.Dashboard.LeftPanel.RedAlertJobs.RedAlertReason'
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice(0, ALERTS_PER_PAGE * currentPage)
                .map((alert, index) => {
                  return (
                    <tr
                      key={alert.id}
                      ref={
                        index === ALERTS_PER_PAGE * currentPage - 1
                          ? lastListElementRef
                          : null
                      }
                    >
                      <td>
                        <Link to={`/tools/jobs/my-jobs/${alert.job.id}`}>
                          {alert.job.id}
                        </Link>
                      </td>

                      <td className={classes.red}>
                        <Link to={`/tools/jobs/my-jobs/${alert.job.id}`}>
                          {alert.job.jobTitle}
                        </Link>
                      </td>
                      <td>
                        {restaurant?.value
                          ? restaurant.label
                          : alert.job.locations[0]?.name}
                      </td>
                      <td>
                        {alert.job.user.userProfile.firstName}{' '}
                        {alert.job.user.userProfile.lastName}
                      </td>
                      <td className={classes.red}>{alertTypes[alert.type]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
