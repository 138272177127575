/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import yupSchemaToFields from '../../../../../helpers/yupSchemaToFields';
import FormButtonsManager from '../../../FormButtonsManager';
import AdminService from '../../../../../services/AdminService';
import { UiContext } from '../../../../../context/UiContext';
// import { getTime } from '../../../../../helpers/getEvents';
import {
  //  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
  getTimezoneDate,
} from '../../../../../helpers/timezones';

import ContactInfo from '../ContactInfo';
import Header from '../Header';
import Details from '../Details';
// import FavStatus from '../../../Header/Search/FavStatus';
import OnboardingDetails from './OnboardingDetails';
// import Button from '../../../../Button';
import classes from './styles.module.scss';
import RadioButton from '../../../../Form/RadioButton';
import Remote from '../../../../../modals/SetOnboardingModal/Remote';
import InPerson from '../../../../../modals/SetOnboardingModal/InPerson';
import UserService from '../../../../../services/UserService';

/* function getCreatedDate(date, noWeekday) {
  const tempDate = new Date(date);

  let formattedDate = `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()} ${tempDate.toLocaleString(
    'en-us',
    {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  )}
  `;

  if (noWeekday) {
    formattedDate = formattedDate.split(',').slice(1).join(',').trim();
  }

  return formattedDate;
}
 */
/* function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
}
 */
export default function Onboarding({
  isEventDetailsVisible,
  handleClose,
  event,
  refetch,
  currentUser,
  setCurrentCandidate,
  setIsCandidateModalVisible,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [onboardingMode, setOnboardingMode] = useState('');
  const [isTriedToSubmitInPersonFormik, setIsTriedToSubmitInPersonFormik] =
    useState(false);
  const [isTriedToSubmitRemoteFormik, setIsTriedToSubmitRemoteFormik] =
    useState(false);

  const { showUnknownErrorModal, showNotification, setIsFetching, showModal } =
    useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (isEventDetailsVisible) {
      setTimeout(() => {
        setIsOpened(true);
      }, 100);
    }
  }, [isEventDetailsVisible]);

  // Set onboarding mode according to server data
  useEffect(() => {
    if (event?.event?.type) {
      setOnboardingMode(event?.event?.type);
    }
  }, [event]);

  const closePopup = () => {
    setIsOpened(false);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const remoteValidationSchema = useMemo(
    () =>
      yup.object({
        onboardingDate: yup.date().required(t('common.requiredField')),
        // completeDate: yup.date().required(t('common.requiredField')),
        userIds: yup.array().required(t('common.requiredField')),
        note: yup.string().trim(),
        startTime: yup.string().trim().required(t('common.requiredField')),
        endTime: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.End time is required'
            )
          )
          .test(
            'less-than-start',
            t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.End Time cannot be lower than Start Time.'
            ),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return endTime > startTime;
            }
          ),
      }),
    [t]
  );

  const inPersonValidationSchema = useMemo(
    () =>
      yup.object({
        onboardingDate: yup.date().required(t('common.requiredField')),
        userIds: yup.array().required(t('common.requiredField')),
        note: yup.string().trim(),
        location: yup.object().required(t('common.requiredField')),
        startTime: yup.string().trim().required(t('common.requiredField')),
        endTime: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.End time is required'
            )
          )
          .test(
            'less-than-start',
            t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.End Time should be greater than Start Time.'
            ),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return endTime > startTime;
            }
          ),
      }),
    [t]
  );

  const isEditAndRemote = isEdit && event?.event?.type === 'Remote';
  const isEditAndInPerson = isEdit && event?.event?.type === 'InPerson';

  const inPersonFormik = useFormik({
    initialValues: {
      onboardingDate:
        isEditAndInPerson && event?.event?.onboardingDate
          ? getTimezoneDate(
              new Date(event?.event?.onboardingDate),
              me?.userProfile?.timezone
            )[0]
          : '',
      location: isEditAndInPerson
        ? {
            label: event?.event?.location?.name,
            value: event?.event?.location?.id,
          }
        : '',
      note: isEditAndInPerson && event?.event?.note ? event?.event?.note : '',
      startTime:
        isEditAndInPerson && event?.event?.startTime
          ? getTimezoneTime(event?.event?.startTime, me?.userProfile?.timezone)
          : '',
      endTime:
        isEditAndInPerson && event?.event?.endTime
          ? getTimezoneTime(event?.event?.endTime, me?.userProfile?.timezone)
          : '',
      userIds: isEditAndInPerson
        ? event?.event?.users?.map((user) => ({
            label: user.userProfile?.fullName,
            value: user.id,
          }))
        : '',
    },
    validationSchema: inPersonValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        /*  if (isEdit) {
          await AdminService.deleteJobApplicationOnboarding({
            jobApplicationId: event?.jobApplicationId,
            onboardingId: event?.id,
          });
        }
 */
        await AdminService.changeJobApplicationOnboarding({
          jobApplicationId: event?.jobApplicationId,
          onboardingDate: setTimezoneDate(
            values.onboardingDate,
            values.startTime,
            me?.userProfile.timezone
          ),
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile.timezone),
          locationName: values.location.label,
          locationId: values.location.value,
          note: values.note,
          userIds: values.userIds.map((user) => user.value),
          type: 'InPerson',
          connectionDetail: values.connectionDetail,
          onboardingId: event?.id,
          status: event?.event?.status,
        });

        /*    await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: event?.jobApplicationId,
          status: 'Onboard',
          subStatus: 'Scheduled',
        }); */

        handleClose();
        await refetch();
        closeAndNotify(
          t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding updated successfully'
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const remoteFormik = useFormik({
    initialValues: {
      onboardingDate: isEditAndRemote
        ? getTimezoneDate(
            new Date(event?.event?.onboardingDate),
            me?.userProfile?.timezone
          )[0]
        : '',
      // completeDate: isEditAndRemote ? new Date(event?.event?.completeDate) : '',
      note: isEditAndRemote ? event?.event?.note : '',
      connectionDetail: isEditAndRemote ? event?.event?.connectionDetail : '',
      startTime:
        isEditAndRemote && event?.event?.startTime
          ? getTimezoneTime(event?.event?.startTime, me?.userProfile?.timezone)
          : '',
      endTime:
        isEditAndRemote && event?.event?.endTime
          ? getTimezoneTime(event?.event?.endTime, me?.userProfile?.timezone)
          : '',
      userIds: isEditAndRemote
        ? event?.event?.users?.map((user) => ({
            label: user.userProfile?.fullName,
            value: user.id,
          }))
        : '',
    },
    validationSchema: remoteValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationOnboarding({
          jobApplicationId: event?.jobApplicationId,
          onboardingDate: setTimezoneDate(
            values.onboardingDate,
            values.startTime,
            me?.userProfile.timezone
          ),
          completeDate: values.completeDate,
          note: values.note,
          userIds: values.userIds.map((user) => user.value),
          type: 'Remote',
          connectionDetail: values.connectionDetail,
          onboardingId: event?.id,
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile.timezone),
          status: event?.event?.status,
        });
        /* 
        await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: event?.jobApplicationId,
          status: 'Onboard',
          subStatus: 'Scheduled',
        }); */

        await refetch();
        handleClose();
        closeAndNotify(
          t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding updated successfully'
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const declineEvent = async () => {
    setIsFetching(true);

    if (event.userId === currentUser.id) {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          await AdminService.changeJobApplicationInterview({
            onboardingId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            onboardingDate: event?.event?.onboardingDate,
            startTime: event?.event?.startTime,
            endTime: event?.event?.startEnd,
            location: event?.event?.location,
            completeDate: event?.event?.completeDate,
            note: event?.event?.note,
            userIds: event?.event?.users.map((user) => user.id),
            type: event?.event?.type,
            connectionDetail: event?.event?.connectionDetail,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };
      try {
        await AdminService.changeJobApplicationOnboarding({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          onboardingDate: event?.event?.onboardingDate,
          connectionDetail: event?.event?.connectionDetail,
          startTime: event?.event?.startTime,
          endTime: event?.event?.startEnd,
          location: event?.event?.location,
          completeDate: event?.event?.completeDate,
          note: event?.event?.note,
          userIds: event?.event?.users.map((user) => user.id),
          type: event?.event?.type,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      const currentInvite = event?.event?.jobApplicationOnboardingInvites.find(
        (invite) => invite?.userId === currentUser?.id
      );
      const prevStatus = currentInvite?.status;
      const undo = async () => {
        try {
          setIsFetching(true);
          await AdminService.changeJobApplicationOnboardingInvite({
            onboardingId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite.id,
            note: currentInvite.note,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };
      try {
        await AdminService.changeJobApplicationOnboardingInvite({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding invitation declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  const acceptEvent = async () => {
    setIsFetching(true);
    if (event.userId === currentUser.id) {
      try {
        await AdminService.changeJobApplicationOnboarding({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          onboardingDate: event?.event?.onboardingDate,
          connectionDetail: event?.event?.connectionDetail,
          startTime: event?.event?.startTime,
          endTime: event?.event?.startEnd,
          location: event?.event?.location,
          completeDate: event?.event?.completeDate,
          note: event?.event?.note,
          userIds: event?.event?.users.map((user) => user.id),
          type: event?.event?.type,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      const currentInvite = event?.event?.jobApplicationOnboardingInvites.find(
        (invite) => invite?.userId === currentUser?.id
      );
      try {
        await AdminService.changeJobApplicationOnboardingInvite({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding invitation accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  const cancelEvent = () => {
    const prevStatus = event?.event?.status;
    const undo = async () => {
      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationInterview({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          onboardingDate: event?.event?.onboardingDate,
          connectionDetail: event?.event?.connectionDetail,
          startTime: event?.event?.startTime,
          endTime: event?.event?.startEnd,
          location: event?.event?.location,
          completeDate: event?.event?.completeDate,
          note: event?.event?.note,
          userIds: event?.event?.users.map((user) => user.id),
          type: event?.event?.type,
          status: prevStatus,
        });
        await refetch();
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    };

    showModal({
      icon: 'infoIcon',
      title: t(
        'dashboardComponents.Calendar.EventDetails.Onboarding.Cancel Onboarding'
      ),
      text: t(
        'dashboardComponents.Calendar.EventDetails.Onboarding.Are you sure you want to cancel?'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationOnboarding({
            onboardingId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            onboardingDate: event?.event?.onboardingDate,
            startTime: event?.event?.startTime,
            endTime: event?.event?.startEnd,
            location: event?.event?.location,
            completeDate: event?.event?.completeDate,
            note: event?.event?.note,
            userIds: event?.event?.users.map((user) => user.id),
            type: event?.event?.type,
            status: 'Canceled',
          });
          await refetch();
          showNotification({
            text: t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding canceled'
            ),
            undo,
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: 'No',
      confirmButtonLabel: 'Yes',
    });
  };

  const status = event?.event?.status;

  let onLeftButtonClick = declineEvent;
  let onRightButtonClick = acceptEvent;
  let leftButtonLabel = t('common.decline');
  let rightButtonLabel = t('common.accept');

  if (currentUser?.id === event?.event?.organizer.id) {
    onLeftButtonClick = cancelEvent;
    onRightButtonClick = () => {
      setIsEdit(true);
    };
    leftButtonLabel = t('common.cancel');
    rightButtonLabel = '';
  }

  if (isEdit) {
    onLeftButtonClick = null;
    onRightButtonClick =
      onboardingMode === 'Remote'
        ? () => {
            setIsTriedToSubmitRemoteFormik(true);
            remoteFormik.handleSubmit();
          }
        : () => {
            setIsTriedToSubmitInPersonFormik(true);
            inPersonFormik.handleSubmit();
          };
    leftButtonLabel = '';
    rightButtonLabel = '';
  }

  return (
    <div
      className={classNames(classes.blur, {
        [classes.blurActivated]: isEventDetailsVisible,
      })}
      onClick={closePopup}
    >
      <div
        className={classNames(classes.container, {
          [classes.visible]: isOpened,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.event}>
          <Header
            status={status}
            event={event}
            refetch={refetch}
            currentUser={currentUser}
            isOnboarding
            title={t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Onboarding'
            )}
          />

          <Details
            event={event}
            setCurrentCandidate={setCurrentCandidate}
            setIsCandidateModalVisible={setIsCandidateModalVisible}
          />

          <div
            className={classNames(classes.details, {
              [classes.detailsEdit]: isEdit,
            })}
          >
            {isEdit ? (
              <>
                <div className={classes.editContainer}>
                  <div className={classes.row}>
                    <div className={classes.onboardingModeSelector}>
                      <h2>
                        {t(
                          'dashboardComponents.Calendar.EventDetails.Onboarding.Choose Onboarding Mode'
                        )}
                      </h2>
                      <div className={classes.radios}>
                        <RadioButton
                          isSelected={onboardingMode === 'InPerson'}
                          label={t(
                            'dashboardComponents.Calendar.EventDetails.Onboarding.In-Person'
                          )}
                          onClick={() => setOnboardingMode('InPerson')}
                        />
                        <RadioButton
                          isSelected={onboardingMode === 'Remote'}
                          label={t(
                            'dashboardComponents.Calendar.EventDetails.Onboarding.Remote'
                          )}
                          onClick={() => setOnboardingMode('Remote')}
                        />
                      </div>
                    </div>
                  </div>
                  {onboardingMode === 'Remote' && (
                    <div className={classes.remote}>
                      <Remote
                        formik={remoteFormik}
                        isPopupOpened
                        calendar
                        isTriedToSubmit={isTriedToSubmitRemoteFormik}
                      />
                    </div>
                  )}
                  {onboardingMode === 'InPerson' && (
                    <div className={classes.inperson}>
                      <InPerson
                        formik={inPersonFormik}
                        isTriedToSubmit={isTriedToSubmitInPersonFormik}
                        isPopupOpened
                        calendar
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <OnboardingDetails event={event} me={me} />
            )}
          </div>

          {!isEdit && <ContactInfo event={event} />}
        </div>
        <div className={classes.buttons}>
          <FormButtonsManager
            buttonStyle={{
              width: 250,
              height: 55,
            }}
            mode={isEdit ? 'edit' : 'view'}
            onLeftButtonClick={onLeftButtonClick}
            onRightButtonClick={onRightButtonClick}
            formik={onboardingMode === 'Remote' ? remoteFormik : inPersonFormik}
            fields={yupSchemaToFields(
              onboardingMode === 'Remote'
                ? remoteValidationSchema
                : inPersonValidationSchema
            )}
            leftButtonLabel={leftButtonLabel}
            rightButtonLabel={rightButtonLabel}
          />
        </div>
        <div className={classes.closeIconContainer}>
          <i className={classes.closeIcon} onClick={closePopup}>
            Close
          </i>
        </div>
      </div>
    </div>
  );
}
