import React, { useCallback } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Question from './Question';
import AdminService from '../../../../../../../../services/AdminService';

export default function Questions({
  vrQuestions,
  setVrQuestions,
  areVrQuestionsValid,
  isTriedToSubmit,
  isJobPostForm,
}) {
  const deleteQuestion = (id) => {
    setVrQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.id !== id)
    );
  };

  const setOption = useCallback(
    async (id, option) => {
      try {
        let existingQuestion;
        if (option.value) {
          existingQuestion = await AdminService.getVrQuestion({
            id: option.value,
          });
        }

        setVrQuestions((prevQuestions) =>
          prevQuestions.map((question) => {
            if (question.id !== id) {
              return question;
            }

            return {
              ...question,
              savedQuestion: {
                ...option,
                question: existingQuestion?.question,
                duration: existingQuestion?.duration,
              },
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    [setVrQuestions]
  );

  const setDuration = useCallback(
    (id, duration) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.id !== id) {
            return question;
          }
          return { ...question, duration };
        })
      );
    },
    [setVrQuestions]
  );

  const setTitle = useCallback(
    (id, title) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.id !== id) {
            return question;
          }
          return { ...question, title };
        })
      );
    },
    [setVrQuestions]
  );

  const setQuestion = useCallback(
    (id, question) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((qstn) => {
          if (qstn.id !== id) {
            return qstn;
          }
          return { ...qstn, question };
        })
      );
    },
    [setVrQuestions]
  );

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newArray = Array.from(vrQuestions);
    const [removed] = newArray.splice(sourceIndex, 1);
    newArray.splice(destinationIndex, 0, removed);

    setVrQuestions(newArray);
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="assessments">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {vrQuestions?.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={`item-$${question.id}-${question.title}`}
                  index={index}
                >
                  {(provid) => (
                    <div
                      {...provid.draggableProps}
                      {...provid.dragHandleProps}
                      ref={provid.innerRef}
                    >
                      <Question
                        key={question.id}
                        vrQuestions={vrQuestions}
                        question={question}
                        onDelete={deleteQuestion}
                        value={question.savedQuestion}
                        setOption={setOption}
                        setDuration={setDuration}
                        setQuestion={setQuestion}
                        setTitle={setTitle}
                        areVrQuestionsValid={areVrQuestionsValid}
                        isTriedToSubmit={isTriedToSubmit}
                        isJobPostForm={isJobPostForm}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
