import React from 'react';

import Lottie from 'lottie-react';

import classes from './styles.module.scss';
import loaderAnimation from '../../../../../../assets/animations/loader.json';

export default function Loader() {
  return (
    <div className={classes.Loader}>
      <Lottie animationData={loaderAnimation} loop />
    </div>
  );
}
