/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';

import classes from './styles.module.scss';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Pdf from './Pdf';
import Word from './Word';

export default function WordResume({
  candidate,
  isModal,
  currentForm,
  addCandidateModal,
  deleteResume,
  formik,
  resumeFieldName,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
  openResumeInput,
}) {
  const [isPdf, setIsPdf] = useState(false);

  const resume = useMemo(
    () =>
      candidate?.jobApplicationAttachments?.[0] ||
      candidate?.userProfile?.userProfileFileResume ||
      candidate?.user?.userProfile?.userProfileFileResume ||
      '',
    [candidate]
  );

  useEffect(() => {
    const name = resume?.fileName || resume?.name || '';
    if (name.endsWith('pdf')) {
      setIsPdf(true);
      return;
    }

    setIsPdf(false);
  }, [resume]);

  return (
    <div className={classes.WordResume}>
      {isPdf ? (
        <Pdf
          candidate={candidate}
          currentForm={currentForm}
          isModal={isModal}
          resume={resume}
          addCandidateModal={addCandidateModal}
          deleteResume={deleteResume}
          formik={formik}
          resumeFieldName={resumeFieldName}
          isPossibleToEdit={isPossibleToEdit}
          isReadOnly={isReadOnly}
          isEdit={isEdit}
          openResumeInput={openResumeInput}
        />
      ) : (
        <Word
          isModal={isModal}
          resume={resume}
          candidate={candidate}
          addCandidateModal={addCandidateModal}
          deleteResume={deleteResume}
          formik={formik}
          resumeFieldName={resumeFieldName}
          isPossibleToEdit={isPossibleToEdit}
          isReadOnly={isReadOnly}
          isEdit={isEdit}
          openResumeInput={openResumeInput}
        />
      )}
    </div>
  );
}
