/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Slide from './Slide';
import Dot from './Dot';

import carouselImage1 from '../../../assets/images/carousel/image-1.jpg';
import carouselImage2 from '../../../assets/images/carousel/image-2.jpg';
import carouselImage3 from '../../../assets/images/carousel/image-3.jpg';
import carouselImage4 from '../../../assets/images/carousel/image-4.jpg';
import classes from './styles.module.scss';
import useResizeObserver from '../../../hooks/useResizeObserver';

let timer;
let timer2;
let timer3;
const slidesSwitchInterval = 5000;

// const offsets = [65, -531, -1127, -1723, -2319];

export default function AuthCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(false);
  const [activeDot, setActiveDot] = useState(0);

  const { t } = useTranslation();

  const frameRef = useRef();
  const { width, height } = useResizeObserver(frameRef);

  const slidesData = [
    {
      id: 0,
      title: t('common.AuthCarousel.titles.0'),
      text: t('common.AuthCarousel.texts.0'),
      image: carouselImage1,
    },
    {
      id: 1,
      title: t('common.AuthCarousel.titles.1'),
      text: t('common.AuthCarousel.texts.1'),
      image: carouselImage2,
    },
    {
      id: 2,
      title: t('common.AuthCarousel.titles.2'),
      text: t('common.AuthCarousel.texts.2'),
      image: carouselImage3,
    },
    {
      id: 3,
      title: t('common.AuthCarousel.titles.3'),
      text: t('common.AuthCarousel.texts.3'),
      image: carouselImage4,
    },
  ];

  const switchSlidesOnTimer = useCallback(() => {
    setActiveDot((prevDot) => {
      if (prevDot + 1 < slidesData.length) {
        return prevDot + 1;
      }
      return 0;
    });
    setCurrentSlide((prevSlide) => {
      if (prevSlide + 1 <= slidesData.length + 1) {
        return prevSlide + 1;
      }
      return prevSlide;
    });
  }, [slidesData.length]);

  // Stop the animation timer when the tab is not visible
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      timer = setInterval(switchSlidesOnTimer, slidesSwitchInterval);
    } else {
      clearInterval(timer);
    }
  }, [switchSlidesOnTimer]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    timer = setInterval(switchSlidesOnTimer, slidesSwitchInterval);

    return () => {
      clearInterval(timer);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange, switchSlidesOnTimer]);

  useEffect(() => {
    if (currentSlide === slidesData.length) {
      timer2 = setTimeout(() => {
        setIsAnimationDisabled(true);
        setCurrentSlide(0);
      }, 290);

      timer3 = setTimeout(() => {
        setIsAnimationDisabled(false);
      }, 310);
    }
  }, [currentSlide, slidesData.length]);

  const switchToSlide = (slideIndex) => {
    clearInterval(timer);
    clearTimeout(timer2);
    clearTimeout(timer3);
    setActiveDot(slideIndex);
    setCurrentSlide(slideIndex);

    setTimeout(() => {
      timer = setInterval(switchSlidesOnTimer, slidesSwitchInterval);
    }, 300);
  };

  return (
    <div
      className={classNames(classes.AuthCarousel, {
        [classes.noAnimation]: isAnimationDisabled,
      })}
    >
      <div className={classes.background} ref={frameRef}>
        <div
          className={classes.carouselSlideContainer}
          style={{ right: currentSlide * -width - currentSlide * 65 }}
        >
          {slidesData.map((slide) => (
            <Slide
              key={slide.id}
              text={slide.text}
              image={slide.image}
              width={width}
              height={height}
              title={slide.title}
            />
          ))}
          <Slide
            text={slidesData[0].text}
            image={slidesData[0].image}
            width={width}
            height={height}
            title={slidesData[0].title}
          />
        </div>
        <div className={classes.dots}>
          {Array(4)
            .fill(null)
            .map((_, i) => i)
            .map((dot, index) => (
              <Dot
                key={dot}
                active={index === activeDot}
                onClick={() => switchToSlide(index)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
