/* eslint-disable no-param-reassign */
import isEqual from 'lodash/isEqual';

export const createVrQuestionsFromServerData = (job) => {
  if (!job?.videoResumeQuestions) {
    return [];
  }

  return job?.videoResumeQuestions
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((qstn) => ({
      id: qstn.id,
      savedQuestion: {
        value: qstn.id,
        label: qstn.title,
        question: qstn.question,
        duration: qstn.duration,
        sortOrder: qstn.sortOrder,
        isDefault: qstn.isDefault,
      },
      duration: qstn.duration,
      title: qstn.title,
      question: qstn.question,
      isDefault: qstn.isDefault,
    }));
};

export const createAssignedAssessments = (assessments) => {
  if (!assessments) {
    return [];
  }
  return assessments.map((assessment, index) => ({
    tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
    assessmentProviderKey: assessment.assessmentProviderKey,
    assessmentId: assessment.assessmentId,
    threshold: `${assessment.threshold}`,
    englishThreshold: `${assessment.englishThreshold}`,
    mentalThreshold: `${assessment.mentalThreshold}`,
    limit: assessment.limit,
    sortOrder: index + 1,
  }));
};

export const isDirty = ({
  basicInfoFormik,
  vrFormik,
  job,
  vrQuestions,
  delegatesFormik,
  assessments,
  initialAssessments,
}) => {
  if (basicInfoFormik.dirty || vrFormik.dirty || delegatesFormik.dirty) {
    return true;
  }

  const initialVrQuestions = createVrQuestionsFromServerData(job);

  if (!isEqual(vrQuestions, initialVrQuestions)) {
    return true;
  }

  if (
    !isEqual(assessments, initialAssessments) &&
    vrFormik.values.isAssessmentNeeded
  ) {
    return true;
  }

  return false;
};
