import React from 'react';

import Chat from './Chat';
import Onboarding from './Onboarding';
import Offer from './Offer';
import Job from './Job';
import New from './New';
import Interview from './Interview';
import Default from './Default';
import Feed from './Feed';
import Auth from './Auth';
import Candidate from './Candidate';
import Screen from './Screen';

import { useUiContext } from '../../../../context/UiContext';
import NotificationsService from '../../../../services/NotificationsService';
import classes from './styles.module.scss';

export default function Notification({
  notification,
  refetch,
  hideNotifications,
  userTimezone,
}) {
  const { setIsFetching } = useUiContext();

  const deleteNotification = async (event) => {
    try {
      setIsFetching(true);
      event.stopPropagation();
      await NotificationsService.deleteNotification({
        notificationId: notification.id,
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const readNotification = async () => {
    try {
      if (!notification.readAt) {
        setIsFetching(true);
        await NotificationsService.readNotification({
          notificationId: notification.id,
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const selectComponent = () => {
    switch (notification.category) {
      case 'Feed':
        return Feed;
      case 'Interview':
        return Interview;
      case 'New':
        return New;
      case 'Job':
        return Job;
      case 'Offer':
        return Offer;
      case 'Onboarding':
        return Onboarding;
      case 'Chat':
        return Chat;
      case 'Auth':
        return Auth;
      case 'Candidate':
        return Candidate;
      case 'Screen':
        return Screen;
      default:
        return Default;
    }
  };

  const isRead = !!notification.readAt;

  const Component = selectComponent();

  return (
    <div onClick={readNotification}>
      <Component
        classes={classes}
        hideNotifications={hideNotifications}
        notification={notification}
        deleteNotification={deleteNotification}
        refetch={refetch}
        isRead={isRead}
        userTimezone={userTimezone}
      />
    </div>
  );
}
