import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import plusIcon from '../../../../../../../../assets/images/dashboard/candidates/white-plus.svg';

export default function ResumeInput({ resumeInputRef }) {
  const { t } = useTranslation();

  return (
    <div className={classes.ResumeInput}>
      <h4 className={classes.label}>
        {t(
          'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Add Resume'
        )}
      </h4>
      <div
        className={classes.input}
        onClick={() => {
          resumeInputRef.current.click();
        }}
      >
        <span>
          Browse & Add <br /> (Word or PDF only)
        </span>
        <button type="button" className={classes.addButton}>
          <img src={plusIcon} alt="Add" />
        </button>
      </div>
    </div>
  );
}
