/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';

import Custom from './Custom';
import NewRegistration from './NewRegistration';
import NewHire from './NewHire';

import UserService from '../../../../../../services/UserService';
import useIntersectionObserver from '../../../../../../hooks/useIntersectionObserver';
import classes from './styles.module.scss';

/* const saveReadStatus = (id) => {
  const readAnnouncementIds = JSON.parse(
    localStorage.getItem('readAnnouncementIds') || '[]'
  );
  if (!readAnnouncementIds.includes(id)) {
    readAnnouncementIds.push(id);
    localStorage.setItem(
      'readAnnouncementIds',
      JSON.stringify(readAnnouncementIds)
    );
  }
};

const isAnnouncementRead = (id) => {
  const readAnnouncementIds = JSON.parse(
    localStorage.getItem('readAnnouncementIds') || '[]'
  );
  return readAnnouncementIds.includes(id);
}; */

export default function Announcement({ feedPost }) {
  const [isViewsPopupVisible, setIsViewsPopupVisible] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const announcementRef = useRef();

  const [setNode, entry] = useIntersectionObserver({ threshold: 1 });

  const { data: me, isFetching } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const iHaveReadThis = feedPost.views.some(
    (view) => view.author.id === me?.id
  );

  useEffect(() => {
    if (announcementRef.current) {
      setNode(announcementRef.current);
    }
  }, [setNode]);

  const { isIntersecting } = entry;

  useEffect(() => {
    if (isIntersecting) {
      setTimeout(() => {
        setIsRead(true);
      }, 1000);
    }
  }, [isIntersecting]);

  let announcementComponent = (
    <Custom
      classes={classes}
      feedPost={feedPost}
      isFetching={isFetching}
      isRead={isRead}
      isIntersecting={isIntersecting}
      iHaveReadThis={iHaveReadThis}
      setIsViewsPopupVisible={setIsViewsPopupVisible}
      isViewsPopupVisible={isViewsPopupVisible}
    />
  );
  const announcementType = feedPost.systemAnnouncementType || 'Custom';

  if (announcementType === 'NewRegistration') {
    announcementComponent = (
      <NewRegistration classes={classes} feedPost={feedPost} />
    );
  }

  if (announcementType === 'NewHire') {
    announcementComponent = <NewHire classes={classes} feedPost={feedPost} />;
  }

  return (
    <div className={classes.Announcement} ref={announcementRef}>
      {announcementComponent}
    </div>
  );
}
