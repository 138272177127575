import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Tooltip from '../../Tooltip';
import NavButton from './NavButton';
import TopMenu from '../Header/TopMenu';

import discover from '../../../assets/images/dashboard/nav/discover.svg';
import discoverActive from '../../../assets/images/dashboard/nav/discover-active.svg';
// import discoverHover from '../../../assets/images/dashboard/nav/discover-hover.svg';

import dashboard from '../../../assets/images/dashboard/nav/dashboard.svg';
import dashboardActive from '../../../assets/images/dashboard/nav/dashboard-active.svg';
// import dashboardHover from '../../../assets/images/dashboard/nav/dashboard-hover.svg';

import jobs from '../../../assets/images/dashboard/nav/jobs.svg';
import jobsActive from '../../../assets/images/dashboard/nav/jobs-active.svg';
// import jobsHover from '../../../assets/images/dashboard/nav/jobs-hover.svg';

import candidates from '../../../assets/images/dashboard/nav/candidates.svg';
import candidatesActive from '../../../assets/images/dashboard/nav/candidates-active.svg';
// import candidatesHover from '../../../assets/images/dashboard/nav/candidates-hover.svg';

import calendar from '../../../assets/images/dashboard/nav/calendar.svg';
import calendarActive from '../../../assets/images/dashboard/nav/calendar-active.svg';
// import calendarHover from '../../../assets/images/dashboard/nav/calendar-hover.svg';

import reports from '../../../assets/images/dashboard/nav/reports.svg';
import reportsActive from '../../../assets/images/dashboard/nav/reports-active.svg';
// import reportsHover from '../../../assets/images/dashboard/nav/reports-hover.svg';

import consoleIcon from '../../../assets/images/dashboard/nav/console.svg';
import consoleActive from '../../../assets/images/dashboard/nav/console-active.svg';
// import consoleHover from '../../../assets/images/dashboard/nav/console-hover.svg';

import discoverHover from '../../../assets/images/dashboard/nav/hover-state/discover-hover.svg';
import adminHover from '../../../assets/images/dashboard/nav/hover-state/admin-hover.svg';
import calendarHover from '../../../assets/images/dashboard/nav/hover-state/calendar-hover.svg';
import candidatesHover from '../../../assets/images/dashboard/nav/hover-state/candidates-hover.svg';
import dashboardHover from '../../../assets/images/dashboard/nav/hover-state/dashboard-hover.svg';
import jobsHover from '../../../assets/images/dashboard/nav/hover-state/jobs-hover.svg';
import reportsHover from '../../../assets/images/dashboard/nav/hover-state/reports-hover.svg';

import getResizedImageUrl from '../../../helpers/getResizedImageUrl';
import { useUserStore, useUiStore } from '../../../store';
import UserService from '../../../services/UserService';
import AdminService from '../../../services/AdminService';
import onlineConnection from '../../../helpers/onlineUsers';
import { clearAuthData } from '../../../helpers/authStorage';
import logo from '../../../assets/images/logo-small.png';
import classes from './styles.module.scss';
import { PreventNavigation } from '../../../context/PreventNavigationContext';

function hasScrollbar(element) {
  if (!element) {
    return { vertical: false, horizontal: false };
  }

  const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
  const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
  return {
    vertical: hasVerticalScrollbar,
    horizontal: hasHorizontalScrollbar,
  };
}

export default function SideMenu() {
  const [visibleSubmenu, setVisibleSubmenu] = useState('');
  const [isOverflowing, setIsOverflowing] = useState(false);

  const sideMenuRef = useRef();

  const { isNavigationAllowed, setShowModal, setNavigationLink } =
    useContext(PreventNavigation);

  const isMobileSidebarVisible = useUiStore(
    (state) => state.isMobileSidebarVisible
  );
  const toggleMobileSidebar = useUiStore((state) => state.toggleMobileSidebar);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({
    query: location.pathname.includes('discover')
      ? '(max-width: 1224px)'
      : '(max-width: 1024px)',
  });

  const links = useMemo(
    () => [
      {
        path: '/tools/discover',
        label: t('dashboardComponents.SideMenu.menuLabels.Discover'),
        icon: discover,
        iconHover: discoverHover,
        iconActive: discoverActive,
        scopes: [
          'api:full',
          'api:admin:jobs',
          'api:admin:candidates',
          'api:admin:read',
        ],
      },
      {
        path: '/tools/dashboard',
        label: t('dashboardComponents.SideMenu.menuLabels.Dashboard'),
        icon: dashboard,
        iconHover: dashboardHover,
        iconActive: dashboardActive,
        scopes: [
          'api:full',
          'api:admin:jobs',
          'api:admin:candidates',
          'api:admin:read',
        ],
      },
      {
        path: '/tools/jobs',
        label: t('dashboardComponents.SideMenu.menuLabels.Jobs'),
        icon: jobs,
        iconHover: jobsHover,
        iconActive: jobsActive,
        resetPath: '/tools/jobs/all-jobs',
        submenu: [
          {
            label: t('dashboardComponents.SideMenu.menuLabels.My Jobs'),
            path: '/tools/jobs/my-jobs',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.All Jobs'),
            path: '/tools/jobs/all-jobs',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Post a Job'),
            path: '/tools/jobs/post-job',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Favorites'),
            path: '/tools/jobs/favorites',
          },
        ],
        scopes: ['api:full', 'api:admin:jobs'],
      },
      {
        path: '/tools/candidates',
        label: t('dashboardComponents.SideMenu.menuLabels.Candidates'),
        icon: candidates,
        iconHover: candidatesHover,
        iconActive: candidatesActive,
        submenu: [
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Search'),
            path: '/tools/candidates/search',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.My Hires'),
            path: '/tools/candidates/my-hires',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Company Hires'),
            path: '/tools/candidates/company-hires',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Recently Viewed'),
            path: '/tools/candidates/recently-viewed',
          },
          { label: 'Favorites', path: '/tools/candidates/favorites' },
        ],
        scopes: ['api:full', 'api:admin:candidates'],
      },
      {
        path: '/tools/calendar',
        label: t('dashboardComponents.SideMenu.menuLabels.Calendar'),
        icon: calendar,
        iconHover: calendarHover,
        iconActive: calendarActive,
        submenu: [
          {
            label: t('dashboardComponents.SideMenu.menuLabels.My Calendar'),
            path: '/tools/calendar/my-calendar',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Team Calendar'),
            path: '/tools/calendar/team-calendar',
          },
          {
            label: t(
              'dashboardComponents.SideMenu.menuLabels.Schedule Interview'
            ),
            path: '/tools/calendar/schedule-interview',
          },
        ],
        scopes: [
          'api:full',
          'api:admin:jobs',
          'api:admin:candidates',
          'api:admin:read',
        ],
      },
      {
        path: '/tools/reports',
        label: t('dashboardComponents.SideMenu.menuLabels.Reports'),
        icon: reports,
        iconHover: reportsHover,
        iconActive: reportsActive,
        submenu: [
          { label: 'XX', path: '/tools/reports/xx' },
          { label: 'XXX', path: '/tools/reports/xxx' },
        ],
        scopes: ['api:full', 'api:admin:jobs', 'api:admin:candidates'],
      },
      {
        path: '/tools/admin',
        label: t('dashboardComponents.SideMenu.menuLabels.Admin'),
        icon: consoleIcon,
        iconHover: adminHover,
        iconActive: consoleActive,
        resetPath: '/tools/admin/user-management',
        submenu: [
          {
            label: t('dashboardComponents.SideMenu.menuLabels.User Management'),
            path: '/tools/admin/user-management',
          },
          {
            label: t(
              'dashboardComponents.SideMenu.menuLabels.Restaurant Management'
            ),
            path: '/tools/admin/restaurant-management',
          },
          {
            label: t('dashboardComponents.SideMenu.menuLabels.Job Management'),
            path: '/tools/admin/job-management',
          },
          {
            label: t(
              'dashboardComponents.SideMenu.menuLabels.Ref Data Management'
            ),
            path: '/tools/admin/ref-data-management',
          },
          {
            label: t(
              'dashboardComponents.SideMenu.menuLabels.Content Management'
            ),
            path: '/tools/admin/content-management',
          },
        ],
        scopes: ['api:full', 'api:admin:auth', 'api:admin:reference'],
      },
    ],
    [t]
  );

  const setUserScopes = useUserStore((state) => state.setUserScopes);
  const setFirstAvailableLink = useUserStore(
    (state) => state.setFirstAvailableLink
  );

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { refetch: refetchOnlineUsers } = useQuery({
    queryKey: ['onlineUsers'],
    queryFn: () => AdminService.getOnlineUsers(),
  });

  const { data: tenantSettings } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const connection = useMemo(() => onlineConnection(), []);

  const logout = () => {
    connection.endConnection();
    clearAuthData();
    navigate('/login', { replace: true });
  };

  const navigateToSettings = () => {
    navigate('/tools/settings/profile', { replace: true });
  };

  useEffect(() => {
    connection?.startConnection();
  }, [connection]);

  useEffect(() => {
    const newConnection = connection.connection;

    if (newConnection) {
      newConnection.on('UserDidJoined', () => {
        refetchOnlineUsers();
      });

      newConnection.on('UserDidDisconnected', () => {
        refetchOnlineUsers();
      });
    }
  }, [connection, refetchOnlineUsers]);

  const availableLinks = useMemo(() => {
    if (!user) return [];

    const userScopes = [];
    user.roles.forEach((role) => {
      const scope = role.scopes.split(',');
      userScopes.push(...scope);
    });
    const uiqueUserScopes = [...new Set(userScopes)];
    setUserScopes(uiqueUserScopes);

    const availableLnks = links.filter((link) => {
      const scopes = link.scopes || [];
      return scopes.some((scope) => uiqueUserScopes.includes(scope));
    });
    setFirstAvailableLink(availableLnks?.[0].path);

    return availableLnks;
  }, [setFirstAvailableLink, setUserScopes, user, links]);

  const logoUrl = tenantSettings?.brand?.logo
    ? getResizedImageUrl({
        url: `brand/${tenantSettings.brand.logo.split('/').pop()}`,
        width: 180,
      })
    : logo;

  useEffect(() => {
    let timer;
    const checkIfOverflowing = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (isTabletOrMobile) {
          setIsOverflowing(false);
          return;
        }
        const isScrollable = hasScrollbar(sideMenuRef.current)?.vertical;
        setIsOverflowing(!!isScrollable);
      }, 100);
    };

    window.addEventListener('resize', checkIfOverflowing);

    checkIfOverflowing();

    return () => {
      window.removeEventListener('resize', checkIfOverflowing);
    };
  }, [isTabletOrMobile]);

  return (
    <>
      {isMobileSidebarVisible && (
        <div
          className={classNames(classes.backdrop, {
            [classes.mobile]: isTabletOrMobile,
          })}
          onClick={toggleMobileSidebar}
        />
      )}
      <div
        ref={sideMenuRef}
        className={classNames(classes.SideMenu, {
          [classes.open]: isMobileSidebarVisible,
          [classes.mobile]: isTabletOrMobile,
          [classes.overflow]: isOverflowing.vertical,
        })}
      >
        <div className={classes.container}>
          <div className={classes.switch} onClick={toggleMobileSidebar} />
          <div className={classes.logo}>
            <img src={logoUrl} alt="Logo" />
            <span className={classes.powered}>
              {t('dashboardComponents.SideMenu.poweredBy')}
            </span>
            <span className={classes.zaphire}>Zaphire</span>
          </div>
          <nav>
            <ul className={classes.buttonsList}>
              {availableLinks.map((link) => (
                <NavButton
                  key={link.label}
                  label={link.label}
                  path={link.path}
                  icon={link.icon}
                  iconHover={link.iconHover}
                  iconActive={link.iconActive}
                  visibleSubmenu={visibleSubmenu}
                  setVisibleSubmenu={setVisibleSubmenu}
                  resetPath={link.resetPath}
                  hasScroll={isOverflowing}
                  // subMenu={link.submenu}
                />
              ))}
            </ul>
          </nav>
          <div className={classes.bottomBlock}>
            <Tooltip
              text={t('dashboardComponents.SideMenu.optionsTooltip')}
              direction={isTabletOrMobile ? 'top-left' : 'top-right'}
              fixed={!isTabletOrMobile}
            >
              <i
                className={classNames(classes.options, {
                  [classes.activeOptions]:
                    location.pathname.includes('/tools/setting'),
                })}
                onClick={() => {
                  if (!isNavigationAllowed) {
                    setShowModal(true);
                    setNavigationLink('/tools/settings/profile');
                    return;
                  }
                  navigateToSettings();
                }}
              >
                Options
              </i>
            </Tooltip>
            <Tooltip
              text={t('dashboardComponents.SideMenu.logoutTooltip')}
              direction={isTabletOrMobile ? 'top-left' : 'top-right'}
              fixed={!isTabletOrMobile}
            >
              <i
                className={classes.logout}
                onClick={() => {
                  if (!isNavigationAllowed) {
                    setShowModal(true);
                    setNavigationLink('/login');
                    return;
                  }
                  logout();
                }}
              >
                Logout
              </i>
            </Tooltip>
          </div>
          <div className={classes.topMenuContainer}>
            <TopMenu />
          </div>
        </div>
      </div>
    </>
  );
}
