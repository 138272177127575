import { useContext } from 'react';

import i18n from '../i18n';
import { UiContext } from '../context/UiContext';

const t = i18n.t.bind(i18n);

export default function useUpdateData({
  updateHandler,
  refetch,
  onError,
  dataName,
}) {
  const {
    showModal,
    showUnknownErrorModal,
    setIsFetching,
    setIsCreatingOrUpdating,
    showDuplicateDataModal,
  } = useContext(UiContext);

  return {
    updateData: async (values) => {
      try {
        setIsCreatingOrUpdating(true);
        setIsFetching(true);
        await updateHandler(values);
        await refetch();
        showModal({
          title: t('hooks.useUpdateData.successTitle'),
          text: t('hooks.useUpdateData.successMessage'),
          confirmButtonLabel: t('common.gotIt'),
          confirmButtonVariant: '',
        });
      } catch (error) {
        console.log(error);

        // This allows to add custom error handling to the component that uses this hook
        if (onError) {
          const shouldStop = onError(error);
          if (shouldStop) {
            return;
          }
        }

        if (error?.response?.data?.message === 'Duplicity') {
          showDuplicateDataModal(dataName || 'data');
        } else {
          showUnknownErrorModal();
        }
      } finally {
        setIsFetching(false);
        setIsCreatingOrUpdating(false);
      }
    },
  };
}
