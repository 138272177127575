import React from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import getTimeAgo from '../../../../../../helpers/getTimeAgo';
import Tooltip from '../../../../../Tooltip';
import classes from './styles.module.scss';
import InterviewRating from '../../../../Jobs/MyJobs/JobDetails/GridView/CandidatesCol/CandidateCard/InterviewRating';
import AssessmentStatus from '../../../../Jobs/MyJobs/JobDetails/GridView/CandidatesCol/CandidateCard/AssessmentStatus';

function getDate(date) {
  return `${date.getDate()}th ${date.toLocaleString('en-us', {
    month: 'short',
  })} ${date.getFullYear()}`;
}

export default function JobInfo({
  application,
  width,
  isCandidateCard,
  reference,
}) {
  const hasVideoResume = application?.jobApplicationVideos?.length;
  const isInvited = application?.status === 'Invited';

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const hasInterviewFeedback =
    application.jobApplicationInterviews[0]?.jobApplicationInterviewFeedbacks[0]
      ?.feedbackRating;

  const isOfferAccepted =
    (application.status === 'Offer' && application.status === 'Accepted') ||
    application.jobApplicationOnboardings.length;
  const isOnboarded =
    (application.status === 'Onboard' &&
      application.subStatus === 'Onboarded') ||
    application.jobApplicationOnboardings.status === 'Onboarded' ||
    (application.jobApplicationJoinings.length &&
      application.subStatus !== 'Joined');

  const daysFromCreation = moment(new Date()).diff(
    moment(application?.createdAt),
    'days'
  );

  let dateIconColor = '#C1C5D6';

  if (
    application?.subStatus !== 'Rejected' &&
    application?.subStatus !== 'NoShow'
  ) {
    if (daysFromCreation > 6) {
      dateIconColor = '#FFBF00';
    } else if (daysFromCreation > 13) {
      dateIconColor = '#FF2D55';
    }
  }

  if (application?.status === 'Invited') {
    if (daysFromCreation > 9) {
      dateIconColor = '#FF0000';
    }
  }

  let applicationStatus = isOnboarded
    ? t(
        'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.onboard'
      )
    : t(
        'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.accepted'
      );

  if (application?.subStatus === 'Joined') {
    applicationStatus = t(
      'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.joined'
    );
  }

  return application ? (
    <div
      ref={reference}
      className={classNames(classes.JobInfo, {
        [classes.candidateCard]: isCandidateCard,
        [classes.joined]: application?.subStatus === 'Joined',
      })}
      style={{ width: `${width}` }}
    >
      {!isCandidateCard && (
        <div className={classes.titleContainer}>
          <h3 className={classes.jobTitle}>{application?.jobPost?.jobTitle}</h3>
          <span className={classes.jobId}>({application?.jobPost?.id})</span>
        </div>
      )}
      <div className={classes.container}>
        {!isInvited && !isCandidateCard && (
          <div className={classes.resume}>
            {hasVideoResume ? (
              <Tooltip
                text={t(
                  'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.videoResume'
                )}
                width={120}
                direction={isTabletOrMobile ? 'top-right' : 'top-left'}
              >
                <svg
                  width="17"
                  height="21"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.05469 0.578125V5.57812L8.05468 5.63253C8.0546 6.0566 8.05452 6.46873 8.10022 6.80864C8.15174 7.19185 8.27726 7.62912 8.64047 7.99234C9.00369 8.35555 9.44097 8.48108 9.82417 8.5326C10.1641 8.5783 10.5762 8.57822 11.0003 8.57813H11.0003L11.0547 8.57812H16.0547V14.5781C16.0547 17.4066 16.0547 18.8208 15.176 19.6994C14.2973 20.5781 12.8831 20.5781 10.0547 20.5781H6.05469C3.22626 20.5781 1.81205 20.5781 0.933367 19.6994C0.0546875 18.8208 0.0546875 17.4066 0.0546875 14.5781V6.57812C0.0546875 3.7497 0.0546875 2.33548 0.933367 1.4568C1.81205 0.578125 3.22626 0.578125 6.05469 0.578125H8.05469ZM10.0547 0.582746V5.57812C10.0547 6.0778 10.0568 6.35196 10.0824 6.54215L10.0834 6.54943L10.0907 6.55043C10.2809 6.576 10.555 6.57812 11.0547 6.57812H16.0501C16.0399 6.16648 16.0072 5.89408 15.9024 5.64119C15.7502 5.27364 15.4612 4.98461 14.8831 4.40655L12.2263 1.7497C11.6482 1.17164 11.3592 0.882607 10.9916 0.730366C10.7387 0.625614 10.4663 0.592938 10.0547 0.582746Z"
                    fill="url(#paint0_linear_0_1)"
                  />
                  <path
                    d="M10.5961 13.2645C10.9094 13.0686 10.9094 12.6123 10.5961 12.4165L6.81969 10.0562C6.48666 9.84811 6.05469 10.0875 6.05469 10.4802V15.2007C6.05469 15.5934 6.48666 15.8328 6.81969 15.6247L10.5961 13.2645Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_0_1"
                      x1="8.05469"
                      y1="-10.6881"
                      x2="-13.3701"
                      y2="4.52539"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00E4AC" />
                      <stop offset="0.504968" stopColor="#10C5E9" />
                      <stop offset="1" stopColor="#9240DC" />
                    </linearGradient>
                  </defs>
                </svg>
              </Tooltip>
            ) : (
              <Tooltip
                text={t(
                  'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.wordResume'
                )}
                width={120}
                direction={isTabletOrMobile ? 'top-right' : 'top-left'}
              >
                <svg
                  width="17"
                  height="21"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.05469 0.738281V5.73828L8.05468 5.79269C8.0546 6.21676 8.05452 6.62889 8.10022 6.9688C8.15174 7.352 8.27726 7.78928 8.64047 8.15249C9.00369 8.51571 9.44097 8.64123 9.82417 8.69275C10.1641 8.73845 10.5762 8.73837 11.0003 8.73829H11.0003L11.0547 8.73828H16.0547V14.7383C16.0547 17.5667 16.0547 18.9809 15.176 19.8596C14.2973 20.7383 12.8831 20.7383 10.0547 20.7383H6.05469C3.22626 20.7383 1.81205 20.7383 0.933367 19.8596C0.0546875 18.9809 0.0546875 17.5667 0.0546875 14.7383V6.73828C0.0546875 3.90985 0.0546875 2.49564 0.933367 1.61696C1.81205 0.738281 3.22626 0.738281 6.05469 0.738281H8.05469ZM10.0547 0.742902V5.73828C10.0547 6.23796 10.0568 6.51211 10.0824 6.70231L10.0834 6.70959L10.0907 6.71059C10.2809 6.73616 10.555 6.73828 11.0547 6.73828H16.0501C16.0399 6.32664 16.0072 6.05424 15.9024 5.80134C15.7502 5.4338 15.4612 5.14477 14.8831 4.56671L12.2263 1.90985C11.6482 1.33179 11.3592 1.04276 10.9916 0.890522C10.7387 0.78577 10.4663 0.753095 10.0547 0.742902Z"
                    fill="#C1C5D6"
                  />
                  <path
                    d="M5.05469 12.7383L11.0547 12.7383"
                    stroke="#F2F4F8"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5.05469 15.7383L10.0547 15.7383"
                    stroke="#F2F4F8"
                    strokeLinecap="round"
                  />
                </svg>
              </Tooltip>
            )}
          </div>
        )}
        {!isCandidateCard && (
          <Tooltip
            text={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.elapsedTime'
            )}
            width={111}
            direction="top"
          >
            <div className={classes.timeAgo}>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.58203 18C14.5526 18 18.582 13.9706 18.582 9C18.582 4.02944 14.5526 0 9.58203 0C4.61147 0 0.582031 4.02944 0.582031 9C0.582031 13.9706 4.61147 18 9.58203 18ZM10.582 3.5C10.582 2.94772 10.1343 2.5 9.58203 2.5C9.02975 2.5 8.58203 2.94772 8.58203 3.5V8.75C8.58203 9.44036 9.14168 10 9.83203 10H13.082C13.6343 10 14.082 9.55228 14.082 9C14.082 8.44772 13.6343 8 13.082 8H10.582V3.5Z"
                  fill={dateIconColor}
                />
              </svg>
              <span
                className={classNames(classes.createdAt, {
                  [classes.new]: getTimeAgo(application?.createdAt) === 'New',
                })}
              >
                {application.status === 'Invited'
                  ? 'Invited'
                  : getTimeAgo(application?.createdAt)}
              </span>
            </div>
          </Tooltip>
        )}

        {!!application?.assignedAssessments?.length && (
          <AssessmentStatus application={application} />
        )}

        {!!hasInterviewFeedback && (
          <Tooltip
            text={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.interviewRating'
            )}
            width={130}
            direction={isCandidateCard ? 'top-right' : 'top-left'}
          >
            <InterviewRating
              feedbackRating={
                application.jobApplicationInterviews[0]
                  .jobApplicationInterviewFeedbacks[0].feedbackRating
              }
            />
          </Tooltip>
        )}

        {!!isOfferAccepted && (
          <Tooltip
            text={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.joiningStatus'
            )}
            width={130}
            direction="top-left"
          >
            <div className={classes.statusContainer}>
              {isOnboarded ? (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.01"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0546875 0.738281V24.7383H24.0547V0.738281H0.0546875Z"
                    fill="#50A1FF"
                  />
                  <mask
                    id="mask0_1311_43733"
                    maskUnits="userSpaceOnUse"
                    x="3"
                    y="3"
                    width="19"
                    height="19"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.59965 6.46833C3.05469 7.53789 3.05469 8.93802 3.05469 11.7383V13.7383C3.05469 16.5385 3.05469 17.9387 3.59965 19.0082C4.07902 19.949 4.84392 20.7139 5.78473 21.1933C6.85429 21.7383 8.25442 21.7383 11.0547 21.7383H13.0547C15.8549 21.7383 17.2551 21.7383 18.3246 21.1933C19.2654 20.7139 20.0303 19.949 20.5097 19.0082C21.0547 17.9387 21.0547 16.5385 21.0547 13.7383V11.7383C21.0547 8.93802 21.0547 7.53789 20.5097 6.46833C20.0303 5.52752 19.2654 4.76262 18.3246 4.28325C17.2551 3.73828 15.8549 3.73828 13.0547 3.73828H11.0547C8.25443 3.73828 6.85429 3.73828 5.78473 4.28325C4.84392 4.76262 4.07902 5.52752 3.59965 6.46833ZM16.7726 9.54214C16.3881 9.14565 15.755 9.13591 15.3585 9.52038L10.8984 13.8453L9.25083 12.2477C8.85435 11.8632 8.22126 11.8729 7.83679 12.2694C7.45232 12.6659 7.46206 13.299 7.85854 13.6835L10.2023 15.9562C10.5902 16.3323 11.2067 16.3323 11.5946 15.9562L16.7508 10.9562C17.1473 10.5717 17.1571 9.93862 16.7726 9.54214Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_1311_43733)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0546875 0.738281H24.0307V24.7383H0.0546875V0.738281Z"
                      fill="#C1C5D6"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 8.5C4 7.10218 4 6.40326 4.22836 5.85195C4.53284 5.11687 5.11687 4.53284 5.85195 4.22836C6.40326 4 7.10218 4 8.5 4H15.5C16.8978 4 17.5967 4 18.1481 4.22836C18.8831 4.53284 19.4672 5.11687 19.7716 5.85195C20 6.40326 20 7.10218 20 8.5V9.4C20 9.73137 19.7314 10 19.4 10C18.4059 10 17.6 10.8059 17.6 11.8V12.2C17.6 13.1941 18.4059 14 19.4 14C19.7314 14 20 14.2686 20 14.6V15.5C20 16.8978 20 17.5967 19.7716 18.1481C19.4672 18.8831 18.8831 19.4672 18.1481 19.7716C17.5967 20 16.8978 20 15.5 20H8.5C7.10218 20 6.40326 20 5.85195 19.7716C5.11687 19.4672 4.53284 18.8831 4.22836 18.1481C4 17.5967 4 16.8978 4 15.5V14.6C4 14.2686 4.26863 14 4.6 14C5.59411 14 6.4 13.1941 6.4 12.2V11.8C6.4 10.8059 5.59411 10 4.6 10C4.26863 10 4 9.73137 4 9.4V8.5Z"
                    fill="#C1C5D6"
                  />
                  <path
                    d="M11.3351 10.0328C11.5873 9.38036 11.7134 9.05413 11.9183 9.00892C11.9722 8.99703 12.0278 8.99703 12.0817 9.00892C12.2866 9.05413 12.4127 9.38036 12.6649 10.0328C12.8083 10.4039 12.88 10.5894 13.0142 10.7156C13.0518 10.751 13.0927 10.7825 13.1362 10.8097C13.2912 10.9067 13.4848 10.9247 13.872 10.9607C14.5275 11.0216 14.8552 11.0521 14.9553 11.246C14.976 11.2861 14.9901 11.3296 14.997 11.3746C15.0302 11.5919 14.7892 11.8194 14.3074 12.2743L14.1736 12.4006C13.9483 12.6133 13.8357 12.7197 13.7705 12.8524C13.7314 12.932 13.7052 13.0178 13.6929 13.1062C13.6725 13.2536 13.7055 13.4079 13.7714 13.7165L13.795 13.8267C13.9133 14.3801 13.9724 14.6567 13.8986 14.7927C13.8323 14.9149 13.7101 14.9931 13.5751 14.9998C13.4249 15.0072 13.2132 14.8282 12.7898 14.4702C12.5108 14.2343 12.3714 14.1163 12.2165 14.0702C12.075 14.0281 11.925 14.0281 11.7835 14.0702C11.6286 14.1163 11.4892 14.2343 11.2102 14.4702C10.7868 14.8282 10.5751 15.0072 10.4249 14.9998C10.2899 14.9931 10.1677 14.9149 10.1014 14.7927C10.0276 14.6567 10.0867 14.3801 10.205 13.8267L10.2286 13.7165C10.2945 13.4079 10.3275 13.2536 10.3071 13.1062C10.2948 13.0178 10.2686 12.932 10.2295 12.8524C10.1643 12.7197 10.0517 12.6133 9.82643 12.4006L9.69262 12.2743C9.21077 11.8194 8.96984 11.5919 9.00302 11.3746C9.00989 11.3296 9.02398 11.2861 9.04471 11.246C9.14479 11.0521 9.47252 11.0216 10.128 10.9607C10.5152 10.9247 10.7088 10.9067 10.8638 10.8097C10.9073 10.7825 10.9482 10.751 10.9858 10.7156C11.12 10.5894 11.1917 10.4039 11.3351 10.0328Z"
                    fill="#F2F4F8"
                    stroke="#F2F4F8"
                  />
                </svg>
              )}
              <span
                className={classNames(classes.status, {
                  [classes.joined]: application?.subStatus === 'Joined',
                })}
              >
                {applicationStatus}
              </span>
            </div>
          </Tooltip>
        )}

        {isInvited && !isCandidateCard && (
          <Tooltip
            text={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.JobInfo.invitationWasSent'
            )}
            width={111}
            direction="top"
          >
            <div className={classes.date}>
              {getDate(new Date(application.createdAt))}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  ) : (
    ''
  );
}
