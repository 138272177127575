import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EventCard from '../../EventCard';
import classes from './styles.module.scss';

function getTime(hour) {
  const time = `${new Date(2023, 0, 1, hour, hour).toLocaleString('en-US', {
    hour: 'numeric',
    hour12: true,
  })}`;

  return time;
}

export default function DayView({
  currentDate,
  setCurrentDate,
  currentWeek,
  weekMode,
  setActiveType,
  dateToday,
  events,
  refetch,
  user,
}) {
  const [linePosition, setLinePosition] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const lineRef = useRef();
  const tableRef = useRef();

  let currentHour = dateToday.getHours();

  if (currentHour === 0) {
    currentHour = 24;
  }
  const isToday =
    currentDate.getDate() === dateToday.getDate() &&
    currentDate.getMonth() === dateToday.getMonth() &&
    currentDate.getFullYear() === dateToday.getFullYear();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventsInHours = {
    '1 AM': [],
    '2 AM': [],
    '3 AM': [],
    '4 AM': [],
    '5 AM': [],
    '6 AM': [],
    '7 AM': [],
    '8 AM': [],
    '9 AM': [],
    '10 AM': [],
    '11 AM': [],
    '12 PM': [],
    '1 PM': [],
    '2 PM': [],
    '3 PM': [],
    '4 PM': [],
    '5 PM': [],
    '6 PM': [],
    '7 PM': [],
    '8 PM': [],
    '9 PM': [],
    '10 PM': [],
    '11 PM': [],
    '12 AM': [],
  };

  const days = useMemo(
    () =>
      currentWeek
        ?.slice(1, -1)
        .map(
          (day) => `${day.getDate()} ${day.getMonth()} ${day.getFullYear()}`
        ),
    [currentWeek]
  );

  if (currentWeek) {
    events?.forEach((event) => {
      const eventDate = new Date(event.eventDate);
      const date = `${eventDate.getDate()} ${eventDate.getMonth()} ${eventDate.getFullYear()}`;

      if (days.includes(date)) {
        if (event?.startTime) {
          const eventHour = getTime(+event.startTime?.slice(0, 2));
          eventsInHours[eventHour]?.push(event);
        }
      }
    });
  } else {
    const tempCurrentDate = `${currentDate.getDate()} ${currentDate.getMonth()} ${currentDate.getFullYear()}`;

    events?.forEach((event) => {
      const eventDate = new Date(event?.eventDate);
      const date = `${eventDate.getDate()} ${eventDate.getMonth()} ${eventDate.getFullYear()}`;

      if (tempCurrentDate === date) {
        if (event?.startTime) {
          const eventHour = getTime(+event?.startTime?.slice(0, 2));
          eventsInHours[eventHour]?.push(event);
        }
      }
    });
  }

  useEffect(() => {
    if (currentHour === 24) {
      setLinePosition((0 + (100 / 60) * dateToday.getMinutes() * 0.01) * 95);

      setIsLoaded(true);

      return;
    }
    setLinePosition(
      (currentHour + (100 / 60) * dateToday.getMinutes() * 0.01) * 95 - 95
    );

    setIsLoaded(true);
  }, [currentDate, currentHour, dateToday]);

  useEffect(() => {
    if (Object.entries(eventsInHours).find((hour) => hour[1].length)) {
      tableRef.current.scrollTop =
        Object.entries(eventsInHours).findIndex((hour) => hour[1].length) * 95;

      return;
    }

    if (currentHour === 24) {
      tableRef.current.scrollTop = 0;

      return;
    }

    tableRef.current.scrollTop = currentHour * 95 - 95;
  }, [currentDate, currentHour, isToday, days]);

  return (
    <div className={classNames(classes.DayView, { [classes.week]: weekMode })}>
      <div className={classes.hours} ref={tableRef}>
        {isLoaded && (
          <div
            className={classes.line}
            ref={lineRef}
            style={{ top: `${linePosition}px` }}
          />
        )}
        {[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24,
        ].map((hour) => (
          <div className={classes.hour} key={getTime(hour)}>
            <span className={classes.title}>{getTime(hour)}</span>
            <div className={classes.columns}>
              {weekMode ? (
                days.map((day) => {
                  const eventsInHour = eventsInHours[getTime(hour)]
                    .filter((event) => {
                      const eventDate = new Date(event.eventDate);
                      const date = `${eventDate.getDate()} ${eventDate.getMonth()} ${eventDate.getFullYear()}`;

                      return date === day;
                    })
                    .sort(
                      (prev, next) =>
                        +prev.startTime.split(':')[1] -
                        +next.startTime.split(':')[1]
                    );
                  return (
                    <div className={classes.column} key={day}>
                      <EventCard
                        mode="WEEK"
                        setActiveType={setActiveType}
                        events={eventsInHour}
                        setCurrentDate={setCurrentDate}
                        refetch={refetch}
                        user={user}
                      />
                    </div>
                  );
                })
              ) : (
                <>
                  {[1, 2, 3, 4, 5].map((day) => {
                    return <div className={classes.column} key={day} />;
                  })}
                  <EventCard
                    mode="DAY"
                    events={eventsInHours[getTime(hour)]}
                    refetch={refetch}
                    user={user}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
