import React, { useState, useRef, useCallback } from 'react';

import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';

import Question from './Question';
import classes from './styles.module.scss';

export default function AssessmentQuestions({
  questions,
  setQuestions,
  isEdit,
}) {
  const [isAnswerBeingDragged, setIsAnswerBeingDragged] = useState(false);
  const [isQuestionBeingDragged, setIsQuestionBeingDragged] = useState(false);

  const questionsContainerRef = useRef();

  const { t } = useTranslation();

  const toggleMultipleAnswers = (id) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            answers: [],
            allowsMultipleAnswers: !question.allowsMultipleAnswers,
          };
        }
        return question;
      });

      return newQuestions;
    });
  };

  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        id: Math.random(),
        name: '',
        answers: [],
        allowsMultipleAnswers: false,
      },
    ]);
  };

  const changeQuestion = (id, updatedQuestion) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            ...updatedQuestion,
          };
        }
        return question;
      });

      return newQuestions;
    });
  };

  const deleteQuestion = (id) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.filter(
        (question) => question.id !== id
      );

      return newQuestions;
    });
  };

  const addAnswer = (id) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            answers: [
              ...question.answers,
              {
                name: '',
                id: Math.random(),
              },
            ],
          };
        }
        return question;
      });

      return newQuestions;
    });
  };

  const deleteAnswer = useCallback(
    (questionId, answerId) => {
      setQuestions((prevQuestions) => {
        const newQuestions = prevQuestions.map((question) => {
          if (question.id === questionId) {
            return {
              ...question,
              answers: question.answers.filter(
                (answer) => answer.id !== answerId
              ),
            };
          }
          return question;
        });

        return newQuestions;
      });
    },
    [setQuestions]
  );

  const changeAnswer = (questionId, answerId, name) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            answers: question.answers.map((answer) => {
              if (answer.id !== answerId) {
                return answer;
              }
              return { ...answer, name };
            }),
          };
        }
        return question;
      });

      return newQuestions;
    });
  };

  const changeAnswersPositions = (questionId, answers) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            answers,
          };
        }
        return question;
      });

      return newQuestions;
    });
  };

  const dragQuestion = (event, index) => {
    setIsQuestionBeingDragged(true);
    event.dataTransfer.setData('questionIndex', index);
  };

  const getQuestionFromChild = (element) => {
    try {
      const parent = element.parentElement;

      if (
        parent.parentElement.classList.contains(classes.AssessmentQuestions)
      ) {
        return parent;
      }
      return getQuestionFromChild(parent);
    } catch (error) {
      return null;
    }
  };

  const handleQuestionDrop = (event) => {
    event.preventDefault();
    const oldQuestionIndex = event.dataTransfer.getData('questionIndex');

    const nearestElement = document.elementFromPoint(
      event.clientX,
      event.clientY
    );

    let newQuestionIndex;
    if (nearestElement.dataset.index !== undefined) {
      newQuestionIndex = nearestElement.dataset.index;
    } else {
      const questionReceiver = getQuestionFromChild(nearestElement);

      if (questionReceiver) {
        newQuestionIndex = questionReceiver.dataset.index;
      }
    }

    const newQuestionsArray = arrayMoveImmutable(
      questions,
      oldQuestionIndex,
      newQuestionIndex
    );
    setQuestions(newQuestionsArray);
  };

  return (
    <div
      className={classes.AssessmentQuestions}
      onDrop={isAnswerBeingDragged ? null : handleQuestionDrop}
      onDragOver={(event) => event.preventDefault()}
      ref={questionsContainerRef}
    >
      {questions?.map?.((question, index) => (
        <Question
          key={question.id}
          isEdit={isEdit}
          question={question}
          order={index + 1}
          onToggleMultiple={() => toggleMultipleAnswers(question.id)}
          changeQuestion={changeQuestion}
          addAnswer={addAnswer}
          deleteAnswer={deleteAnswer}
          changeAnswer={changeAnswer}
          deleteQuestion={deleteQuestion}
          changeAnswersPositions={changeAnswersPositions}
          dragQuestion={(event) => dragQuestion(event, index)}
          setIsAnswerBeingDragged={setIsAnswerBeingDragged}
          setIsQuestionBeingDragged={setIsQuestionBeingDragged}
          isDuplicate={questions.some(
            (qstn) =>
              qstn.id !== question.id &&
              qstn.name &&
              qstn.name === question.name
          )}
        />
      ))}
      {!isAnswerBeingDragged && !isQuestionBeingDragged && (
        <button
          type="button"
          className={classes.addButton}
          onClick={addQuestion}
        >
          {t(
            'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.addMore'
          )}
        </button>
      )}
    </div>
  );
}
