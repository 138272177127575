import React from 'react';

import { useTranslation } from 'react-i18next';

import Stat from './Stat';

import classes from './styles.module.scss';

function calculateRatio(n1, n2) {
  let num1 = n1;
  let num2 = n2;
  // eslint-disable-next-line no-plusplus
  for (let num = num2; num > 1; num--) {
    if (num1 % num === 0 && num2 % num === 0) {
      num1 /= num;
      num2 /= num;
    }
  }
  const ratio = `${num1}:${num2}`;
  return ratio;
}

export default function StatsList({ data }) {
  const { t } = useTranslation();

  const stats = [
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.ApplicationsPerJob'
      ),
      value:
        Math.round(
          data?.newJobs.current / data?.completedApplications.current
        ) || 0,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.ApplicationCompletionRate'
      ),
      value: `${
        Math.round(
          (data?.completedApplications.current /
            data?.newApplications.current) *
            100
        ) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.InterviewToHireRatio'
      ),
      value: calculateRatio(
        data?.interviewedApplications.current,
        data?.joinedApplications.current
      ),
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.AverageTimeToHire'
      ),
      value: data?.timeToHire.toFixed(1),
      note: 'days',
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.OfferAcceptanceRate'
      ),
      value: `${
        Math.round(
          (data?.offerAcceptedApplications.current /
            data?.offeredApplications.current) *
            100
        ) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.CandidatesPerHire'
      ),
      value: calculateRatio(
        data?.newApplicants.current,
        data?.joinedApplicants.current
      ),
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.StatsList.SelectionRatio'
      ),
      value: calculateRatio(
        data?.joinedApplicants.current,
        data?.newApplicants.current
      ),
    },
  ];

  return (
    <div className={classes.StatsList}>
      {stats.map((stat) => {
        return (
          <Stat
            key={stat.label}
            label={stat.label}
            value={stat.value}
            note={stat.note}
            hasNoData={!data}
          />
        );
      })}
    </div>
  );
}
