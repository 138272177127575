import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function CommButton({ onClick, icon }) {
  const [isMouseOver, setIsMouseOver] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [flash, setFlash] = useState(false);

  // Reset isClicked state after 1 second
  useEffect(() => {
    let timeout1;
    let timeout2;
    if (isClicked) {
      // Flash effect
      timeout2 = setTimeout(() => {
        setFlash(true);

        setTimeout(() => {
          setFlash(false);
        }, 75);
      }, 200);

      timeout1 = setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [isClicked]);

  return (
    <button
      type="button"
      onClick={() => {
        onClick?.();
        setIsClicked(true);
      }}
      className={classNames(classes.CommButton, {
        [classes.hovered]: isMouseOver === true,
        [classes.notHovered]: isMouseOver === false,
        [classes.clicked]: isClicked,
        [classes.flash]: flash,
      })}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <img src={icon} alt="icon" />
    </button>
  );
}
