/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import usePaginatedDropdownOptions from '../../../hooks/usePaginatedDropdownOptions';
import Tooltip from '../../Tooltip';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import TextInput from '../TextInput';

export default function DropdownWithTextInput({
  options,
  value,
  placeholder,
  width,
  dangerOption,
  height,
  label,
  error,
  touched,
  searchInfo,
  searchInfoWidth,
  name,
  setFieldValue,
  setFieldTouched,
  maxOptionsHeight,
  info,
  note,
  infoWidth,
  textInputValue,
  setTextInputValue,
  fetchOptions,
  warning,
  labelStyle,
  readOnly,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isEnoughHeight, setIsEnoughHeight] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const dropdownRef = useRef();
  const optionsRef = useRef();
  const isOpened = useRef();

  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const setOption = (option) => {
    setFieldValue(name, option);
    setTextInputValue(option.label);
    setIsOptionsListVisible(false);
  };

  useEffect(() => {
    if (isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `-${optionsRef.current.clientHeight}px`
      );

      const { bottom } = optionsRef.current.getBoundingClientRect();

      if (window.innerHeight - bottom < dropdownRef.current.clientHeight) {
        setIsEnoughHeight(false);
      } else {
        setIsEnoughHeight(true);
      }
    } else {
      setIsEnoughHeight(true);
      if (isOpened.current) {
        setFieldTouched?.(name, true, true);
      }
    }
  }, [isOptionsListVisible, name, setFieldTouched]);

  useEffect(() => {
    if (!isOptionsListVisible) {
      setSearchTerm('');
    }
  }, [isOptionsListVisible]);

  const { options: fetchedOptions, lastListElementRef } =
    usePaginatedDropdownOptions({
      dataName: fetchOptions?.dataName,
      getDataHandler: fetchOptions?.getDataHandler,
      searchTerm,
      labelName: fetchOptions?.labelName,
    });

  const optionsToDisplay = fetchOptions
    ? fetchedOptions
    : options?.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const changeTextInputValue = (event) => {
    setFieldValue(name, {});
    setTextInputValue(event.target.value);

    const possibleOption = optionsToDisplay.find(
      (option) => option.label === event.target.value
    );

    if (possibleOption) {
      setFieldValue(name, possibleOption);
    }
  };

  return (
    <div
      style={{ width }}
      tabIndex={0}
      role="listbox"
      ref={dropdownRef}
      className={classNames(classes.DropdownWithTextInput, {
        [classes.open]: isOptionsListVisible,
        [classes.optionsTop]: !isEnoughHeight,
        [classes.warning]: warning && touched,
        [classes.error]: error && touched,
        [classes.readOnly]: readOnly,
      })}
    >
      <span className={classes.label} style={labelStyle}>
        {label}{' '}
        {info && (
          <Tooltip text={info} width={infoWidth} direction="top-right">
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && <span className={classes.note}>({note})</span>}
      </span>
      <div
        style={{ width, height }}
        className={classes.selector}
        onClick={() => {
          isOpened.current = true;
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        <div className={classes.arrow} />
        <TextInput
          value={textInputValue || value?.label}
          placeholder={placeholder}
          onChange={changeTextInputValue}
          width="100%"
        />
      </div>
      <div
        className={classNames(classes.error, {
          [classes.warning]: warning && !error,
        })}
      >
        {error || warning}
      </div>
      {isOptionsListVisible && (
        <div className={classes.searchAndOptions} ref={optionsRef}>
          <div className={classes.search}>
            <input
              type="text"
              placeholder={t('common.search')}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {searchInfo && (
              <Tooltip text={searchInfo} width={searchInfoWidth}>
                <i>Info</i>
              </Tooltip>
            )}
          </div>
          <div className={classes.options}>
            <ul style={{ maxHeight: maxOptionsHeight }}>
              {optionsToDisplay?.map((option, index) => (
                <li
                  key={option.value}
                  onClick={() => {
                    setOption(option);
                  }}
                  className={classNames({
                    [classes.active]: value?.value === option.value,
                    [classes.danger]: dangerOption === option.value,
                  })}
                  ref={
                    index === optionsToDisplay.length - 1
                      ? lastListElementRef
                      : null
                  }
                >
                  <span>{option.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
