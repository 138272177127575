export default function yupSchemaToFields(schema) {
  if (!schema.fields) {
    return {};
  }

  return Object.keys(schema.fields).map((key) => {
    const isRequired = schema.fields[key].exclusiveTests.required;
    return { field: key, validation: isRequired ? ['required'] : [] };
  });
}
