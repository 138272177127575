import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Template from '../Template';
import Textarea from '../../../../../Form/Textarea';
import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';

import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import classes from './styles.module.scss';

export default function ViewJobTemplate({
  isVisible,
  handleClose,
  currentDataId,
  data,
  showEditDataSingle,
  isOverlayed,
  deleteData,
}) {
  const [isTemplateVisible, setIsTemplateVisible] = useState(false);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(data, currentDataId);

  useEffect(() => {
    if (!isVisible) {
      setIsTemplateVisible(false);
    }
  }, [isVisible]);

  return (
    <SidePopup
      mode="view"
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={t(
        'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.ViewJobTemplateTitle'
      )}
      switchNext={data?.length > 1 && switchToNextDataElement}
      switchPrev={data?.length > 1 && switchToPrevDataElement}
      leftButtonLabel={t('common.delete')}
      rightButtonLabel={t('common.edit')}
      onRightButtonClick={() => {
        showEditDataSingle(data?.[currentDataIndex]?.id);
      }}
      isOverlayed={isOverlayed}
      onLeftButtonClick={() => deleteData(data?.[currentDataIndex]?.id)}
    >
      <div className={classes.ViewJobTemplate}>
        <div className={classes.templateStatus}>
          {data?.[currentDataIndex]?.templateStatus === 'Draft'
            ? t(
                'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.DraftStatus'
              )
            : t(
                'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.PublishedStatus'
              )}
        </div>
        {isTemplateVisible ? (
          <Template data={data?.[currentDataIndex]} readOnly />
        ) : (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.col}>
                <TextInput
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.JobTemplateNameLabel'
                  )}
                  readonly
                  height={50}
                  value={data?.[currentDataIndex]?.templateName}
                />
              </div>
              <div className={classes.col}>
                <TextInput
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.SortOrderLabel'
                  )}
                  readonly
                  height={50}
                  value={data?.[currentDataIndex]?.templateSortOrder}
                  maxLength={5}
                />
                <Textarea
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.NotesLabel'
                  )}
                  readonly
                  height={100}
                  value={data?.[currentDataIndex]?.note}
                />
              </div>
            </div>
            <button
              className={classes.viewTemplateButton}
              type="button"
              onClick={() => setIsTemplateVisible(true)}
            >
              {t(
                'dashboardComponents.Console.JobManagement.JobTemplates.ViewJobTemplate.ViewTemplateButtonLabel'
              )}
            </button>
          </>
        )}
      </div>
    </SidePopup>
  );
}
