import React from 'react';

import { useTranslation } from 'react-i18next';

import CandidatesCol from './CandidatesCol';

import classes from './styles.module.scss';

export default function GridView({
  candidates,
  refetch,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
  job,
  candidatesFilter,
}) {
  const { t } = useTranslation();

  const showCandidatePopup = (candidateId) => {
    const currentCandidate = candidates?.find((cnd) => cnd.id === candidateId);
    setCurrentCandidate(currentCandidate);
    setIsCandidatePopupVisible(true);
  };

  const newCandidates = candidates?.filter(
    (candidate) => candidate.status === 'New' || candidate.status === 'Invited'
  );
  const screenCandidates =
    true && candidates?.filter((candidate) => candidate.status === 'Screen');
  const interviewCandidates = candidates?.filter(
    (candidate) => candidate.status === 'Interview'
  );
  const offerCandidates = candidates?.filter(
    (candidate) => candidate.status === 'Offer'
  );
  const onboardCandidates = candidates?.filter(
    (candidate) => candidate.status === 'Onboard'
  );

  const someCandidateHasAssessment = candidates?.some(
    (candidate) => candidate?.assignedAssessmentCount
  );

  return (
    <div className={classes.GridView}>
      <div className={classes.container}>
        <CandidatesCol
          showCandidatePopup={showCandidatePopup}
          candidates={newCandidates}
          refetch={refetch}
          title={t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.New')}
          jobStatus={job?.status}
          hasAssessment={!!job?.assignedAssessments?.length}
          candidatesFilter={candidatesFilter}
        />
        {(!!job?.assignedAssessments?.length || someCandidateHasAssessment) && (
          <CandidatesCol
            showCandidatePopup={showCandidatePopup}
            candidates={screenCandidates}
            refetch={refetch}
            title={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Screen'
            )}
            jobStatus={job?.status}
            hasAssessment={!!job?.assignedAssessments?.length}
            candidatesFilter={candidatesFilter}
          />
        )}
        <CandidatesCol
          showCandidatePopup={showCandidatePopup}
          candidates={interviewCandidates}
          refetch={refetch}
          title={t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Interview'
          )}
          jobStatus={job?.status}
          hasAssessment={!!job?.assignedAssessments?.length}
          candidatesFilter={candidatesFilter}
        />
        <CandidatesCol
          showCandidatePopup={showCandidatePopup}
          candidates={offerCandidates}
          refetch={refetch}
          title={t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Offer')}
          jobStatus={job?.status}
          hasAssessment={!!job?.assignedAssessments?.length}
          candidatesFilter={candidatesFilter}
        />
        <CandidatesCol
          showCandidatePopup={showCandidatePopup}
          candidates={onboardCandidates}
          refetch={refetch}
          title={t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Onboard'
          )}
          jobStatus={job?.status}
          hasAssessment={!!job?.assignedAssessments?.length}
          candidatesFilter={candidatesFilter}
        />
      </div>
    </div>
  );
}
