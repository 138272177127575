import React, { useState, useEffect, useContext } from 'react';

import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom';

import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../../../Button';
import LinkButton from './LinkButton';

import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function Controls({
  links,
  activeLink,
  setActiveLink,
  setLinks,
  updateLinks,
  initiaLinks,
  linksValidity,
}) {
  const [areInputsValid, setAreInputsValid] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const navigate = useNavigate();

  const { showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const changeLinkPositionOnDrop = (event) => {
    event.preventDefault();

    const oldLinkIndex = event.dataTransfer.getData('buttonIndex');

    const coords = event.currentTarget.getBoundingClientRect();

    const yPosition = event.clientX - coords.left;

    const newLinkIndex = Math.max(Math.floor(yPosition / (40 + 18)), 0);

    const newLinksArray = arrayMoveImmutable(links, oldLinkIndex, newLinkIndex);

    setLinks(newLinksArray);
  };

  // Check inputs validity
  useEffect(() => {
    setAreInputsValid(
      links.every((link) => {
        return (
          !link.url ||
          (link.url && /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(link.url))
        );
      })
    );
  }, [links]);

  // Check if links have changed
  useEffect(() => {
    const isDirty = links.some((link, index) => {
      const initialLink = initiaLinks?.find((iL) => link.title === iL.type);
      return (
        link.url !== initialLink?.url ||
        index !== initialLink?.sortOrder ||
        link.isEnabled === initialLink?.isHidden
      );
    });

    setHasChanged(isDirty);
  }, [links, initiaLinks]);

  useEffect(() => {
    setIsBlocking(hasChanged);
  }, [hasChanged]);

  useEffect(() => {
    const preventClosingTab = (event) => {
      if (!isBlocking) {
        return;
      }

      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', preventClosingTab);

    return () => {
      window.removeEventListener('beforeunload', preventClosingTab);
    };
  }, [isBlocking]);

  useBlocker((params) => {
    if (!isBlocking) {
      return false;
    }

    showModal({
      title: t(
        'dashboardComponents.ProfileSettings.SocialMedia.Controls.exitScreenTitle'
      ),
      text: t(
        'dashboardComponents.ProfileSettings.SocialMedia.Controls.exitScreenText'
      ),
      dismissButtonLabel: t('common.no'),
      dismissButtonVariant: ' ',
      confirmButtonVariant: 'modal',
      confirmButtonLabel: t('common.yes'),
      onConfirm: () => {
        setIsBlocking(false);
        setTimeout(() => {
          navigate(params.nextLocation.pathname);
        }, 100);
      },
      onCancel: () => {},
    });

    return true;
  });

  return (
    <div className={classes.Controls}>
      <div
        onDrop={changeLinkPositionOnDrop}
        onDragOver={(event) => event.preventDefault()}
        className={classNames(classes.buttonsContainer, {
          [classes.noActiveButton]: !activeLink,
        })}
      >
        {links
          .filter((link) => link.isEnabled)
          .map((link, index) => (
            <LinkButton
              buttonIndex={index}
              key={link.title}
              icon={link.icon}
              isActive={activeLink === link.title}
              onClick={() => {
                setActiveLink(link.title);
                window.open(link.url, '_blank');
              }}
              url={initiaLinks?.find((iL) => link.title === iL.type)?.url}
            />
          ))}
      </div>
      <Button
        height={40}
        width={119}
        style={{
          fontSize: 14,
        }}
        onClick={updateLinks}
        disabled={
          !areInputsValid ||
          !hasChanged ||
          Object.keys(linksValidity).some((key) => !linksValidity[key])
        }
      >
        {t(
          'dashboardComponents.ProfileSettings.SocialMedia.Controls.publishButtonLabel'
        )}
      </Button>
    </div>
  );
}
