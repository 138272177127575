/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Auth({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const userManagementPath = `/tools/admin/user-management`;
  const goToAssessmentRequest = () => {
    navigate(userManagementPath);
    hideNotifications();
  };

  const createNotificationContent = (notification) => {
    const content = {
      'notification.access_request.new': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequest.title'
        )} ${notification.messageInfo}`,
        text: t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequest.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequest.buttonLabel'
        ),
        onClick: goToAssessmentRequest,
      },
      NewAccessRequestReminder: {
        title: t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequestReminder.title',
          {
            name: notification.messageInfo,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequestReminder.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Auth.newAssessmentRequestReminder.buttonLabel'
        ),
        onClick: goToAssessmentRequest,
      },
      'Password reset': {
        title: t(
          'dashboardComponents.Notifications.Notification.Auth.passwordReset'
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Auth.checkEmail'
        ),
        buttonLabel: '',
        onClick: () => {},
      },
    };

    return (
      content[notification.name] ||
      content[notification.eventNote] ||
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
