import React from 'react';

import { useQuery } from '@tanstack/react-query';

import DashboardDropdown from '../../DashboardDropdown';
import TitleDropdown from './TitleDropdown';

import classes from './styles.module.scss';
import AdminService from '../../../../../services/AdminService';

export default function DashboardHeader({
  location,
  setLocation,
  dateRange,
  setDateRange,
  city,
  setCity,
  defaultCityOption,
  defaultLocationOption,
  dashboardMode,
  setDashboardMode,
  dashboardModeOptions,
}) {
  const { data: locations } = useQuery({
    queryKey: ['locations', city?.value],
    queryFn: () => {
      const requestData = {
        pageSize: 9999,
      };

      if (city?.value) {
        requestData.Filters = `city.id:eq:${city.value}`;
      }

      return AdminService.getRestaurants(requestData);
    },
  });

  const { data: cities } = useQuery({
    queryKey: ['cities', locations],
    queryFn: () => AdminService.getCities({ pageSize: 9999 }),
  });

  const locationOptions = locations
    ? locations?.data?.map((loc) => ({
        value: loc.id,
        label: loc.name,
        city: loc.city,
      }))
    : [];

  const citiesOptions = cities
    ? cities?.data?.map((ct) => ({
        value: ct.id,
        label: ct.name,
      }))
    : [];

  const locationOptionsWithDefaultValue = [
    defaultLocationOption,
    ...locationOptions,
  ];

  const citiesOptionsWithDefaultValue = [defaultCityOption, ...citiesOptions];

  return (
    <header className={classes.DashboardHeader}>
      <TitleDropdown
        dashboardMode={dashboardMode}
        setDashboardMode={setDashboardMode}
        dashboardModeOptions={dashboardModeOptions}
      />
      <div className={classes.dropdowns}>
        <div className={classes.dropdownContainer}>
          <DashboardDropdown
            currentOption={city}
            setCurrentOption={setCity}
            placeholder="City"
            options={citiesOptionsWithDefaultValue}
            style={{ minWidth: 90 }}
          />
        </div>
        <div className={classes.dropdownContainer}>
          <DashboardDropdown
            currentOption={location}
            setCurrentOption={setLocation}
            placeholder="Location"
            options={locationOptionsWithDefaultValue}
          />
        </div>
        <div className={classes.dropdownContainer}>
          <DashboardDropdown
            currentOption={dateRange}
            setCurrentOption={setDateRange}
            placeholder="This month"
            calendar
          />
        </div>
      </div>
    </header>
  );
}
