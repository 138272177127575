/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
// import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

// import UserService from '../../../../services/UserService';
// import useOnClickOutside from '../../../../hooks/useOnClickOutside';
// import { getTimezoneDate } from '../../../../helpers/timezones';
import classes from './styles.module.scss';

const options = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export default function TimeDropdown({
  value,
  name,
  setFieldValue,
  placeholder,
  error,
  // fromValue,
  height,
  style,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  // const [timeValue, setTimeValue] = useState('');

  const dropdownRef = useRef();
  const optionsRef = useRef();

  /*  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const diff = +value?.slice(3) >= 30 ? 1 : 0;

  useEffect(() => setTimeValue(value), [value]);

  useEffect(() => {
    if (value && optionsRef?.current) {
      optionsRef.current.scrollTop = 14 + 36 * (value?.slice(0, 2) * 2 + diff);
    }
  }, [diff, isOptionsListVisible, value]);

  useEffect(() => {
    if (value || !isOptionsListVisible) {
      return;
    }
    const currentDate = getTimezoneDate(
      new Date(),
      user?.userProfile?.timezone
    )[0];

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const adjustedHours = hours % 24; // This wraps around the hour to 0 if it's 24.
    const formattedHours =
      adjustedHours < 10 ? `0${adjustedHours}` : `${adjustedHours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const startValue = `${formattedHours}:${formattedMinutes}`;

    if (name === 'startTime') {
      setFieldValue(name, startValue);
    }
  }, [
    isOptionsListVisible,
    name,
    setFieldValue,
    user?.userProfile?.timezone,
    value,
  ]);

  useEffect(() => {
    if (isOptionsListVisible && name === 'startTime') {
      setFieldValue('endTime', '');
    }
  }, [isOptionsListVisible, name, setFieldValue]);

  useEffect(() => {
    if (name === 'endTime' && fromValue?.length && !value.length) {
      const [startHours, startMinutes] = fromValue?.split(':');
      let endHours = +startHours;
      let endMinutes = +startMinutes + 30;

      if (endMinutes >= 60) {
        endHours += 1;
        endMinutes %= 60;
      }

      endHours %= 24; // Adjust for cases where endHours exceeds 23

      setFieldValue(
        name,
        `${endHours < 10 ? `0${endHours}` : endHours}:${
          endMinutes < 10 ? `0${endMinutes}` : endMinutes
        }`
      );
    }
  }, [fromValue, name, setFieldValue, value]);
  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const checkTimeValue = (time) => {
    const stringTime = String(time);

    if (!/^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(stringTime)) {
      setTimeValue(value);

      return;
    }

    if (stringTime.split(':')[0].length === 1) {
      setFieldValue(name, `0${timeValue}`);
      setTimeValue(`0${timeValue}`);

      return;
    }

    setFieldValue(name, timeValue);
  };
 */
  return (
    <div className={classes.TimeDropdown} ref={dropdownRef}>
      <div
        style={{ ...style, height }}
        className={classNames(classes.selector, {
          [classes.error]: error,
        })}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {value ? (
          <input
            className={classes.value}
            value={value}
            onChange={(e) => setFieldValue(name, e.target.value)}
            // onChange={(e) => setTimeValue(e.target.value)}
            // onBlur={() => checkTimeValue(timeValue)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          placeholder || 'Time'
        )}
      </div>
      {isOptionsListVisible && (
        <ul className={classes.options} ref={optionsRef}>
          {options.map((option) => {
            return (
              <li
                key={option}
                className={classNames(classes.option, {
                  // [classes.active]: option === value,
                })}
                onClick={() => {
                  setFieldValue(name, option);
                  setIsOptionsListVisible(false);
                }}
              >
                {option}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
