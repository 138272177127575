import React, { memo } from 'react';

import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import TwoWeeksCalendar from './TwoWeeksCalendar';
import LatestNotifications from './LatestNotifications';
import MessagesAndFeedback from './MessagesAndFeedback';
import UpcomingEvents from './UpcomingEvents';
import RecruitmentFunnel from '../LeftPanel/RecruitmentFunnel';
import RedAlertJobs from '../LeftPanel/RedAlertJobs';

import { getAuthData } from '../../../../helpers/authStorage';
import { getEvents } from '../../../../helpers/getEvents';
import classes from './styles.module.scss';
// import AdminService from '../../../../services/AdminService';
import CalendarService from '../../../../services/CalendarService';
import UserService from '../../../../services/UserService';

const today = moment();

function RightPanel({ leftPanelData }) {
  const { userId } = getAuthData();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  /* const { data: jobApplications } = useQuery({
    queryKey: ['events'],
    queryFn: AdminService.getJobApplications,
  }); */

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { data: calendarEvents } = useQuery({
    queryKey: ['calendarEvents'],
    queryFn: CalendarService.getEvents,
  });

  const events = getEvents(
    calendarEvents,
    +userId,
    null,
    user?.userProfile?.timezone
  );

  return (
    <div className={classes.RightPanel}>
      <div className={classes.container}>
        <div className={classes.calendarContainer}>
          <TwoWeeksCalendar today={today} events={events} />
        </div>
        <div className={classes.upcomingEventsContainer}>
          <UpcomingEvents today={today} events={events} />
        </div>
        <div className={classes.messagesAndFeedbackContainer}>
          <MessagesAndFeedback />
        </div>
        <LatestNotifications />
      </div>
      {isTabletOrMobile && (
        <div className={classes.funnelAndJobsContainer}>
          <RedAlertJobs />
          <RecruitmentFunnel data={leftPanelData} />
        </div>
      )}
    </div>
  );
}

export default memo(RightPanel, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
