import React, { useState, useEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import useDebouncedSearchWithHistory from '../../../../../hooks/useDebouncedSearchWithHistory';
import { UiContext } from '../../../../../context/UiContext';
import AddOrEditCity from './AddOrEditCity';
import ViewCity from './ViewCity';
import tableColsToSortOptions from '../../../../../helpers/tableColsToSortOptions';
import AdminService from '../../../../../services/AdminService';
import TableMenu from '../../TableMenu';
import Table from '../../../../Table';

export default function Cities({ setItemCount }) {
  const [activeAction, setActiveAction] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isViewCityVisible, setIsViewCityVisible] = useState(false);
  const [currentCityId, setCurrentCityId] = useState(null);
  const [isAddCityVisible, setIsAddCityVisible] = useState(false);
  const [isEditCityVisible, setIsEditCityVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const [activeSortOption, setActiveSortOption] = useState({
    label: t('common.createdOn'),
    value: 'createdAt',
  });

  const {
    showUnknownErrorModal,
    showModal,
    showSelectionNeededModal,
    setIsFetching,
    showConflictModal,
  } = useContext(UiContext);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'citiesSearchHistory'
  );

  const {
    data: citiesPages,
    refetch,
    fetchNextPage,
    isFetching,
  } = useQuery({
    queryKey: [
      'locations',
      activeSortOption.value,
      debouncedSearchTerm,
      sortOrder,
      currentPage,
    ],
    queryFn: () =>
      AdminService.getCities({
        orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        sortOrder,
        pageNumber: currentPage,
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (citiesPages) {
      setItemCount(citiesPages.totalCount);
    }
  }, [citiesPages, setItemCount]);

  const rows = citiesPages?.data || [];

  useEffect(() => {
    refetch();
  }, [activeSortOption, debouncedSearchTerm, refetch, sortOrder]);

  const showViewCity = (id) => {
    setCurrentCityId(id);
    setIsViewCityVisible(true);
  };

  const showAddCity = () => {
    setIsAddCityVisible(true);
  };

  const showEditCity = (id) => {
    if (id) {
      setSelectedRows([id]);
    }
    setIsEditCityVisible(true);
  };

  const deleteCities = (cityIds, callback) => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.modal.Delete City'
      ),
      text: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.modal.Are you sure you want to delete?'
      ),
      confirmButtonLabel: t('common.yes'),
      dismissButtonLabel: t('common.no'),
      onConfirm: async () => {
        try {
          setIsFetching(true);

          const promises = cityIds.map((id) => AdminService.deleteCity({ id }));
          await Promise.all(promises);
          showModal({
            title: t(
              'dashboardComponents.Console.RestaurantManagement.Cities.modal.Success!'
            ),
            text: t(
              'dashboardComponents.Console.RestaurantManagement.Cities.modal.City deleted successfully'
            ),
            dismissButtonLabel: t('common.gotIt'),
            dismissButtonVariant: '',
          });
          callback?.();
        } catch (error) {
          if (error.response.status === 409) {
            showConflictModal(error.response.data.message);
          } else {
            showUnknownErrorModal();
          }
        } finally {
          await refetch();
          setIsFetching(false);
        }
      },
    });
  };

  const actions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: showAddCity,
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          showEditCity();
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteCities(selectedRows);
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.columns.City Name'
      ),
      field: 'name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.columns.Country'
      ),
      field: 'country',
      sortable: false,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.columns.Zipcode'
      ),
      field: 'postCode',
      sortable: false,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Cities.columns.Picture'
      ),
      field: 'coverImageName',
      sortable: false,
      type: 'cityImage',
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  return (
    <div>
      <TableMenu
        actions={actions}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
        dangerAction={t('common.delete')}
        sortOptions={sortOptions}
        activeSortOption={activeSortOption}
        setActiveSortOption={setActiveSortOption}
        selectedCount={selectedRows.length}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
        setSortOrder={setSortOrder}
        currentPage={currentPage}
        totalPages={citiesPages?.totalPages || 1}
        setCurrentPage={setCurrentPage}
        searchResultCount={
          debouncedSearchTerm && !isFetching ? citiesPages.totalCount : null
        }
      />
      <Table
        name={t(
          'dashboardComponents.Console.RestaurantManagement.Cities.table.Cities'
        )}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        rows={rows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortOptions={sortOptions}
        setActiveSortOption={setActiveSortOption}
        activeSortOption={activeSortOption}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        columns={columns}
        onRowClick={showViewCity}
      />

      <ViewCity
        isVisible={isViewCityVisible}
        handleClose={() => setIsViewCityVisible(false)}
        currentCityId={currentCityId}
        cities={rows}
        deleteCities={deleteCities}
        showEditCity={showEditCity}
        isOverlayed={isEditCityVisible}
      />

      <AddOrEditCity
        isVisible={isAddCityVisible}
        handleClose={() => setIsAddCityVisible(false)}
        refetch={refetch}
        showAgain={() => setIsAddCityVisible(true)}
        isAdd
      />

      <AddOrEditCity
        isVisible={isEditCityVisible}
        handleClose={() => setIsEditCityVisible(false)}
        refetch={refetch}
        isEdit
        selectedRows={selectedRows}
        cities={rows}
        hasNoAnimation={isViewCityVisible}
      />
    </div>
  );
}
