import React, { useEffect, useRef, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import { JOBS_PORTAL_URL } from '../../constants/main';
import classes from './styles.module.scss';

// const JOBS_PORTAL_URL = 'http://localhost:3001';

export default function JobPostPreviewModal({ show, handleClose, jobPostId }) {
  const [height, setHeight] = useState(987);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const iframeRef = useRef();

  useEffect(() => {
    const readMessage = (event) => {
      if (event.data.type === 'resizeIframe') {
        setHeight(event.data.height);
      }
    };

    if (show) {
      window.addEventListener('message', readMessage);
    }

    return () => {
      window.removeEventListener('message', readMessage);
    };
  }, [show]);

  return (
    <Modal
      show={show}
      centered
      className={classes.JobPostPreviewModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content} style={{ height }}>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <iframe
          ref={iframeRef}
          width={isTabletOrMobile ? window.innerWidth - 16 : 1097}
          height={isTabletOrMobile ? height : height}
          title="Job Post"
          src={`${JOBS_PORTAL_URL}/jobs/${jobPostId}`}
          frameBorder="0"
          allow="clipboard-write"
        />
      </div>
    </Modal>
  );
}
