import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function SortIcon({ sortOrder, activeSortOption, name, style }) {
  return (
    <div
      style={style}
      className={classNames(classes.SortIcon, {
        [classes.desc]: sortOrder === 'desc',
        [classes.visible]: activeSortOption.label === name,
      })}
    >
      <svg
        width="11"
        height="16"
        viewBox="0 0 11 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M1.25526 12.083L4.73344 15.3459C5.25718 15.8373 6.10322 15.8373 6.62696 15.3459L10.1051 12.083C10.9512 11.2893 10.3469 9.92871 9.15166 9.92871H2.19531C1.00011 9.92871 0.409221 11.2893 1.25526 12.083Z"
          fill="#888888"
        />
        <path
          d="M10.1019 3.91798L6.62368 0.655049C6.09994 0.16372 5.2539 0.16372 4.73016 0.655049L1.25198 3.91798C0.405938 4.71166 1.01025 6.07226 2.20546 6.07227L9.16181 6.07227C10.357 6.07227 10.9479 4.71166 10.1019 3.91798Z"
          fill="#6941FF"
        />
      </svg>
    </div>
  );
}
