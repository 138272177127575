/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { get } from 'lodash';
import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik, getIn } from 'formik';

import { useTranslation } from 'react-i18next';

import Switch from '../../../Switch';
import NumberInput from '../../../Form/NumberInput';
import { UiContext } from '../../../../context/UiContext';
import useSwitchableRowData from '../../../../hooks/useSwitchableRowData';
import TextInput from '../../../Form/TextInput';
import Textarea from '../../../Form/Textarea';
import FormDropdown from '../../../Form/FormDropdown';
import SidePopup from '../SidePopup';
import classes from './styles.module.scss';

export default function AddOrEditData({
  isVisible,
  handleClose,
  title,
  leftButtonLabel,
  rightButtonLabel,
  rightColumnFields,
  leftColumnFields,
  submitHandler,
  isAdd,
  selectedRows,
  data,
  hasNoAnimation,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [filteredFields, setFilteredFields] = useState(
    rightColumnFields.concat(leftColumnFields)
  );

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(data, selectedRows);

  const { showModal, isCreatingOrUpdating } = useContext(UiContext);

  const { t } = useTranslation();

  const initialValues = {};

  rightColumnFields.concat(leftColumnFields).forEach((field) => {
    let initialValue = '';
    const fieldName = field.dataFieldName ? field.dataFieldName : field.field;
    const existingValue = get(data?.[currentDataIndex], fieldName);

    if (field.defaultValue !== undefined) {
      initialValue = field.defaultValue;
    }

    if (!isAdd && existingValue !== null && existingValue !== undefined) {
      if (field.type === 'dropdown') {
        initialValue = { value: existingValue.id, label: existingValue.name };
      } else {
        initialValue = get(data?.[currentDataIndex], field.field);
      }
    }

    initialValues[field.field] = initialValue;
  });

  const validation = useMemo(() => {
    const validationObject = {};
    filteredFields.forEach((field) => {
      let yupValidation;

      if (Array.isArray(field.validation)) {
        if (field.validation?.includes?.('object')) {
          yupValidation = yup.object();
        } else {
          yupValidation = yup.string().trim();
        }

        if (field.validation?.includes?.('required')) {
          yupValidation = yupValidation.required(
            t('dashboardComponents.Console.AddOrEditData.Cannot be empty')
          );
        }

        if (field.validation?.includes?.('alphabets')) {
          yupValidation = yupValidation.matches(
            /^[aA-zZ\s]+$/,
            t(
              'dashboardComponents.Console.AddOrEditData.Only alphabets allowed'
            )
          );
        }

        if (field.validation?.includes?.('numbers')) {
          yupValidation = yupValidation.matches(
            /^\d+$/,
            t('dashboardComponents.Console.AddOrEditData.Only numbers allowed')
          );
        }
      } else {
        yupValidation = field.validation;
      }

      validationObject[field.field] = yupValidation;
    });

    return validationObject;
  }, [filteredFields, t]);

  const validationSchema = useMemo(() => yup.object(validation), [validation]);

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsSaving(true);
      try {
        await submitHandler({ ...values, id: data?.[currentDataIndex]?.id });
      } finally {
        setIsSaving(false);
      }
    },
    enableReinitialize: true,
  });

  // Filter fields which should not be shown
  useEffect(() => {
    const newFields = rightColumnFields
      .concat(leftColumnFields)
      .filter((field) => {
        if (field.isVisible) {
          if (field.isVisible(formik.values)) {
            return true;
          }
          return false;
        }
        return true;
      });

    setFilteredFields(newFields);
  }, [formik.values, leftColumnFields, rightColumnFields]);

  const closeAddFormWithWarning = () => {
    if (
      Object.values(formik.values).some((val) => !!val) &&
      Object.keys(formik.values).some(
        (key) => formik.values[key] !== formik.initialValues[key]
      )
    ) {
      showModal({
        title: t('dashboardComponents.Console.AddOrEditData.Exit Form.title'),
        text: t('dashboardComponents.Console.AddOrEditData.Exit Form.text'),
        dismissButtonLabel: t('common.yes'),
        confirmButtonLabel: t('common.no'),
        onConfirm: () => {},
        onCancel: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const closeEditFormWithWarning = () => {
    if (
      Object.keys(formik.values).some(
        (key) => formik.values[key] !== formik.initialValues[key]
      )
    ) {
      showModal({
        title: t('dashboardComponents.Console.AddOrEditData.Exit Screen.title'),
        text: t('dashboardComponents.Console.AddOrEditData.Exit Screen.text'),
        dismissButtonLabel: 'Yes',
        confirmButtonLabel: 'No',
        onConfirm: () => {},
        onCancel: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const isSubmitButtonDisabled = Object.keys(formik.values).some((key) => {
    if (isCreatingOrUpdating) {
      return true;
    }

    const field = filteredFields.find((fld) => fld.field === key);

    if (
      (field?.validation?.includes?.('required') &&
        formik.values[key] === '') ||
      isSaving
    ) {
      return true;
    }

    return false;
  });

  const createDataFields = (fields) => {
    const fieldsJsx = fields.map((field) => {
      if (field.isVisible) {
        if (!field.isVisible(formik.values)) {
          return null;
        }
      }

      let fieldJsx = (
        <div
          className={classNames({
            [classes.fullWidth]: field.fullWidth,
          })}
        >
          <TextInput
            width={field.width}
            fullWidth={field.fullWidth}
            height={50}
            label={field.label}
            key={field.field}
            value={getIn(formik.values, field.field)}
            name={field.field}
            onChange={formik.handleChange}
            error={getIn(formik.errors, field.field)}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            placeholder={field.placeholder}
            maxLength={field.maxLength}
            warning={field?.warning?.(formik.values[field.field])}
          />
        </div>
      );

      if (field.type === 'textarea') {
        fieldJsx = (
          <Textarea
            height={100}
            label={field.label}
            key={field.field}
            value={getIn(formik.values, field.field)}
            name={field.field}
            onChange={formik.handleChange}
            error={getIn(formik.errors, field.field)}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            placeholder={field.placeholder}
            maxLength={field.maxLength}
            warning={field?.warning?.(formik.values[field.field])}
          />
        );
      } else if (field.type === 'dropdown') {
        fieldJsx = (
          <FormDropdown
            height={50}
            label={field.label}
            key={field.field}
            value={formik.values[field.field]}
            name={field.field}
            setFieldValue={formik.setFieldValue}
            error={getIn(formik.errors, field.field)}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            placeholder={field.placeholder}
            options={field.options}
            fetchOptions={field.fetchOptions}
          />
        );
      } else if (field.type === 'number') {
        fieldJsx = (
          <NumberInput
            height={50}
            label={field.label}
            key={field.field}
            value={formik.values[field.field]}
            minValue={field.minValue || 0}
            name={field.field}
            setFieldValue={formik.setFieldValue}
            error={getIn(formik.errors, field.field)}
            onBlur={formik.handleBlur}
            touched={isTriedToSubmit}
            placeholder={field.placeholder}
            warning={field?.warning?.(formik.values[field.field])}
            note={field.note}
          />
        );
      } else if (field.type === 'switch') {
        fieldJsx = (
          <div className={classes.switchContainer}>
            <Switch
              label={field.label}
              isEnabled={getIn(formik.values, field.field)}
              onClick={() =>
                formik.setFieldValue(field.field, !formik.values[field.field])
              }
            />
          </div>
        );
      }

      if (!isAdd && field.isNotEditable) {
        let value = getIn(formik.values, field.field);

        if (typeof value === 'string') {
          value = value.trim();
        } else {
          value = value?.label;
        }

        fieldJsx = (
          <TextInput
            width={field.width}
            fullWidth={field.fullWidth}
            height={50}
            label={field.label}
            key={field.field}
            value={getIn(formik.values, field.field).label}
            readonly
          />
        );
      }

      return fieldJsx;
    });

    return fieldsJsx;
  };

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      setIsTriedToSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  // const isAbleToResetForm = !isEqual(formik.values, formik.initialValues);
  const isDirty = formik.dirty;

  return (
    <SidePopup
      fields={filteredFields}
      isVisible={isVisible}
      handleClose={isAdd ? closeAddFormWithWarning : closeEditFormWithWarning}
      title={title}
      leftButtonLabel={leftButtonLabel}
      rightButtonLabel={rightButtonLabel}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      onLeftButtonClick={formik.resetForm}
      isLeftButtonDisabled={!isDirty || isCreatingOrUpdating}
      isRightButtonDisabled={isSubmitButtonDisabled}
      switchNext={selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && switchToPrevDataElement}
      hasNoAnimation={hasNoAnimation}
      formik={formik}
      mode={isAdd ? 'add' : 'edit'}
    >
      <div className={classes.AddOrEditData}>
        <div className={classes.col}>{createDataFields(leftColumnFields)}</div>
        <div className={classes.col}>{createDataFields(rightColumnFields)}</div>
      </div>
    </SidePopup>
  );
}
