import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';

import { useTranslation } from 'react-i18next';

import ResumeInput from './ResumeInput';
import ResumeUploadProgress from './ResumeUploadProgress';

import classes from './styles.module.scss';
import { useUiContext } from '../../../../../../../context/UiContext';
import CandidatesService from '../../../../../../../services/CandidatesService';

const WordResume = lazy(() => import('../../../../../Candidate/WordResume'));

const resumeFieldName = 'userProfileFileResume';

export default function Resume({
  formik,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
}) {
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const resumeInputRef = useRef();

  const { showNotification } = useUiContext();

  const { t } = useTranslation();

  const { userProfileFileResume } = formik.values;

  const deleteResume = () => {
    formik.setFieldValue('userProfileFileResume', null);
  };

  const openResumeInput = () => {
    resumeInputRef.current.click();
  };

  const setFile = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (validTypes.includes(fileType)) {
      formik.setFieldValue(resumeFieldName, event.target.files[0]);
    }

    resumeInputRef.current.value = '';
  };

  // Upload resume on file change
  useEffect(() => {
    const uploadResume = async () => {
      if (
        !formik.values[resumeFieldName] ||
        formik.values[resumeFieldName].url
      ) {
        return;
      }

      try {
        const resume = await CandidatesService.uploadResume(
          formik.values[resumeFieldName],
          (progress) => {
            setUploadingProgress(progress);
          }
        );

        formik.setFieldValue(resumeFieldName, {
          ...resume,
          url: resume.uri,
          createdAt: new Date(),
        });
        showNotification({
          text: t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Resume uploaded successfully'
          ),
        });
      } catch (error) {
        console.log(error);
      }
    };

    // Upload resume only if it's not uploaded yet
    if (formik.values[resumeFieldName] && !formik.values[resumeFieldName].url) {
      uploadResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[resumeFieldName], t, showNotification]);

  const isResumeUploaded = !!formik.values[resumeFieldName]?.url;
  const isResumeUploading =
    !isResumeUploaded &&
    uploadingProgress > 0 &&
    formik.values[resumeFieldName];

  let content = (
    <div className={classes.resumeContainer}>
      <ResumeInput
        resumeInputRef={resumeInputRef}
        formik={formik}
        name="userProfileFileResume"
        setFieldValue={formik.setFieldValue}
      />
    </div>
  );

  if (isResumeUploading) {
    content = (
      <div className={classes.resumeContainer}>
        <ResumeUploadProgress progress={uploadingProgress} />
      </div>
    );
  } else if (isResumeUploaded) {
    content = (
      <Suspense
        fallback={
          <div>{t('dashboardComponents.Discover.CandidateModal.loading')}</div>
        }
      >
        <WordResume
          candidate={{
            userProfile: {
              userProfileFileResume,
            },
          }}
          isModal
          currentForm={{}}
          addCandidateModal
          deleteResume={deleteResume}
          formik={formik}
          resumeFieldName={resumeFieldName}
          isPossibleToEdit={isPossibleToEdit}
          isReadOnly={isReadOnly}
          isEdit={isEdit}
          openResumeInput={openResumeInput}
        />
      </Suspense>
    );
  }

  return (
    <div className={classes.Resume}>
      <input
        onChange={setFile}
        ref={resumeInputRef}
        type="file"
        accept=".doc,.docx,.pdf"
        style={{
          display: 'none',
        }}
      />
      {content}
    </div>
  );
}
