import React from 'react';

import striptags from 'striptags';

export default function PostContent({ title, content }) {
  return (
    <>
      {title}:{'  '}
      {striptags(content)}
      <br />
    </>
  );
}
