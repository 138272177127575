import React, { useRef } from 'react';

import classNames from 'classnames';

import Tooltip from '../../../../Tooltip';

import classes from './styles.module.scss';
import deleteIcon from '../../../../../assets/images/dashboard/candidate/wordResume/delete.svg';
import uploadIcon from '../../../../../assets/images/dashboard/candidate/wordResume/upload.svg';

export default function Icons({
  isOpened,
  handleChange,
  inputRef,
  searchText,
  setIsFocused,
  setIsOpened,
  url,
  handlePrint,
  setSearchText,
  isFocused,
  onChange,
  addCandidateModal,
  formik,
  resumeFieldName,
  word,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
  openResumeInput,
}) {
  const fileInputRef = useRef();

  const deleteResume = () => {
    formik.setFieldValue(resumeFieldName, null);
  };

  const setResume = (event) => {
    formik.setFieldValue(resumeFieldName, event.target.files[0]);
  };

  const addCandidateModalIcons = (
    <>
      <Tooltip text="Delete" direction="left">
        <button
          onClick={deleteResume}
          type="button"
          className={classNames(classes.icon, classes.delete, {
            [classes.disabled]: (!isPossibleToEdit && isEdit) || isReadOnly,
          })}
        >
          <img src={deleteIcon} alt="Delete" />
        </button>
      </Tooltip>
      <Tooltip text="Upload" direction="left">
        <button
          type="button"
          className={classNames(classes.icon, classes.upload, {
            [classes.disabled]: (!isPossibleToEdit && isEdit) || isReadOnly,
          })}
          onClick={openResumeInput}
        >
          <img src={uploadIcon} alt="Upload" />
        </button>
      </Tooltip>
    </>
  );

  return (
    <div
      className={classNames(classes.Icons, {
        [classes.addCandidateModal]: addCandidateModal,
      })}
    >
      <input
        accept=".doc,.docx,.pdf"
        type="file"
        ref={fileInputRef}
        onChange={setResume}
        style={{
          display: 'none',
        }}
      />
      <Tooltip text="Search" direction="left">
        <div
          className={classNames(classes.icon, classes.search, {
            [classes.disabled]: word,
          })}
        >
          <input
            type="checkbox"
            checked={isOpened}
            onChange={(event) => handleChange(event)}
            className={classes.searchBox}
          />
          {isOpened && (
            <>
              <input
                ref={inputRef}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setIsFocused(false);
                    setIsOpened(false);
                  }, 150);
                }}
                className={classes.searchInput}
                value={searchText}
                onChange={onChange}
              />
              {isFocused && (
                <i
                  className={classes.clearIcon}
                  onMouseDown={() => {
                    setSearchText('');
                    setIsOpened(false);
                  }}
                >
                  Clear
                </i>
              )}
            </>
          )}
        </div>
      </Tooltip>

      <Tooltip text="Download" direction="left">
        <a
          href={url}
          download
          className={classNames(
            classes.icon,
            classes.download,
            classNames({
              [classes.disabled]: !url,
            })
          )}
        >
          {' '}
        </a>
      </Tooltip>

      {!word && (
        <Tooltip text="Print" direction="left">
          <div
            className={classNames(
              classes.icon,
              classes.print,
              classNames({
                [classes.disabled]: !url,
              })
            )}
            onClick={handlePrint}
          />
        </Tooltip>
      )}

      {addCandidateModal && addCandidateModalIcons}
    </div>
  );
}
