import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import RefreshButton from './RefreshButton';

import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

function getDate(date) {
  const temp = new Date(date);

  if (!date) {
    return false;
  }

  return `${temp?.getDate()} ${temp
    ?.toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp?.getFullYear()}`;
}

export default function Assessment({ candidate }) {
  const [visibleAssessments, setVisibleAssessments] = useState([]);

  const { t } = useTranslation();

  const candidateAssessments = candidate?.assignedAssessments;

  const { data: flowsProfiles } = useQuery({
    queryKey: ['flowsProfiles'],
    queryFn: () => AdminService.getFlowsProfiles(),
    keepPreviousData: true,
  });

  const { data: assessments } = useQuery({
    queryKey: ['assessments'],
    queryFn: () => AdminService.getAssessment({ term: '', pageSize: 9999 }),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (candidate?.status === 'New' || candidate?.status === 'Invited') {
      setVisibleAssessments([]);
      return;
    }

    setVisibleAssessments(
      candidateAssessments?.map((assessment) => {
        if (assessment?.assessmentProviderKey === 'zaphire') {
          return {
            ...assessment,
            name: assessments?.data?.find(
              (assessmnt) => assessmnt.id === assessment.assessmentId
            )?.name,
          };
        }
        return {
          ...assessment,
          name: flowsProfiles?.find(
            (assessmnt) => assessmnt.id === assessment.assessmentId
          )?.name,
          report: candidate?.flowQCandidateResults?.resultPDF,
        };
      })
    );
  }, [flowsProfiles, assessments, candidateAssessments, candidate]);

  const openAssessmentReport = (report) => {
    const url = report;
    window.open(url, '_blank');
  };

  // Get score for assessment from flowQCandidateResults.resultDat
  const resultData = candidate?.flowQCandidateResults?.resultData
    ? JSON.parse(candidate.flowQCandidateResults.resultData)
    : null;

  let score = '';

  if (resultData?.ab) {
    const valueWithPKey = Object.values(resultData?.ab).find(
      (value) => value.p
    );

    if (valueWithPKey) {
      score = valueWithPKey.p;
    }
  } else if (resultData?.la) {
    score = resultData?.la?.p;
  }

  return (
    <div className={classes.assessments}>
      <div className={classNames(classes.container, classes.titleContainer)}>
        <h2 className={classes.title}>
          {t(
            'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.title'
          )}
        </h2>
        {candidateAssessments && candidateAssessments.length > 0 ? (
          <div className={classes.refreshButtonContainer}>
            <RefreshButton jobPostId={candidate?.jobPostId} />
          </div>
        ) : null}
        {visibleAssessments?.length ? (
          <div className={classes.amount}>{visibleAssessments.length}</div>
        ) : null}
      </div>
      {!visibleAssessments?.length ? (
        <p className={classes.noData}>
          {t(
            'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.noData'
          )}
        </p>
      ) : (
        <>
          <div className={classes.options}>
            <p className={classes.optionScore}>
              {t(
                'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.options.score'
              )}
            </p>
            <p className={classes.optionStatus}>
              {t(
                'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.options.status'
              )}
            </p>
            <p className={classes.optionDate}>
              {t(
                'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.options.dateTaken'
              )}
            </p>
            <p className={classes.optionReport}>
              {t(
                'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.options.report'
              )}
            </p>
          </div>
          <div className={classes.assessmentList}>
            {visibleAssessments?.map((assessment) => (
              <div className={classes.assessment} key={assessment?.key}>
                <p className={classes.name}>{assessment?.name}</p>
                <p
                  className={classNames(classes.score, {
                    //  [classes.red]: score && score <= 20,
                    [classes.red]: false, // disabled because threshold is not used with flowQ for now
                  })}
                >
                  {score || 'N/A'}
                </p>
                <p className={classes.status}>
                  {' '}
                  {t(
                    `dashboardComponents.Candidate.AssessmentAndInterview.Assessment.AssessmentList.Status.${assessment?.status}`
                  )}
                </p>
                <p className={classes.date}>
                  {getDate(candidate?.flowQCandidateResults?.finishedAt)}
                </p>
                {assessment.report && (
                  <div
                    className={classes.report}
                    onClick={() => openAssessmentReport(assessment.report)}
                  />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
