import moment from 'moment';
import { getTimezoneDate } from './timezones';

export function updateSuggestedEndTime(start, end, suggestedStartTime) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  const differenceInMinutes = endTime.diff(startTime, 'minutes');

  const baseTime = moment(suggestedStartTime, 'HH:mm');

  const newTime = baseTime.clone().add(differenceInMinutes, 'minutes');

  return newTime.format('HH:mm');
}

export function getTime(startTime, endTime) {
  if (!endTime) {
    return `${new Date(
      2023,
      0,
      1,
      startTime?.slice(0, 2),
      startTime?.slice(3)
    ).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  }

  const time = `${new Date(
    2023,
    0,
    1,
    startTime?.slice(0, 2),
    startTime?.slice(3)
  ).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })} - ${new Date(
    2023,
    0,
    1,
    endTime?.slice(0, 2),
    endTime?.slice(3)
  ).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;

  return time;
}

export function getEvents(calendarEvents, userId, candidateId, timezone) {
  const events = [];

  if (!timezone) {
    return getEvents(calendarEvents, userId, candidateId, 'Turkey');
  }

  calendarEvents?.forEach((application) => {
    if (application?.jobApplicationInterview) {
      let interview = application.jobApplicationInterview;

      const {
        id,
        startTime,
        endTime,
        interviewDate,
        suggestedInterviewDate,
        suggestedStartTime,
        status,
        jobApplicationInterviewInvites,
      } = interview;

      const [timezoneDate, timezoneStartTime, timezoneEndTime] =
        getTimezoneDate(interviewDate, timezone, startTime, endTime);

      const event = {
        ...interview,
        organizer: application.organizer,
        users: application.users,
      };

      if (suggestedInterviewDate) {
        const suggestedEndTime = updateSuggestedEndTime(
          startTime,
          endTime,
          suggestedStartTime
        );

        const [requestedDate, requestedStartTime, requestedEndTime] =
          getTimezoneDate(
            suggestedInterviewDate,
            timezone,
            suggestedStartTime,
            suggestedEndTime
          );

        event.requestedDate = requestedDate;
        event.requestedStartTime = requestedStartTime;
        event.requestedEndTime = requestedEndTime;
      }

      interview = {
        id,
        event,
        jobApplicationId: application.jobApplicationId,
        jobPost: application.jobApplication?.jobPost,
        type: 'Interview',
        startTime: timezoneStartTime,
        endTime: timezoneEndTime,
        time: getTime(timezoneStartTime, timezoneEndTime),
        eventDate: timezoneDate,
        status,
        users: application.users,
        userId: application.jobApplication.user?.id,
        candidate: {
          ...application,
          user: application.jobApplication.user,
          jobPost: application.jobApplication.jobPost,
        },
        userFirstName: application.jobApplication.user?.userProfile?.firstName,
        userLastName: application.jobApplication.user?.userProfile?.lastName,
        invites: jobApplicationInterviewInvites,
        jobApplications: calendarEvents,
        isFavorite: application.isFavorite,
        originalEvent: application,
      };

      events.push({ ...interview });
    }

    if (application.jobApplicationJoining) {
      let joining = application.jobApplicationJoining;

      const {
        id,
        startTime,
        endTime,
        joiningDate,
        suggestedJoiningDate,
        suggestedStartTime,

        jobApplicationJoiningInvites,
      } = joining;

      const [timezoneDate, timezoneStartTime, timezoneEndTime] =
        getTimezoneDate(joiningDate, timezone, startTime, endTime);

      const event = {
        ...joining,
        organizer: application.organizer,
        users: application.users,
      };

      if (suggestedJoiningDate) {
        const suggestedEndTime = updateSuggestedEndTime(
          startTime,
          endTime,
          suggestedStartTime
        );

        const [requestedDate, requestedStartTime, requestedEndTime] =
          getTimezoneDate(
            suggestedJoiningDate,
            timezone,
            suggestedStartTime,
            suggestedEndTime
          );

        event.requestedDate = requestedDate;
        event.requestedStartTime = requestedStartTime;
        event.requestedEndTime = requestedEndTime;
      }

      joining = {
        id,
        event,
        jobApplicationId: application.jobApplication?.id,
        jobPost: application.jobApplication?.jobPost,
        type: 'Joining',
        startTime: timezoneStartTime,
        endTime: timezoneEndTime,
        time: getTime(timezoneStartTime, timezoneEndTime),
        eventDate: timezoneDate,
        users: application.users,
        userId: application.jobApplication.user?.id,
        candidate: {
          ...application,
          user: application.jobApplication.user,
          jobPost: application.jobApplication.jobPost,
        },
        userFirstName: application.jobApplication.user?.userProfile?.firstName,
        userLastName: application.jobApplication.user?.userProfile?.lastName,
        invites: jobApplicationJoiningInvites,
        jobApplications: calendarEvents,
        isFavorite: joining.isFavorite,
      };

      events.push({ ...joining });
    }

    if (application.jobApplicationOnboarding) {
      let onboarding = application.jobApplicationOnboarding;

      const {
        id,
        startTime,
        endTime,
        onboardingDate,
        jobApplicationOnboardingInvites,
        suggestedOnboardingDate,
        suggestedStartTime,
      } = onboarding;

      const [timezoneDate, timezoneStartTime, timezoneEndTime] =
        getTimezoneDate(onboardingDate, timezone, startTime, endTime);

      const event = {
        ...onboarding,
        organizer: application.organizer,
        users: application.users,
      };

      if (suggestedOnboardingDate) {
        const suggestedEndTime = updateSuggestedEndTime(
          startTime,
          endTime,
          suggestedStartTime
        );

        const [requestedDate, requestedStartTime, requestedEndTime] =
          getTimezoneDate(
            suggestedOnboardingDate,
            timezone,
            suggestedStartTime,
            suggestedEndTime
          );

        event.requestedDate = requestedDate;
        event.requestedStartTime = requestedStartTime;
        event.requestedEndTime = requestedEndTime;
      }

      onboarding = {
        id,
        event,
        jobApplicationId: application.jobApplication?.id,
        jobPost: application.jobApplication?.jobPost,
        type: 'Onboarding',
        startTime: timezoneStartTime,
        endTime: timezoneEndTime,
        time: getTime(timezoneStartTime, timezoneEndTime),
        eventDate: timezoneDate,
        users: application.users,
        userId: application.jobApplication.user?.id,
        candidate: {
          ...application,
          user: application.jobApplication.user,
          jobPost: application.jobApplication.jobPost,
        },
        userFirstName: application.jobApplication.user?.userProfile?.firstName,
        userLastName: application.jobApplication.user?.userProfile?.lastName,
        invites: jobApplicationOnboardingInvites,
        jobApplications: calendarEvents,
        isFavorite: application.isFavorite,
      };

      events.push({ ...onboarding });
    }
  });

  if (candidateId) {
    return events?.filter((event) => event.id === candidateId);
  }

  /*   return events?.filter(
    (event) =>
      event.users.find(({ id }) => id === userId) ||
      event?.event?.organizer?.id === userId
  ); */

  return events;
}

export function getEventFromSearch(calendarEvents, userId, timezone) {
  if (!timezone) {
    return getEventFromSearch(calendarEvents, userId, 'Turkey');
  }

  return calendarEvents?.map((calendarEvent) => {
    const event =
      calendarEvent?.jobApplicationInterview ||
      calendarEvent?.jobApplicationJoining ||
      calendarEvent?.jobApplicationOnboarding;

    const eventDate =
      event?.onboardingDate || event?.joiningDate || event?.interviewDate;

    const [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
      eventDate,
      timezone,
      event?.startTime,
      event?.endTime
    );

    return {
      id: event?.id,
      event,
      jobApplicationId: calendarEvent.jobApplicationId,
      jobPost: calendarEvent?.jobApplication?.jobPost,
      type: calendarEvent?.type,
      startTime: timezoneStartTime,
      endTime: timezoneEndTime,
      time: getTime(timezoneStartTime, timezoneEndTime),
      eventDate: timezoneDate,
      users: event?.users,
      // userId: application.user.id,
      // candidate: application,
      userFirstName: calendarEvent?.candidateName?.split(' ')[0],
      userLastName:
        calendarEvent?.candidateName?.split(' ')[
          calendarEvent?.candidateName?.split(' ').length - 1
        ],
      invites:
        event?.jobApplicationInterviewInvites ||
        event?.jobApplicationOnboardingInvites ||
        event?.jobApplicationJoiningInvites,
      // jobApplications,
      isFavorite: event?.eventFavorites?.some((fav) => fav.userId === userId),
    };
  });
}
