import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
import parsePhoneNumber from 'libphonenumber-js';

import CountriesDropdown from './CountriesDropdown';
import classes from './styles.module.scss';

// import arrowDownIcon from '../../assets/images/arrow-down.svg';
// import searchIcon from '../../assets/images/search-gray.svg';
import { countries } from '../../../constants/countries';

export default function PhoneInput({
  name,
  label,
  placeholder,
  setFieldValue,
  onBlur,
  touched,
  error,
  value,
  height,
  readOnly,
}) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCountry, setActiveCountry] = useState('');
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  const { t } = useTranslation();

  const preferedCountries = [
    {
      name: t('common.countries.Turkey'),
      iso: 'TR',
      phoneCode: '+90',
    },
    {
      name: t('common.countries.India'),
      iso: 'IN',
      phoneCode: '+91',
    },
    {
      name: t('common.countries.United Arab Emirates'),
      iso: 'AE',
      phoneCode: '+971',
    },
  ];

  const sorteredCountries = useMemo(() => {
    return [
      ...countries?.sort((a, b) => {
        if (a.areas !== undefined && b.areas === undefined) {
          return -1;
        }
        if (a.areas === undefined && b.areas !== undefined) {
          return 1;
        }
        return 0;
      }),
    ];
  }, []);

  useEffect(() => {
    if (searchTerm.length) {
      setFilteredCountries(
        countries?.filter(
          (country) =>
            country?.name
              ?.trim()
              .toLowerCase()
              .includes(searchTerm?.trim().toLowerCase()) ||
            country?.phoneCode?.trim().includes(searchTerm.trim())
        )
      );
      return;
    }
    setFilteredCountries(
      countries?.filter(
        (country) =>
          (country?.name
            ?.trim()
            .toLowerCase()
            .includes(searchTerm?.trim().toLowerCase()) ||
            country?.phoneCode?.trim().includes(searchTerm.trim())) &&
          !preferedCountries.find(
            (preferedCountry) => preferedCountry.name === country.name
          )
      )
    );
  }, [searchTerm]);

  /*  useEffect(() => {
    if (value?.length > 7 && value[0] !== '+') {
      setFieldValue(name, `+${value}`);
    }

    if (value?.length > 7 && activeCountry) {
      const parsedNumber = parsePhoneNumber(value, activeCountry.iso);

      setFieldValue(name, parsedNumber?.formatInternational(parsedNumber));
    }
  }, [activeCountry, name, setFieldValue, value]); */

  useEffect(() => {
    if (value?.length >= 0 && value?.length <= 1) {
      setIsCountrySelected(false);
    }
    if (isCountrySelected) {
      return;
    }
    setActiveCountry(() => {
      return sorteredCountries.find((country) => {
        if (country.areas) {
          return value
            ?.replace(/\s/g, '')
            ?.startsWith(
              country.phoneCode +
                country.areas.find((area) =>
                  value
                    .slice(country.phoneCode?.length)
                    .replace(/\s/g, '')
                    .startsWith(String(area))
                )
            );
        }

        return value?.replace(/\s/g, '')?.startsWith(country.phoneCode);
      });
    });
  }, [value]);

  useEffect(() => {
    if (activeCountry) {
      setFieldValue(name, `${value} `);
    }
  }, [activeCountry]);

  const setCountry = useCallback(
    (country, shouldFocus = true) => {
      console.log(country);
      setActiveCountry(country);
      setFieldValue(name, country.phoneCode);
      setIsCountrySelected(true);

      if (shouldFocus) {
        setTimeout(() => {
          phoneInputRef?.current?.focus();
        }, 200);
      }
    },
    [name]
  );

  // Select Turkey initially
  useEffect(() => {
    if (!value) {
      const turkey = countries.find((country) => country.iso === 'TR');
      setCountry(turkey, false);
    }
  }, [setCountry, value]);

  const formatPhoneNumber = (inputNumber) => {
    if (!activeCountry) {
      setFieldValue(name, inputNumber);
      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(inputNumber, activeCountry.iso);

      setFieldValue(name, parsedNumber.formatInternational(parsedNumber));
    } catch (err) {
      setFieldValue(name, inputNumber);
    }
  };

  const handlePhoneChange = (event) => {
    let inputNumber = event.target.value?.replace(/[^0-9+\s]/g, '');

    if (inputNumber.length && !inputNumber?.startsWith('+')) {
      inputNumber = `+${inputNumber}`;
    }

    if (inputNumber.length < 8) {
      setFieldValue(name, inputNumber);

      return;
    }

    formatPhoneNumber(inputNumber.replace(/ /g, ''));
  };

  const handleDropdownClick = () => {
    if (readOnly) {
      return;
    }

    setIsDropdownOpened(!isDropdownOpened);

    const parentPosition = containerRef?.current?.getBoundingClientRect();

    setDropdownPosition({ x: parentPosition.x, y: parentPosition.y });
  };

  useEffect(() => {
    if (isDropdownOpened) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpened]);

  return (
    <div className={classes.PhoneInput}>
      <label htmlFor={name}>{label}</label>
      <div className={classes.container} ref={containerRef}>
        {isDropdownOpened ? (
          <>
            <input
              ref={searchInputRef}
              style={{ height }}
              type="text"
              placeholder="Search for a country"
              onChange={(event) => setSearchTerm(event.target.value)}
              onBlur={onBlur}
              value={searchTerm}
            />
          </>
        ) : (
          <>
            <div
              className={classes.dropdown}
              onClick={handleDropdownClick}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleDropdownClick();
                }
              }}
            />
            <input
              ref={phoneInputRef}
              style={{ height }}
              type="text"
              id={name}
              placeholder={placeholder}
              onChange={handlePhoneChange}
              onBlur={onBlur}
              name={name}
              value={value}
              readOnly={readOnly}
            />
          </>
        )}
        {activeCountry && (
          <button
            className={classes.country}
            type="button"
            onClick={handleDropdownClick}
          >
            <img
              src={`https://flagcdn.com/w40/${activeCountry?.iso.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w80/${activeCountry?.iso.toLowerCase()}.png 2x`}
              width="24"
              alt="Country"
            />
          </button>
        )}
      </div>
      {touched && error && <div className={classes.error}>{error}</div>}
      <CountriesDropdown
        isDropdownOpened={isDropdownOpened}
        top={dropdownPosition.y + 61}
        left={dropdownPosition.x}
        countries={filteredCountries}
        preferedCountries={preferedCountries}
        activeCountry={activeCountry}
        setActiveCountry={setCountry}
        handleClose={() => {
          setIsDropdownOpened(false);
        }}
        containerRef={containerRef}
        hasSearchTerm={searchTerm?.trim().length}
        setCountry
      />
    </div>
  );
}
