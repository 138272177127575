/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import EmojiPickerContainer from '../EmojiPickerContainer';
import Reactions from '../../Reactions';
import ReactionPicker from '../../ReactionPicker';
import UsersPopup from '../../../../AddContent/TextEditor/UsersPopup';

import { UiContext } from '../../../../../../../context/UiContext';
import getResizedImageUrl from '../../../../../../../helpers/getResizedImageUrl';
import { placeCaretAtEnd } from '../../../../../../../helpers/contentEditable';
import { useFeedStore } from '../../../../../../../store';
import FeedService from '../../../../../../../services/FeedService';
import useMutationObserver from '../../../../../../../hooks/useMutationObserver';
import classes from './styles.module.scss';

function getNumberSuffix(day) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  const lastDigit = day % 10;

  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

function formatDate(date) {
  const dateFormat = new Date(date);
  const options = {
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = dateFormat.toLocaleString('en-US', options);

  const day = dateFormat.getDate();
  const suffix = getNumberSuffix(day);
  const formattedDay = `${day}${suffix}`;

  const hours = dateFormat.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const minutes = dateFormat.getMinutes();
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedDay} ${formattedDate} ${formattedHours}:${formattedMinutes} ${ampm}`;
}

export default function Comment({
  comment,
  feedPost,
  refetch,
  userReply,
  setUserReply,
  left,
  user,
}) {
  const [commentValue, setCommentValue] = useState(comment.content);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState('');
  const [emojiPickerCoordX, setEmojiPickerCoordX] = useState(0);
  const [emojiPickerCoordY, setEmojiPickerCoordY] = useState(0);
  const [isReactionAdded, setIsReactionAdded] = useState(false);
  const [commentUsersTags, setCommentUsersTags] = useState([]);
  const [isUserPopupVisible, setIsUserPopupVisible] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const commentRef = useRef(null);
  const commentContainerRef = useRef(null);
  const emojiPickerIconRef = useRef(null);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('commentId');

  const setLastTextBoxRange = useFeedStore(
    (state) => state.setLastTextBoxRange
  );
  const lastTextBoxRange = useFeedStore((state) => state.lastTextBoxRange);

  useEffect(() => {
    if (+commentId === comment.id) {
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comment.id, commentId]);

  useEffect(() => {
    setCommentUsersTags(
      comment?.mentions?.map((mention) => ({
        value: mention?.id,
        label: mention?.fullName,
      }))
    );
  }, [comment]);

  const reset = () => {
    setCommentUsersTags(
      comment?.mentions?.map((mention) => ({
        value: mention?.id,
        label: mention?.fullName,
      }))
    );
    setIsEditing(false);
    setCommentValue(comment.content);
    setIsEmojiPickerVisible(false);
    commentContainerRef.current.innerHTML = comment.content;
  };

  const handleReply = () => {
    setUserReply({
      value: comment.author.id,
      commentId: comment.id,
      label: comment.author.fullName,
    });
  };

  const handleEmojiPickerClick = (event) => {
    setEmojiPickerCoordY(event.clientY);
    setEmojiPickerCoordX(event.clientX);
  };

  const editComment = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (commentContainerRef?.current) {
      commentContainerRef.current.innerHTML = comment.content;
    }
  }, [comment]);

  // Remove user tag from the message if the user is removed from the addressees list
  useEffect(() => {
    if (!isEditing) {
      return;
    }

    const mentionUsersElems =
      commentContainerRef.current?.querySelectorAll(`[data-userid]`);
    const mentionedUsers = Array.from(mentionUsersElems).map((elem) => ({
      value: elem.dataset.userid,
      label: elem.innerText.trim(),
    }));

    const removedUsers = mentionedUsers.filter((mUser) => {
      // eslint-disable-next-line no-shadow
      return !commentUsersTags.find((user) => +user.value === +mUser.value);
    });

    if (removedUsers.length) {
      const mentionedUserElement = commentContainerRef.current.querySelector(
        `[data-userid="${removedUsers[0].value}"]`
      );

      if (mentionedUserElement) {
        mentionedUserElement.parentNode?.remove();
      }
    }
  }, [commentContainerRef, commentUsersTags, isEditing]);

  const removeUserFromAddressessWhenRemovedFromText = useCallback(() => {
    const removedUserIds = [];
    // eslint-disable-next-line no-shadow
    commentUsersTags.forEach((user) => {
      const mentionedUserElement = commentContainerRef.current.querySelector(
        `[data-userid="${user.value}"]`
      );

      if (!mentionedUserElement || !mentionedUserElement.innerText.trim()) {
        removedUserIds.push(user.value);
      }
    });

    if (removedUserIds.length) {
      setCommentUsersTags((prevState) =>
        // eslint-disable-next-line no-shadow
        prevState.filter((user) => !removedUserIds.includes(user.value))
      );
    }
  }, [commentUsersTags]);

  const mentionUser = useCallback(
    (userName, userId) => {
      const nnode = document.createElement('span');
      nnode.contentEditable = false;
      lastTextBoxRange.surroundContents(nnode);
      nnode.innerHTML = `<span data-userid="${userId}" 
        style="color:#00E4AC;">${` ${userName}`}</span>`;

      const emptySpan = document.createElement('span');
      emptySpan.innerHTML = '&#8203;';
      nnode.after(emptySpan);

      const newRange = new Range();
      newRange.setStartAfter(nnode, 0);
      setLastTextBoxRange(newRange);
    },
    [lastTextBoxRange, setLastTextBoxRange]
  );

  useMutationObserver(commentContainerRef, () => {
    if (commentContainerRef.current?.innerText.length > 500) {
      commentContainerRef.current.innerHTML =
        commentContainerRef.current.innerText.slice(0, 500);
      placeCaretAtEnd(commentContainerRef);
    }

    removeUserFromAddressessWhenRemovedFromText(
      commentContainerRef.current.innerHTML
    );

    setCommentValue(commentContainerRef.current?.innerHTML);
  });

  const deleteReaction = async (reactionId) => {
    try {
      setIsFetching(true);

      await FeedService.deleteCommentReaction({
        id: feedPost.id,
        commentId: comment.id,
        reactionId,
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const addReaction = async (type) => {
    try {
      setIsReactionAdded(true);
      setIsFetching(true);

      const userReaction = comment.reactions.find(
        (reaction) => reaction.author.id === user.id
      );

      if (userReaction) {
        await deleteReaction(userReaction.id);
      }

      await FeedService.addCommentReaction({
        id: feedPost.id,
        commentId: comment.id,
        type,
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsReactionAdded(false);
    }
  };

  const deleteComment = async (deletedComment) => {
    try {
      const commentChilds = deletedComment?.children;

      if (commentChilds) {
        commentChilds.forEach((com) => deleteComment(com));
      }

      setIsFetching(true);
      await FeedService.deleteCommentFromFeedPost({
        id: feedPost.id,
        commentId: deletedComment.id,
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const updateComment = async () => {
    if (commentValue.length === 0) {
      deleteComment(comment);
      return;
    }

    try {
      setIsFetching(true);
      await FeedService.updateCommentFeedPost({
        id: feedPost.id,
        commentId: comment.id,
        content: commentContainerRef.current.innerHTML,
        parentId: comment.parentId,
        mentionIds: commentUsersTags.map((userTag) => userTag.value),
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsEmojiPickerVisible(false);
      setIsEditing(false);
    }
  };

  let footer;

  if (user?.id === comment?.author.id) {
    footer = (
      <div className={classes.leftPanel}>
        <span onClick={handleReply}>
          {t('dashboardComponents.Discover.Feed.Post.Comments.Comment.reply')}
        </span>
        <span onClick={editComment}>{t('common.edit')}</span>
        <span onClick={() => deleteComment(comment)}>{t('common.delete')}</span>
      </div>
    );
  } else if (user?.id === feedPost?.author?.id) {
    footer = (
      <div className={classes.leftPanel}>
        <span onClick={handleReply}>
          {t('dashboardComponents.Discover.Feed.Post.Comments.Comment.reply')}
        </span>
        <span onClick={() => deleteComment(comment)}>{t('common.delete')}</span>
      </div>
    );
  } else {
    footer = (
      <div className={classes.leftPanel}>
        <span onClick={handleReply}>
          {t('dashboardComponents.Discover.Feed.Post.Comments.Comment.reply')}
        </span>
      </div>
    );
  }

  const authorName = comment?.author?.fullName;
  const hasPhoto = comment?.author?.avatar;

  return (
    <>
      <div
        className={classes.Comment}
        style={{
          left,
          maxWidth: `calc(100% - 80px - ${40 * (comment.left - 1)}px)`,
        }}
        ref={commentRef}
      >
        <div className={classes.animationWrapper}>
          <div className={classes.animationScale}>
            {hasPhoto ? (
              <img
                src={getResizedImageUrl({
                  url: `avatar/${comment?.author?.avatar}`,
                  width: 80,
                })}
                alt="avatar"
              />
            ) : (
              <div className={classes.noPhoto}>
                {`${comment?.author?.firstName[0]}${comment?.author?.lastName[0]}`}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(classes.commentContainer, {
            [classes.selected]: userReply?.commentId === comment?.id,
          })}
        >
          <span className={classes.header}>
            <span className={classes.authorName}>{authorName}</span>
          </span>

          <div
            className={classes.textBox}
            ref={commentContainerRef}
            onKeyDown={(event) => {
              if (event.key === '@') {
                event.preventDefault();
                const range = document.getSelection().getRangeAt(0);
                setLastTextBoxRange(range);
                setIsUserPopupVisible(true);
              }
              if (
                commentContainerRef.current?.innerText.trim().length >= 500 &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete'
              ) {
                event.preventDefault();
              }
            }}
            contentEditable={isEditing}
          />

          <div className={classes.footer}>
            {isEditing ? (
              <>
                <div className={classes.leftPanel}>
                  <span onClick={updateComment}>Save</span>
                  <span onClick={reset}>Reset</span>
                  <span onClick={() => deleteComment(comment)}>Delete</span>
                </div>
                <div className={classes.rightPanel}>
                  <span
                    className={classNames(classes.charsLeft, {
                      [classes.red]:
                        commentContainerRef?.current?.innerText?.length === 500,
                    })}
                  >
                    {500 - commentContainerRef.current?.innerText.trim().length}{' '}
                    {t(
                      'dashboardComponents.Discover.Feed.Post.Comments.Comment.charactersLeft'
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                {footer}
                <div className={classes.rightPanel}>
                  {comment.updatedAt && (
                    <span>
                      {t(
                        'dashboardComponents.Discover.Feed.Post.Comments.Comment.edited'
                      )}
                    </span>
                  )}
                  <span>
                    {formatDate(comment?.updatedAt || comment.createdAt)}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={classes.reactions}>
            <Reactions data={comment} isRight popupStyle={{ left: '-200%' }}>
              <span className={classes.child}>
                {comment?.reactions?.length || ''}
              </span>
            </Reactions>
          </div>
          {isEditing ? (
            <div className={classes.emodjiContainer}>
              <div
                className={classes.emodji}
                ref={emojiPickerIconRef}
                onClick={(event) => {
                  handleEmojiPickerClick(event);
                  setIsEmojiPickerVisible(!isEmojiPickerVisible);
                }}
              />
            </div>
          ) : (
            <div className={classes.emodjiContainer}>
              <ReactionPicker
                addReaction={addReaction}
                disabled={isReactionAdded}
              >
                <div
                  className={classNames(classes.emodji, {
                    [classes.disabled]: isReactionAdded,
                  })}
                />
              </ReactionPicker>{' '}
            </div>
          )}
        </div>
      </div>
      {isEmojiPickerVisible && (
        <EmojiPickerContainer
          textRef={commentContainerRef?.current}
          top={emojiPickerCoordY}
          left={emojiPickerCoordX}
          handleClose={() => setIsEmojiPickerVisible(false)}
          iconRef={emojiPickerIconRef}
        />
      )}
      <UsersPopup
        show={isUserPopupVisible}
        addressees={commentUsersTags}
        setAddressees={setCommentUsersTags}
        onHide={() => setIsUserPopupVisible(false)}
        top={commentRef?.current?.getBoundingClientRect().top - 288}
        left={commentRef?.current?.getBoundingClientRect().left}
        onUserAdd={mentionUser}
        disableAll
      />
      {comment.children &&
        comment.children.map((com) => (
          <Comment
            comment={com}
            key={com.id}
            feedPost={feedPost}
            refetch={refetch}
            userReply={userReply}
            setUserReply={setUserReply}
            user={user}
            left={40 * (com.left - 1)}
          />
        ))}
    </>
  );
}
