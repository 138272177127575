import React from 'react';

import classNames from 'classnames';

import InactiveOverlay from '../InactiveOverlay';

import getResizedImageUrl from '../../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function LogoPreview({ logoSettings, isActive }) {
  const imageUrl =
    logoSettings.logo instanceof File
      ? URL.createObjectURL(logoSettings.logo)
      : getResizedImageUrl({
          url: `brand/${logoSettings?.logo?.split('/')?.pop()}`,
          width: 110,
        });

  return (
    <div
      className={classNames(classes.LogoPreview, {
        [classes.active]: isActive,
      })}
    >
      <InactiveOverlay isActive={isActive} />
      <div className={classes.container}>
        <h4 className={classes.ttile}>{logoSettings?.brandName}</h4>
        <img
          src={imageUrl}
          alt="Logo"
          style={{
            visibility: logoSettings.logo ? 'visible' : 'hidden',
          }}
        />
      </div>
    </div>
  );
}
