/* eslint-disable no-shadow */
import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Template from '../Template';

import { getTimezoneDate } from '../../../../../helpers/timezones';
import UserService from '../../../../../services/UserService';

// const getJobRole = (jobPost, me) => {
//   if (jobPost?.user?.id === me?.id) {
//     return 'OWNER';
//   }

//   if (
//     jobPost?.jobPostDelegates?.some((delegate) => delegate.user?.id === me?.id)
//   ) {
//     return 'DELEGATE';
//   }

//   if (
//     jobPost?.jobPostInterviewers?.some(
//       (interviewer) => interviewer.user?.id === me?.id
//     )
//   ) {
//     return 'INTERVIEWER';
//   }

//   return '';
// };

export default function Job({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const jobPath = `/tools/jobs/my-jobs/${notification.jobPost?.id}`;
    const goToJob = () => {
      navigate(jobPath);
      hideNotifications();
    };

    const content = {
      NewJobPostOwner: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwner.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwner.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwner.buttonLabel'
        ),
        onClick: goToJob,
      },
      DelegationExtended: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.delegationExtended.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.createdAt,
                user?.userProfile?.timezone
              )[0]
            ).format('Do MMM YYYY'),
            newDate: moment(
              getTimezoneDate(
                notification.jobPost?.jobPostDelegates[0]?.endsAt,
                user?.userProfile?.timezone
              )[0]
            ).format('Do MMM YYYY'),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.delegationExtended.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.delegationExtended.buttonLabel'
        ),
        onClick: goToJob,
      },
      NewJobPostOwnerOther: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwnerOther.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwnerOther.text',
          {
            message: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostOwnerOther.buttonLabel'
        ),
        onClick: goToJob,
      },
      NewJobPostDelegate: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostDelegate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostDelegate.text',
          {
            message: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostDelegate.buttonLabel'
        ),
        onClick: goToJob,
      },
      NewJobPostInterviewer: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostInterviewer.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostInterviewer.text',
          {
            message: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.newJobPostInterviewer.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostExtended: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExtended.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            expiresAt: moment(
              getTimezoneDate(
                notification.jobPost?.expiresAt,
                user?.userProfile?.timezone
              )[0]
            ).format('Do MMM YYYY'),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExtended.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExtended.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostExtendedDelegates: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExtendedDelegates.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            expiresAt: moment(
              getTimezoneDate(
                notification.jobPost?.expiresAt,
                user?.userProfile?.timezone
              )[0]
            ).format('Do MMM YYYY'),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExtendedDelegates.text'
        ),
        buttonLabel: '',
        onClick: () => {},
      },
      JobPostOnHold: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostOnHold.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostOnHold.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostOnHold.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostOnHoldDelegates: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostOnHoldDelegates.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostOnHoldDelegates.text'
        ),
        buttonLabel: '',
        onClick: () => {},
      },
      JobPostClosed: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostClosed.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostClosed.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostClosed.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostClosedFavorite: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostClosedFavorite.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        buttonLabel: '',
        onClick: () => {},
      },
      JobPostRepublished: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublished.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublished.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublished.buttonLabel'
        ),
        onClick: goToJob,
      },
      DelegationWillExpire: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostDelegationWillExpire.title',
          {
            name: notification.messageInfo,
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostDelegationWillExpire.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostDelegationWillExpire.buttonLabel'
        ),
        onClick: goToJob,
      },
      DelegationWillExpireDelegates: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostDelegationWillExpireDelegates.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostDelegationWillExpireDelegates.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: '',
        onClick: () => {},
      },
      JobPostRepublishedDelegates: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublishedDelegates.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublishedDelegates.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostRepublishedDelegates.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostExpired: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpired.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpired.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpired.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostExpiredDelegates: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpiredDelegates.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpiredDelegates.text'
        ),
        buttonLabel: '',
        onClick: () => {},
      },
      JobPostExpire1: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpire1.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpire1.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpired.buttonLabel'
        ),
        onClick: goToJob,
      },
      JobPostExpire5: {
        title: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpire5.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpire5.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Job.jobPostExpire5.buttonLabel'
        ),
        onClick: goToJob,
      },
      'External candidated added to job': {
        title: `${notification.jobApplication?.user?.userProfile?.fullName} ${t(
          'dashboardComponents.Notifications.Notification.Job.insertedTo'
        )}  ${notification.onEventJobApplicationStatus?.toUpperCase()}: ${
          notification.jobPost?.jobTitle
        } (${notification.jobPost?.id})`,
        text: user?.userProfile?.fullName,
        buttonLabel: '',
        onClick: () => {},
      },
      'notitification.job.candidate_added_no_job': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Job.addedTo'
        )}: ${notification.eventNote}`,
        text: user?.userProfile?.fullName,
        buttonLabel: '',
        onClick: () => {},
      },
    };

    return (
      content[notification.name] ||
      content[notification.eventNote] ||
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
