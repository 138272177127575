import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Candidates from './Candidates';
import Jobs from './Jobs';
import Calendar from './Calendar';

import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import Spinner from '../../../../modals/Spinner';

export default function Search({
  searchInputRef,
  searchTerm,
  currentTab,
  handleClose,
  long,
  user,
  isVisible,
  onSearchFinish,
}) {
  const [activeTab, setActiveTab] = useState(currentTab);
  const [isCandidatePopupVisible, setIsCandidatePopupVisible] = useState(false);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(
    dropdownRef,
    () => {
      if (!isCandidatePopupVisible) {
        handleClose();
      }
    },
    searchInputRef
  );

  const {
    data: searchData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['search', searchTerm],
    queryFn: () =>
      AdminService.getSearch({
        term: searchTerm,
      }),
    onSuccess: (data) => {
      if (onSearchFinish) {
        onSearchFinish(data);
      }
    },
  });

  const tabs = [
    {
      text: t('dashboardComponents.Header.Search.All'),
      amount:
        searchData?.candidates?.length +
          searchData?.jobs?.length +
          searchData?.calendarEvents?.length || 0,
    },
    {
      text: t('dashboardComponents.Header.Search.candidates'),
      amount: searchData?.candidates?.length || 0,
    },
    {
      text: t('dashboardComponents.Header.Search.jobs'),
      amount: searchData?.jobs?.length || 0,
    },
    {
      text: t('dashboardComponents.Header.Search.calendar'),
      amount: searchData?.calendarEvents?.length || 0,
    },
  ];

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <div
      className={classes.Search}
      ref={dropdownRef}
      style={{
        width: long && '480px',
        right: long && '-160px',
        left: long && 'auto',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
    >
      {currentTab === 'All' && (
        <div className={classes.tabsLinks}>
          {tabs.map(({ text, amount }) => (
            <div
              className={classNames(classes.tabLink, {
                [classes.isActive]: text === activeTab,
              })}
              onClick={() => handleClick(text)}
              key={text}
            >
              {`${text} ${amount}`}
            </div>
          ))}
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {activeTab === t('dashboardComponents.Header.Search.All') && (
            <div className={classes.tabs}>
              <Candidates
                searchTerm={searchTerm}
                candidates={searchData?.candidates}
                activeTab={activeTab}
                refetch={refetch}
                isCandidatePopupVisible={isCandidatePopupVisible}
                setIsCandidatePopupVisible={setIsCandidatePopupVisible}
                closeSearchPopup={handleClose}
              />
              <Jobs
                searchTerm={searchTerm}
                jobs={searchData?.jobs}
                activeTab={activeTab}
                handleClosePopup={handleClose}
                refetch={refetch}
                closeSearchPopup={handleClose}
              />
              <Calendar
                searchTerm={searchTerm}
                calendarEvents={searchData?.calendarEvents}
                activeTab={activeTab}
                user={user}
                refetch={refetch}
                closeSearchPopup={handleClose}
              />
            </div>
          )}
          {activeTab === t('dashboardComponents.Header.Search.candidates') && (
            <Candidates
              searchTerm={searchTerm}
              candidates={searchData?.candidates}
              activeTab={activeTab}
              refetch={refetch}
              isCandidatePopupVisible={isCandidatePopupVisible}
              setIsCandidatePopupVisible={setIsCandidatePopupVisible}
              closeSearchPopup={handleClose}
            />
          )}
          {activeTab === t('dashboardComponents.Header.Search.jobs') && (
            <Jobs
              searchTerm={searchTerm}
              jobs={searchData?.jobs}
              activeTab={activeTab}
              handleClosePopup={handleClose}
              refetch={refetch}
              closeSearchPopup={handleClose}
            />
          )}
          {activeTab === t('dashboardComponents.Header.Search.calendar') && (
            <Calendar
              searchTerm={searchTerm}
              calendarEvents={searchData?.calendarEvents}
              activeTab={activeTab}
              user={user}
              refetch={refetch}
              closeSearchPopup={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
}
