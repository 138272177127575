/* eslint-disable no-plusplus */
import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import PasswordValidation from '../../../PasswordValidation';
import PasswordInput from '../PasswordInput';
import Button from '../../Button';

import { UiContext } from '../../../../../context/UiContext';
import UserService from '../../../../../services/UserService';
import classes from './styles.module.scss';

export default function SetNewPassword({ handleCancel, isEditing }) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const { showNotification, showUnknownErrorModal, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const handleSave = async () => {
    try {
      setIsFetching(true);
      await UserService.updatePassword(newPassword);
      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordChangedNotification'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      handleCancel();
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.SetNewPassword}>
      <h3 className={classes.title}>
        {t('dashboardComponents.ProfileSettings.Password.SetNewPassword.title')}
      </h3>
      <div className={classes.container}>
        <div className={classes.column}>
          <PasswordInput
            readOnly={!isEditing}
            value={newPassword}
            name="newPassword"
            label={t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.newPasswordLabel'
            )}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className={classes.column}>
          <PasswordInput
            readOnly={!isEditing}
            value={confirmNewPassword}
            name="confirmNewPassword"
            label={t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.confirmNewPasswordLabel'
            )}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>

      <PasswordValidation
        newPassword={newPassword}
        confirmNewPassword={confirmNewPassword}
        setIsValid={setIsValid}
      />
      {isEditing && (
        <div className={classes.buttons}>
          <Button onClick={() => handleCancel()}>{t('common.cancel')}</Button>
          <Button type="save" disabled={!isValid} onClick={handleSave}>
            {t('common.save')}
          </Button>
        </div>
      )}
    </div>
  );
}
