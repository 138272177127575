import React from 'react';

import DotMenu from '../../DotMenu';
import FavStatus from '../../FavStatus';

import classes from './styles.module.scss';
import getResizedImageUrl from '../../../../../../helpers/getResizedImageUrl';

export default function CandidateCard({
  candidate,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
  refetch,
}) {
  const hasPhoto = !!candidate.avatar;

  const candidateName = hasPhoto
    ? ''
    : (candidate?.candidateName?.split(' ')[0]?.[0] ?? '') +
      (candidate?.candidateName?.split(' ')[1]?.[0] ?? '');

  return (
    <div
      className={classes.candidateCard}
      onClick={() => {
        setCurrentCandidate(candidate);
        setIsCandidatePopupVisible(true);
      }}
    >
      {hasPhoto ? (
        <img
          src={getResizedImageUrl({
            url: `avatar/${candidate?.avatar}`,
            width: 100,
          })}
          alt="canditatePhoto"
          className={classes.photo}
        />
      ) : (
        <div className={classes.noPhoto}>{candidateName}</div>
      )}
      <div className={classes.info}>
        <span className={classes.name}>{candidate?.candidateName}</span>
        <span className={classes.location}>
          {candidate?.candidateLocation || candidate?.user?.userProfile?.city}
        </span>
      </div>
      <div className={classes.settings}>
        <FavStatus candidate={candidate} refetch={refetch} />
        <DotMenu candidate={candidate} />
      </div>
    </div>
  );
}
