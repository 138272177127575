/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useCallback } from 'react';

import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import HiringPipelineSubHeadings from './HiringPipelineSubHeadings';
import SortIcon from './SortIcon';
import Checkbox from '../Form/Checkbox';
import Td from './Td';

import classes from './styles.module.scss';
import useIntersectionObserverPagination from '../../hooks/useIntersectionObserverPagination';

export default function Table({
  setSelectedRows,
  sortOptions,
  setActiveSortOption,
  selectedRows,
  activeSortOption,
  setSortOrder,
  sortOrder,
  rows,
  columns,
  onRowClick,
  cellHeight,
  fetchNextPage,
  isFetching,
  name,
  height,
  noSort,
  theadHeight,
  thTopPadding,
  refetch,
  onRowRightClick,
  minHeight,
  style,
  noDataMessage,
}) {
  const [orderedColumns, setOrderedColumns] = useState([]);

  const { t } = useTranslation();

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  const toggleAllRowsSelection = () => {
    setSelectedRows((prevRows) => {
      if (prevRows.length && prevRows.length === rows?.length) {
        return [];
      }
      const newRows = rows.map((user) => user.id);
      return newRows;
    });
  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevRows) => {
      if (prevRows.includes(id)) {
        const newRows = prevRows.filter((row) => row !== id);
        return newRows;
      }
      const newRows = [...prevRows, id];
      return newRows;
    });
  };

  const setSortOption = (option) => {
    let currentOption = sortOptions.find((opt) => opt.label === option);

    if (option.includes('summary.')) {
      currentOption = {
        label: t('miscComponents.Table.hiringPipeline'),
        value: option,
      };
    }

    if (currentOption) {
      setActiveSortOption(currentOption);
    }
  };

  const changeSortSettings = (sortOptionName) => {
    if (
      activeSortOption.label === sortOptionName ||
      (activeSortOption.value === sortOptionName &&
        sortOptionName.includes('summary.'))
    ) {
      setSortOrder((prevOrder) => {
        if (prevOrder === 'asc') {
          return 'desc';
        }
        if (prevOrder === 'desc') {
          return 'asc';
        }
        return 'asc';
      });
    } else {
      setSortOption(sortOptionName);
      setSortOrder('asc');
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const getOrder = useCallback(() => {
    if (noSort) {
      setOrderedColumns(columns);
      return;
    }

    let savedSortOrder;
    try {
      const tableColumnsSortOrder = JSON.parse(
        localStorage.getItem('tableColumnsSortOrder')
      );

      savedSortOrder = tableColumnsSortOrder[name];
    } catch (error) {
      // console.log(error);
    }

    if (savedSortOrder) {
      const orderMap = savedSortOrder.reduce((acc, elem, index) => {
        acc[elem.field] = index;
        return acc;
      }, {});

      setOrderedColumns(
        columns.sort((a, b) => orderMap[a.field] - orderMap[b.field])
      );
    } else {
      setOrderedColumns(columns);
    }
  }, [columns, name, noSort]);

  const saveOrder = (orderedCols) => {
    let prevSortOrder;

    try {
      prevSortOrder = JSON.parse(localStorage.getItem('tableColumnsSortOrder'));
    } catch (error) {
      // console.log(error);
    }

    if (prevSortOrder) {
      prevSortOrder = {};
    }

    const newSortOrder = { ...prevSortOrder, [name]: orderedCols };

    localStorage.setItem('tableColumnsSortOrder', JSON.stringify(newSortOrder));
  };

  const changeColumnPosition = (event, newPosition) => {
    const oldPosition = event.dataTransfer.getData('index');

    const newOrderedColumns = arrayMoveImmutable(
      orderedColumns,
      oldPosition,
      newPosition
    );
    setOrderedColumns(newOrderedColumns);
    saveOrder(newOrderedColumns);
  };

  const dragColumn = (event, index) => {
    event.dataTransfer.setData('index', index);
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  const hasNoData = (!rows || !rows?.length) && noDataMessage && !isFetching;

  return (
    <div
      className={classNames(classes.Table, {
        [classes.noData]: hasNoData,
      })}
      style={{ ...style, height, minHeight }}
    >
      {hasNoData && (
        <div className={classes.noData}>
          <span>{noDataMessage}</span>
        </div>
      )}
      <table>
        <thead style={{ height: theadHeight }}>
          <tr>
            <th
              style={{ paddingTop: thTopPadding }}
              onDragOver={allowDrop}
              onDrop={(event) => changeColumnPosition(event, 0)}
            >
              <div
                className={classNames(
                  classes.thContainer,
                  classes.withCheckbox
                )}
              >
                <Checkbox
                  backgroundColor="white"
                  onChange={toggleAllRowsSelection}
                  isSelected={
                    selectedRows.length && selectedRows.length === rows?.length
                  }
                />
              </div>
            </th>
            {orderedColumns.map((column, index) => {
              return (
                <th
                  style={{ paddingTop: thTopPadding }}
                  key={column.field}
                  draggable
                  onDragOver={allowDrop}
                  onDrop={(event) => changeColumnPosition(event, index)}
                  onDragStart={(event) => dragColumn(event, index)}
                  colSpan={column.type === 'hiringPipeline' ? 5 : ''}
                  className={classNames({
                    [classes.notSortable]: !column.sortable,
                  })}
                >
                  <div
                    className={classNames(classes.thContainer, {
                      [classes.hiringPipeline]:
                        column.type === 'hiringPipeline',
                      [classes.jobStatus]: column.type === 'jobStatus',
                      [classes.daysFromCreation]:
                        column.type === 'daysFromCreation',
                    })}
                    onClick={() => {
                      if (column.sortable) {
                        changeSortSettings(column.name);
                      }
                    }}
                  >
                    <span className={classes.columnName}>
                      {column.name}
                      {column.sortable && (
                        <SortIcon
                          sortOrder={sortOrder}
                          activeSortOption={activeSortOption}
                          name={column.name}
                        />
                      )}
                    </span>
                  </div>
                </th>
              );
            })}
          </tr>
          {orderedColumns.some((column) => column.type === 'hiringPipeline') ? (
            <tr>
              <th />
              {orderedColumns.map((col, index) => {
                return col.type === 'hiringPipeline' ? (
                  <HiringPipelineSubHeadings
                    col={col}
                    index={index}
                    classes={classes}
                    changeSortSettings={changeSortSettings}
                    sortOrder={sortOrder}
                    activeSortOption={activeSortOption}
                  />
                ) : (
                  <th key={`${col.field}1`} />
                );
              })}
            </tr>
          ) : null}
        </thead>
        <tbody>
          {rows?.map?.((row, index) => {
            return (
              <tr
                style={{ height: cellHeight }}
                key={row.id}
                onClick={() => {
                  onRowClick?.(row.id);
                }}
                onContextMenu={(event) => {
                  event.preventDefault();
                  onRowRightClick?.(row.id, event.clientX, event.clientY);
                }}
                ref={index === rows.length - 1 ? lastListElementRef : null}
              >
                <td>
                  <Checkbox
                    backgroundColor={(index + 1) % 2 === 0 && 'white'}
                    isSelected={selectedRows.includes(row.id)}
                    onChange={(event) => {
                      event.stopPropagation();
                      toggleRowSelection(row.id);
                    }}
                  />
                </td>
                {orderedColumns?.map((column) => {
                  return (
                    <Td
                      refetch={refetch}
                      classes={classes}
                      column={column}
                      row={row}
                      key={column.name + row.id}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
