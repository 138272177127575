import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Notifications from '../../Notifications';
import Favorites from '../../Favorites';
import Tooltip from '../../../Tooltip';
import AddOptionsDropdown from './AddOptionsDropdown';

import { PreventNavigation } from '../../../../context/PreventNavigationContext';
import { UiContext } from '../../../../context/UiContext';
import ChatService from '../../../../services/ChatService';
import NotificationsService from '../../../../services/NotificationsService';
import classes from './styles.module.scss';
import mapIcon from '../../../../assets/images/dashboard/header/map.svg';
import mapIconActive from '../../../../assets/images/dashboard/header/mapActive.svg';
import helpIcon from '../../../../assets/images/dashboard/header/help.svg';
import helpIconActive from '../../../../assets/images/dashboard/header/helpActive.svg';
import chatIcon from '../../../../assets/images/dashboard/header/chat.svg';
import chatIconActive from '../../../../assets/images/dashboard/header/chatActive.svg';
import favoritesIcon from '../../../../assets/images/dashboard/header/favorites.svg';
import favoritesIconActive from '../../../../assets/images/dashboard/header/favoritesActive.svg';
import notificationsIcon from '../../../../assets/images/dashboard/header/notifications.svg';
import notificationsIconActive from '../../../../assets/images/dashboard/header/notificationsActive.svg';
// import chatConnection from '../../../../helpers/chatConnection';
import UserService from '../../../../services/UserService';
import useHubConnection from '../../../../hooks/useHubConnection';
import { CHAT_HUB_URL } from '../../../../constants/main';

export default function TopMenu() {
  const [isAddOptionsDropdownOpen, setIsAddOptionsDropdownOpen] =
    useState(false);

  const { isNavigationAllowed, setShowModal, setNavigationLink } =
    useContext(PreventNavigation);

  const { topMenuActiveButton, setTopMenuActiveButton } = useContext(UiContext);

  const topMenuRef = useRef();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { page } = useParams();

  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({
    query: location.pathname.includes('discover')
      ? '(max-width: 1224px)'
      : '(max-width: 1024px)',
  });

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { data: notifications } = useQuery({
    queryKey: ['notifications'],
    queryFn: NotificationsService.getNotifications,
  });

  const hasUnreadNotifications = notifications?.some((ntfcn) => !ntfcn.readAt);

  const { data: chatThreads, refetch: refetchChatThreads } = useQuery({
    queryKey: ['chatThreads'],
    queryFn: ChatService.getThreads,
    keepPreviousData: true,
  });

  const contacts = [];
  chatThreads?.forEach((group) => contacts.push(...group.threads));
  const hasUnreadChatMessages = contacts.some((contact) => {
    return contact.unreadCount;
  });

  const connection = useHubConnection(CHAT_HUB_URL);

  useEffect(() => {
    connection?.startConnection();

    return () => {
      connection.endConnection();
    };
  }, [connection]);

  useEffect(() => {
    const newConnection = connection.connection;

    if (newConnection) {
      newConnection.on('NewMessage', () => {
        refetchChatThreads();
      });
    }
  }, [connection, refetchChatThreads]);

  const toggleNonPopupButton = (button) => {
    if (!isNavigationAllowed) {
      setShowModal(true);
      setNavigationLink(`/tools/${button.searchParam}`);
      return;
    }
    navigate(`/tools/${button.searchParam}`);
    setTopMenuActiveButton(null);
  };

  const togglePopupButton = (button) => {
    if (!isNavigationAllowed) {
      setShowModal(true);
      return;
    }
    if (topMenuActiveButton === button.label) {
      setTopMenuActiveButton(null);
    } else {
      setTopMenuActiveButton(button.label);
    }
  };

  const toggleAddOptionsDropdown = () => {
    setIsAddOptionsDropdownOpen(() =>
      setIsAddOptionsDropdownOpen((prevState) => !prevState)
    );
  };

  useEffect(() => {
    setTopMenuActiveButton(null);
  }, [navigate, setTopMenuActiveButton]);

  const buttons = [
    {
      label: t('dashboardComponents.Header.TopMenu.Map'),
      icon: mapIcon,
      width: 20,
      height: 24,
      activeIcon: mapIconActive,
      tooltip: t('dashboardComponents.Header.TopMenu.Map'),
      searchParam: 'map',
    },
    {
      label: t('dashboardComponents.Header.TopMenu.Help'),
      icon: helpIcon,
      activeIcon: helpIconActive,
      tooltip: t('dashboardComponents.Header.TopMenu.Help'),
      link: 'https://zaphire.zendesk.com/hc/en-us',
      isOuterLink: true,
    },
    {
      label: t('dashboardComponents.Header.TopMenu.Chat'),
      icon: chatIcon,
      activeIcon: chatIconActive,
      tooltip: t('dashboardComponents.Header.TopMenu.Chat'),
      searchParam: 'chat',
    },
    {
      label: t('dashboardComponents.Header.TopMenu.Favorites'),
      icon: favoritesIcon,
      activeIcon: favoritesIconActive,
      tooltip: t('dashboardComponents.Header.TopMenu.Favorites'),
      searchParam: 'favorites',
      isPopup: true,
    },
    {
      label: t('dashboardComponents.Header.TopMenu.Notifications'),
      icon: notificationsIcon,
      activeIcon: notificationsIconActive,
      tooltip: t('dashboardComponents.Header.TopMenu.Notifications'),
      searchParam: 'notifications',
      isPopup: true,
    },
  ];

  const userScopes = useMemo(() => {
    const scopes = [];
    user?.roles.forEach((role) => {
      const scope = role.scopes.split(',');
      scopes.push(...scope);
    });
    const uniqueUserScopes = [...new Set(scopes)];

    return uniqueUserScopes;
  }, [user]);

  const shouldDisplayFavorites =
    userScopes.includes('api:full') ||
    userScopes.includes('api:admin:jobs') ||
    userScopes.includes('api:admin:candidates');

  const buttonsToDisplay = shouldDisplayFavorites
    ? buttons
    : buttons.filter((button) => button.searchParam !== 'favorites');

  return (
    <div
      className={classNames(classes.TopMenu, {
        [classes.mobile]: isTabletOrMobile,
      })}
      ref={topMenuRef}
    >
      {userScopes?.some((scope) =>
        ['api:full', 'api:admin:jobs'].includes(scope)
      ) && (
        <div className={classes.optionsWrapper}>
          <div className={classes.scaleWrapper}>
            <div className={classes.addOptionsContainer}>
              <Tooltip text={t('dashboardComponents.Header.TopMenu.Create')}>
                <i
                  className={classes.create}
                  onClick={toggleAddOptionsDropdown}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      toggleAddOptionsDropdown();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {t('dashboardComponents.Header.TopMenu.Create')}
                </i>
              </Tooltip>
              {isAddOptionsDropdownOpen && (
                <AddOptionsDropdown
                  handleClose={() => setIsAddOptionsDropdownOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {buttonsToDisplay.map((button) => {
        return (
          <Tooltip
            text={button.tooltip}
            key={button.label}
            direction={isTabletOrMobile ? 'top-left' : ''}
          >
            <i
              className={classNames({
                [classes.active]:
                  topMenuActiveButton === button.label ||
                  page === button.searchParam,
                [classes.hasUnreadNotifications]: hasUnreadNotifications,
                [classes.hasUnreadChatMessages]: hasUnreadChatMessages,
                [classes.notificationIcon]: button.label === 'Notifications',
                [classes.chatIcon]: button.label === 'Chat',
                [classes.favoritesIcon]: button.label === 'Favorites',
                [classes.helpIcon]: button.label === 'Help',
                [classes.mapIcon]: button.label === 'Map',
              })}
              onClick={() => {
                if (button.isPopup) {
                  togglePopupButton(button);
                } else if (button.isOuterLink) {
                  window.open(button.link, '_blank');
                } else {
                  toggleNonPopupButton(button);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (button.isPopup) {
                    togglePopupButton(button);
                  } else if (button.isOuterLink) {
                    window.open(button.link, '_blank');
                  } else {
                    toggleNonPopupButton(button);
                  }
                }
              }}
              style={{
                backgroundImage: `url(${
                  topMenuActiveButton === button.label ||
                  page === button.searchParam
                    ? button.activeIcon
                    : button.icon
                })`,
              }}
              role="button"
              tabIndex={0}
            />
          </Tooltip>
        );
      })}
      {topMenuActiveButton === 'Favorites' && (
        <Favorites
          topMenuRef={topMenuRef}
          hidePopup={() => setTopMenuActiveButton(null)}
          favorite
        />
      )}
      {topMenuActiveButton === 'Notifications' && (
        <Notifications
          hideNotifications={() => setTopMenuActiveButton(null)}
          topMenuRef={topMenuRef}
        />
      )}
    </div>
  );
}
