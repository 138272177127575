/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classNames from 'classnames';

import Tooltip from '../../Tooltip';
import classes from './styles.module.scss';

export default function TextInput({
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  touched,
  success,
  type,
  warning,
  width,
  height,
  readonly,
  maxLength,
  info,
  note,
  centerText,
  inputRef,
  onFocus,
  infoWidth,
  style,
  labelStyle,
  staticErrorPositon,
  icon,
  noteStyle,
}) {
  const input = (
    <input
      style={{ ...style, width, height }}
      name={name}
      id={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={readonly ? () => {} : onBlur}
      onFocus={onFocus}
      type={type || 'text'}
      readOnly={readonly}
      maxLength={maxLength}
      ref={inputRef}
    />
  );

  return (
    <div
      style={{ width }}
      className={classNames(classes.TextInput, {
        [classes.error]: error && touched,
        [classes.success]: !error && !warning && touched && value?.length,
        [classes.warning]: !error && warning && touched,
        [classes.centerText]: centerText,
        [classes.staticErrorPositon]: staticErrorPositon,
        // [classes.fullWidth]: fullWidth,
      })}
    >
      <label
        htmlFor={name}
        style={{ display: label ? 'flex' : 'none', ...labelStyle }}
      >
        <span>{label}</span>{' '}
        {info && (
          <Tooltip text={info} width={infoWidth}>
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && (
          <span className={classes.note} style={noteStyle}>
            ({note})
          </span>
        )}
      </label>
      {icon ? (
        <div className={classes.iconContainer} style={{ width }}>
          {input}
          <img src={icon} alt="icon" className={classes.icon} />
        </div>
      ) : (
        input
      )}
      {error && touched && <div className={classes.status}>{error}</div>}
      {!error && touched && success && (
        <div className={classes.status}>{success}</div>
      )}
      {!error && touched && warning && (
        <div className={classes.status}>{warning}</div>
      )}
    </div>
  );
}
