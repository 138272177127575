import React from 'react';

import classNames from 'classnames';

import getResizedImageUrl from '../../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function Recipient({ user, rounded }) {
  return (
    <div
      className={classNames(classes.Recipient, {
        [classes.rounded]: rounded,
      })}
    >
      {user?.userProfile?.avatar ? (
        <img
          src={getResizedImageUrl({
            url: `avatar/${user?.userProfile?.avatar}`,
            width: 160,
          })}
          alt="User"
        />
      ) : (
        <div className={classes.noAvatar}>
          <span className={classes.initials}>
            {user?.userProfile?.firstName?.[0]}
            {user?.userProfile?.lastName?.IMAGES_URL?.[0]}
          </span>
        </div>
      )}
      <span className={classes.name}>
        {user?.userProfile?.firstName} {user?.userProfile?.lastName}
      </span>
    </div>
  );
}
