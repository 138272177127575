import React, { useState, useRef, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Search from '../Search';
import CandidatesDropdown from '../CandidatesDropdown';

import UserService from '../../../../services/UserService';
import { SearchContext } from '../../../../context/SearchContext';
import { PreventNavigation } from '../../../../context/PreventNavigationContext';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import classes from './styles.module.scss';

export default function SearchDropdown({
  setIsUserMenuVisible,
  isTabletOrMobile,
  isVisibleOnMobile,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [searchPopupIsOpened, setSearchPopupIsOpened] = useState(false);

  const searchRef = useRef();

  const { isNavigationAllowed, setShowModal } = useContext(PreventNavigation);
  const { activeDropDownOption, setActiveDropDownOption } =
    useContext(SearchContext);

  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'globalSearchHistory'
  );

  const { data } = useQuery({ queryKey: ['me'], queryFn: UserService.getMe });

  return (
    <>
      {isTabletOrMobile && isVisibleOnMobile && (
        <div className={classes.background} />
      )}
      <div
        className={classNames(classes.SearchDropdown, {
          [classes.mobile]: isTabletOrMobile,
          [classes.visible]: isVisibleOnMobile,
        })}
      >
        <input
          ref={searchRef}
          type="text"
          placeholder={t('dashboardComponents.Header.search')}
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onFocus={() => {
            if (!isNavigationAllowed) {
              setShowModal(true);
              return;
            }
            setIsFocused(true);
            setSearchPopupIsOpened(true);
            setSearchParams({});
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchPopupIsOpened(true);
            }
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 150);
          }}
        />
        {isFocused && !searchTerm.length && searchHistory.length ? (
          <ul className={classes.searchHistory}>
            {searchHistory.map((record) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={record}
                onMouseDown={() => {
                  setSearchTerm(record);
                  setSearchPopupIsOpened(true);
                }}
              >
                {record}
              </li>
            ))}
          </ul>
        ) : null}
        <CandidatesDropdown
          activeDropDownOption={activeDropDownOption}
          setActiveDropDownOption={setActiveDropDownOption}
          isTabletOrMobile={isTabletOrMobile}
        />
        {searchTerm && (
          <button
            type="button"
            onClick={() => {
              setSearchTerm('');
              setIsFocused(false);
              setIsUserMenuVisible(false);
            }}
            className={classes.button}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#F2F4F8" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9142 15.0252L19.9194 11.02C20.2123 10.7271 20.6872 10.7271 20.9801 11.02C21.273 11.3129 21.273 11.7877 20.9801 12.0806L16.9749 16.0858L20.9801 20.091C21.273 20.3839 21.273 20.8588 20.9801 21.1517C20.6872 21.4446 20.2123 21.4446 19.9194 21.1517L15.9142 17.1465L11.909 21.1517C11.6161 21.4446 11.1412 21.4446 10.8483 21.1517C10.5554 20.8588 10.5554 20.3839 10.8483 20.091L14.8535 16.0858L10.8483 12.0806C10.5554 11.7877 10.5554 11.3129 10.8483 11.02C11.1412 10.7271 11.6161 10.7271 11.909 11.02L15.9142 15.0252Z"
                fill="#242833"
              />
            </svg>
          </button>
        )}

        <Search
          searchTerm={searchTerm}
          currentTab={activeDropDownOption}
          handleClose={() => setSearchPopupIsOpened(false)}
          searchInputRef={searchRef}
          user={data}
          isVisible={searchTerm.length > 0 && searchPopupIsOpened}
        />
      </div>
    </>
  );
}
