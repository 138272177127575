/* eslint-disable no-nested-ternary */
import React from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import classes from './styles.module.scss';
import VideoResume from './VideoResume';

function getDate(date) {
  const temp = new Date(date);

  if (!date) {
    return false;
  }

  return `${temp?.getDate()} ${temp
    ?.toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp?.getFullYear()}`;
}

export default function Details({
  candidate,
  currentForm,
  setCurrentForm,
  sidePopupIsOpened,
  isApplication,
  isModal,
  candidateApplications,
}) {
  const { t } = useTranslation();

  function getBirthDate(date) {
    if (!date) {
      return '';
    }
    const [year, month, day] = date.split('-');

    console.log('date', date);

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthAbbreviation = monthNames[Number(month) - 1];

    return `${Number(day)} ${t(
      `common.shortMonths.${monthAbbreviation}`
    )} ${year}`;
  }

  const userEmail =
    candidate?.user?.userName || candidate?.userProfile?.email || '-';
  const userPhone =
    candidate?.user?.userProfile?.phone || candidate?.userProfile?.phone || '-';
  const userDateOfBirth =
    getBirthDate(candidate?.userProfile?.birthDate) ||
    getBirthDate(candidate?.user?.userProfile?.birthDate) ||
    '-';

  const userLocation =
    candidate?.applicantLocation ||
    candidate?.user?.userProfile?.city ||
    candidate?.userProfile?.city ||
    '-';
  const userEducation =
    candidate?.graduation?.name ||
    candidate?.userProfile?.userProfileGraduation?.name ||
    candidate?.user?.userProfile?.userProfileGraduation?.name ||
    'No';
  const hasExperience =
    candidate?.jobApplicationExperiences?.length ||
    candidate?.user?.userProfile?.userProfileExperiences?.length ||
    candidate?.userProfile?.userProfileExperiences?.length ||
    '';

  let experiences;

  if (hasExperience) {
    experiences = candidate?.jobApplicationExperiences?.length
      ? candidate?.jobApplicationExperiences
      : candidate?.user?.userProfile?.userProfileExperiences?.length
      ? candidate?.user?.userProfile?.userProfileExperiences
      : candidate?.userProfile?.userProfileExperiences;
  }

  const candidatesJobApplicationsApplied = candidateApplications?.filter(
    (application) => application?.status !== 'Invited'
  );

  return (
    <div className={classes.details}>
      <div
        className={classes.information}
        style={{ marginTop: isModal && '37px' }}
      >
        {isApplication && (
          <>
            <h2 className={classes.title}>
              {t('dashboardComponents.Candidate.Details.application')}
            </h2>
            <div className={classes.application}>
              <div className={classes.applicationDateAndJobOwner}>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.appliedOn')}
                  </p>
                  <p className={classes.description}>
                    {getDate(candidate?.createdAt)}
                  </p>
                </div>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.jobOwner')}
                  </p>
                  <p className={classes.description}>-</p>
                </div>
              </div>

              <div className={classes.positions}>
                <p className={classes.text}>
                  {t(
                    'dashboardComponents.Candidate.Details.otherPositionsApplied'
                  )}
                </p>
                <div className={classes.amount}>
                  {candidatesJobApplicationsApplied?.length - 1 || '0'}
                </div>
                <div
                  className={classes.seeAll}
                  onClick={() => setCurrentForm('Applications')}
                >
                  {t('dashboardComponents.Candidate.Details.seeAll')}
                </div>
              </div>
            </div>
          </>
        )}

        <h2 className={classes.title}>
          {t('dashboardComponents.Candidate.Details.basicInformation')}
        </h2>
        <div className={classes.basicInfo}>
          <div className={classes.container}>
            <p className={classes.name}>{t('common.email')}</p>
            <p className={classes.description}>{userEmail}</p>
          </div>
          <div className={classes.container}>
            <p className={classes.name}>{t('common.phone')}</p>
            <p className={classes.description}>{userPhone}</p>
          </div>
          <div className={classes.container}>
            <p className={classes.name}>{t('common.dateOfBirth')}</p>
            <p className={classes.description}>{userDateOfBirth}</p>
          </div>
          <div className={classes.container}>
            <p className={classes.name}>{t('common.location')}</p>
            <p className={classes.description}>{userLocation}</p>
          </div>
          <div className={classes.container}>
            <p className={classes.name}>{t('common.education')}</p>
            <p className={classes.description}>{userEducation}</p>
          </div>
          <div className={classNames(classes.container, classes.dummy)}>
            <p className={classes.name}>{t('common.experience')}</p>
            <p className={classes.description}>
              {hasExperience ? t('common.yes') : t('common.no')}
            </p>
          </div>
          <div className={classNames(classes.container, classes.hasExperience)}>
            <p className={classes.name}>{t('common.experience')}</p>
          </div>
          <div
            className={classNames(classes.container, classes.hasExperienceName)}
          >
            <p className={classes.description}>
              {hasExperience ? t('common.yes') : t('common.no')}
            </p>
          </div>
        </div>
        {!!hasExperience && (
          <div className={classes.experience}>
            {experiences?.map((exp) => (
              <div className={classes.exp} key={exp}>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.jobTitle')}
                  </p>
                  <p className={classes.description}>{exp.jobTitle}</p>
                </div>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.nameOfCompany')}
                  </p>
                  <p className={classes.description}>{exp.company}</p>
                </div>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.startDate')}
                  </p>
                  <p className={classes.description}>
                    {getDate(exp.startDate)}
                  </p>
                </div>
                <div className={classes.container}>
                  <p className={classes.name}>
                    {t('dashboardComponents.Candidate.Details.endDate')}
                  </p>
                  <p className={classes.description}>{getDate(exp.endDate)}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <VideoResume
        candidate={candidate}
        sidePopupIsOpened={sidePopupIsOpened}
        currentForm={currentForm}
        isModal={isModal}
      />
    </div>
  );
}
