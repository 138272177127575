import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function CounterOfferStatus({ offer, status }) {
  const { t } = useTranslation();

  const counterAmount = offer?.counterAmount;

  return (
    <div
      className={classNames(classes.CounterOfferStatus, {
        [classes.accepted]: status === 'update',
        [classes.declined]: status === 'decline',
      })}
    >
      {t('modals.OfferModal.offer.counterOffer')}: {counterAmount}
    </div>
  );
}
