import React, { createContext, useState } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

export const SearchContext = createContext();

export default function SearchContextProvider({ children }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeDropDownOption, setActiveDropDownOption] = useLocalStorage(
    'headerSearch',
    'All'
  );
  const [activeSearchScreenTab, setActiveSearchScreenTab] =
    useState('Candidates');
  const [isOpenedSearchScreen, setIsOpenedSearchScreen] = useState(false);

  const value = {
    searchTerm,
    setSearchTerm,
    activeDropDownOption,
    setActiveDropDownOption,
    isOpenedSearchScreen,
    setIsOpenedSearchScreen,
    activeSearchScreenTab,
    setActiveSearchScreenTab,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
