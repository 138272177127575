import React from 'react';

import { Link } from 'react-router-dom';

import Button from '../../Button';

import classes from './styles.module.scss';

export default function ButtonWithHelperText({
  onClick,
  isDisabled,
  helperLink,
  helperText,
  helperLinkText,
  buttonLabel,
  noHelper,
}) {
  return (
    <div className={classes.ButtonWithHelperText}>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttons}>
          <Button
            width="100%"
            onClick={onClick}
            disabled={isDisabled}
            variant="gradient"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
      {!noHelper && (
        <p className={classes.helper}>
          {helperText}{' '}
          <Link to={helperLink}>
            <span>{helperLinkText}</span>
          </Link>
        </p>
      )}
    </div>
  );
}
