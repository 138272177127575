/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import thumbsUpIcon from '../../../../../../assets/images/dashboard/discover/emojis/thumbs-up.png';
import heartIcon from '../../../../../../assets/images/dashboard/discover/emojis/heart.png';
import celebrateIcon from '../../../../../../assets/images/dashboard/discover/emojis/celebrate.png';
import laughIcon from '../../../../../../assets/images/dashboard/discover/emojis/laugh.png';
import surprisedIcon from '../../../../../../assets/images/dashboard/discover/emojis/surprised.png';
import sadIcon from '../../../../../../assets/images/dashboard/discover/emojis/sad.png';
import angryIcon from '../../../../../../assets/images/dashboard/discover/emojis/angry.png';
import MembersPopup from '../../../MembersPopup';

function getReactions(obj) {
  const result = [];

  for (const key in obj) {
    if (obj[key]?.list?.length) {
      result.push({
        label: key,
        reactions: obj[key].list,
      });
    }
  }

  return result;
}

export default function Reactions({
  children,
  data,
  isLeft,
  isRight,
  popupStyle,
}) {
  const [selectedReaction, setSelectedReaction] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const reactionTypes = {
    Laugh: {
      list: [],
      icon: laughIcon,
    },
    Suprised: {
      list: [],
      icon: surprisedIcon,
    },
    Sad: {
      list: [],
      icon: sadIcon,
    },
    Angry: {
      list: [],
      icon: angryIcon,
    },
    Celebrate: {
      list: [],
      icon: celebrateIcon,
    },
    Love: {
      list: [],
      icon: heartIcon,
    },
    ThumbUp: {
      list: [],
      icon: thumbsUpIcon,
    },
  };

  data.reactions.forEach((rction) => {
    reactionTypes[`${rction.type}`].list.push(rction);
  });

  const reactionLists = getReactions(reactionTypes);

  return (
    <div
      className={classes.Reactions}
      style={{
        left: isLeft ? 'calc(10px)' : 'auto',
        right: isLeft
          ? 'auto'
          : `calc(${reactionLists.length} * -10px - ${
              reactionLists.length * (isTabletOrMobile ? 8 : 20)
            }px)`,
      }}
    >
      {reactionLists
        .sort((a, b) => b.reactions.length - a.reactions.length)
        .map((reaction, index) => (
          <div
            className={classNames(classes.reaction, {
              [classes.selected]: selectedReaction === reaction.label,
            })}
            onClick={() => {
              setSelectedReaction(reaction.label);
              setIsPopupVisible(true);
            }}
            key={reaction.label}
            style={{
              left: (index + 1) * (isTabletOrMobile ? -4 : -10),
              zIndex:
                selectedReaction === reaction.label
                  ? 100000
                  : reactionLists.length - index,
              backgroundColor:
                (index === 0 && !selectedReaction) ||
                selectedReaction === reaction.label
                  ? 'white'
                  : '',
            }}
          >
            <img
              src={reactionTypes[reaction.label].icon}
              alt={reaction.label}
              width={isTabletOrMobile ? 15 : 18}
              height={isTabletOrMobile ? 15 : 18}
            />
          </div>
        ))}
      {isPopupVisible && (
        <MembersPopup
          reactionLists={reactionLists}
          activeReaction={selectedReaction}
          isRight={isRight}
          isLedt={isLeft}
          closePopup={() => {
            setIsPopupVisible(false);
            setSelectedReaction('');
          }}
          style={popupStyle || { left: '100%' }}
        />
      )}
      <div
        className={classes.child}
        style={{ left: `calc(${reactionLists.length} * 20px + 7px)` }}
      >
        {children}
      </div>
    </div>
  );
}
