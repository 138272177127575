import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

let timer;

export default function RenderOnTop({ children, style, initialStyle }) {
  const [isMeasured, setIsMeasured] = useState(false);
  const [coords, setCoords] = useState({
    top: 0,
    left: 0,
  });

  const componentRef = useRef();

  // Measure coords of the component and set it position to fixed
  useLayoutEffect(() => {
    const rect = componentRef.current.getBoundingClientRect();
    setCoords({
      top: rect.top,
      left: rect.left,
    });
    timer = setTimeout(() => {
      setIsMeasured(true);
    });
  }, []);

  useEffect(() => {
    if (isMeasured) {
      const child = componentRef.current.children[0];

      if (child) {
        child.style.position = 'static';
      }
    }
  }, [isMeasured]);

  // Clean up
  useEffect(() => {
    return () => {
      setIsMeasured(false);
      setCoords({
        top: 0,
        left: 0,
      });

      clearTimeout(timer);

      const child = componentRef.current?.children?.[0];

      if (child) {
        child.style.position = '';
      }
    };
  }, []);

  const content = (
    <div
      ref={componentRef}
      style={{
        position: isMeasured ? 'fixed' : '',
        top: coords.top,
        left: coords.left,
        zIndex: 100001,
        visibility: isMeasured ? 'visible' : 'hidden',
        ...(isMeasured ? style || {} : initialStyle || {}),
      }}
    >
      {children}
    </div>
  );

  return isMeasured ? createPortal(content, document.body) : content;
}
