import React, { useState, useEffect, useRef, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import UrlPreview from './UrlPreview';
import Controls from './Controls';
import Links from './Links';

import AdminService from '../../../../services/AdminService';
import { UiContext } from '../../../../context/UiContext';
import instagramIcon from '../../../../assets/images/socialMedia/instagram.svg';
import facebookIcon from '../../../../assets/images/socialMedia/facebook.svg';
import linkedinIcon from '../../../../assets/images/socialMedia/linkedin.svg';
import twitterIcon from '../../../../assets/images/socialMedia/twitter-square.svg';
import tiktokIcon from '../../../../assets/images/socialMedia/tiktok.svg';
import classes from './styles.module.scss';

const iconsMap = {
  Instagram: instagramIcon,
  Facebook: facebookIcon,
  LinkedIn: linkedinIcon,
  Twitter: twitterIcon,
  TikTok: tiktokIcon,
};

export default function SocialMedia() {
  const [linksValidity, setLinksValidity] = useState({
    Instagram: true,
    Facebook: true,
    LinkedIn: true,
    Twitter: true,
    TikTok: true,
  });
  const [activeLink, setActiveLink] = useState('');
  const [links, setLinks] = useState([
    {
      title: 'Instagram',
      icon: instagramIcon,
      url: '',
      isEnabled: false,
    },
    {
      title: 'Facebook',
      icon: facebookIcon,
      url: '',
      isEnabled: false,
    },
    {
      title: 'LinkedIn',
      icon: linkedinIcon,
      url: '',
      isEnabled: false,
    },
    {
      title: 'Twitter',
      icon: twitterIcon,
      url: '',
      isEnabled: false,
    },
    {
      title: 'TikTok',
      icon: tiktokIcon,
      url: '',
      isEnabled: false,
    },
  ]);

  const iframeRef = useRef();

  const { setIsFetching, showModal, showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: tenantSettings, refetch } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const updateLinks = async () => {
    try {
      setIsFetching(true);

      const sodialMediaLinks = links.map((link, index) => {
        return {
          type: link.title,
          url: link.url,
          sortOrder: index,
          isHidden: !link.isEnabled,
        };
      });

      await AdminService.updateTenantSettings({
        sodialMediaLinks,
      });

      refetch();
      iframeRef.current.contentWindow.postMessage('reload', '*');
      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.SocialMedia.changesPublishedMessage'
        ),
      });
    } catch (error) {
      console.log(error);

      if (error.response?.data?.message?.includes('Wrong url')) {
        const url = error.response.data.message.split('url:')[1];
        const linkWithError = links.find((link) => {
          return link.url.trim() === url.trim();
        });

        if (linkWithError) {
          setLinksValidity((prevLinksValidity) => {
            return {
              ...prevLinksValidity,
              [linkWithError.title]: false,
            };
          });
        }

        showModal({
          title: t(
            'dashboardComponents.ProfileSettings.SocialMedia.invalidUrlTitle'
          ),
          text: t(
            'dashboardComponents.ProfileSettings.SocialMedia.invalidUrlMessage',
            {
              url,
            }
          ),
        });
      }
    } finally {
      setIsFetching(false);
    }
  };

  // Update links from api response
  useEffect(() => {
    if (tenantSettings) {
      const sortedLinksFromApi = tenantSettings.sodialMediaLinks.sort(
        (a, b) => a.sortOrder - b.sortOrder
      );

      const updatedLinks = sortedLinksFromApi.map((link) => {
        return {
          title: link.type,
          icon: iconsMap[link.type],
          url: link.url,
          isEnabled: !link.isHidden,
        };
      });

      setLinks(updatedLinks);
    }
  }, [tenantSettings]);

  return (
    <div className={classes.SocialMedia}>
      <div className={classNames(classes.col, classes.links)}>
        <header className={classes.header}>
          <h2>
            {t('dashboardComponents.ProfileSettings.SocialMedia.pageTitle')}
          </h2>
          <p className={classes.description}>
            {t(
              'dashboardComponents.ProfileSettings.SocialMedia.pageDescription'
            )}
          </p>
        </header>
        <Links
          links={links}
          setLinks={setLinks}
          linksValidity={linksValidity}
          setLinksValidity={setLinksValidity}
        />
      </div>
      <div className={classNames(classes.col, classes.preview)}>
        <header className={classes.header}>
          <h2>
            {t('dashboardComponents.ProfileSettings.SocialMedia.previewTitle')}
          </h2>
          <p className={classes.description}>
            {t(
              'dashboardComponents.ProfileSettings.SocialMedia.previewDescription'
            )}
          </p>
        </header>
        <Controls
          linksValidity={linksValidity}
          links={links}
          setLinks={setLinks}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          updateLinks={updateLinks}
          initiaLinks={tenantSettings?.sodialMediaLinks}
        />
        <UrlPreview
          iframeRef={iframeRef}
          // links={tenantSettings?.sodialMediaLinks}
          // activeLink={activeLink}
        />
      </div>
    </div>
  );
}
