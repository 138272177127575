import React from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import FavStatus from '../../../Header/Search/FavStatus';

/* import {
  getTimezoneDate,
  getTimezoneTime,
} from '../../../../../helpers/timezones'; */

// import { getTime } from '../../../../../helpers/getEvents';
import classes from './styles.module.scss';

/* function getCreatedDate(date, noWeekday) {
  const tempDate = new Date(date);

  let formattedDate = `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()} ${tempDate.toLocaleString(
    'en-us',
    {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  )}
  `;

  if (noWeekday) {
    formattedDate = formattedDate.split(',').slice(1).join(',').trim();
  }

  return formattedDate;
}
 */
function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
}

export default function Header({
  status,
  event,
  refetch,
  currentUser,
  // isOnboarding,
  title,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  /*   const suggestedOnboardingDate = getEventDate(
    getTimezoneDate(
      event?.event?.suggestedOnboardingDate,
      currentUser?.userProfile?.timezone,
      event?.event?.suggestedStartTime
    )[0]
  );

  const suggestedJoiningDate = getEventDate(
    getTimezoneDate(
      event?.event?.suggestedJoiningDate,
      currentUser?.userProfile?.timezone,
      event?.event?.suggestedStartTime
    )[0]
  );

  const suggestedStartTime = getTimezoneTime(
    event?.event?.suggestedStartTime,
    currentUser?.userProfile?.timezone
  ); */

  let eventDate = getEventDate(event?.eventDate);
  let eventTime = event?.time;

  if (status === 'ChangeRequest') {
    eventDate = getEventDate(event?.event?.requestedDate);
    eventTime = `${event?.event?.requestedStartTime} - ${event?.event?.requestedEndTime}`;
  }

  return (
    <div className={classes.Header}>
      <div className={classes.row}>
        <div className={classes.titleContainer}>
          <span className={classes.headline}>{title}</span>
          {(status === 'Accepted' ||
            status === 'Declined' ||
            status === 'ChangeRequest') && (
            <div className={classes.status}>
              <div
                className={classNames(classes.statusText, {
                  [classes.statusDeclined]: status === 'Declined',
                  [classes.changeRequest]: status === 'ChangeRequest',
                })}
              >
                {status.replace(/([A-Z])/g, ' $1').trim()}
              </div>
            </div>
          )}
          {isTabletOrMobile && (
            <FavStatus
              calendarEvent={event}
              width={30}
              userId={currentUser?.id}
              refetch={refetch}
            />
          )}
          <span className={classes.headerDate}>
            {eventDate} {eventTime}
          </span>
        </div>
        {!isTabletOrMobile && (
          <FavStatus
            calendarEvent={event}
            width={30}
            userId={currentUser?.id}
            refetch={refetch}
          />
        )}
      </div>
      {/*  <div className={classes.row}>
        {status === 'ChangeRequest' && (
          <span className={classes.statusDate}>
            {`${t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Requested'
            )}:
                        ${
                          isOnboarding
                            ? suggestedOnboardingDate
                            : suggestedJoiningDate
                        } ${getTime(suggestedStartTime)}`}
          </span>
        )}
      </div> */}
    </div>
  );
}
