import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import UserTag from './UserTag';
import UsersPopup from '../../../../AddContent/TextEditor/UsersPopup';

import { UiContext } from '../../../../../../../context/UiContext';
import FeedService from '../../../../../../../services/FeedService';
import EmojiPickerContainer from '../EmojiPickerContainer';
import { useFeedStore } from '../../../../../../../store';
import { placeCaretAtEnd } from '../../../../../../../helpers/contentEditable';
import classes from './styles.module.scss';
import useMutationObserver from '../../../../../../../hooks/useMutationObserver';

export default function AddComment({
  feedPost,
  refetch,
  userReply,
  setUserReply,
}) {
  const [commentValue, setCommentValue] = useState('');
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState('');
  const [emojiPickerCoordX, setEmojiPickerCoordX] = useState(0);
  const [emojiPickerCoordY, setEmojiPickerCoordY] = useState(0);
  const [isAdded, setIsAdded] = useState(false);
  const [commentUsersTags, setCommentUsersTags] = useState([]);
  const [isUserPopupVisible, setIsUserPopupVisible] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [moveArrow, setMoveArrow] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const containerRef = useRef(null);
  const emojiPickerIconRef = useRef(null);
  const commentContainerRef = useRef(null);

  const { t } = useTranslation();

  const setLastTextBoxRange = useFeedStore(
    (state) => state.setLastTextBoxRange
  );
  const lastTextBoxRange = useFeedStore((state) => state.lastTextBoxRange);

  const isSentButtonValidate = commentValue.trim().length !== 0 && !isAdded;

  const handleEmojiPickerClick = (event) => {
    setEmojiPickerCoordY(event.clientY);
    setEmojiPickerCoordX(event.clientX);
  };

  const removeUserFromAddressessWhenRemovedFromText = useCallback(() => {
    const removedUserIds = [];
    commentUsersTags.forEach((user) => {
      const mentionedUserElement = commentContainerRef.current.querySelector(
        `[data-userid="${user.value}"]`
      );

      if (!mentionedUserElement || !mentionedUserElement.innerText.trim()) {
        removedUserIds.push(user.value);
      }
    });

    if (removedUserIds.length) {
      setCommentUsersTags((prevState) =>
        prevState.filter((user) => !removedUserIds.includes(user.value))
      );
    }
  }, [commentUsersTags]);

  const mentionUser = useCallback(
    (userName, userId) => {
      const nnode = document.createElement('span');
      nnode.contentEditable = false;
      lastTextBoxRange.surroundContents(nnode);
      nnode.innerHTML = `<span data-userid="${userId}" 
        style="color:#00E4AC;">${` ${userName}`}</span>`;

      const emptySpan = document.createElement('span');
      emptySpan.innerHTML = '&#8203;';
      nnode.after(emptySpan);

      const newRange = new Range();
      newRange.setStartAfter(nnode, 0);
      setLastTextBoxRange(newRange);
    },
    [lastTextBoxRange, setLastTextBoxRange]
  );

  useMutationObserver(commentContainerRef, () => {
    if (commentContainerRef.current?.innerText.length > 500) {
      commentContainerRef.current.innerHTML =
        commentContainerRef.current.innerText.slice(0, 500);
      placeCaretAtEnd(commentContainerRef);
    }

    removeUserFromAddressessWhenRemovedFromText(
      commentContainerRef.current?.innerHTML
    );

    setCommentValue(commentContainerRef.current?.innerHTML);
  });

  // Remove user tag from the message if the user is removed from the addressees list
  useEffect(() => {
    const mentionUsersElems =
      commentContainerRef.current?.querySelectorAll(`[data-userid]`);
    const mentionedUsers = Array.from(mentionUsersElems).map((elem) => ({
      value: elem.dataset.userid,
      label: elem.innerText.trim(),
    }));

    const removedUsers = mentionedUsers.filter((mUser) => {
      return !commentUsersTags.find((user) => +user.value === +mUser.value);
    });

    if (removedUsers.length) {
      const mentionedUserElement = commentContainerRef.current.querySelector(
        `[data-userid="${removedUsers[0].value}"]`
      );

      if (mentionedUserElement) {
        mentionedUserElement.parentNode?.remove();
      }
    }
  }, [commentContainerRef, commentUsersTags]);

  const addComment = async () => {
    if (!isSentButtonValidate) {
      return;
    }
    try {
      setIsFetching(true);
      setIsAdded(true);
      await FeedService.addCommentToFeedPost({
        id: feedPost?.id,
        content: commentValue,
        parentId: userReply?.commentId || null,
        mentionIds: commentUsersTags.map((user) => user.value),
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setCommentValue('');
      setUserReply(null);
      setIsAdded(false);
      setIsFetching(false);
      commentContainerRef.current.innerHTML = '';
    }
  };

  const moveArrowFn = () => {
    setMoveArrow((prev) => !prev);
    setTimeout(() => {
      setMoveArrow((prev) => !prev);
    }, 700);
  };

  return (
    <>
      <div className={classes.AddComment}>
        <div className={classes.container} ref={containerRef}>
          <div
            className={classes.addUserWrapper}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
          >
            <div
              className={classNames(classes.scaleTransition, {
                [classes.mouseOverScale]: mouseOver,
                [classes.mouseLeaveScale]: !mouseOver,
              })}
            >
              <div
                className={classNames(classes.addUsers, {
                  [classes.mouseOverUsers]: mouseOver,
                  [classes.mouseLeaveUsers]: !mouseOver,
                })}
                onClick={() => {
                  setIsUserPopupVisible(true);
                  commentContainerRef.current.focus();
                }}
              />
            </div>
          </div>

          <div className={classes.content}>
            {userReply && (
              <div className={classes.usersReply} contentEditable={false}>
                <UserTag user={userReply} setUser={setUserReply} />
              </div>
            )}
            <div
              className={classes.textBox}
              ref={commentContainerRef}
              onFocus={(event) => {
                event.preventDefault();
                const range = document.getSelection().getRangeAt(0);
                setLastTextBoxRange(range);
              }}
              onKeyDown={(event) => {
                if (event.key === '@') {
                  event.preventDefault();
                  const range = document.getSelection().getRangeAt(0);
                  setLastTextBoxRange(range);
                  setIsUserPopupVisible(true);
                }
                if (
                  commentContainerRef.current?.innerText.trim().length >= 500 &&
                  event.key !== 'Backspace' &&
                  event.key !== 'Delete'
                ) {
                  event.preventDefault();
                }
              }}
              contentEditable
              placeholder="12345"
            />
          </div>
          <div
            ref={emojiPickerIconRef}
            className={classes.emodji}
            onClick={(event) => {
              handleEmojiPickerClick(event);
              setIsEmojiPickerVisible(!isEmojiPickerVisible);
            }}
          />
          <span
            className={classNames(classes.charsLeft, {
              [classes.red]:
                commentContainerRef?.current?.innerText?.length === 500,
            })}
          >
            {500 - commentContainerRef.current?.innerText.trim().length}{' '}
            {t(
              'dashboardComponents.Discover.Feed.Post.Comments.Comment.charactersLeft'
            )}
          </span>
        </div>
        <div className={classes.sentWrapper}>
          <div className={classes.graybackgroundExpanded} />
          <div
            className={classNames(classes.greenbackgroundExpanded, {
              [classes.showGreenBg]: moveArrow,
            })}
          />
          <div
            className={classNames(classes.sent, {
              [classes.active]: isSentButtonValidate,
              [classes.moveArrow]: moveArrow,
              [classes.moveArrowLeft]: !moveArrow,
            })}
            onClick={() => {
              addComment();
              moveArrowFn();
            }}
          />
        </div>
      </div>
      {isEmojiPickerVisible && (
        <EmojiPickerContainer
          textRef={commentContainerRef?.current}
          setValue={setCommentValue}
          top={emojiPickerCoordY}
          left={emojiPickerCoordX}
          handleClose={() => setIsEmojiPickerVisible(false)}
          iconRef={emojiPickerIconRef}
        />
      )}
      <UsersPopup
        show={isUserPopupVisible}
        addressees={commentUsersTags}
        setAddressees={setCommentUsersTags}
        onHide={() => setIsUserPopupVisible(false)}
        top={containerRef?.current?.getBoundingClientRect().top - 281}
        left={containerRef?.current?.getBoundingClientRect().left}
        onUserAdd={mentionUser}
        disableAll
      />
    </>
  );
}
