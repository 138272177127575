/* eslint-disable react/jsx-handler-names */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import SidePopup from '../../../SidePopup';
import Textarea from '../../../../../Form/Textarea';
import TextInput from '../../../../../Form/TextInput';
import QuestionsHeader from './QuestionsHeader';
import AssessmentQuestions from './AssessmentQuestions';

import yupSchemaToFields from '../../../../../../helpers/yupSchemaToFields';
import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import AdminService from '../../../../../../services/AdminService';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import flattenPaginatedData from '../../../../../../helpers/flattenPaginatedData';
import { useUiStore } from '../../../../../../store';

const areQuestionsValid = (quetions) => {
  if (!quetions.length) {
    return false;
  }

  const isQuestionsValid = quetions.every((question) => {
    const isQuestionNameValid = question.name.length > 0;
    const isAnswersValid = question.allowsMultipleAnswers
      ? question.answers.length > 1 &&
        question.answers.every((answer) => {
          return answer.name.length > 0;
        })
      : true;

    return isQuestionNameValid && isAnswersValid;
  });

  return isQuestionsValid;
};

export default function AddOrEditAssessment({
  isVisible,
  handleClose,
  createDataHandler,
  refetch,
  setIsAddDataVisible,
  isAdd,
  isEdit,
  data,
  selectedRows,
  hasNoAnimation,
  updateDataHandler,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [isQuestionsBlockVisible, setIsQuestionsBlockVisible] = useState(false);
  const [questions, setQuestions] = useState([
    {
      id: Math.random(),
      name: '',
      answers: [],
      allowsMultipleAnswers: false,
      limit: 2,
    },
  ]);

  const { t } = useTranslation();

  const editAssessmentInitialScreen = useUiStore(
    (state) => state.editAssessmentInitialScreen
  );
  const setEditAssessmentInitialScreen = useUiStore(
    (state) => state.setEditAssessmentInitialScreen
  );

  useEffect(() => {
    if (isEdit && isVisible && editAssessmentInitialScreen === 'questions') {
      setIsQuestionsBlockVisible(true);
    } else {
      setIsQuestionsBlockVisible(false);
    }
  }, [editAssessmentInitialScreen, isEdit, isVisible]);

  useEffect(() => {
    if (!isVisible) {
      setEditAssessmentInitialScreen('');
    }
  }, [isVisible, setEditAssessmentInitialScreen]);

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(data, selectedRows);

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
    showDuplicateDataModal,
  } = useContext(UiContext);

  const { data: fetchedQuestionsData } = useInfiniteQuery({
    queryKey: ['questions', currentDataIndex, data],
    queryFn: ({ pageParam = 1 }) =>
      AdminService.getAssessmentQuestions({
        assessmentId: data?.[currentDataIndex]?.id,
        pageNumber: pageParam,
        pageSize: 50,
      }),
    enabled: isEdit && isVisible && !!data?.[currentDataIndex],
  });

  const fetchedQuestions = useMemo(
    () => flattenPaginatedData(fetchedQuestionsData),
    [fetchedQuestionsData]
  );

  const createAssessmentQuestionAnswer = async ({
    answer,
    sortOrder,
    assessmentId,
    questionId,
  }) => {
    const response = await AdminService.createAssessmentQuestionAnswer({
      answer,
      sortOrder,
      questionId,
      assessmentId,
    });

    return response;
  };

  const updateAssessmentQuestionAnswer = async ({
    answer,
    sortOrder,
    assessmentId,
    questionId,
    answerId,
  }) => {
    const response = await AdminService.updateAssessmentQuestionAnswer({
      answer,
      sortOrder,
      questionId,
      assessmentId,
      answerId,
    });

    return response;
  };

  const createAssessmentQuestionWithAnswers = async (
    question,
    sortOrder,
    assessmentId
  ) => {
    const response = await AdminService.createAssessmentQuestion({
      name: question.name,
      sortOrder,
      allowsMultipleAnswers: question.allowsMultipleAnswers,
      assessmentId,
      limit: question.limit,
    });

    const promises = question.answers.map((answer, index) =>
      createAssessmentQuestionAnswer({
        answer: answer.name,
        sortOrder: index,
        questionId: response.id,
        assessmentId,
      })
    );

    return Promise.all(promises);
  };

  const deleteAssessmentQuestionAnswer = async (
    answerId,
    questionId,
    assessmentId
  ) => {
    const resposne = await AdminService.deleteAssessmentQuestionAnswer({
      answerId,
      questionId,
      assessmentId,
    });

    return resposne;
  };

  const updateAssessmentQuestionWithAnswers = async (
    question,
    sortOrder,
    assessmentId
  ) => {
    if (question.id.toString().startsWith('0.')) {
      return createAssessmentQuestionWithAnswers(
        question,
        sortOrder,
        assessmentId
      );
    }

    const response = await AdminService.updateAssessmentQuestion({
      name: question.name,
      sortOrder,
      allowsMultipleAnswers: question.allowsMultipleAnswers,
      assessmentId,
      questionId: question.id,
      limit: question.limit,
    });

    const answersToDelete = response.assessmentQuestionAnswers.filter(
      (answer) =>
        !question?.answers?.find((a) => a.id === answer.id) &&
        !answer.id.toString().startsWith('0.')
    );

    const deleteAnswersPromises = answersToDelete.map((answer) =>
      deleteAssessmentQuestionAnswer(answer.id, question.id, assessmentId)
    );

    await Promise.all(deleteAnswersPromises);

    const promises = question.answers
      .filter((answer) => !answersToDelete.find((ad) => ad.id === answer.id))
      .map((answer, index) =>
        answer.id.toString().startsWith('0.')
          ? createAssessmentQuestionAnswer({
              answer: answer.name,
              sortOrder: index,
              questionId: response.id,
              assessmentId,
            })
          : updateAssessmentQuestionAnswer({
              answer: answer.name,
              sortOrder: index,
              questionId: response.id,
              assessmentId,
              answerId: answer.id,
            })
      );

    return Promise.all(promises);
  };

  const createAssessment = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const assessment = await createDataHandler(values);
      const promises = questions?.map?.((question, index) =>
        createAssessmentQuestionWithAnswers(question, index, assessment.id)
      );
      await Promise.all(promises);
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.success'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentAddedSuccessfully'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addMore'
        ),
        confirmButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.gotIt'
        ),
        onConfirm: () => {},
        onCancel: () => setIsAddDataVisible(true),
      });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message === 'Duplicity') {
        showDuplicateDataModal('Assessment');
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
      setIsTriedToSubmit(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const updateAssessment = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const assessment = await updateDataHandler({
        ...values,
        assessmentId: data?.[currentDataIndex].id,
      });
      const updateQuestionsPromises = questions.map((question, index) =>
        updateAssessmentQuestionWithAnswers(question, index, assessment.id)
      );
      await Promise.all(updateQuestionsPromises);

      const questionsToDelete = fetchedQuestions.filter(
        (question) => !questions.find((q) => q.id === question.id)
      );

      if (questionsToDelete.length) {
        const deleteQuestionsPromises = questionsToDelete.map((question) =>
          AdminService.deleteAssessmentQuestion({
            questionId: question.id,
            assessmentId: assessment.id,
          })
        );
        await Promise.all(deleteQuestionsPromises);
      }

      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.success'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.changesSavedSuccessfully'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.gotIt'
        ),
        dismissButtonVariant: '',
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message === 'Duplicity') {
        showDuplicateDataModal('Assessment');
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
      setIsTriedToSubmit(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.cannotBeEmpty'
            )
          ),
        note: yup.string().trim(),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: isAdd ? '' : data?.[currentDataIndex]?.name,
      note: isAdd ? '' : data?.[currentDataIndex]?.note,
    },
    validationSchema,
    onSubmit: isAdd ? createAssessment : updateAssessment,
    enableReinitialize: true,
  });

  const closeAddFormWithWarning = () => {
    if (Object.values(formik.values).some((val) => !!val)) {
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.exitForm'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.exitFormWarning'
        ),
        dismissButtonLabel: t('common.yes'),
        confirmButtonLabel: t('common.no'),
        onConfirm: () => {},
        onCancel: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const getQuestionsFromBackendResponse = useCallback(
    () =>
      fetchedQuestions
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
        ?.map((question) => ({
          limit: question.limit,
          name: question.name,
          id: question.id,
          allowsMultipleAnswers: question.allowsMultipleAnswers,
          answers: question.assessmentQuestionAnswers.map((answer) => ({
            id: answer.id,
            name: answer.answer,
          })),
        })),
    [fetchedQuestions]
  );

  const saveFetchedQuestionsToState = useCallback(() => {
    if (isEdit && fetchedQuestions) {
      setQuestions(getQuestionsFromBackendResponse());
    }
  }, [fetchedQuestions, getQuestionsFromBackendResponse, isEdit]);

  const resetForm = () => {
    formik.resetForm();
    setIsTriedToSubmit(false);
    setIsQuestionsBlockVisible(false);

    if (isEdit) {
      saveFetchedQuestionsToState();
    } else {
      setQuestions([
        {
          id: Math.random(),
          name: '',
          answers: [],
          allowsMultipleAnswers: false,
        },
      ]);
    }
  };

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    saveFetchedQuestionsToState();
  }, [isEdit, fetchedQuestions, saveFetchedQuestionsToState]);

  const isDirty =
    formik.dirty || isEdit
      ? !isEqual(questions, getQuestionsFromBackendResponse())
      : questions.length > 1;

  return (
    <SidePopup
      fields={[
        ...yupSchemaToFields(validationSchema),
        {
          field: 'questionsField',
          validation: areQuestionsValid(questions) ? [] : ['required'],
        },
      ]}
      formik={{ ...formik, resetForm, dirty: isDirty }}
      mode={isAdd ? 'add' : 'edit'}
      isVisible={isVisible}
      handleClose={isAdd ? closeAddFormWithWarning : handleClose}
      title={
        isAdd
          ? t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addAssessment'
            )
          : t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.editAssessment'
            )
      }
      leftButtonLabel={isAdd ? t('common.clear') : t('common.reset')}
      rightButtonLabel={t('common.save')}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      isRightButtonDisabled={
        !formik.values.name ||
        !areQuestionsValid(questions) ||
        isTriedToSubmit ||
        isCreatingOrUpdating
      }
      onLeftButtonClick={resetForm}
      switchNext={selectedRows.length > 1 && isEdit && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && isEdit && switchToPrevDataElement}
      hasNoAnimation={hasNoAnimation}
    >
      <div className={classes.AddOrEditAssessment}>
        {isQuestionsBlockVisible && (
          <QuestionsHeader
            title={formik.values.name}
            onEditIconClick={() => setIsQuestionsBlockVisible(false)}
            questionCount={questions?.length}
          />
        )}
        {isQuestionsBlockVisible ? (
          <AssessmentQuestions
            questions={questions}
            setQuestions={setQuestions}
            isEdit={isEdit}
          />
        ) : (
          <>
            <div className={classes.col}>
              <TextInput
                value={formik.values.name}
                height={50}
                label={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentName'
                )}
                placeholder={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentNamePlaceholder'
                )}
                onChange={formik.handleChange}
                name="name"
                touched={isTriedToSubmit}
                onBlur={formik.handleBlur}
                error={formik.errors.name}
              />
            </div>
            <div className={classes.col}>
              <Textarea
                value={formik.values.note}
                height={100}
                label={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.notes'
                )}
                placeholder={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.notesPlaceholder'
                )}
                onChange={formik.handleChange}
                name="note"
                touched={isTriedToSubmit}
                onBlur={formik.handleBlur}
                error={formik.errors.note}
              />
            </div>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                onClick={() => setIsQuestionsBlockVisible(true)}
              >
                {isAdd
                  ? t(
                      'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addQuestions'
                    )
                  : t(
                      'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.viewQuestions'
                    )}
              </button>
            </div>
          </>
        )}
      </div>
    </SidePopup>
  );
}
