import React, { useContext, useState, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';
import InnerHeader from '../../components/Auth/InnerHeader';
import TextInput from '../../components/Form/TextInput';
import AuthHeading from '../../components/Auth/AuthHeading';

import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import { AuthContext } from '../../context/AuthContext';
import { UiContext } from '../../context/UiContext';
import emailIcon from '../../assets/images/auth/email.svg';

export default function LoginPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const navigate = useNavigate();

  const { setEmail } = useContext(AuthContext);

  const { showUnknownErrorModal, showModal } = useContext(UiContext);

  const { t } = useTranslation(); // Initialize the t function

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .trim()
          .email(t('pages.LoginPage.emailValidation')) // Use translation keys here
          .required(t('pages.LoginPage.emailRequired')), // Use translation keys here
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const checkaccount = await UserService.checkAccount({
          userName: values.email,
        });

        setEmail(values.email);

        if (checkaccount?.granted) {
          navigate('/login/method');
        } else {
          showModal({
            title: t('pages.LoginPage.noAccessTitle'),
            text: t('pages.LoginPage.noAccessText'),
            onConfirm: () => navigate('/request-access'),
          });
        }
      } catch (error) {
        showUnknownErrorModal();
      }
    },
  });

  return (
    <div className={classes.LoginPage}>
      <Helmet>
        <title>{t('pages.LoginPage.pageTitle')}</title>{' '}
        {/* Translate the title */}
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          title={t('common.signIn')}
          label={t('pages.RequestAccessPage.header')}
          onButtonClick={() => navigate('/request-access')}
        />
        <div className={classes.group}>
          <AuthHeading />
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.inputContainer}>
              <TextInput
                icon={emailIcon}
                error={formik.errors.email}
                touched={isTriedToSubmit}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t('pages.LoginPage.emailLabel')}
                name="email"
                placeholder={t('pages.LoginPage.emailPlaceholder')}
                height={55}
              />
            </div>
          </form>
        </div>
        <ButtonWithHelperText
          onClick={() => {
            setIsTriedToSubmit(true);
            formik.handleSubmit();
          }}
          isDisabled={!formik.values.email}
          helperLink="/request-access"
          helperText={t('pages.LoginPage.newUserMessage')}
          helperLinkText={t('pages.LoginPage.requestAccessLink')}
          buttonLabel={t('pages.LoginPage.continueButton')}
        />
      </div>
    </div>
  );
}
