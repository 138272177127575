/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import FormButtonsManager from '../../../FormButtonsManager';
import InterviewDetails from './InterviewDetails';
// import Button from '../../../../Button';
import RecruiterInterview from './RecruiterInterview';
import ContactInfo from '../ContactInfo';
import Details from '../Details';
import Header from '../Header';

import yupSchemaToFields from '../../../../../helpers/yupSchemaToFields';
import AdminService from '../../../../../services/AdminService';
import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function Interview({
  isEventDetailsVisible,
  handleClose,
  event,
  refetch,
  currentUser,
  setCurrentCandidate,
  setIsCandidateModalVisible,
}) {
  const [isOpened, setIsOpened] = useState(false);

  const { showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (isEventDetailsVisible) {
      setTimeout(() => {
        setIsOpened(true);
      }, 100);
    }
  }, [isEventDetailsVisible]);

  const closePopup = () => {
    setIsOpened(false);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  const declineEvent = async () => {
    setIsFetching(true);

    if (event.userId === currentUser.id) {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          await AdminService.changeJobApplicationInterview({
            interviewId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            interviewDate: event?.event?.interviewDate,
            type: event?.event?.type,
            level: event?.event?.level,
            customLocation: event?.event?.customLocation,
            connectionDetail: event?.event?.connectionDetail,
            userIds: event?.event?.users.map((user) => user.id),
            note: event?.event?.note,
            startTime: event?.event?.startTime,
            endTime: event?.event?.endTime,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };

      try {
        await AdminService.changeJobApplicationInterview({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          interviewDate: event?.event?.interviewDate,
          type: event?.event?.type,
          level: event?.event?.level,
          customLocation: event?.event?.customLocation,
          connectionDetail: event?.event?.connectionDetail,
          userIds: event?.event?.users.map((user) => user.id),
          note: event?.event?.note,
          startTime: event?.event?.startTime,
          endTime: event?.event?.endTime,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Interview.Interview declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      const currentInvite = event?.event?.jobApplicationInterviewInvites?.find(
        (invite) => invite?.userId === currentUser?.id
      );
      const prevStatus = currentInvite?.status;

      const undo = async () => {
        try {
          await AdminService.changeJobApplicationInterviewInvite({
            interviewId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite?.id,
            note: currentInvite?.note,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };

      try {
        await AdminService.changeJobApplicationInterviewInvite({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite?.id,
          note: currentInvite?.note,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Interview.Interview invitation declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  const acceptEvent = async () => {
    setIsFetching(true);
    if (event.userId === currentUser.id) {
      try {
        await AdminService.changeJobApplicationInterview({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          interviewDate: event?.event?.interviewDate,
          type: event?.event?.type,
          level: event?.event?.level,
          customLocation: event?.event?.customLocation,
          connectionDetail: event?.event?.connectionDetail,
          userIds: event?.event?.users.map((user) => user.id),
          note: event?.event?.note,
          startTime: event?.event?.startTime,
          endTime: event?.event?.endTime,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Interview.Interview accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      const currentInvite = event?.event?.jobApplicationInterviewInvites?.find(
        (invite) => invite?.userId === currentUser?.id
      );
      try {
        await AdminService.changeJobApplicationInterviewInvite({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite?.id,
          note: currentInvite?.note,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Interview.Interview invitation accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  if (
    currentUser?.id === event?.event?.organizer.id &&
    event?.type === 'Interview'
  ) {
    return (
      <>
        <RecruiterInterview
          isScheduleInterviewVisible
          setIsScheduleInterviewVisible={closePopup}
          candidate={event?.candidate}
          interview={event}
          currentUser={currentUser}
          jobApplications={event?.jobApplications}
          refetch={refetch}
          setIsCandidateModalVisible={setIsCandidateModalVisible}
          setCurrentCandidate={setCurrentCandidate}
        />
      </>
    );
  }

  const status = event?.event?.status;

  const onLeftButtonClick = declineEvent;
  const onRightButtonClick = acceptEvent;
  const leftButtonLabel = t('common.decline');
  const rightButtonLabel = t('common.accept');

  return (
    <div
      className={classNames(classes.blur, {
        [classes.blurActivated]: isEventDetailsVisible,
      })}
      onClick={closePopup}
    >
      <div
        className={classNames(classes.container, {
          [classes.visible]: isOpened,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.event}>
          {/* <div className={classes.header}>
            <div className={classes.row}>
              <div>
                <span className={classes.headline}>Interview Details</span>
                {(status === 'Accepted' ||
                  status === 'Declined' ||
                  status === 'ChangeRequest') && (
                  <div className={classes.status}>
                    <div
                      className={classNames(classes.statusText, {
                        [classes.statusDeclined]: status === 'Declined',
                        [classes.changeRequest]: status === 'ChangeRequest',
                      })}
                    >
                      {status.replace(/([A-Z])/g, ' $1').trim()}
                    </div>
                  </div>
                )}
              </div>
              <FavStatus
                calendarEvent={event}
                big
                userId={currentUser?.id}
                refetch={refetch}
              />
            </div>
            <div className={classes.row}>
              {status === 'ChangeRequest' && (
                <span className={classes.statusDate}>
                  {`${t(
                    'dashboardComponents.Calendar.EventDetails.Interview.Requested'
                  )}:
                                  ${suggestedInterviewDate} ${getTime(
                    suggestedStartTime
                  )}`}
                </span>
              )}
            </div>
          </div> */}

          <Header
            title={t(
              'dashboardComponents.Calendar.EventDetails.Interview.Interview Details'
            )}
            status={status}
            event={event}
            refetch={refetch}
            currentUser={currentUser}
          />

          {/*   <div className={classes.recruiter}>
            <div>
              <span className={classes.title}>
                {t(
                  'dashboardComponents.Calendar.EventDetails.Interview.Recruiter'
                )}
              </span>
              <div
                className={classes.description}
              >{`${event?.event?.organizer?.userProfile?.firstName} ${event?.event?.organizer?.userProfile?.lastName}`}</div>
            </div>
            <div>
              <span className={classes.title}>{t('common.sent')}</span>
              <div className={classes.description}>
                {getCreatedDate(event?.event?.createdAt)}
              </div>
            </div>
          </div>

          <div className={classes.eventInfo}>
            <div className={classes.col}>
              <span className={classes.title}>{t('common.candidate')}</span>
              <span
                className={classes.name}
              >{`${event?.userFirstName} ${event?.userLastName}`}</span>
              <div
                className={classes.view}
                onClick={() => {
                  setIsCandidateModalVisible(true);
                  setCurrentCandidate(event?.candidate?.user);
                }}
              >
                {t('common.viewProfile')}
              </div>
            </div>
            <div className={classes.col}>
              <span className={classes.title}>Position</span>
              <span className={classes.name}>{event?.jobPost?.jobTitle}</span>
              <div>
                {t('common.jobId')}: <span>{event?.jobPost?.id}</span>
              </div>
            </div>
          </div> */}

          <Details
            event={event}
            setIsCandidateModalVisible={setIsCandidateModalVisible}
            setCurrentCandidate={setCurrentCandidate}
          />

          <div className={classes.details}>
            <InterviewDetails event={event} />
          </div>

          <ContactInfo event={event} />
        </div>
        <div className={classes.buttons}>
          <FormButtonsManager
            fields={yupSchemaToFields({})}
            formik={{}}
            mode="view"
            onLeftButtonClick={onLeftButtonClick}
            onRightButtonClick={onRightButtonClick}
            leftButtonLabel={leftButtonLabel}
            rightButtonLabel={rightButtonLabel}
          />
        </div>
        <div className={classes.closeIconContainer}>
          <i className={classes.closeIcon} onClick={closePopup}>
            Close
          </i>
        </div>
      </div>
      <div className={classes.bottom} />
    </div>
  );
}
