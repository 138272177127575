import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tabs({ tabs, activeTab, setActiveTab, cleanUp }) {
  const [borderWidth, setBorderWidth] = useState(0);
  const [borderLeft, setBorderLeft] = useState(0);

  const tabsRef = useRef();
  const activeTabRef = useRef();

  useEffect(() => {
    const tabsRect = tabsRef.current.getBoundingClientRect();
    const activeTabRect = activeTabRef.current.getBoundingClientRect();

    const activeTabLeft = activeTabRect?.left - tabsRect?.left;
    const activeTabWidth = activeTabRect?.width;

    setBorderLeft(activeTabLeft);
    setBorderWidth(activeTabWidth);
  }, [activeTab]);

  return (
    <div className={classes.Tabs} ref={tabsRef}>
      {tabs.map((tab) => {
        return (
          <div
            ref={activeTab === tab ? activeTabRef : null}
            tabIndex={0}
            className={classNames(classes.tab, {
              [classes.active]: tab === activeTab,
            })}
            role="tab"
            key={tab}
            onClick={() => {
              cleanUp();
              setActiveTab(tab);
            }}
          >
            {tab}
          </div>
        );
      })}
      <div
        className={classes.activeTabBorder}
        style={{ width: borderWidth, left: borderLeft }}
      />
    </div>
  );
}
