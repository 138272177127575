import { IMAGES_URL } from '../constants/main';

export default function getResizedImageUrl({ quality = 70, width = 800, url }) {
  let imageFormat = url.split('.').pop();

  if (imageFormat === 'jpeg') {
    imageFormat = 'jpg';
  }

  if (imageFormat !== 'jpg' && imageFormat !== 'png') {
    imageFormat = 'jpg';
  }

  return `${IMAGES_URL}/${url}?width=${width}&format=${imageFormat}&quality=${quality}`;
}
