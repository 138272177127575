import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import classes from './styles.module.scss';
import { getTimezoneTime, getTimezoneDate } from '../../../helpers/timezones';
import UserService from '../../../services/UserService';

function convertToAmPm(time) {
  console.log(time);
  // Split the time by ":" to get hours and minutes
  let [hours, minutes] = time.split(':');

  // Convert the hours part to an integer
  hours = parseInt(hours, 10);

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  hours %= 12;
  // In 12-hour format, 0 is represented as 12
  hours = hours || 12;

  // If hours or minutes are single digit, pad with zero
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return the formatted time string
  return `${hours}:${minutes.slice(0, 2)} ${ampm}`;
}

export default function ChangeRequestHeader({
  title,
  suggestedDate,
  suggestedStartTime,
}) {
  const { t } = useTranslation();

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  return (
    <div className={classes.ChangeRequestHeader}>
      <h1 className={classes.title}>
        <div className={classes.row}>
          <h1 className={classes.title}>{title}</h1>
          <div className={classes.changeRequest}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Joining.ChangeRequest'
            )}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.date}>
            Requested:{' '}
            {moment(
              getTimezoneDate(
                suggestedDate,
                me?.userProfile?.timezone,
                suggestedStartTime
              )?.[0]
            ).format('DD MMM YYYY')}{' '}
            {convertToAmPm(
              getTimezoneTime(suggestedStartTime, me?.userProfile?.timezone)
            )}
          </div>
        </div>
      </h1>
    </div>
  );
}
