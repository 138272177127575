import { useMemo, useCallback } from 'react';

import * as signalR from '@microsoft/signalr';

import { getAuthData } from '../helpers/authStorage';

export default function useHubConnection(url) {
  const { accessToken } = getAuthData();
  const connection = useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => {
            return accessToken;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build(),
    [accessToken, url]
  );

  const startConnection = useCallback(async () => {
    try {
      await connection.start();
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  const endConnection = useCallback(async () => {
    try {
      await connection.stop();
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  return useMemo(
    () => ({
      connection,
      startConnection,
      endConnection,
    }),
    [connection, startConnection, endConnection]
  );
}
