import React, { useState, useEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import useUpdateData from '../../../../../hooks/useUpdateData';
import useCreateData from '../../../../../hooks/useCreateData';
import { UiContext } from '../../../../../context/UiContext';
import AddOrEditData from '../../AddOrEditData';
import ViewData from '../../ViewData';
import useDebouncedSearchWithHistory from '../../../../../hooks/useDebouncedSearchWithHistory';
import AdminService from '../../../../../services/AdminService';
import Table from '../../../../Table';
import TableMenu from '../../TableMenu';
import tableColsToSortOptions from '../../../../../helpers/tableColsToSortOptions';
import useDeleteData from '../../../../../hooks/useDeleteData';

export default function Copies({ setItemCount }) {
  const [activeAction, setActiveAction] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSortOption, setActiveSortOption] = useState({
    label: 'Created On',
    value: 'createdAt',
  });
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isViewDataVisible, setIsViewDataVisible] = useState(false);
  const [currentDataId, setCurrentDataId] = useState(null);
  const [isAddDataVisible, setIsAddDataVisible] = useState(false);
  const [isEditDataVisible, setIsEditDataVisible] = useState(false);

  const { t } = useTranslation();

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'copiesSearchHistory'
  );

  const { showSelectionNeededModal } = useContext(UiContext);

  const {
    data: rows,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['skills', activeSortOption, debouncedSearchTerm, sortOrder],
    queryFn: () =>
      AdminService.getSkills({
        orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        sortOrder,
      }),
    keepPreviousData: true,
  });

  const showViewData = (id) => {
    setCurrentDataId(id);
    setIsViewDataVisible(true);
  };

  useEffect(() => {
    if (rows) {
      setItemCount(rows.length);
    }
  }, [rows, setItemCount]);

  const showEditData = () => {
    setIsEditDataVisible(true);
  };

  const showAddData = () => {
    setIsAddDataVisible(true);
  };

  const showEditDataSingle = (id) => {
    setSelectedRows([id]);
    setIsEditDataVisible(true);
  };

  const { createData } = useCreateData(
    AdminService.createSkill,
    setIsAddDataVisible,
    refetch,
    'Skill'
  );

  const { updateData } = useUpdateData({
    updateHandler: AdminService.updateSkill,
    refetch,
  });

  const { deleteData } = useDeleteData(
    selectedRows,
    () => setIsViewDataVisible(false),
    refetch,
    setSelectedRows,
    AdminService.deleteSkill,
    'Skill'
  );

  const actions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: showAddData,
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          showEditData();
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteData();
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const isAddDataMode = isAddDataVisible;

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.Copies.PageReference'
      ),
      field: t('dashboardComponents.Console.ContentManagement.Copies.text'),
      sortable: true,
    },
    { name: 'Text', field: 'sortOrder', sortable: false },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.Copies.Instructions'
      ),
      field: 'note',
      sortable: false,
    },
    {
      name: t('dashboardComponents.Console.ContentManagement.Copies.UpdatedBy'),
      field: 'abyrwalg',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.ContentManagement.Copies.UpdatedOn'),
      field: 'some',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  return (
    <div>
      <TableMenu
        actions={actions}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
        dangerAction={t('common.delete')}
        sortOptions={sortOptions}
        activeSortOption={activeSortOption}
        setActiveSortOption={setActiveSortOption}
        selectedCount={selectedRows.length}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
        setSortOrder={setSortOrder}
        searchResultCount={debouncedSearchTerm && !isFetching ? 0 : null}
      />
      <Table
        name={t('dashboardComponents.Console.ContentManagement.Copies.copies')}
        rows={rows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortOptions={sortOptions}
        setActiveSortOption={setActiveSortOption}
        activeSortOption={activeSortOption}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        columns={columns}
        onRowClick={showViewData}
        cellHeight={148}
      />

      <ViewData
        isOverlayed={isEditDataVisible}
        isVisible={isViewDataVisible}
        handleClose={() => setIsViewDataVisible(false)}
        currentDataId={currentDataId}
        data={rows}
        title={t(
          'dashboardComponents.Console.ContentManagement.Copies.ViewSkill'
        )}
        leftButtonLabel={t('common.delete')}
        rightButtonLabel={t('common.edit')}
        onRightButtonClick={showEditDataSingle}
        onLeftButtonClick={deleteData}
        leftColumnFields={[
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.SkillName'
            ),
            field: 'name',
          },
        ]}
        rightColumnFields={[
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.SortOrder'
            ),
            field: 'sortOrder',
          },
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.Notes'
            ),
            field: 'note',
            type: 'textarea',
          },
        ]}
      />

      <AddOrEditData
        hasNoAnimation={isViewDataVisible}
        data={rows}
        selectedRows={selectedRows}
        isEdit={isEditDataVisible}
        isAdd={isAddDataMode}
        isVisible={isAddDataVisible || isEditDataVisible}
        handleClose={() => {
          setIsEditDataVisible(false);
          setIsAddDataVisible(false);
        }}
        title={
          isAddDataMode
            ? t('dashboardComponents.Console.ContentManagement.Copies.AddSkill')
            : t(
                'dashboardComponents.Console.ContentManagement.Copies.EditSkill'
              )
        }
        leftButtonLabel={isAddDataMode ? t('common.clear') : t('common.reset')}
        rightButtonLabel={isAddDataMode ? t('common.add') : t('common.save')}
        leftColumnFields={[
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.SkillName'
            ),
            field: 'name',
            validation: ['string', 'required'],
            placeholder: t(
              'dashboardComponents.Console.ContentManagement.Copies.AddSkillNamePlaceholder'
            ),
          },
        ]}
        rightColumnFields={[
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.SortOrder'
            ),
            field: 'sortOrder',
            validation: ['string', 'required', 'numbers'],
            placeholder: t(
              'dashboardComponents.Console.ContentManagement.Copies.AddSortOrderPlaceholder'
            ),
            maxLength: 5,
          },
          {
            label: t(
              'dashboardComponents.Console.ContentManagement.Copies.Notes'
            ),
            field: 'note',
            type: 'textarea',
            validation: ['string'],
            placeholder: t(
              'dashboardComponents.Console.ContentManagement.Copies.AddNotesPlaceholder'
            ),
          },
        ]}
        submitHandler={isAddDataMode ? createData : updateData}
      />
    </div>
  );
}
