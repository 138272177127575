import React from 'react';

import classNames from 'classnames';

// import AssessmentInfo from './AssessmentInfo';

import classes from './styles.module.scss';

export default function Test({ test, isSelected, toggleTestSelection }) {
  const input =
    test.type === 'radio' ? (
      <div className={classNames(classes.radio, classes.circle)} />
    ) : (
      <div className={classes.checkbox} />
    );

  return (
    <div
      className={classNames(classes.Test, {
        [classes.active]: isSelected,
      })}
      onClick={(event) => {
        event.stopPropagation();
        toggleTestSelection(test);
      }}
    >
      <div className={classes.container}>
        {input}
        <span>{test?.name}</span>
        {/* <AssessmentInfo test={test} /> */}
      </div>
    </div>
  );
}
