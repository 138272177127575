import React from 'react';

import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import useOfferStatus from '../hooks/useOfferStatus';

import classes from './styles.module.scss';

export default function CandidateOfferInfo({
  userLocation,
  candidate,
  counterOfferMode,
}) {
  const { t } = useTranslation();

  const { offer, isAccepted } = useOfferStatus({
    candidate,
    counterOfferMode,
  });

  if (!offer) {
    return (
      <div className={classes.CandidateOfferInfo}>
        <span className={classes.location}>{userLocation}</span>
      </div>
    );
  }

  const lastOffersHistoryObject = offer?.statusHistory?.at(-1);
  const lastOfferCreatedAt = moment(
    lastOffersHistoryObject?.createdAt
  )?.format?.('Do MMMM YYYY');

  let label = '';
  let content = '';

  if (!isAccepted) {
    label = t('modals.OfferModal.sentOn');
    content = lastOfferCreatedAt;
  } else if (offer.status === 'Accepted') {
    label = t('modals.OfferModal.acceptedOn');
    content = lastOfferCreatedAt;
  } else if (offer.status === 'Declined') {
    label = t('modals.OfferModal.declinedOn');
    content = lastOfferCreatedAt;
  }

  return (
    <div className={classes.CandidateOfferInfo}>
      <span>
        {label} {content}
      </span>
    </div>
  );
}
