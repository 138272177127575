import React, { useRef, useState, useContext } from 'react';

// import JobPostPreviewModal from '../../../../../modals/JobPostPreviewModal';
import FavStatus from '../../../Header/Search/FavStatus';

import classes from './styles.module.scss';
import getResizedImageUrl from '../../../../../helpers/getResizedImageUrl';
import { JobsContext } from '../../../../../context/JobsContext';

export default function Card({
  card,
  setCurrentCandidate,
  setIsCandidateModalVisible,
  refetch,
  user,
}) {
  /*  const [isJobPostPreviewModalVisible, setIsJobPostPreviewModalVisible] =
    useState(false); */
  const [showRecruiterInfo, setShowRecruiterInfo] = useState(false);
  const [recruiterInfoPosition, setRecruiterInfoPosition] = useState({
    x: 0,
    y: 0,
  });

  const { showPostJobSuccessPopup } = useContext(JobsContext);

  const recruiterRef = useRef(null);
  const handleMouseEnter = () => {
    const parentPosition = recruiterRef?.current?.getBoundingClientRect();

    setShowRecruiterInfo(true);

    setRecruiterInfoPosition({ x: parentPosition?.x, y: parentPosition?.y });
  };

  const handleMouseLeave = () => {
    setShowRecruiterInfo(false);
  };

  const isJob = !!card.jobTitle;

  const hasJobPhoto = !!card.coverFileName;

  const title = isJob
    ? card?.jobTitle
    : `${card?.userProfile?.firstName} ${card?.userProfile?.lastName}`;
  const subtitle = isJob ? card?.locationCity?.name : '';

  const hasRecruiterPhoto = isJob && card?.user?.userProfile?.avatar;

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.mouseLeaveEnter}
    >
      <div className={classes.cardWrapper}>
        <div
          className={classes.Card}
          style={{
            backgroundImage:
              hasJobPhoto &&
              `linear-gradient(180deg, rgba(36, 40, 51, 0) 0%, #242833 100%), url(${`${getResizedImageUrl(
                {
                  url: `jobpostcover/${card.coverFileName}`,
                  width: 300,
                }
              )}`})`,
          }}
          onClick={() => {
            if (isJob) {
              showPostJobSuccessPopup(card);
              return;
            }

            setCurrentCandidate(card);
            setIsCandidateModalVisible(true);
          }}
        >
          <div className={classes.header}>
            <span className={classes.label}>{isJob ? 'Job' : 'Candidate'}</span>
            {isJob ? (
              <FavStatus job={card} refetch={refetch} userId={user?.id} />
            ) : (
              <FavStatus candidate={card} refetch={refetch} />
            )}
          </div>
          <div className={classes.footer}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subtitle}>{subtitle}</span>
          </div>
          {isJob && card?.user && (
            <div
              className={classes.personContainer}
              onClick={(event) => {
                event.stopPropagation();
                setCurrentCandidate(card.user);
                setIsCandidateModalVisible(true);
              }}
              ref={recruiterRef}
            >
              {hasRecruiterPhoto ? (
                <img
                  src={getResizedImageUrl({
                    url: `avatar/${card.user.userProfile.avatar}`,
                    width: 300,
                  })}
                  alt="person"
                />
              ) : (
                <div className={classes.noPhoto}>
                  {`${card?.user?.userProfile?.firstName[0] || ''}${
                    card?.user?.userProfile?.lastName[0] || ''
                  }`}
                </div>
              )}
              {showRecruiterInfo && (
                <div
                  className={classes.personInfo}
                  style={{
                    left: recruiterInfoPosition.x - 7,
                    top: recruiterInfoPosition.y - 8,
                  }}
                >
                  {hasRecruiterPhoto ? (
                    <div className={classes.avatarContainer}>
                      <img
                        src={getResizedImageUrl({
                          url: `avatar/${card.user.userProfile.avatar}`,
                          width: 300,
                        })}
                        alt="person"
                      />
                    </div>
                  ) : (
                    <div className={classes.noPhoto}>
                      {`${card?.user?.userProfile?.firstName[0] || ''}${
                        card?.user?.userProfile?.lastName[0] || ''
                      }`}
                    </div>
                  )}
                  <div className={classes.text}>
                    <span
                      className={classes.name}
                    >{`${card?.user?.userProfile?.firstName} ${card?.user?.userProfile?.lastName}`}</span>
                    <span className={classes.email}>
                      {card?.user?.userName}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
