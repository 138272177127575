import React, { useState, useEffect, useContext } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import SocialButtons from '../../components/Dashboard/Jobs/SocialButtons';
import CopyJobLinkButton from '../../components/Dashboard/Jobs/CopyJobLinkButton';
import Textarea from '../../components/Form/Textarea';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import MultiDropdown from '../../components/Form/MultiDropdown';
import Button from '../../components/Button';
import JobPostPreviewModal from '../JobPostPreviewModal';
import FormButton from '../../components/FormButton';

import { UiContext } from '../../context/UiContext';
import AdminService from '../../services/AdminService';
import classes from './styles.module.scss';

export default function ShareJobModal({ show, handleClose, job }) {
  const [users, setUsers] = useState('');
  const [isJobPostPreviewModalVisible, setIsJobPostPreviewModalVisible] =
    useState(false);
  const [isPending, setIsPending] = useState(false);

  const { showNotification, showUnknownErrorModal } = useContext(UiContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const jobPostId = window.location.pathname.split('/').pop();

  useEffect(() => {
    return () => {
      setUsers('');
    };
  }, [show]);

  const shareJob = async () => {
    try {
      setIsPending(true);
      await AdminService.shareJobPost({
        jobPostId,
        userIds: users.map((user) => user.value),
      });
      showNotification({
        text: t('modals.ShareJobModal.jobSuccessfullyShared'),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsPending(false);
      handleClose();
    }
  };

  const headerButtons = (
    <div className={classes.buttonsContainer}>
      <Button
        width={109}
        height={45}
        variant="gradient"
        style={{
          fontFamily: 'Gilroy-Medium',
          fontSize: 14,
        }}
        onClick={() => setIsJobPostPreviewModalVisible(true)}
      >
        {t('modals.PostJobSuccessModal.previewButton')}
      </Button>
      <CopyJobLinkButton
        jobPostId={jobPostId}
        style={{
          backgroundColor: '#F2F4F8',
        }}
      />
      {!isTabletOrMobile && (
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
      )}
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ShareJobModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>{t('modals.ShareJobModal.share')}</h1>
          {!isTabletOrMobile && headerButtons}
          {isTabletOrMobile && (
            <i className={classes.closeIcon} onClick={handleClose}>
              Close
            </i>
          )}
        </header>
        {isTabletOrMobile && headerButtons}
        <div className={classes.container}>
          <div className={classes.col}>
            <div className={classes.share}>
              <div className={classes.selectContainer}>
                <MultiDropdown
                  note={t('modals.ShareJobModal.selectUpTo12')}
                  width={isTabletOrMobile ? '100%' : 352}
                  label={t('modals.ShareJobModal.shareWithTeamViaEmail')}
                  fetchOptions={{
                    dataName: 'userOptionPostJobSuccess',
                    getDataHandler: AdminService.getUsers,
                    labelName: 'userName',
                    isUserList: true,
                  }}
                  value={users}
                  setFieldValue={(_name, option) => setUsers(option)}
                  placeholder={t('modals.ShareJobModal.selectName')}
                  maxLength={12}
                />
              </div>
              <div className={classes.selectedUsers}>
                <SelectedUsers
                  style={{ width: isTabletOrMobile ? '100%' : 352 }}
                  invitedUsers={users}
                  setInvitedUsers={setUsers}
                />
              </div>
            </div>
          </div>
          <div className={classes.col}>
            <Textarea
              label={t('modals.ShareJobModal.emailMessage')}
              note={t('common.optional')}
              width={isTabletOrMobile ? '' : 352}
              height={158}
              placeholder={t('modals.ShareJobModal.enterNotes')}
            />
          </div>
        </div>
        <footer>
          <SocialButtons
            noPadding
            jobPostId={jobPostId}
            jobTitle={job?.jobTitle}
          />
          <FormButton
            variant="delete"
            onClick={shareJob}
            style={{
              width: 352,
              height: 45,
            }}
            disabled={!users || isPending}
          >
            {t('modals.ShareJobModal.shareButton')}
          </FormButton>
        </footer>
      </div>
      <JobPostPreviewModal
        show={isJobPostPreviewModalVisible}
        handleClose={() => setIsJobPostPreviewModalVisible(false)}
        jobPostId={jobPostId}
      />
    </Modal>
  );
}
