import React, { useContext, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CalendarForm from '../../../../../Calendar/CalendarForm';
import AdminService from '../../../../../../../services/AdminService';
import classes from './styles.module.scss';
import { UiContext } from '../../../../../../../context/UiContext';
import { getTimezoneDate } from '../../../../../../../helpers/timezones';
import UserService from '../../../../../../../services/UserService';

function getDate(date) {
  if (Number.isNaN(date?.getDate())) {
    return '';
  }
  return `${date?.getDate()} ${date?.toLocaleString('en-us', {
    month: 'long',
  })} ${date?.getFullYear()}`;
}

export default function JobDuration({ job, refetch }) {
  const [expiredDateCalendarOpened, setExpiredDateCalendarOpened] =
    useState(false);
  const [postedDate, setPostedDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [duration, setDuration] = useState(30);
  const [prevDuration, setPrevDuration] = useState(30);

  const buttonValidation = duration === prevDuration;

  const calendarIconRef = useRef();

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    if (!job) {
      return;
    }

    setPostedDate(
      getTimezoneDate(job?.createdAt, me?.userProfile?.timezone)[0]
    );
  }, [job, me?.userProfile?.timezone]);

  useEffect(() => {
    if (job?.expiresAt) {
      const expiresAt = getTimezoneDate(
        job?.expiresAt,
        me?.userProfile?.timezone
      )[0];
      setExpiredDate(new Date(expiresAt));
      setDuration(
        Math.ceil(
          Math.abs(new Date(postedDate) - new Date(expiresAt)) /
            (1000 * 60 * 60 * 24)
        )
      );
      setPrevDuration(
        Math.ceil(
          Math.abs(new Date(postedDate) - new Date(expiresAt)) /
            (1000 * 60 * 60 * 24)
        )
      );

      return;
    }

    setExpiredDate(
      new Date(new Date(postedDate).setDate(postedDate?.getDate() + 30))
    );
  }, [postedDate, job.expiresAt, me?.userProfile?.timezone]);

  useEffect(() => {
    if (postedDate > expiredDate) {
      setExpiredDate(
        new Date(new Date(postedDate).setDate(postedDate?.getDate() + 1))
      );

      setDuration(1);

      return;
    }
    setDuration(
      Math.ceil(
        Math.abs(new Date(postedDate) - new Date(expiredDate)) /
          (1000 * 60 * 60 * 24)
      )
    );
  }, [expiredDate, postedDate]);

  const decreaseDuration = () => {
    if (duration - 1 === 0) {
      return;
    }
    setDuration((prev) => prev - 1);
    setExpiredDate(new Date(expiredDate?.setDate(expiredDate?.getDate() - 1)));
  };

  const increaseDuration = () => {
    setDuration((prev) => prev + 1);
    setExpiredDate(new Date(expiredDate?.setDate(expiredDate?.getDate() + 1)));
  };

  const extend = async () => {
    try {
      setIsFetching(true);
      await AdminService.updateJobPostExpiration({
        id: job?.id,
        expiresAt: expiredDate,
      });

      if (refetch) {
        await refetch();
      }

      showNotification({
        text: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobDuration.jobExtendedSuccessfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.date}>
      <div className={classes.container}>
        <div className={classes.field}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobDuration.recruitmentPeriod'
            )}
          </span>
          <div className={classes.flexContainer}>
            <div className={classes.from}>
              {getDate(postedDate) || ''}
              <div className={classes.calendarIcon} />
            </div>
            <div className={classes.to}>
              {getDate(expiredDate) || ''}
              <div
                className={classes.calendarIcon}
                onClick={() =>
                  setExpiredDateCalendarOpened(!expiredDateCalendarOpened)
                }
                ref={calendarIconRef}
              />
              {expiredDateCalendarOpened && (
                <CalendarForm
                  currentDate={expiredDate}
                  setCurrentDate={setExpiredDate}
                  dateToday={new Date()}
                  isPopup
                  onClick={() => setExpiredDateCalendarOpened(false)}
                  calendarIconRef={calendarIconRef}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.field}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobDuration.duration'
            )}
          </span>
          <div className={classes.duration}>
            <div className={classes.minus} onClick={decreaseDuration}>
              -
            </div>
            <span className={classes.value}>{duration || ''}</span>
            <div className={classes.plus} onClick={increaseDuration}>
              +
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(classes.button, {
          [classes.disabled]: buttonValidation,
        })}
        onClick={extend}
      >
        {t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobDuration.extend'
        )}
      </div>
    </div>
  );
}
