/* eslint-disable no-unused-vars */
import React from 'react';

import classNames from 'classnames';

import InactiveOverlay from '../InactiveOverlay';

import getResizedImageUrl from '../../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function HeaderPreview({
  backgroundColor,
  logo,
  titleFontFamily,
  brandColor,
  isActive,
}) {
  const imageUrl =
    logo instanceof File
      ? URL.createObjectURL(logo)
      : getResizedImageUrl({
          url: `brand/${logo?.split('/')?.pop()}`,
          width: 78,
        });

  return (
    <div
      className={classNames(classes.headerPreview, {
        [classes.active]: isActive,
      })}
    >
      <InactiveOverlay isActive={isActive} />
      <div
        className={classes.container}
        style={{ backgroundColor: backgroundColor || '#fbf2e5' }}
      >
        <img
          src={imageUrl}
          alt="Logo"
          style={{
            visibility: logo ? 'visible' : 'hidden',
          }}
        />
        <p
          style={{
            fontFamily: titleFontFamily,
          }}
        >
          Work at{' '}
          <span
            style={{
              color: brandColor,
            }}
          >
            KFC
          </span>
        </p>
      </div>
    </div>
  );
}
