import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function calculatePercentage(value, totalCount) {
  return Math.round((value / totalCount) * 100);
}

export default function RecruitmentFunnel({ data }) {
  const { t } = useTranslation();

  const total =
    data?.newApplications?.current +
    data?.assessmentApplications?.current +
    data?.interviewedApplications?.current +
    data?.offeredApplications?.current +
    data?.onboardApplications?.current +
    data?.joinedApplications?.current;

  const stats = [
    {
      label: t('dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.New'),
      value: data?.newApplications?.current,
      dynamic: `${
        calculatePercentage(data?.newApplications?.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Assessment'
      ),
      value: data?.assessmentApplications?.current,
      dynamic: `${
        calculatePercentage(data?.assessmentApplications?.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Interview'
      ),
      value: data?.interviewedApplications?.current,
      dynamic: `${
        calculatePercentage(data?.interviewedApplications?.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Offer'
      ),
      value: data?.offeredApplications?.current,
      dynamic: `${
        calculatePercentage(data?.offeredApplications?.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Onboard'
      ),
      value:
        data?.onboardApplications?.current + data?.joinedApplications?.current,
      dynamic: `${
        calculatePercentage(
          data?.onboardApplications?.current +
            data?.joinedApplications?.current,
          total
        ) || 0
      }%`,
    },
  ];

  // It should prevent the sum of the dynamic values from exceeding 100%
  const firstStatPercentage =
    100 -
    stats.slice(1).reduce((acc, stat) => {
      return acc + parseInt(stat.dynamic, 10);
    }, 0);

  const noData =
    !data ||
    Object.values(data).every(
      (value) =>
        !value ||
        (Array.isArray(value) && !value.length) ||
        (!value.current && !value.previous)
    );

  console.log(stats);

  return (
    <div className={classes.RecruitmentFunnel}>
      <div className={classes.header}>
        <h2>
          {t('dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Title')}
        </h2>
      </div>
      {noData ? (
        <div className={classes.noData}>
          <p>
            {t(
              'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.NoDataMessage'
            )}
          </p>
        </div>
      ) : (
        <ul>
          {stats.map((stat, index) => {
            return (
              <li key={stat.label}>
                <div className={classes.title}>{stat.label}</div>
                <div className={classes.data}>
                  <span className={classes.value}>{stat.value}</span>
                  <span className={classes.dynamic}>
                    {index === 0 ? `${firstStatPercentage}%` : stat.dynamic}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
