import React, { useContext, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../../services/AdminService';
import { UiContext } from '../../../../../../context/UiContext';
import Stars from './Stars';

import classes from './styles.module.scss';
import convertDropdownValues from '../../../../../../helpers/convertDropdownValues';

export default function Feedback({
  setIsFeedbackVisible,
  candidate,
  interview,
  isEditable,
  job,
  refetch,
  user,
}) {
  const [feedbackText, setFeedbackText] = useState('');
  const [stars, setStars] = useState(0);
  const [isRecommended, setIsRecommended] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { showModal, showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const isInterviewerOrOrganizer =
    user?.id === interview?.organizer.id ||
    interview?.users?.some((usr) => usr.id === user?.id) ||
    interview?.jobApplicationInterviewFeedbacks?.some(
      (feedback) => feedback.userId === user?.id
    );
  const isInterviewStage = candidate?.status === 'Interview';

  useEffect(() => {
    if (isEditable) {
      const {
        feedbackNote,
        feedbackRating,
        isRecommended: feedbackRecommended,
      } = interview?.jobApplicationInterviewFeedbacks[0];

      setFeedbackText(feedbackNote);
      setIsRecommended(feedbackRecommended);
      setStars(feedbackRating);
    }
  }, [isEditable, interview?.jobApplicationInterviewFeedbacks]);

  const textareaRef = useRef(null);

  const validationSubmit =
    stars === 0 ||
    isRecommended === null ||
    (feedbackText.length <= 25 && isRecommended === false) ||
    (feedbackText.length <= 25 && stars < 4);

  const handleFeedbackOnChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const closeFeedbackPopup = () => {
    setIsFeedbackVisible(false);
  };

  const clearFeedback = () => {
    setStars(0);
    setIsRecommended(null);
    setFeedbackText('');
  };

  const setInterviewStatus = async () => {
    await AdminService.changeJobApplicationInterview({
      ...convertDropdownValues(interview),
      jobApplicationId: candidate?.id,
      interviewId: interview?.id,
      status: isRecommended ? 'Pass' : 'Fail',
    });
  };

  const deleteFeedback = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.Delete Feedback'
      ),
      text: t(
        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.Are you sure you want to delete?'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteFeedbackFromInterview({
            jobApplicationId: candidate?.id,
            interviewId: interview?.id,
            feedbackId: interview?.jobApplicationInterviewFeedbacks[0].id,
          });

          if (refetch) {
            await refetch();
          }
          showNotification({
            text: t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.Interview feedback deleted successfully'
            ),
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          closeFeedbackPopup();
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const handleSubmit = async () => {
    setIsTriedToSubmit(true);

    if (validationSubmit) {
      return;
    }

    try {
      setIsFetching(true);

      await AdminService.addFeedbackToInterview({
        jobApplicationId: candidate?.id,
        interviewId: interview.id,
        feedbackNote: feedbackText,
        feedbackRating: stars,
        isRecommended,
      });
      await setInterviewStatus();
      if (refetch) {
        await refetch();
      }
      showNotification({
        text: t(
          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.Interview feedback submitted successfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      closeFeedbackPopup();
      setIsFetching(false);
    }
  };

  const saveFeedback = async () => {
    if (validationSubmit) {
      return;
    }

    try {
      setIsFetching(true);
      if (isEditing) {
        await AdminService.deleteFeedbackFromInterview({
          jobApplicationId: candidate?.id,
          interviewId: interview.id,
          feedbackId: interview.jobApplicationInterviewFeedbacks[0].id,
        });
        if (refetch) {
          await refetch();
        }
      }
      await AdminService.addFeedbackToInterview({
        jobApplicationId: candidate?.id,
        interviewId: interview.id,
        feedbackNote: feedbackText,
        feedbackRating: stars,
        isRecommended,
      });
      await setInterviewStatus();
      if (refetch) {
        await refetch();
      }
      showNotification({
        text: t(
          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.Interview feedback updated successfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      closeFeedbackPopup();
      setIsFetching(false);
    }
  };

  return (
    <div
      className={classNames(classes.Feedback, {
        [classes.interviewOrganizer]: isInterviewerOrOrganizer,
      })}
    >
      <h2>
        {t(
          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.interviewFeedback'
        )}
      </h2>
      <div className={classes.close} onClick={closeFeedbackPopup} />
      <div
        className={classNames(classes.area, {
          [classes.noManipulations]: !isEditing && isEditable,
        })}
      >
        <div className={classes.info}>
          <div className={classes.container}>
            <p className={classes.title}>{t('common.candidate')}</p>
            <p>{`${candidate?.user?.userProfile?.firstName} ${candidate?.user?.userProfile?.lastName}`}</p>
            <span className={classes.location}>
              {candidate?.user?.userProfile?.city}
            </span>
          </div>
          <div className={classes.container}>
            <p className={classes.title}>{t('common.position')}</p>
            <p>{job?.jobTitle}</p>
            <span className={classes.job}>
              {t('common.jobId')}: {job.id}
            </span>
          </div>
        </div>

        <div className={classes.rating}>
          <p className={classes.title}>
            {t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.rateCandidate'
            )}
          </p>
          <Stars stars={stars} setStars={setStars} />
        </div>

        <p className={classes.title}>
          {t(
            'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.recommendCandidate'
          )}
        </p>
        <div className={classes.recommend}>
          <div
            className={classNames(classes.yes, {
              [classes.isRecommend]: isRecommended,
            })}
            onClick={() => setIsRecommended(true)}
          />
          <div
            className={classNames(classes.no, {
              [classes.isNoRecommend]: isRecommended === false,
            })}
            onClick={() => setIsRecommended(false)}
          />
        </div>

        <div className={classes.feedback}>
          <p className={classes.title}>
            {t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.feedback.title'
            )}
          </p>
          <span className={classes.description}>
            {t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.feedback.description'
            )}
          </span>
          <textarea
            ref={textareaRef}
            className={classes.textarea}
            value={feedbackText}
            onChange={(event) => handleFeedbackOnChange(event)}
            maxLength={5000}
            placeholder={t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.feedback.placeholder'
            )}
          />
          <span className={classes.validation}>
            {validationSubmit &&
              isTriedToSubmit &&
              t(
                'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.feedback.validation'
              )}
          </span>
          <span className={classes.counter}>
            {5000 - feedbackText.length}{' '}
            {t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Feedback.feedback.counter'
            )}
          </span>
        </div>
      </div>

      {isEditable ? (
        <div className={classes.buttons}>
          <div
            className={classNames(classes.button, classes.delete, {
              [classes.disabled]: !isInterviewStage,
            })}
            onClick={deleteFeedback}
          >
            {t('common.delete')}
          </div>
          {isEditing ? (
            <div
              className={classNames(classes.button, {
                [classes.disabled]: validationSubmit,
              })}
              onClick={saveFeedback}
            >
              {t('common.save')}
            </div>
          ) : (
            <div
              className={classNames(classes.button, {
                [classes.disabled]: !isInterviewStage,
              })}
              onClick={() => setIsEditing(true)}
            >
              {t('common.edit')}
            </div>
          )}
        </div>
      ) : (
        <div className={classes.buttons}>
          <div className={classes.button} onClick={clearFeedback}>
            {t('common.clear')}
          </div>
          <div
            className={classNames(classes.button, {
              [classes.submit]: !validationSubmit,
            })}
            onClick={handleSubmit}
          >
            {t('common.submit')}
          </div>
        </div>
      )}
    </div>
  );
}
