/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

export default function useQueryParamPagination() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageFromParams = parseInt(searchParams.get('page'), 10) || 1;
  const [currentPage, setCurrentPage] = useState(currentPageFromParams);

  // Update the state only when query parameter changes
  useEffect(() => {
    if (currentPage !== currentPageFromParams) {
      setCurrentPage(currentPageFromParams);
    }
  }, [currentPage, currentPageFromParams]);

  const setPage = useCallback(
    (page) => {
      setCurrentPage(page);
      setSearchParams({ page });
    },
    [setSearchParams]
  );

  return [currentPage, setPage];
}
