import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Stat({
  label,
  value,
  dynamic,
  hasNoData,
  noPreviousData,
}) {
  const { t } = useTranslation();

  const isZero = Math.round(dynamic) === 0;
  const isNegative = dynamic < 0;

  return (
    <div
      className={classNames(classes.Stat, {
        [classes.zero]: isZero || hasNoData,
        [classes.negative]: isNegative && !hasNoData,
      })}
    >
      <h3>{label}</h3>
      <div
        className={classNames(classes.value, {
          [classes.noData]: hasNoData || value === 0,
        })}
      >
        {hasNoData ? 0 : value}
      </div>
      {noPreviousData ? (
        <div className={classes.noDataToCompare}>
          {t(
            'dashboardComponents.Dashboard.LeftPanel.MainStats.Stat.No data to compare'
          )}
        </div>
      ) : (
        <div className={classes.dynamic}>{hasNoData ? 0 : dynamic}%</div>
      )}
      <div className={classes.period}>
        {t(
          'dashboardComponents.Dashboard.LeftPanel.MainStats.Stat.Since last month'
        )}
      </div>
    </div>
  );
}
