import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import TextInput from '../../components/Form/TextInput';
import PasswordValidation from '../../components/Dashboard/PasswordValidation';
import Buttons from './Buttons';
import UserValidation from './UserValidation';

import classes from './styles.module.scss';
import closeIcon from '../../assets/images/dashboard/close.svg';
import UserService from '../../services/UserService';
import { useUiContext } from '../../context/UiContext';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
  newPassword: Yup.string(),
});

export default function ChangePasswordModal({
  show,
  handleClose,
  currentUser,
}) {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isUserValid, setIsUserValid] = useState(null);

  const { t } = useTranslation();

  const { showUnknownErrorModal, showModal } = useUiContext();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const formik = useFormik({
    initialValues: {
      email: currentUser?.userName || '',
      password: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema,
    enableReinitialize: true,
  });

  const closeAndReset = () => {
    handleClose();
    formik.resetForm();
  };

  const changePassword = async () => {
    try {
      let userId;

      if (currentUser?.id && formik.values.email === currentUser?.userName) {
        userId = currentUser?.id;
      } else {
        const userIdResponse = await UserService.getUserId(formik.values.email);
        userId = userIdResponse.id;
      }

      if (!userId) {
        showUnknownErrorModal();
        return;
      }

      await UserService.setUsersPassword({
        password: formik.values.password,
        userId,
      });

      showModal({
        title: t('common.success'),
        text: t('modals.ChangePasswordModal.Password changed successfully'),
        dismissButtonLabel: t('common.gotIt'),
        onCancel: () => {},
      });

      closeAndReset();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    }
  };

  return (
    <Modal
      show={show}
      centered
      className={classes.ChangePasswordModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h1 className={classes.title}>
            {t('modals.ChangePasswordModal.Change Password')}
          </h1>
          <button
            type="button"
            className={classes.closeButton}
            onClick={closeAndReset}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </header>
        <div className={classes.form}>
          <div className={classes.row}>
            <div className={classes.col}>
              <TextInput
                label={t('modals.ChangePasswordModal.User Email ID')}
                height={45}
                placeholder={t(
                  'modals.ChangePasswordModal.Enter the email ID of the user'
                )}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email}
                touched={false}
                name="email"
              />
            </div>
            {isTabletOrMobile && (
              <div className={classes.col}>
                <UserValidation
                  email={formik.values.email}
                  isEmailValid={!formik.errors.email}
                  setIsUserValid={setIsUserValid}
                  isUserValid={isUserValid}
                />
              </div>
            )}
            <div className={classes.col}>
              <TextInput
                label={t('modals.ChangePasswordModal.New Password')}
                height={45}
                placeholder={t(
                  'modals.ChangePasswordModal.Enter the new password here'
                )}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.password}
                touched={false}
                name="password"
              />
            </div>
          </div>
          <div className={classes.row}>
            {!isTabletOrMobile && (
              <div className={classes.col}>
                <UserValidation
                  email={formik.values.email}
                  isEmailValid={!formik.errors.email}
                  setIsUserValid={setIsUserValid}
                  isUserValid={isUserValid}
                />
              </div>
            )}
            <div className={classes.col}>
              <PasswordValidation
                newPassword={formik.values.password}
                setIsValid={setIsPasswordValid}
                noConfirm
                style={{ marginBottom: 0 }}
              />
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <Buttons
            isSaveButtonDisabled={!isPasswordValid || isUserValid !== true}
            isResetButtonDisabled={!formik.dirty && !formik.isSubmitting}
            resetForm={formik.resetForm}
            onSubmit={changePassword}
          />
        </div>
      </div>
    </Modal>
  );
}
