import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

export default function Timeline({ data, candidatePopup }) {
  return (
    <div
      className={classNames(classes.Timeline, {
        [classes.candidatePopup]: candidatePopup,
      })}
    >
      {data?.map((event) => (
        <div className={classes.event} key={Math.random()}>
          <span className={classes.title}>{event?.title}</span>
          {event?.event?.additionalInfo && (
            <span className={classes.additionalInfo}>
              <span className={classes.bold}>Exception:</span>{' '}
              {event.event.additionalInfo}
            </span>
          )}
          <span className={classes.date}>{event?.date}</span>
        </div>
      ))}
    </div>
  );
}
