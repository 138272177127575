import React, { useState } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function LinkButton({ icon, onClick, buttonIndex, url }) {
  const [wasHovered, setWasHovered] = useState(false);
  const isDisabled = !url;

  const dragButton = (event) => {
    event.dataTransfer.setData('buttonIndex', buttonIndex);
  };

  return (
    <div
      className={classNames(classes.LinkWrapper, {
        [classes.wasHovered]: wasHovered,
      })}
      onMouseOver={() => setWasHovered(true)}
      //  onMouseLeave={() => setMouseOver(false)}
    >
      <div
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick();
          }
        }}
        onDragStart={dragButton}
        draggable
        role="button"
        tabIndex={isDisabled ? -1 : 0}
        className={classNames(classes.LinkButton, {
          [classes.disabled]: isDisabled,

          //  [classes.mouseOverAnimate]: mouseOver,
          // [classes.mouseLeaveAnimate]: !mouseOver,
        })}
        style={{ backgroundImage: `url(${icon})` }}
        onClick={isDisabled ? null : onClick}
      />
    </div>
  );
}
