import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function InterviewButton({
  job,
  interview,
  handleClickOnFeedback,
  isInterviewerOrOrganizer,
  candidate,
}) {
  const { t } = useTranslation();

  const { interviewDate, jobApplicationInterviewFeedbacks } = interview;

  let isButtonDisabled =
    job?.status === 'UnPublished' || new Date(interviewDate) > new Date();

  let buttonLabel = t(
    'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Submit Feedback'
  );

  if (jobApplicationInterviewFeedbacks?.length) {
    buttonLabel = t(
      'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Edit Feedback'
    );
  }

  if (!isInterviewerOrOrganizer || candidate?.status !== 'Interview') {
    isButtonDisabled = !interview?.jobApplicationInterviewFeedbacks?.length;
    buttonLabel = t(
      'dashboardComponents.Candidate.AssessmentAndInterview.Interview.View Feedback'
    );
  }
  return (
    <button
      type="button"
      className={classNames(classes.Button, classes.feedbackButton, {
        [classes.editFeedback]:
          jobApplicationInterviewFeedbacks?.length && isInterviewerOrOrganizer,
        [classes.viewFeedback]:
          !isInterviewerOrOrganizer && jobApplicationInterviewFeedbacks?.length,
      })}
      onClick={() => handleClickOnFeedback(interview)}
      disabled={isButtonDisabled}
    >
      {buttonLabel}
    </button>
  );
}
