import React, { useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

import classes from './styles.module.scss';
import AdminService from '../../../services/AdminService';
import UserService from '../../../services/UserService';

const today = new Date();

const dashboardModeOptions = [
  { label: 'Dashboard', value: 'All Data' },
  { label: 'My Dashboard', value: 'My Dashboard' },
];

export default function Dashboard() {
  const [dateRange, setDateRange] = useState([
    new Date(today.getFullYear(), today.getMonth(), 1),
    new Date(),
  ]);
  const [dashboardMode, setDashboardMode] = useState(dashboardModeOptions[0]);

  const { t } = useTranslation();

  const defaultLocationOption = {
    value: '',
    label: t('dashboardComponents.Dashboard.LeftPanel.Header.All locations'),
  };
  const defaultCityOption = {
    value: '',
    label: t('dashboardComponents.Dashboard.LeftPanel.Header.All cities'),
  };

  const [location, setLocation] = useState(defaultLocationOption);

  const [city, setCity] = useState(defaultCityOption);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { data } = useQuery({
    queryKey: [
      'dashboardStats',
      dateRange,
      location,
      city,
      dashboardMode,
      user,
    ],
    queryFn: () =>
      AdminService.getDashboardStats({
        startDate: moment(dateRange[0]).format('YYYY-MM-DD'),
        endDate: moment(dateRange[1]).format('YYYY-MM-DD'),
        periodType: 'Month',
        locationIds: location.value,
        cityIds: city?.value,
        userId: dashboardMode?.value === 'My Dashboard' ? user?.id : null,
      }),
    keepPreviousData: true,
    enabled: !!user,
  });

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  // Change city on location change and vice versa
  useEffect(() => {
    if (location?.city) {
      setCity({
        value: location.city.id,
        label: location.city.name,
      });
    }
  }, [location]);

  useEffect(() => {
    if (city.value && city?.value !== location?.city?.id) {
      setLocation(defaultLocationOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <div className={classes.Dashboard}>
      <LeftPanel
        location={location}
        setLocation={setLocation}
        dateRange={dateRange}
        setDateRange={setDateRange}
        data={data}
        city={city}
        setCity={setCity}
        defaultCityOption={defaultCityOption}
        defaultLocationOption={defaultLocationOption}
        dashboardMode={dashboardMode}
        setDashboardMode={setDashboardMode}
        dashboardModeOptions={dashboardModeOptions}
      />
      <RightPanel leftPanelData={isTabletOrMobile ? data : {}} />
    </div>
  );
}
