import React from 'react';

import { useTranslation } from 'react-i18next';

import Tooltip from '../../../Tooltip';

import classes from './styles.module.scss';
import { useUiContext } from '../../../../context/UiContext';
import { JOBS_PORTAL_URL } from '../../../../constants/main';

export default function CopyJobLinkButton({ jobPostId, style }) {
  const { showNotification } = useUiContext();

  const { t } = useTranslation();

  return (
    <Tooltip
      text={t('modals.PostJobSuccessModal.copyButton.tooltip')}
      width={151}
      direction="top"
    >
      <button
        style={style}
        className={classes.CopyJobLinkButton}
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(`${JOBS_PORTAL_URL}/jobs/${jobPostId}`);
          showNotification({
            text: t('modals.PostJobSuccessModal.linkCopied'),
          });
        }}
      >
        <svg
          width="27"
          height="26"
          viewBox="0 0 27 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13.5" cy="13" r="13" fill="#7A5BE9" />
          <mask
            id="mask0_780_13380"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="7"
            y="5"
            width="13"
            height="15"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 5.51562H20V19.0131H7V5.51562Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_780_13380)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5825 13.3311C15.5036 14.4513 13.7784 14.5133 12.637 13.4937C12.4069 13.2911 12.3784 12.933 12.5735 12.6939C12.7687 12.4547 13.1136 12.4251 13.3438 12.6279C13.3451 12.6288 13.3463 12.6299 13.3474 12.6311C14.0658 13.2726 15.1267 13.2423 15.8119 12.5309L18.3806 9.86393C19.0989 9.11826 19.0989 7.94262 18.3806 7.19694C17.6624 6.45127 16.53 6.45127 15.812 7.19694L13.3716 9.7306C13.1618 9.95483 12.8169 9.95992 12.6011 9.74211C12.3853 9.52429 12.3804 9.16616 12.5902 8.94193C12.5936 8.93816 12.5974 8.93439 12.6011 8.93062L15.0414 6.39696C16.1731 5.22188 18.0195 5.22188 19.1512 6.39677C20.283 7.57185 20.283 9.48902 19.1512 10.6641L16.5825 13.3311ZM11.9587 18.1322C10.827 19.3073 8.98045 19.3073 7.84867 18.1324C6.71708 16.9571 6.71708 15.0402 7.84885 13.8651L10.4176 11.1981C11.4967 10.0777 13.2217 10.0158 14.3629 11.0355C14.5921 11.2396 14.6184 11.5977 14.4219 11.8357C14.2257 12.0729 13.8817 12.101 13.6525 11.8981C12.9343 11.2566 11.8732 11.2869 11.1881 11.9981L8.61935 14.6651C7.90117 15.4107 7.90117 16.5864 8.61935 17.3321C9.33754 18.0777 10.4699 18.0777 11.1881 17.3321L13.6283 14.7984C13.8385 14.5747 14.1836 14.57 14.399 14.7882C14.6144 15.0064 14.6188 15.3647 14.4086 15.5884C14.4056 15.5918 14.4023 15.5952 14.399 15.5986L11.9587 18.1322Z"
              fill="white"
            />
          </g>
        </svg>
        {t('modals.PostJobSuccessModal.copyButton.copyLink')}
      </button>
    </Tooltip>
  );
}
