/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import classNames from 'classnames';
import { useFormik } from 'formik';
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { useTranslation } from 'react-i18next';

import AdminService from '../../../../services/AdminService';
import UserService from '../../../../services/UserService';
import TextInput from '../../../Form/TextInput';
import FormDropdown from '../../../Form/FormDropdown';
import Button from '../Button';

import getResizedImageUrl from '../../../../helpers/getResizedImageUrl';
import { UiContext } from '../../../../context/UiContext';
import { getAllTimezonesNames } from '../../../../helpers/timezones';
import classes from './styles.module.scss';

export default function Profile({ user, refetch, isEditing, setIsEditing }) {
  const [isBlocking, setIsBlocking] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(true);

  const { showNotification, showUnknownErrorModal, setIsFetching, showModal } =
    useContext(UiContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useBlocker((params) => {
    if (!isBlocking) {
      return false;
    }

    showModal({
      icon: 'infoIcon',
      title: t('dashboardComponents.ProfileSettings.Profile.cancelChangeTitle'),
      text: t('dashboardComponents.ProfileSettings.Profile.cancelChangeText'),
      onConfirm: async () => {
        setIsEditing(false);
        setTimeout(() => {
          setIsEditing(false);
          navigate(params.nextLocation.pathname);
        }, 100);
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });

    return true;
  });

  const handleSubmit = async (values) => {
    try {
      let response;
      if (!values?.avatar) {
        response = '';
      } else if (values?.avatar === user?.userProfile?.avatar) {
        response = user?.userProfile?.avatar;
      } else {
        response = await AdminService.uploadImage(values?.avatar, 'avatar');
      }

      setIsFetching(true);

      await UserService.updateProfile({
        firstName: values.firstName,
        middleName: user?.userProfile?.middleName,
        lastName: values.lastName,
        email: values.email,
        phone: user?.userProfile?.phone,
        address: user?.userProfile?.address,
        city: user?.userProfile?.city,
        postalCode: user?.userProfile?.postalCode,
        countryCode: user?.userProfile?.countryCode,
        birdthDate: user?.userProfile?.birdthDate,
        avatar: response?.fileName || response,
        languageCode: values.language.value,
        timezone: values.timezone.value,
        jobTitle: values.jobTitle,
        customLocation: user?.userProfile?.customLocation,
        isPrivate: user?.userProfile?.isPrivate,
      });

      if (refetch) {
        await refetch();
      }

      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.Profile.changesSavedSuccessfullyText'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsEditing(false);
      setIsFetching(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.userProfile?.firstName,
      lastName: user?.userProfile?.lastName || '',
      jobTitle: user?.userProfile?.jobTitle || '',
      email: user?.userName || '',
      language: user?.userProfile?.languageCode
        ? {
            label: user?.userProfile?.languageCode,
            value: user?.userProfile?.languageCode,
          }
        : {
            label: t(
              'dashboardComponents.ProfileSettings.Profile.languageOptionEnglish'
            ),
            value: 'English',
          },
      timezone: user?.userProfile?.timezone
        ? {
            label: user?.userProfile?.timezone,
            value: user?.userProfile?.timezone,
          }
        : {
            label: t(
              'dashboardComponents.ProfileSettings.Profile.timeZoneOptionTurkey'
            ),
            value: 'Turkey',
          },
      avatar: user?.userProfile?.avatar || null,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: yup.object().shape({
      firstName: yup.string().trim().required(t('common.cannotBeEmpty')),
      lastName: yup.string().trim().required(t('common.cannotBeEmpty')),
      jobTitle: yup.string().trim(),
      language: yup.object(),
      timezone: yup.object(),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isEditing) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [isEditing]);

  useEffect(() => {
    if (user?.userProfile?.avatar) {
      setHasPhoto(true);

      return;
    }

    setHasPhoto(false);
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      formik.setFieldValue('avatar', file);
      setHasPhoto(false);
    } else {
      formik.setFieldValue('avatar', null);
    }
  };

  const handleReset = () => {
    formik.handleReset();
    setIsEditing(false);

    if (!user?.userProfile?.avatar) {
      setHasPhoto(false);
      return;
    }

    setHasPhoto(true);
  };

  const timezones = useMemo(() => getAllTimezonesNames(), []);

  const lastNameInput = (
    <TextInput
      width="100%"
      height={45}
      label={t('dashboardComponents.ProfileSettings.Profile.lastNameLabel')}
      placeholder={t(
        'dashboardComponents.ProfileSettings.Profile.lastNameLabel'
      )}
      value={formik.values.lastName}
      name="lastName"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={formik.errors.lastName}
      touched={formik.touched.lastName}
    />
  );

  return (
    <div className={classes.Profile}>
      <div className={classes.row}>
        <div className={classes.col}>
          <h3 className={classes.title}>
            {t('common.dashboardSections.settings')}
          </h3>
          <div className={classes.avatar}>
            {hasPhoto || formik.values.avatar ? (
              <div
                className={classNames(classes.container, {
                  [classes.animateHover]: isEditing,
                })}
              >
                <img
                  src={
                    hasPhoto
                      ? getResizedImageUrl({
                          url: `avatar/${user?.userProfile?.avatar}`,
                          width: 240,
                        })
                      : URL.createObjectURL(formik?.values?.avatar)
                  }
                  className={classes.photo}
                  alt="avatar"
                />
                {isEditing && (
                  <div
                    className={classes.deletePhoto}
                    onClick={() => {
                      formik.setFieldValue('avatar', null);
                      setHasPhoto(false);
                    }}
                  >
                    <div className={classes.crossIcon} />
                  </div>
                )}
              </div>
            ) : (
              <div className={classes.container}>
                <input
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  onChange={handleFileChange}
                  id="file"
                  className={classes.file}
                  onClick={(e) => (e.target.value = null)}
                />
                <label htmlFor="file" className={classes.addPhoto}>
                  {t(
                    'dashboardComponents.ProfileSettings.Profile.uploadProfilePictureLabel'
                  )}
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div
          className={classNames(classes.column, {
            [classes.noActive]: !isEditing,
          })}
        >
          <div className={classes.row}>
            <FormDropdown
              height={45}
              label={t(
                'dashboardComponents.ProfileSettings.Profile.languagePreferenceLabel'
              )}
              placeholder={t(
                'dashboardComponents.ProfileSettings.Profile.languagePlaceholder'
              )}
              options={[
                {
                  label: t(
                    'dashboardComponents.ProfileSettings.Profile.languageOptionEnglish'
                  ),
                  value: 'English',
                },
                {
                  label: t(
                    'dashboardComponents.ProfileSettings.Profile.languageOptionTurkey'
                  ),
                  value: 'Turkey (coming soon)',
                },
                {
                  label: t(
                    'dashboardComponents.ProfileSettings.Profile.languageOptionRussian'
                  ),
                  value: 'Russian (coming soon)',
                },
              ]}
              value={formik.values.language}
              infoWidth={151}
              name="language"
              setFieldValue={formik.setFieldValue}
              error={formik.errors.language}
              touched={formik.touched.language}
              width="100%"
              readOnly={!isEditing}
            />
          </div>
          <div className={classes.row}>
            <FormDropdown
              height={45}
              label={t(
                'dashboardComponents.ProfileSettings.Profile.timeZoneLabel'
              )}
              placeholder={t(
                'dashboardComponents.ProfileSettings.Profile.timezonePlaceholder'
              )}
              options={timezones}
              value={formik.values.timezone}
              infoWidth={151}
              name="timezone"
              setFieldValue={formik.setFieldValue}
              error={formik.errors.timezone}
              touched={formik.touched.timezone}
              width="100%"
              readOnly={!isEditing}
            />
          </div>
          <div className={classes.row}>
            <TextInput
              width="100%"
              height={45}
              label={t(
                'dashboardComponents.ProfileSettings.Profile.firstNameLabel'
              )}
              placeholder={t(
                'dashboardComponents.ProfileSettings.Profile.firstNameLabel'
              )}
              value={formik.values.firstName}
              name="firstName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.firstName}
              touched={formik.touched.firstName}
            />
          </div>
          {isTabletOrMobile && (
            <div className={classes.row}>{lastNameInput}</div>
          )}
        </div>
        <div
          className={classNames(classes.column, {
            [classes.noActive]: !isEditing,
          })}
        >
          <div className={classes.row}>
            <TextInput
              width="100%"
              height={45}
              label={t(
                'dashboardComponents.ProfileSettings.Profile.jobTitleLabel'
              )}
              placeholder={t(
                'dashboardComponents.ProfileSettings.Profile.jobTitleLabel'
              )}
              value={formik.values.jobTitle}
              name="jobTitle"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.jobTitle}
              touched={formik.touched.jobTitle}
            />
          </div>
          <div
            className={classNames(classes.row, {
              [classes.emailDisabled]: isEditing,
            })}
          >
            <TextInput
              width="100%"
              height={45}
              label={t(
                'dashboardComponents.ProfileSettings.Profile.emailLabel'
              )}
              value={formik.values.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </div>
          {!isTabletOrMobile && (
            <div className={classes.row}>{lastNameInput}</div>
          )}
        </div>
      </div>
      <div className={classNames(classes.row, classes.buttonsRow)}>
        <div className={classes.buttons}>
          {isEditing ? (
            <>
              <Button onClick={handleReset}>{t('common.reset')}</Button>
              <Button
                type="save"
                onClick={() => formik.handleSubmit()}
                disabled={!formik.dirty}
              >
                {t('common.save')}
              </Button>
            </>
          ) : (
            <Button type="button" onClick={() => setIsEditing(true)}>
              {t('common.edit')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
