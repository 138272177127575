/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import JobStatusDropdown from './JobStatusDropdown';
import Button from '../../../../../Button';

import classes from './styles.module.scss';
import Favorites from '../../../../Favorites';
import { JobsContext } from '../../../../../../context/JobsContext';
import FavStatus from '../../../../Header/Search/FavStatus';
import { getAuthData } from '../../../../../../helpers/authStorage';

export default function JobHeader({
  job,
  jobTitle,
  jobId,
  createdAt,
  status,
  refetchJob,
  showJobPostPreviewModal,
}) {
  const [isInviteWindowOpened, setIsInviteWindowOpened] = useState(false);

  const { showShareJobModal, showQRQModal } = useContext(JobsContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { userId } = getAuthData();

  const jobControls = (
    <div className={classes.jobControls}>
      <div className={classes.qrq} onClick={() => showQRQModal(job)} />
      <div className={classes.share} onClick={() => showShareJobModal(job)} />
      <Button
        variant="delete"
        width={isTabletOrMobile ? 131 : 148}
        height={40}
        style={{ fontSize: 14 }}
        onClick={() => setIsInviteWindowOpened(true)}
      >
        {t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.inviteCandidate'
        )}
      </Button>
      <JobStatusDropdown
        status={status}
        jobId={jobId}
        refetchJob={refetchJob}
      />
    </div>
  );

  return (
    <>
      <header className={classes.JobHeader}>
        <div className={classes.row}>
          <div className={classes.title}>
            <h1 onClick={showJobPostPreviewModal}>{jobTitle}</h1>
            <div className={classes.favoritesIcon}>
              <FavStatus
                job={job}
                width={isTabletOrMobile ? 20 : 25}
                refetch={refetchJob}
                userId={userId}
              />
            </div>
          </div>
          {!isTabletOrMobile && jobControls}
        </div>
        <div className={classes.row}>
          <div className={classes.jobInfo}>
            <div className={classes.details}>
              {t('common.jobId')}:{' '}
              <span className={classes.value}>{jobId}</span>
            </div>
            <div className={classes.details}>
              {t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.datePosted'
              )}
              :{' '}
              <span className={classes.value}>
                {moment(createdAt).format('DD MMM, YYYY')}
              </span>
            </div>
          </div>
        </div>
        {isTabletOrMobile && jobControls}
      </header>
      {isInviteWindowOpened && (
        <Favorites
          hidePopup={() => setIsInviteWindowOpened(false)}
          invite
          job={job}
          refetchJob={refetchJob}
        />
      )}
    </>
  );
}
