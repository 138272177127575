import React, { useState, useEffect } from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PostMenu from './PostMenu';
import ViewIcon from './ViewIcon';

import useResizeObserver from '../../../../../../hooks/useResizeObserver';
import getResizedImageUrl from '../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function Header({
  role,
  feedPost,
  refetch,
  setEditingPost,
  isRead,
  modal,
  collapsePost,
  isCollapsed,
  postRef,
  isHiddenTabActive,
}) {
  const [isPostBig, setIsPostBig] = useState(false);

  const { height } = useResizeObserver(postRef);

  const { t } = useTranslation();

  useEffect(() => {
    if (!postRef?.current) {
      return;
    }

    if (height > 1000) {
      setIsPostBig(true);
    }
  }, [postRef, height]);

  let avatar = (
    <div className={classes.noAvatar}>
      <span className={classes.initials}>
        {feedPost?.author?.firstName[0]}
        {feedPost?.author?.lastName[0]}
      </span>
    </div>
  );
  let name = `${feedPost?.author?.fullName}`;

  if (feedPost?.author?.avatar) {
    avatar = (
      <img
        src={getResizedImageUrl({
          url: `avatar/${feedPost?.author?.avatar}`,
          width: 100,
        })}
        alt="User"
      />
    );
  }

  if (feedPost?.systemAnnouncementType === 'NewRegistration') {
    avatar = (
      <div className={classNames(classes.systemAvatar, classes.zaphire)} />
    );
    name = 'Zaphire';
  }

  if (
    feedPost?.systemAnnouncementType &&
    feedPost?.systemAnnouncementType !== 'NewRegistration'
  ) {
    avatar = <div className={classNames(classes.systemAvatar, classes.kfc)} />;
    name = 'KFC';
  }

  return (
    <div
      className={classNames(classes.Header, {
        [classes.insideModal]: modal,
      })}
    >
      <div className={classes.leftBlock}>
        <div className={classes.avatarWrapper}>
          <div className={classes.avatarAnimation}>{avatar}</div>
        </div>
        {!isCollapsed && (
          <div className={classes.postInfo}>
            <div className={classes.authorName}>
              {name}
              <span className={classes.arrow} />{' '}
              {!feedPost.mentions.length && (
                <span>
                  {t(
                    'dashboardComponents.Discover.Feed.Post.Header.To all members'
                  )}
                </span>
              )}
              {feedPost.mentions.map?.((recepient, index) => {
                return (
                  <span>
                    {recepient?.fullName}
                    {index !== feedPost.mentions.length - 1 && ', '}
                  </span>
                );
              })}
            </div>
            <div className={classes.createdAt}>
              {moment(feedPost.createdAt).format('DD MMMM YYYY h:mm a')}
              {isRead && <ViewIcon />}
            </div>
          </div>
        )}
      </div>
      {!modal && !isCollapsed && (
        <div className={classes.rightBlock}>
          {isPostBig && (
            <button
              type="button"
              className={classes.collapseButton}
              onClick={collapsePost}
            >
              {t('dashboardComponents.Discover.Feed.Post.Header.Collapse')}
            </button>
          )}
          <PostMenu
            postRef={postRef}
            feedPost={feedPost}
            refetch={refetch}
            setEditingPost={setEditingPost}
            role={role}
            isHiddenTabActive={isHiddenTabActive}
          />
        </div>
      )}
    </div>
  );
}
