/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const options = ['Candidates', 'Jobs', 'Calendar', 'All'];

export default function CandidatesDropdown({
  activeDropDownOption,
  setActiveDropDownOption,
  isTabletOrMobile,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.CandidatesDropdown, {
        [classes.open]: isOptionsListVisible,
        [classes.mobile]: isTabletOrMobile,
      })}
    >
      <div
        className={classNames(classes.selector, {
          [classes.isOpened]: isOptionsListVisible,
        })}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {activeDropDownOption}
      </div>
      {isOptionsListVisible && (
        <div className={classes.options}>
          <ul>
            {options.map((option) => (
              <li
                key={option}
                onClick={() => {
                  setActiveDropDownOption(option);
                  setIsOptionsListVisible(false);
                }}
                className={classNames({
                  [classes.active]: activeDropDownOption === option,
                })}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
