import React from 'react';

import getResizedImageUrl from '../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function UserWithAvatar({ user }) {
  return (
    <div className={classes.UserWithAvatar}>
      {user?.avatar && (
        <img
          src={getResizedImageUrl({
            url: `avatar/${user?.avatar}`,
            width: 40,
          })}
          alt="avatar"
          className={classes.avatar}
        />
      )}
      <div className={classes.name}>
        <span>{user}</span>
      </div>
    </div>
  );
}
