import React from 'react';

import { useTranslation } from 'react-i18next';

import FormButtonManager from '../FormButtonsManager';
import FormButton from '../../FormButton';

export default function Buttons({
  isEdit,
  deleteEvent,
  formik,
  setIsTriedToSubmit,
  isDisabled,
  isChangeRequested,
  isEditChangeRequestMode,
  setIsEditChangeRequestMode,
  fields,
}) {
  const { t } = useTranslation();

  const defaultButtons = (
    <>
      <FormButton
        variant={isEdit ? 'delete' : 'reset'}
        disabled={isEdit ? false : !formik.dirty}
        style={{
          width: 250,
          height: 52,
        }}
        onClick={isEdit ? deleteEvent : formik.resetForm}
      >
        {isEdit
          ? t('modals.SetJoiningModal.delete')
          : t('modals.SetJoiningModal.clear')}
      </FormButton>
      <FormButton
        variant="submit"
        style={{
          width: 250,
          height: 52,
        }}
        disabled={isDisabled}
        onClick={() => {
          setIsTriedToSubmit(true);
          formik.handleSubmit();
        }}
      >
        {t('common.submit')}
      </FormButton>
    </>
  );

  const changeRequestedButtons = (
    <>
      <FormButton
        variant="delete"
        disabled={isEdit ? false : !formik.dirty}
        style={{
          width: 250,
          height: 52,
        }}
        onClick={deleteEvent}
      >
        {t('common.cancel')}
      </FormButton>
      <FormButton
        variant="reset"
        style={{
          width: 250,
          height: 52,
        }}
        onClick={() => {
          setIsEditChangeRequestMode(true);
        }}
      >
        {t('common.edit')}
      </FormButton>
    </>
  );

  const editChangeRequestButtons = (
    <FormButtonManager
      formik={formik}
      mode="edit"
      rightButtonLabel={t('common.update')}
      fields={fields}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
    />
  );

  let currentButtons = defaultButtons;

  if (isChangeRequested) {
    currentButtons = changeRequestedButtons;
  }

  if (isEditChangeRequestMode) {
    currentButtons = editChangeRequestButtons;
  }

  return currentButtons;
}
