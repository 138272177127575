import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';
import InnerHeader from '../../components/Auth/InnerHeader';
import Button from '../../components/Button';
import classes from './styles.module.scss';

export default function SignInMethodPage() {
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [info, setInfo] = useState('Use your password to login');
  const [activeMethod, setActiveMethod] = useState('password'); // [password, otp]

  const navigate = useNavigate();

  const { t } = useTranslation();

  const showPasswordInfo = () => {
    setInfo(t('pages.SignInMethodPage.passwordInfo'));
    setIsInfoVisible(true);
  };

  const showOtpInfo = () => {
    setInfo(t('pages.SignInMethodPage.otpInfo'));
    setIsInfoVisible(true);
  };

  const hideInfo = () => {
    setIsInfoVisible(false);
  };

  const handleContinue = () => {
    if (activeMethod === 'password') {
      navigate('/login/method/password');
    } else {
      navigate('/login/method/otp');
    }
  };

  return (
    <div className={classes.SignInMethodPage}>
      <Helmet>
        <title>{t('pages.SignInMethodPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          withBackButton
          noButton
          title={`${t('pages.SignInMethodPage.chooseYour')} ${t(
            'pages.SignInMethodPage.signInMethod'
          )}`}
        />
        <div className={classes.buttons}>
          <Button
            variant={activeMethod === 'otp' ? '' : 'secondary'}
            width={150}
            height={150}
            onMouseEnter={showOtpInfo}
            onMouseLeave={hideInfo}
            // onClick={() => navigate('/login/method/otp')}
            onClick={() => setActiveMethod('otp')}
          >
            {t('pages.SignInMethodPage.otpButton')}
          </Button>
          <Button
            variant={activeMethod === 'password' ? '' : 'secondary'}
            width={150}
            height={150}
            onMouseEnter={showPasswordInfo}
            onMouseLeave={hideInfo}
            // onClick={() => navigate('/login/method/password')}
            onClick={() => setActiveMethod('password')}
          >
            {t('pages.SignInMethodPage.passwordButton')}
          </Button>
        </div>
        <p
          className={classes.info}
          style={{ visibility: isInfoVisible ? 'visible' : 'hidden' }}
        >
          {info}
        </p>
        <ButtonWithHelperText
          onClick={handleContinue}
          helperLink="/request-access"
          helperText={t('pages.LoginPage.newUserMessage')}
          helperLinkText={t('pages.LoginPage.requestAccessLink')}
          buttonLabel={t('pages.LoginPage.continueButton')}
        />
      </div>
    </div>
  );
}
