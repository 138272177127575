import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icons from '../Icons';

import classes from './styles.module.scss';

function getDate(date) {
  const temp = new Date(date);

  if (!date) {
    return false;
  }

  return `${temp?.getDate()} ${temp
    ?.toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp?.getFullYear()}`;
}

export default function Word({
  isModal,
  resume,
  candidate,
  addCandidateModal,
  formik,
  resumeFieldName,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
  openResumeInput,
}) {
  const { t } = useTranslation();

  const handleOpenFile = () => {
    window.open(resume?.url, '_blank');
  };
  const handlePrint = () => {
    window.open(resume?.url, '_blank');

    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const fileName =
    resume?.displayName || resume?.fileName || resume?.name || '';

  return (
    <div
      className={classNames(classes.Word, {
        [classes.addCandidateModal]: addCandidateModal,
      })}
      style={{ marginTop: isModal && '30px' }}
    >
      {resume && (
        <div className={classes.wordContainer}>
          <span className={classes.wordName} onClick={handleOpenFile}>
            {fileName}
          </span>
          <span
            className={classes.date}
            style={{
              display: resume.createdAt ? 'flex' : 'none',
            }}
          >
            {t('dashboardComponents.Candidate.WordResume.Word.uploaded')}{' '}
            {getDate(resume?.createdAt) || getDate(candidate?.createdAt)}
          </span>
        </div>
      )}
      <div style={{ display: 'unset' }}>
        <Icons
          handlePrint={handlePrint}
          word
          url={resume?.url}
          addCandidateModal={addCandidateModal}
          formik={formik}
          resumeFieldName={resumeFieldName}
          isPossibleToEdit={isPossibleToEdit}
          isReadOnly={isReadOnly}
          isEdit={isEdit}
          openResumeInput={openResumeInput}
        />
      </div>
    </div>
  );
}
