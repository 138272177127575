/* eslint-disable react/jsx-handler-names */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';

import Button from '../../../../Button';
import SidePopup from '../../SidePopup';
import MultiDropdown from '../../../../Form/MultiDropdown';
import TextInput from '../../../../Form/TextInput';

import yupSchemaToFields from '../../../../../helpers/yupSchemaToFields';
import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';
import AdminService from '../../../../../services/AdminService';

export default function AddUserForm({
  handleClose,
  refetch,
  isVisible,
  setIsAddUserFormVisible,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    showModal,
    showUnknownErrorModal,
    setIsFetching,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const createUserMutation = useMutation({
    mutationFn: ({
      firstName,
      lastName,
      email,
      cities,
      restaurants,
      roles,
    }) => {
      return AdminService.createUser({
        firstName,
        lastName,
        email,
        cityIds: cities,
        locationsIds: restaurants,
        roleIds: roles,
      });
    },
    onMutate: () => {
      setIsCreatingOrUpdating(true);
      setIsFetching(true);
    },
    onSuccess: () => {
      setIsCreatingOrUpdating(false);
      setIsFetching(false);
      refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.UserManagement.AddUserForm.successModal.title'
        ),
        text: t(
          'dashboardComponents.Console.UserManagement.AddUserForm.successModal.text'
        ),
        onConfirm: () => {},
        onCancel: () => setIsAddUserFormVisible(true),
        dismissButtonLabel: t('common.addMore'),
        confirmButtonLabel: t('common.gotIt'),
      });
      handleClose();
    },
    onError: (error) => {
      setIsCreatingOrUpdating(false);
      setIsFetching(false);
      if (error.response.status === 400) {
        showModal({
          title: t(
            'dashboardComponents.Console.UserManagement.AddUserForm.duplicateUserModal.title'
          ),
          text: t(
            'dashboardComponents.Console.UserManagement.AddUserForm.duplicateUserModal.text'
          ),
          dismissButtonLabel: t(
            'dashboardComponents.Console.UserManagement.AddUserForm.duplicateUserModal.dismissButtonLabel'
          ),
        });
      } else {
        showUnknownErrorModal();
      }
    },
  });

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .matches(
            /^[\p{L}\p{M}'\-\s]+$/u,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          )
          .trim()
          .required(t('common.requiredField')),
        lastName: yup
          .string()
          .matches(
            /^[\p{L}\p{M}'\-\s]+$/u,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          )
          .trim()
          .required(t('common.requiredField')),
        email: yup
          .string()
          .trim()
          .email(
            t(
              'dashboardComponents.Console.UserManagement.AddUserForm.errorMessages.validEmail'
            )
          )
          .required(t('common.requiredField')),
        city: yup.array(),
        restaurant: yup.array(),
        role: yup
          .array()
          .required(
            t(
              'dashboardComponents.Console.UserManagement.AddUserForm.errorMessages.roleRequired'
            )
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      city: '',
      restaurant: '',
      role: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const requestData = {
          firstName: values.firstName.replace(/\s/g, ''),
          lastName: values.lastName.replace(/\s/g, ''),
          email: values.email,
          roles: values.role.map((role) => role.value),
        };

        if (values.city) {
          requestData.cities = values.city.map((city) => city.value);
        } else {
          requestData.cities = [];
        }

        if (values.restaurant) {
          requestData.restaurants = values.restaurant.map(
            (restaurant) => restaurant.value
          );
        } else {
          requestData.restaurants = [];
        }

        createUserMutation.mutate(requestData);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const closeForm = () => {
    if (Object.values(formik.values).every?.((val) => !val)) {
      handleClose();
    } else {
      showModal({
        title: t(
          'dashboardComponents.Console.UserManagement.AddUserForm.exitFormConfirmation.title'
        ),
        text: t(
          'dashboardComponents.Console.UserManagement.AddUserForm.exitFormConfirmation.text'
        ),
        onConfirm: () => {},
        onCancel: handleClose,
        dismissButtonLabel: t('common.yes'),
        confirmButtonLabel: t('common.no'),
      });
    }
  };

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  /* const isRightButtonDisabled =
    Object.values(formik.values).some((val) => !val) || isCreatingOrUpdating; */

  return (
    <SidePopup
      fields={yupSchemaToFields(validationSchema)}
      mode="add"
      formik={formik}
      isVisible={isVisible}
      handleClose={closeForm}
      title={t('dashboardComponents.Console.UserManagement.AddUserForm.title')}
      leftButtonLabel={t('common.clear')}
      onLeftButtonClick={formik.resetForm}
      rightButtonLabel={t('common.invite')}
      rightButtonComponent={Button}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
    >
      <div className={classes.AddUserForm}>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.leftCol}>
            <TextInput
              error={formik.errors.firstName}
              touched={isTriedToSubmit}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.firstName'
              )}
              name="firstName"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.firstName'
              )}
              height={50}
            />
            <TextInput
              error={formik.errors.email}
              touched={isTriedToSubmit}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.email'
              )}
              name="email"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.email'
              )}
              height={50}
            />
            <MultiDropdown
              error={formik.errors.city}
              touched={isTriedToSubmit}
              value={formik.values.city}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.city'
              )}
              name="city"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.city'
              )}
              height={50}
              width={400}
              fetchOptions={{
                dataName: 'citiesOptionsUser',
                getDataHandler: AdminService.getCities,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.searchInfo.city'
              )}
              searchInfoWidth={173}
              setFieldTouched={formik.setFieldTouched}
            />
            <MultiDropdown
              error={formik.errors.role}
              touched={isTriedToSubmit}
              value={formik.values.role}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.role'
              )}
              name="role"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.role'
              )}
              height={50}
              width={400}
              fetchOptions={{
                dataName: 'rolesOptionsUser',
                getDataHandler: AdminService.getRoles,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.searchInfo.role'
              )}
              searchInfoWidth={173}
              setFieldTouched={formik.setFieldTouched}
            />
          </div>
          <div className={classes.rightCol}>
            <TextInput
              error={formik.errors.lastName}
              touched={isTriedToSubmit}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.lastName'
              )}
              name="lastName"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.lastName'
              )}
              height={50}
            />
            <MultiDropdown
              error={formik.errors.restaurant}
              touched={isTriedToSubmit}
              value={formik.values.restaurant}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.labels.restaurant'
              )}
              name="restaurant"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.placeholders.restaurant'
              )}
              height={50}
              width={400}
              fetchOptions={{
                dataName: 'restaurantsOptionsUser',
                getDataHandler: AdminService.getRestaurants,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.AddUserForm.searchInfo.restaurant'
              )}
              searchInfoWidth={133}
              setFieldTouched={formik.setFieldTouched}
            />
          </div>
        </form>
      </div>
    </SidePopup>
  );
}
