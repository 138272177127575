import React, { useState } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function CloseIcon({ onClick }) {
  const [addClass, setAddClass] = useState(false);

  const removeClass = () => {
    setAddClass(true);
  };

  return (
    <div
      className={classNames({
        [classes.CloseIcon]: true,
      })}
      onMouseOut={removeClass}
    >
      <i
        className={classNames({
          [classes.icon]: true,
          [classes.closeIconBackAnimation]: addClass,
        })}
        onAnimationEnd={() => {
          setAddClass(false);
        }}
        onClick={onClick}
      >
        Close
      </i>
    </div>
  );
}
