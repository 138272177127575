import React from 'react';

// import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../components/Form/Dropdown';
import CurrencyInput from '../../../components/Form/CurrencyInput';

import classes from './styles.module.scss';
import useOfferStatus from '../hooks/useOfferStatus';

export default function CurrentOffer({
  notAllowedToEdit,
  formik,
  status,
  candidate,
}) {
  const { t } = useTranslation();

  const { amountStatus, floatValue, readOnly, isAccepted, offer, isCountered } =
    useOfferStatus({
      candidate,
      counterOfferMode: status,
      formik,
    });

  let offerColor = '';
  let value = formik.values.amount;
  let offerLabel = t('modals.OfferModal.offer.header');

  // Counter offer received from the candidate
  if (isCountered && status === 'default') {
    value = offer.counterAmount;
    offerColor = '#FFBF00';
    offerLabel = t('modals.OfferModal.offer.counterOffer');
    // Employer is going to decline the counter offer
  } else if (isCountered && status === 'decline') {
    offerLabel = t('modals.OfferModal.offer.existingOffer');
    // Employer is going to accept the counter offer or update the offer
  } else if (isCountered && status === 'update') {
    offerLabel = t('modals.OfferModal.offer.newOffer');
  }

  if (isCountered && status === 'update' && !isAccepted) {
    if (amountStatus === 'lessThanCounter') {
      offerColor = '#FFBF00';
    }

    if (amountStatus === 'lessThanPrev') {
      offerColor = '#FF2D55';
    }

    if (floatValue >= offer.counterAmount && floatValue >= offer.amount) {
      offerColor = '#00E4AC';
    }
  } else if (isAccepted && offer?.status === 'Accepted') {
    offerColor = '#00E4AC';
    offerLabel = t('modals.OfferModal.offer.acceptedOffer');
  } else if (
    isAccepted &&
    offer?.status === 'Declined' &&
    offer.declineReson !== 'CounterOffer'
  ) {
    offerColor = '#FF2D55';
    offerLabel = t('modals.OfferModal.offer.declinedOffer');
  }

  // Offer or counter offer of the employer is sent to the candidate but not accepted or declined yet
  if (readOnly && !isAccepted) {
    value = offer?.counterAmount || offer?.amount;
    offerLabel = offer?.counterAmount
      ? t('modals.OfferModal.offer.newOffer')
      : t('modals.OfferModal.offer.header');
    offerColor = '';
  }

  return (
    <div className={classes.CurrentOffer}>
      <div className={classes.dropdownContainer}>
        <h4 className={classes.dropdownTitle}>{offerLabel}</h4>
        <Dropdown
          readOnly={notAllowedToEdit}
          width={95}
          height={48}
          options={[
            { value: 'TRY', label: 'TRY' },
            { value: 'USD', label: 'USD' },
            { value: 'EUR', label: 'EUR' },
          ]}
          activeOption={formik.values.currency}
          setActiveOption={(option) => formik.setFieldValue('currency', option)}
        />
      </div>
      <div className={classes.currencyContainer}>
        <CurrencyInput
          style={{
            color: offerColor,
            backgroundColor: isCountered ? 'white' : '',
            border: isCountered ? '1px solid #C1C5D6' : '',
          }}
          height={48}
          name="amount"
          value={value}
          onChange={formik.handleChange}
          readonly={notAllowedToEdit}
        />
        {amountStatus === 'lessThanCounter' && (
          <div className={classes.warning}>
            {t('modals.OfferModal.lowerThanCounterOffer')}
          </div>
        )}
        {amountStatus === 'lessThanPrev' && (
          <div className={classes.error}>
            {t('modals.OfferModal.lowerThanPreviousOffer')}
          </div>
        )}
      </div>
    </div>
  );
}
