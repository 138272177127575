/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FilterTabs({
  count,
  activeFilter,
  setActiveFilter,
  filters,
  translationPath,
  maxWidth,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.FilterTabs}>
      <ul className={classes.list} style={{ maxWidth }}>
        {filters.map((filter) => {
          return (
            <li
              role="tab"
              tabIndex={0}
              onClick={() => setActiveFilter(filter)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setActiveFilter(filter);
                }
              }}
              key={filter}
              className={classNames({
                [classes.active]: activeFilter === filter,
              })}
            >
              <h6 className={classes.title}>
                {t(`${translationPath}.${filter}`)}
              </h6>
              {count[filter] !== -1 && (
                <div className={classes.count}>{count[filter]}</div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
