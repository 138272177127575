import React, { useState, useEffect, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import TitleWithTooltip from '../TitleWithTooltip';

import { BrandKitContext } from '../../../../../../../../context/BrandKitContext';
import classes from './styles.module.scss';
import editIcon from '../../../../../../../../assets/images/dashboard/brand-kit/edit.svg';

export default function BrandName({ brandName, setBrandName, label }) {
  const [inputValue, setInputValue] = useState(brandName);

  const { isBrandNameEditingMode, setIsBrandNameEditingMode } =
    useContext(BrandKitContext);

  const inputRef = useRef();

  const { t } = useTranslation();

  const startEditing = () => {
    setInputValue(brandName);
    if (brandName) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    setBrandName(inputValue);
  }, [inputValue, setBrandName]);

  useEffect(() => {
    if (isBrandNameEditingMode) {
      startEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandNameEditingMode, brandName]);

  useEffect(() => {
    if (!brandName?.trim() && brandName !== null) {
      setIsBrandNameEditingMode(true);
    }
  }, [brandName, setIsBrandNameEditingMode]);

  return (
    <div className={classes.BrandName}>
      <TitleWithTooltip
        // title={t(
        //   'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.BrandName.brandName'
        // )}
        title={label}
        text={t(
          'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.BrandName.brandNameTooltip'
        )}
        tooltipWidth={278}
      />
      <div className={classes.inputContainer}>
        {isBrandNameEditingMode ? (
          <input
            maxLength={25}
            ref={inputRef}
            type="text"
            placeholder={t(
              'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.BrandName.brandNamePlaceholder'
            )}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onBlur={() => {
              if (inputValue?.trim()) {
                setIsBrandNameEditingMode(false);
              }
            }}
          />
        ) : (
          <div className={classes.savedName}>
            <span>{brandName}</span>
            <button
              className={classes.editButton}
              onClick={() => setIsBrandNameEditingMode(true)}
              type="button"
            >
              <img src={editIcon} alt="Edit" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
