import AdminService from '../services/AdminService';

export default async function createOrUpdateVrQuestions(vrQuestions) {
  try {
    const questionsToCreate = [];
    const questionsToUpdate = [];
    const existingQuestions = [];

    vrQuestions.forEach((question, index) => {
      if (
        question.title === question.savedQuestion?.label &&
        question.question === question.savedQuestion?.question &&
        question.duration === question.savedQuestion?.duration &&
        question.sortOrder === index &&
        question.isDefault === question.savedQuestion?.isDefault
      ) {
        existingQuestions.push({
          value: question.savedQuestion.value,
          sortOrder: index,
        });
      } else if (question.id.toString().startsWith('0.')) {
        questionsToCreate.push({ ...question, sortOrder: index });
      } else {
        questionsToUpdate.push({ ...question, sortOrder: index });
      }
    });

    const questionsToCreatePromises = questionsToCreate.map((qstn) =>
      AdminService.createVrQuestion({
        title: qstn.title,
        question: qstn.question,
        duration: qstn.duration,
        sortOrder: qstn.sortOrder,
      })
    );

    const questionsToUpdatePromises = questionsToUpdate.map((qstn) =>
      AdminService.updateVrQuestion(qstn)
    );

    const createdQuestions = await Promise.all(questionsToCreatePromises);
    const updatedQuestions = await Promise.all(questionsToUpdatePromises);

    const questionsIds = [
      ...createdQuestions.map((qstn) => qstn.id),
      ...updatedQuestions.map((qstn) => qstn.id),
      ...existingQuestions.map((qstn) => qstn.value),
    ];

    return questionsIds;
  } catch (error) {
    console.log(error);
    return null;
  }
}
