import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import RadioButton from '../../../../Form/RadioButton';
import Textarea from '../../../../Form/Textarea';
import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';
import Checkbox from '../../../../Form/Checkbox';

import yupSchemaToFields from '../../../../../helpers/yupSchemaToFields';
import useSwitchableRowData from '../../../../../hooks/useSwitchableRowData';
import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function EditRole({
  isVisible,
  handleClose,
  refetch,
  roles,
  currentRoleId,
  selectedRows,
  noAnimation,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [addOrEditRoleMode, setAddOrEditRoleMode] = useState(false);

  const { t } = useTranslation();

  const roleOptionsToScopesMap = useMemo(
    () => ({
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
      )]: 'api:full',
      'View Only Access': 'api:admin:read',
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
      )]: 'api:admin:jobs',
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
      )]: 'api:admin:candidates',
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
      )]: 'api:admin:auth',
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
      )]: 'api:admin:reference',
    }),
    [t]
  );

  const [roleAccess, setRoleAccess] = useState(
    t('dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess')
  );
  const [prevRoleAccess, setPrevRoleAccess] = useState(
    t('dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess')
  );

  const [customRoleOptions, setCustomRoleOptions] = useState({
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.accessLabel'
    )]: false,
    /* [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.viewOnlyAccessLabel'
    )]: false, */
  });
  const [customSavedRoleOptions, setCustomSavedRoleOptions] = useState({
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.accessLabel'
    )]: false,
    /*    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.viewOnlyAccessLabel'
    )]: false, */
  });

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(roles, selectedRows);

  const updateRole = async (values) => {
    function getScopes() {
      const scopes = [];
      Object.keys(customRoleOptions).forEach((key) => {
        if (customRoleOptions[key]) {
          scopes.push(roleOptionsToScopesMap[key]);
        }
      });
      return scopes;
    }

    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const scopes =
        roleAccess ===
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
        )
          ? getScopes()
          : [roleOptionsToScopesMap[roleAccess]];

      await AdminService.updateRole({
        scopes,
        note: values.notes,
        name: values.name,
        id: roles[currentDataIndex]?.id,
      });
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.UserManagement.EditRole.successModal.title'
        ),
        text: t(
          'dashboardComponents.Console.UserManagement.EditRole.successModal.text'
        ),
        onCancel: handleClose,
      });
    } catch (error) {
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().trim().required(t('common.requiredField')),
        notes: yup.string().trim(),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: roles?.[currentDataIndex]?.name,
      notes: addOrEditRoleMode ? '' : roles?.[currentDataIndex]?.note,
    },
    validationSchema,
    onSubmit: (values, formikBag) => {
      updateRole(values, formikBag);
    },
    enableReinitialize: true,
  });

  const changeCustomRoleOptions = (option) => {
    setCustomRoleOptions((prevOptions) => {
      if (prevOptions[option]) {
        return { ...prevOptions, [option]: false };
      }
      return { ...prevOptions, [option]: true };
    });
  };

  const changeRoleAccess = (option) => {
    setRoleAccess(option);
  };

  const setRadioButtons = useCallback(() => {
    const role = roles?.[currentDataIndex];

    if (!role) {
      return;
    }

    if (role.scopes === 'api:full') {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
        )
      );
      setPrevRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
        )
      );
    } else if (role.scopes === 'api:admin:read') {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
        )
      );
      setPrevRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
        )
      );
    } else {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
        )
      );
      setPrevRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
        )
      );

      const currentOptions = {};
      Object.keys(roleOptionsToScopesMap).forEach((key) => {
        if (role.scopes.includes(roleOptionsToScopesMap[key])) {
          currentOptions[key] = true;
        } else {
          currentOptions[key] = false;
        }
      });

      setCustomRoleOptions(currentOptions);
      setCustomSavedRoleOptions(currentOptions);
    }
  }, [currentDataIndex, roleOptionsToScopesMap, roles, t]);

  const clear = useCallback(() => {
    setAddOrEditRoleMode(true);

    formik.resetForm();

    setRadioButtons();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRadioButtons]);

  useEffect(() => {
    setCustomRoleOptions({
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.EditRole.labels.access'
      )]: false,
      /*    [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.viewOnlyAccessLabel'
      )]: false, */
    });
    setRadioButtons();

    return () => {
      setAddOrEditRoleMode(false);
    };
  }, [currentRoleId, currentDataIndex, setRadioButtons, roles, t]);

  const formHasChanged =
    Object.keys(formik.values).some(
      (key) => formik.values[key] !== formik.initialValues[key]
    ) ||
    Object.values(customSavedRoleOptions).toString() !==
      Object.values(customRoleOptions).toString() ||
    roleAccess !== prevRoleAccess;

  return (
    <SidePopup
      fields={yupSchemaToFields(validationSchema)}
      mode="edit"
      formik={
        formHasChanged
          ? {
              ...formik,
              dirty: true,
              resetForm: clear,
            }
          : formik
      }
      isVisible={isVisible}
      handleClose={handleClose}
      title={t('dashboardComponents.Console.UserManagement.EditRole.title')}
      isOnTop
      hasNoAnimation={noAnimation}
      leftButtonLabel={t('common.reset')}
      rightButtonLabel={t('common.save')}
      switchNext={selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && switchToPrevDataElement}
      onLeftButtonClick={clear}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      isRightButtonDisabled={
        !formik.values.name ||
        (roleAccess ===
          t(
            'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
          ) &&
          Object.values(customRoleOptions).every((val) => !val)) ||
        !formHasChanged ||
        isCreatingOrUpdating
      }
    >
      <div className={classes.EditRole}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.EditRole.labels.roleName'
              )}
              height={50}
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditRole.placeholders.roleName'
              )}
              error={formik.errors.name}
              touched={isTriedToSubmit}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
            />
            <div className={classes.innerRow}>
              <div className={classes.innerCol}>
                <RadioButton
                  label={t(
                    'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
                  )}
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
                    )
                  }
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.EditRole.labels.fullAccess'
                      )
                    )
                  }
                />
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                  )}
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                      )
                    )
                  }
                />
              </div>
              <div className={classes.innerCol}>
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
                  )}
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
                      )
                    )
                  }
                />
                <div
                  className={classNames(classes.checkboxes, {
                    [classes.visible]:
                      roleAccess ===
                      t(
                        'dashboardComponents.Console.UserManagement.EditRole.labels.buildYourOwn'
                      ),
                  })}
                >
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageJobs'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageCandidates'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageUsers'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.manageRefData'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.access'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.access'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.access'
                        )
                      )
                    }
                  />
                  {/*  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.EditRole.labels.viewOnlyAccess'
                        )
                      )
                    }
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.col}>
            <Textarea
              label={t('common.notes')}
              height={100}
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditRole.placeholders.notes'
              )}
              error={formik.errors.notes}
              touched={isTriedToSubmit}
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="notes"
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
