import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import CloseIcon from '../../../Candidate/CloseIcon';
import Container from './Container';
import Header from './Header';
import Main from './Main';
import Buttons from './Buttons';

import { useUiContext } from '../../../../../context/UiContext';
import CandidatesService from '../../../../../services/CandidatesService';
import classes from './styles.module.scss';

import {
  detailsMainInputs,
  nameInputs,
  experienceInputs,
  validationSchema,
} from './config';
import {
  createInitialValues,
  createExperienceSubFormInitailValues,
  areAllRequiredFieldsFilled,
} from './helpers';

const filters = ['Details', 'Word Resume', 'Applications'];

let timer;

export default function AddCandidate({
  isVisible,
  setIsVisible,
  candidates = [],
  refetch,
  // showAddCandidatePopup,
}) {
  const [warnings, setWarnings] = useState({});
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [validatedEmail, setValidatedEmail] = useState({
    email: '',
    isValid: true,
  });
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [currentTab, setCurrentTab] = useState(filters[0]);
  const [tabCount, setTabCount] = useState({
    Details: -1,
    'Word Resume': -1,
    Applications: 0,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);

  const containerRef = useRef();

  const {
    showNotification,
    showModal,
    setIsFetching,
    showConflictModal,
    showUnknownErrorModal,
  } = useUiContext();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const isEdit = !!currentCandidate;

  useEffect(() => {
    if (currentCandidate) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [currentCandidate]);

  let formik;

  const hidePopup = (force) => {
    const close = () => {
      setIsVisible(false);
      setCurrentCandidate(null);
      formik.resetForm();
      setHasTriedToSubmit(false);
      setValidatedEmail({ email: '', isValid: true });
      setCurrentTab(filters[0]);
      setTabCount({
        Details: -1,
        'Word Resume': -1,
        Applications: 0,
      });
      setIsReadOnly(false);
      clearTimeout(timer);
    };

    if (force === true) {
      close();
      return;
    }

    if (formik.dirty && isEdit && !isReadOnly) {
      showModal({
        title: t('common.exitScreen.title'),
        text: t('common.exitScreen.text'),
        confirmButtonLabel: t('common.no'),
        dismissButtonLabel: t('common.yes'),
        onConfirm: () => {},
        onCancel: close,
      });

      return;
    }

    close();
  };

  const isResumeUploaded = () => {
    if (
      !formik.values.userProfileFileResume ||
      !formik.values.userProfileFileResume?.url
    ) {
      showModal({
        title: t(
          'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Resume Mandatory'
        ),
        text: t(
          'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.pleaseUpload'
        ),
        dismissButtonLabel: t('common.OK'),
        dismissButtonVariant: 'modal',
        onCancel: () => {},
      });

      setCurrentTab('Word Resume');

      return false;
    }
    return true;
  };

  const createCandidate = async (values) => {
    if (!isResumeUploaded()) {
      return;
    }

    try {
      setIsFetching(true);
      await CandidatesService.createCandidate(values);
      refetch();

      hidePopup();

      showNotification({
        text: t(
          'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Candidate added successfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const updateCandidate = async (values) => {
    if (!isResumeUploaded()) {
      return;
    }

    try {
      setIsFetching(true);
      await CandidatesService.updateCandidate({
        ...values,
        id: currentCandidate.id,
      });
      refetch();

      hidePopup(true);

      showNotification({
        text: t(
          'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Candidate updated successfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteCandidate = async () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Delete Candidate/s'
      ),
      text: t('common.deleteConfirmation'),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await CandidatesService.deleteCandidate(currentCandidate.id);
          showNotification({
            text: t('Candidate deleted successfully'),
          });
          refetch();
          hidePopup(true);
        } catch (error) {
          console.log(error);
          if (error.response.status === 409) {
            showConflictModal(error.response.data.message);
          } else {
            showUnknownErrorModal();
          }
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  formik = useFormik({
    initialValues: createInitialValues(
      detailsMainInputs,
      nameInputs,
      currentCandidate
    ),
    validationSchema,
    onSubmit: isEdit ? updateCandidate : createCandidate,
    enableReinitialize: true,
  });

  const addExperienceSubForm = () => {
    formik.setFieldValue('userProfileExperiences', [
      ...formik.values.userProfileExperiences,
      createExperienceSubFormInitailValues(experienceInputs),
    ]);
  };

  // Update applications count
  useEffect(() => {
    if (currentCandidate) {
      setTabCount((prevState) => ({
        ...prevState,
        Applications: currentCandidate?.jobApplications?.length || 0,
      }));
    } else {
      setTabCount((prevState) => ({ ...prevState, Applications: 0 }));
    }
  }, [currentCandidate]);

  // Set current candidate if the user is editing
  useEffect(() => {
    if (candidates.length) {
      setCurrentCandidate(candidates[0]);
    } else {
      setCurrentCandidate(null);
    }
  }, [candidates]);

  useEffect(() => {
    // Check if the user is less than 16 years old
    const birthDate = new Date(formik.values.birthDate);
    const today = new Date();
    const diff = today - birthDate;
    const age = Math.floor(diff / 31557600000);

    if (age < 16) {
      setWarnings((prev) => ({
        ...prev,
        birthDate: 'Less than 16 years old.',
      }));
    } else {
      setWarnings((prev) => ({ ...prev, birthDate: '' }));
    }
  }, [formik.values.birthDate]);

  // Add experience sub form if the user has experience
  useEffect(() => {
    if (
      currentCandidate &&
      currentCandidate.userProfile?.userProfileExperiences?.length
    ) {
      return;
    }

    if (formik.values.hasExperience?.value) {
      addExperienceSubForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.hasExperience, currentCandidate]);

  // Remove experience sub form if the user has no experience
  useEffect(() => {
    if (!formik.values.hasExperience?.value) {
      formik.setFieldValue('userProfileExperiences', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.hasExperience]);

  // Validate email on input (when email is valid and user stopped typing)
  useEffect(() => {
    if (!formik.touched.email && currentCandidate) {
      setValidatedEmail({ email: formik.values.email, isValid: true });
      return;
    }

    if (formik.values.email === currentCandidate?.userProfile?.email) {
      setValidatedEmail({ email: formik.values.email, isValid: true });
      return;
    }

    if (validatedEmail.email === formik.values.email) {
      return;
    }

    setValidatedEmail({ email: '', isValid: true });

    const validateEmail = async () => {
      try {
        await CandidatesService.checkCandidateEmail(formik.values.email);

        setValidatedEmail({ email: formik.values.email, isValid: true });
      } catch (error) {
        if (error?.response?.data?.message === 'Duplicity') {
          setValidatedEmail({ email: formik.values.email, isValid: false });
        }
      }
    };

    clearTimeout(timer);

    if (formik.values.email && !formik.errors.email) {
      timer = setTimeout(() => {
        validateEmail();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.email,
    formik.errors.email,
    formik.touched.email,
    currentCandidate,
    isVisible,
  ]);

  // Scroll component to top on reopening
  useEffect(() => {
    if (isVisible) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [isVisible]);

  const switchCandidate = (direction) => {
    const currentCandidateIndex = candidates.findIndex(
      (candidate) => candidate.id === currentCandidate.id
    );

    if (direction === 'next') {
      if (currentCandidateIndex === candidates.length - 1) {
        setCurrentCandidate(candidates[0]);
      } else {
        setCurrentCandidate(candidates[currentCandidateIndex + 1]);
      }
    }

    if (direction === 'previous') {
      if (currentCandidateIndex === 0) {
        setCurrentCandidate(candidates[candidates.length - 1]);
      } else {
        setCurrentCandidate(candidates[currentCandidateIndex - 1]);
      }
    }
  };

  const canSwitchCandidates = candidates?.length > 1;

  const isPossibleToEdit =
    currentCandidate?.sourceType === 'External' &&
    !currentCandidate?.lastLoginAt;

  return (
    <div
      className={classNames(classes.AddCandidate, {
        [classes.visible]: isVisible,
      })}
    >
      <div className={classes.backdrop} />
      <div className={classes.popup}>
        <div className={classes.closeIconContainer}>
          <CloseIcon onClick={hidePopup} />
        </div>
        <div className={classes.container} ref={containerRef}>
          <div className={classes.innerContainer}>
            <Container style={{ marginBottom: isTabletOrMobile ? 15 : 20 }}>
              <Header
                inputs={nameInputs}
                formik={formik}
                touched={hasTriedToSubmit}
                isReadOnly={isReadOnly}
              />
            </Container>
            <Container
              style={{
                flex: 1,
                borderRadius: isTabletOrMobile ? '16px 16px 0 0' : '',
              }}
            >
              <Main
                detailsMainInputs={detailsMainInputs}
                experienceInputs={experienceInputs}
                formik={formik}
                warnings={warnings}
                addExperienceSubForm={addExperienceSubForm}
                touched={hasTriedToSubmit}
                validatedEmail={validatedEmail}
                filters={filters}
                tabCount={tabCount}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                currentCandidate={currentCandidate}
                isPossibleToEdit={isPossibleToEdit}
                isReadOnly={isReadOnly}
              />
            </Container>
            <Buttons
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              areAllRequiredFieldsFilled={areAllRequiredFieldsFilled(
                formik,
                validationSchema
              )}
              hasResume={formik.values.userProfileFileResume?.url}
              submitForm={() => {
                setHasTriedToSubmit(true);
                formik.handleSubmit();
              }}
              canSwitchCandidates={canSwitchCandidates}
              switchCandidate={switchCandidate}
              isEdit={isEdit}
              resetForm={formik.resetForm}
              isPossibleToEdit={isPossibleToEdit}
              enableEditing={() => setIsReadOnly(false)}
              isReadOnly={isReadOnly}
              deleteCandidate={deleteCandidate}
            />
          </div>
        </div>
      </div>
      <div className={classes.space} />
    </div>
  );
}
