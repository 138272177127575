/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function UserMenu({
  language,
  setLanguage,
  online,
  setOnline,
  hideUserMenu,
  toggleRef,
}) {
  const [isOnlineMenuVisible, setIsOnlineMenuVisible] = useState(false);
  const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);

  const menuRef = useRef();
  useOnClickOutside(menuRef, hideUserMenu, toggleRef);

  const { t } = useTranslation();

  const showOnlineMenu = () => {
    setIsOnlineMenuVisible(true);
  };

  const showLanguageMenu = () => {
    setIsLanguageMenuVisible(true);
  };

  const hideOnlineMenu = () => {
    setIsOnlineMenuVisible(false);
  };

  const hideLanguageMenu = () => {
    setIsLanguageMenuVisible(false);
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setIsLanguageMenuVisible(false);
    hideUserMenu();
  };

  const changeOnline = (status) => {
    setOnline(status);
    setIsOnlineMenuVisible(false);
    hideUserMenu();
  };

  return (
    <div className={classes.UserMenu} ref={menuRef}>
      <ul className={classes.firstLevel}>
        <li
          className={classes.firstLevelOption}
          onMouseEnter={showOnlineMenu}
          onMouseLeave={hideOnlineMenu}
        >
          {t('dashboardComponents.Header.UserMenu.Online')}
        </li>
        <li
          className={classes.firstLevelOption}
          onMouseEnter={showLanguageMenu}
          onMouseLeave={hideLanguageMenu}
        >
          {t('dashboardComponents.Header.UserMenu.Language')}
        </li>
      </ul>
      {isLanguageMenuVisible && (
        <div
          className={classNames(classes.secondLevel, classes.language)}
          onMouseEnter={showLanguageMenu}
          onMouseLeave={hideLanguageMenu}
        >
          <ul>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  language === t('dashboardComponents.Header.UserMenu.English'),
              })}
              onClick={() =>
                changeLanguage(t('dashboardComponents.Header.UserMenu.English'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.English')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  language === t('dashboardComponents.Header.UserMenu.Russian'),
              })}
              onClick={() =>
                changeLanguage(t('dashboardComponents.Header.UserMenu.Russian'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Russian')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  language === t('dashboardComponents.Header.UserMenu.Turkish'),
              })}
              onClick={() =>
                changeLanguage(t('dashboardComponents.Header.UserMenu.Turkish'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Turkish')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  language === t('dashboardComponents.Header.UserMenu.Urdu'),
              })}
              onClick={() =>
                changeLanguage(t('dashboardComponents.Header.UserMenu.Urdu'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Urdu')}
            </li>
          </ul>
        </div>
      )}
      {isOnlineMenuVisible && (
        <div
          className={classNames(classes.secondLevel, classes.online)}
          onMouseEnter={showOnlineMenu}
          onMouseLeave={hideOnlineMenu}
        >
          <ul>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  online === t('dashboardComponents.Header.UserMenu.Online'),
              })}
              onClick={() =>
                changeOnline(t('dashboardComponents.Header.UserMenu.Online'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Online')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  online === t('dashboardComponents.Header.UserMenu.Away'),
              })}
              onClick={() =>
                changeOnline(t('dashboardComponents.Header.UserMenu.Away'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Away')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  online === t('dashboardComponents.Header.UserMenu.Break'),
              })}
              onClick={() =>
                changeOnline(t('dashboardComponents.Header.UserMenu.Break'))
              }
            >
              {t('dashboardComponents.Header.UserMenu.Break')}
            </li>
            <li
              className={classNames(classes.secondLevelOption, {
                [classes.active]:
                  online ===
                  t('dashboardComponents.Header.UserMenu.Do not disturb'),
              })}
              onClick={() =>
                changeOnline(
                  t('dashboardComponents.Header.UserMenu.Do not disturb')
                )
              }
            >
              {t('dashboardComponents.Header.UserMenu.Do not disturb')}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
