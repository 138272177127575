import React, { useRef } from 'react';

import classNames from 'classnames';

import useHoverAndClickStatus from '../../../../hooks/useHoverAndClickStatus';
import classes from './styles.module.scss';

export default function NoAvatar({ children, style }) {
  const noAvatarRef = useRef();

  const { isMouseOver } = useHoverAndClickStatus(noAvatarRef);

  return (
    <div
      style={style}
      ref={noAvatarRef}
      className={classNames(classes.NoAvatar, {
        [classes.hovered]: isMouseOver,
        [classes.notHovered]: !isMouseOver,
      })}
    >
      <span>{children}</span>
    </div>
  );
}
