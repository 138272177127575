/* eslint-disable react/jsx-handler-names */
import React from 'react';

import { useTranslation } from 'react-i18next';

import FormButton from '../../../components/FormButton';

import useOfferStatus from '../hooks/useOfferStatus';
import { useUiContext } from '../../../context/UiContext';

export default function Buttons({
  candidate,
  formik,
  readOnly,
  isDisabled,
  setIsTriedToSubmit,
  counterOfferMode,
  setCounterOfferMode,
  handleClose,
}) {
  const { t } = useTranslation();

  const { showModal } = useUiContext();

  const { offer, isCountered, floatValue } = useOfferStatus({
    candidate,
    counterOfferMode,
    formik,
  });

  const isOfferLowerThanPrev = floatValue < offer?.amount;

  const declineCounterOffer = async () => {
    showModal({
      title: t('modals.OfferModal.declineCounterOfferTitle'),
      text: t('modals.OfferModal.declineCounterOfferText'),
      confirmButtonLabel: t('common.no'),
      dismissButtonLabel: t('common.yes'),
      onConfirm: () => {},
      onCancel: formik.handleSubmit,
    });
  };

  const defaultButtons = (
    <>
      <FormButton
        variant="reset"
        style={{
          width: 150,
          height: 52,
        }}
        onClick={formik.resetForm}
        disabled={readOnly || !formik.dirty}
      >
        {t('modals.OfferModal.clearButton')}
      </FormButton>
      <FormButton
        variant="submit"
        style={{
          width: 150,
          height: 52,
        }}
        disabled={isDisabled}
        onClick={() => {
          setIsTriedToSubmit(true);
          formik.handleSubmit();
        }}
      >
        {t('modals.OfferModal.sendButton')}
      </FormButton>
    </>
  );

  const counterOfferDefaultButtons = (
    <>
      <FormButton
        variant="deactivate"
        style={{
          width: 150,
          height: 52,
        }}
        onClick={() => setCounterOfferMode('decline')}
      >
        {t('common.decline')}
      </FormButton>
      <FormButton
        variant="submit"
        style={{
          width: 150,
          height: 52,
        }}
        onClick={() => {
          setCounterOfferMode('update');
          formik.setFieldValue('amount', offer.counterAmount);
        }}
      >
        {t('common.update')}
      </FormButton>
    </>
  );

  const counterOfferNonDefaultButtons = (
    <>
      <FormButton
        variant="reset"
        style={{
          width: 150,
          height: 52,
        }}
        onClick={() => {
          setCounterOfferMode('default');
          formik.resetForm();
        }}
      >
        {t('common.undo')}
      </FormButton>
      <FormButton
        disabled={isOfferLowerThanPrev && counterOfferMode === 'update'}
        variant="submit"
        style={{
          width: 150,
          height: 52,
        }}
        onClick={
          counterOfferMode === 'decline'
            ? declineCounterOffer
            : formik.handleSubmit
        }
      >
        {t('common.send')}
      </FormButton>
    </>
  );

  let currentButtons = defaultButtons;

  if (isCountered && counterOfferMode === 'default') {
    currentButtons = counterOfferDefaultButtons;
  } else if (isCountered && counterOfferMode !== 'default') {
    currentButtons = counterOfferNonDefaultButtons;
  }

  if (readOnly) {
    currentButtons = (
      <FormButton onClick={handleClose} variant="reset">
        {t('common.close')}
      </FormButton>
    );
  }

  return <footer>{currentButtons}</footer>;
}
