import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';

import { COUNTRY_OPTIONS } from '../../../../../../constants/main';
import { UiContext } from '../../../../../../context/UiContext';
import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

export default function ViewRestaurant({
  isVisible,
  handleClose,
  restaurants,
  currentRestaurantId,
  refetch,
  isOverlayed,
  showEditRestaurant,
}) {
  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(restaurants, currentRestaurantId);

  const { showModal, showUnknownErrorModal, setIsFetching } =
    useContext(UiContext);

  const deleteRestaurant = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.deleteConfirmationTitle'
      ),
      text: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.deleteConfirmationText'
      ),
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteRestaurant({
            id: restaurants[currentDataIndex].id,
          });
          showModal({
            title: t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.deleteSuccessTitle'
            ),
            text: t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.deleteSuccessText'
            ),
            dismissButtonLabel: t('common.gotIt'),
            dismissButtonVariant: '',
          });
          handleClose();
        } catch (error) {
          showUnknownErrorModal();
        } finally {
          await refetch();
          setIsFetching(false);
        }
      },
    });
  };

  let displayedCountry = '';

  const country = COUNTRY_OPTIONS.find(
    (countr) => countr.value === restaurants?.[currentDataIndex]?.country
  );

  if (country) {
    displayedCountry = country.label;
  }

  return (
    <SidePopup
      mode="view"
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.viewRestaurant'
      )}
      leftButtonLabel={t('common.delete')}
      rightButtonLabel={t('common.edit')}
      switchPrev={restaurants?.length > 1 && switchToPrevDataElement}
      switchNext={restaurants?.length > 1 && switchToNextDataElement}
      onLeftButtonClick={deleteRestaurant}
      onRightButtonClick={() =>
        showEditRestaurant(restaurants[currentDataIndex].id)
      }
      isOverlayed={isOverlayed}
    >
      <div className={classes.ViewRestaurant}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.Restaurant Name'
              )}
              value={restaurants?.[currentDataIndex]?.name}
              readonly
              height={50}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.District'
              )}
              readonly
              height={50}
              value={restaurants?.[currentDataIndex]?.district?.name}
            />
          </div>
        </div>
        <div className={classes.row}>
          <TextInput
            width={670}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.Address'
            )}
            value={restaurants?.[currentDataIndex]?.address}
            readonly
            height={50}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.City'
              )}
              value={restaurants?.[currentDataIndex]?.city?.name}
              readonly
              height={50}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.Zipcode'
              )}
              value={restaurants?.[currentDataIndex]?.postCode}
              readonly
              height={50}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.Country'
              )}
              value={displayedCountry}
              readonly
              height={50}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.ViewRestaurant.labels.RGM'
              )}
              value={restaurants?.[currentDataIndex]?.managers?.[0]?.userName}
              readonly
              height={50}
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
