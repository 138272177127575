import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function AuthHeading() {
  const { t } = useTranslation();

  return (
    <h1 className={classes.AuthHeading}>
      {t('pages.LoginPage.welcomeTo')}
      <br /> <span> {t('common.poweredByZaphire')}</span>
    </h1>
  );
}
