/* eslint-disable react/button-has-type */
import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FormButton({
  type,
  children,
  variant,
  style,
  disabled,
  onClick,
}) {
  return (
    <button
      disabled={disabled}
      style={style}
      className={classNames(classes.FormButton, {
        [classes.submit]: variant === 'submit',
        [classes.reset]: variant === 'reset',
        [classes.delete]: variant === 'delete',
        [classes.deactivate]: variant === 'deactivate',
      })}
      type={type || 'button'}
      onClick={(event) => {
        onClick?.();
        event.target.blur();
      }}
    >
      <div className={classes.animatedBackground} />
      <div className={classes.chilredWrapper}>{children}</div>
    </button>
  );
}
