import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Badge({
  badge,
  areBadgesEnabled,
  enabledBadges,
  addTagToJobPost,
}) {
  /* const [isBadgeActive, setIsBadgeActive] = useState(false);

  useEffect(() => {
    setIsBadgeActive(enabledBadges.some(({ id }) => id === badge.id));
  }, [areBadgesEnabled, badge.id, enabledBadges]);

  useEffect(() => {
    if (isBadgeActive) {
      setEnabledBadges((prev) => [...prev, badge]);

      return;
    }

    setEnabledBadges((prev) => {
      const currentBadgeIndex = prev.findIndex((tag) => tag === badge);
      prev.splice(currentBadgeIndex, 1);
      return [...prev];
    });
  }, [badge, isBadgeActive, setEnabledBadges]);

  useEffect(() => {
    if (!areBadgesEnabled) {
      setIsBadgeActive(false);
    }
  }, [areBadgesEnabled]); */

  const isActive = enabledBadges.some(({ id }) => id === badge.id);

  return (
    <div
      onClick={() => {
        if (!areBadgesEnabled) {
          return;
        }
        const updatedBadges = isActive
          ? enabledBadges.filter((b) => b.id !== badge.id)
          : [...enabledBadges, badge];

        addTagToJobPost(updatedBadges);
      }}
      className={classNames(classes.Badge, {
        [classes.active]: isActive,
      })}
    >
      {badge.name}
    </div>
  );
}
