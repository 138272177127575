/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Dropdown({
  options,
  activeOption,
  setActiveOption,
  placeholder,
  width,
  height,
  dangerOption,
  style,
  readOnly,
}) {
  const [animationEnd, setAnimationEnd] = useState(false);
  const [initialLoadFlag, setInitialLoadFlag] = useState(true);

  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  useEffect(() => {
    if (isOptionsListVisible) {
      setInitialLoadFlag(false);
    }
    setTimeout(() => {
      setAnimationEnd(false);
    }, 0);
  }, [isOptionsListVisible]);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));
  const removeFocus = () => {
    dropdownRef.current.blur();
  };

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.Dropdown, {
        [classes.open]: isOptionsListVisible,
        [classes.readOnly]: readOnly,
      })}
      tabIndex={0}
      role="listbox"
      style={{ width }}
    >
      <div
        style={{ ...style, width, height }}
        className={classNames({
          [classes.selector]: true,
          [classes.dropdownActive]: isOptionsListVisible,
        })}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {activeOption ? (
          <span className={classes.hasOption}>{activeOption.label}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      {!initialLoadFlag && (
        <div
          className={classNames({
            [classes.options]: true,
            [classes.animation]: isOptionsListVisible,
            [classes.animationReverse]: !isOptionsListVisible,
            [classes.hiddenDisplay]: animationEnd && !isOptionsListVisible,
          })}
          onAnimationEnd={() => setAnimationEnd(true)}
        >
          <ul>
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => {
                  setActiveOption(option);
                  setIsOptionsListVisible(false);
                  removeFocus();

                  if (option.onClick) {
                    option.onClick();
                  }
                }}
                className={classNames({
                  [classes.active]: activeOption.value === option.value,
                  [classes.danger]: dangerOption === option.value,
                  [classes.withDivider]: option.withDivider,
                })}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
