/* eslint-disable react/no-array-index-key */
import React from 'react';

import classes from './styles.module.scss';
import Stage from './Stage';

// const stages = ['New', 'Screen', 'Interview', 'Offer', 'Onboard'];

const statuses = {
  Invited: {
    Pending: [
      { stageName: 'New', class: 'pending' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Rejected: [
      { stageName: 'New', class: 'rejected' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'rejected' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Accepted: [
      { stageName: 'New', class: 'current' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
  },
  New: {
    Pending: [
      { stageName: 'New', class: 'pending' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Rejected: [
      { stageName: 'New', class: 'rejected' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'rejected' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Accepted: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Declined: [
      { stageName: 'New', class: 'declined' },
      { stageName: 'Screen', class: '' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
  },
  Screen: {
    Pending: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'pending' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Requested: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'requested' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Completed: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'current' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Passed: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'current' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Failed: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'rejected' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Rejected: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'rejected' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Incomplete: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'rejected' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'rejected' },
      { stageName: 'Interview', class: '' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
  },
  Interview: {
    Pending: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'pending' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    ChangeRequested: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'declined' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Rescheduled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'requested' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Scheduled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'requested' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Accepted: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'requested' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Declined: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'rejected' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Canceled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'declined' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Passed: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'current' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Rejected: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'rejected' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'rejected' },
      { stageName: 'Offer', class: '' },
      { stageName: 'Onboard', class: '' },
    ],
  },
  Offer: {
    Pending: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'pending' },
      { stageName: 'Onboard', class: '' },
    ],
    Offered: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'requested' },
      { stageName: 'Onboard', class: '' },
    ],
    Accepted: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'current' },
      { stageName: 'Onboard', class: '' },
    ],
    CounterAccepted: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'requested' },
      { stageName: 'Onboard', class: '' },
    ],
    Rejected: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'rejected' },
      { stageName: 'Onboard', class: '' },
    ],
    Declined: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'rejected' },
      { stageName: 'Onboard', class: '' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'rejected' },
      { stageName: 'Onboard', class: '' },
    ],
    Countered: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'declined' },
      { stageName: 'Onboard', class: '' },
    ],
  },
  Onboard: {
    Scheduled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'requested' },
    ],
    Rescheduled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'requested' },
    ],
    Pending: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'pending' },
    ],
    Onboarded: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'current' },
    ],
    Joined: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'completed' },
    ],
    Accepted: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'current' },
    ],
    StartDateSet: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'current' },
    ],
    StartConfirmed: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'current' },
    ],
    StartDeclined: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'rejected' },
    ],
    Declined: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'rejected' },
    ],
    NoShow: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'rejected' },
    ],
    Canceled: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'declined' },
    ],
    Rejected: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'rejected' },
    ],
    ChangeRequested: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'declined' },
    ],
    Withdrawn: [
      { stageName: 'New', class: 'completed' },
      { stageName: 'Screen', class: 'completed' },
      { stageName: 'Interview', class: 'completed' },
      { stageName: 'Offer', class: 'completed' },
      { stageName: 'Onboard', class: 'rejected' },
    ],
  },
};

export default function HiringStages({ status, subStatus, hasAssessment }) {
  if (!hasAssessment) {
    let stageIndex = 0;
    return (
      <div className={classes.HiringStages}>
        {statuses[status]?.[subStatus]?.map((stage, index) => {
          if (stage.stageName === 'Screen') {
            return null;
          }

          if (index !== 0) {
            stageIndex += 1;
          }
          return (
            <Stage
              key={stageIndex}
              stageName={stage.stageName}
              index={stageIndex}
              className={stage.class}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.HiringStages}>
      {statuses[status]?.[subStatus]?.map((stage, index) => {
        return (
          <Stage
            key={index}
            stageName={stage.stageName}
            index={index}
            className={stage.class}
          />
        );
      })}
    </div>
  );
}
