import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import SocialButtons from '../../components/Dashboard/Jobs/SocialButtons';
import JobPostPreviewModal from '../JobPostPreviewModal';
import Button from '../../components/Button';
import MultiDropdown from '../../components/Form/MultiDropdown';
import FavStatus from '../../components/Dashboard/Header/Search/FavStatus';
import AdminService from '../../services/AdminService';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import Badges from './Badges';
import QRCodeGenerator from '../../components/Dashboard/QRCodeGenerator';
import CopyJobLinkButton from '../../components/Dashboard/Jobs/CopyJobLinkButton';

import UserService from '../../services/UserService';
import { useUserStore } from '../../store';
import { JOBS_PORTAL_URL } from '../../constants/main';
import classes from './styles.module.scss';

export default function PostJobSuccessModal({
  show,
  handleClose,
  createdJobPost,
}) {
  const [invitedUsers, setInvitedUsers] = useState('');
  const [isJobPostPreviewModalVisible, setIsJobPostPreviewModalVisible] =
    useState(false);

  const { t } = useTranslation();

  const isDiscoveryPage = window.location.pathname.includes('discover');

  const userScopes = useUserStore((state) => state.userScopes);

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const isOwner = user?.id === createdJobPost?.user?.id;
  const isAdmin =
    userScopes.includes('api:full') || userScopes.includes('api:admin:jobs');

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={classes.PostJobSuccessModal}
        backdropClassName={classes.backdrop}
        contentClassName={classes.modalContent}
        dialogClassName={classes.dialog}
      >
        <div className={classes.content}>
          <header className={classes.header}>
            {!isDiscoveryPage && (
              <>
                <h1>{t('modals.PostJobSuccessModal.modalTitle')}</h1>
                <span className={classes.live}>
                  {t('modals.PostJobSuccessModal.liveMessage')}
                </span>
              </>
            )}
            <i className={classes.closeIcon} onClick={handleClose}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.91444 5.02566L9.91964 1.02045C10.2125 0.727558 10.6874 0.727558 10.9803 1.02045C11.2732 1.31334 11.2732 1.78822 10.9803 2.08111L6.9751 6.08632L10.9803 10.0915C11.2732 10.3844 11.2732 10.8593 10.9803 11.1522C10.6874 11.4451 10.2125 11.4451 9.91964 11.1522L5.91444 7.14698L1.90924 11.1522C1.61634 11.4451 1.14147 11.4451 0.848576 11.1522C0.555683 10.8593 0.555683 10.3844 0.848576 10.0915L4.85378 6.08632L0.848576 2.08111C0.555683 1.78822 0.555683 1.31334 0.848576 1.02045C1.14147 0.727558 1.61634 0.727558 1.90924 1.02045L5.91444 5.02566Z"
                  fill="#242833"
                />
              </svg>
            </i>
          </header>
          <div className={classes.jobDetails}>
            <div className={classes.col}>
              <h2>
                {createdJobPost?.jobTitle}{' '}
                <div className={classes.favoriteIconContainer}>
                  <FavStatus job={createdJobPost} noRefetch />
                </div>
              </h2>
              <span className={classes.location}>
                {createdJobPost?.locationCity?.name},{' '}
                {createdJobPost?.locationCityDistrict?.name},{' '}
                {createdJobPost?.locationCity?.country}
              </span>
            </div>
            <div className={classes.col}>
              <h3>{t('modals.PostJobSuccessModal.jobDetails.jobId')}</h3>
              <span className={classes.jobId}>{createdJobPost?.id}</span>
            </div>
          </div>
          <div className={classes.settings}>
            <div className={classes.col}>
              <Badges
                job={createdJobPost}
                readOnly={!isAdmin && !isOwner}
                isDiscoveryPage={isDiscoveryPage}
              />
              <div className={classes.share}>
                <div className={classes.selectContainer}>
                  <MultiDropdown
                    readOnly={!isAdmin && !isOwner}
                    maxLength={24}
                    width={311}
                    height={45}
                    labelStyle={{
                      fontSize: '16px',
                      fontFamily: 'Gilroy-SemiBold',
                    }}
                    label={t(
                      'modals.PostJobSuccessModal.settings.shareWithTeam'
                    )}
                    fetchOptions={{
                      dataName: 'userOptionPostJobSuccess',
                      getDataHandler: AdminService.getUsers,
                      labelName: 'userName',
                      isUserList: true,
                    }}
                    value={invitedUsers}
                    setFieldValue={(_name, option) => setInvitedUsers(option)}
                    placeholder={t(
                      'modals.PostJobSuccessModal.settings.placeholder'
                    )}
                  />
                  <Button
                    disabled={!invitedUsers}
                    width={85}
                    height={45}
                    style={{
                      borderRdius: '16px',
                      fontSize: '14px',
                    }}
                  >
                    {t('modals.PostJobSuccessModal.settings.shareButton')}
                  </Button>
                </div>
                <SelectedUsers
                  invitedUsers={invitedUsers}
                  setInvitedUsers={setInvitedUsers}
                />
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.qrContainer}>
                <div
                  className={classes.qrInnerContainer}
                  onClick={() => {
                    window.location.href = `mailto:?subject=&body=${`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}`;
                  }}
                >
                  <div className={classes.qr}>
                    <QRCodeGenerator
                      imageSize={{
                        width: 60,
                        height: 33,
                      }}
                      size={110}
                      value={`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}
                    />
                  </div>
                  <h4>{createdJobPost?.jobTitle}</h4>
                </div>
                <span className={classes.tip}>
                  {t('modals.PostJobSuccessModal.qrCodeTip')}
                </span>
              </div>
            </div>
          </div>
          <footer className={classes.footer}>
            <SocialButtons
              jobPostId={createdJobPost?.id}
              jobTitle={createdJobPost?.jobTitle}
            />
            <div className={classes.copyButtonContainer}>
              <button
                type="button"
                className={classes.previewJobButton}
                onClick={() => setIsJobPostPreviewModalVisible(true)}
              >
                Preview Job
              </button>
              <CopyJobLinkButton jobPostId={createdJobPost?.id} />
              {/* <Tooltip
                text={t('modals.PostJobSuccessModal.copyButton.tooltip')}
                width={151}
                direction="top"
              >
                <button
                  className={classes.copyButton}
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`
                    );
                    showNotification({
                      text: t('modals.PostJobSuccessModal.previewButton'),
                    });
                  }}
                >
                  <svg
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13" r="13" fill="#7A5BE9" />
                    <mask
                      id="mask0_780_13380"
                      style={{ maskType: 'alpha' }}
                      maskUnits="userSpaceOnUse"
                      x="7"
                      y="5"
                      width="13"
                      height="15"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 5.51562H20V19.0131H7V5.51562Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_780_13380)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.5825 13.3311C15.5036 14.4513 13.7784 14.5133 12.637 13.4937C12.4069 13.2911 12.3784 12.933 12.5735 12.6939C12.7687 12.4547 13.1136 12.4251 13.3438 12.6279C13.3451 12.6288 13.3463 12.6299 13.3474 12.6311C14.0658 13.2726 15.1267 13.2423 15.8119 12.5309L18.3806 9.86393C19.0989 9.11826 19.0989 7.94262 18.3806 7.19694C17.6624 6.45127 16.53 6.45127 15.812 7.19694L13.3716 9.7306C13.1618 9.95483 12.8169 9.95992 12.6011 9.74211C12.3853 9.52429 12.3804 9.16616 12.5902 8.94193C12.5936 8.93816 12.5974 8.93439 12.6011 8.93062L15.0414 6.39696C16.1731 5.22188 18.0195 5.22188 19.1512 6.39677C20.283 7.57185 20.283 9.48902 19.1512 10.6641L16.5825 13.3311ZM11.9587 18.1322C10.827 19.3073 8.98045 19.3073 7.84867 18.1324C6.71708 16.9571 6.71708 15.0402 7.84885 13.8651L10.4176 11.1981C11.4967 10.0777 13.2217 10.0158 14.3629 11.0355C14.5921 11.2396 14.6184 11.5977 14.4219 11.8357C14.2257 12.0729 13.8817 12.101 13.6525 11.8981C12.9343 11.2566 11.8732 11.2869 11.1881 11.9981L8.61935 14.6651C7.90117 15.4107 7.90117 16.5864 8.61935 17.3321C9.33754 18.0777 10.4699 18.0777 11.1881 17.3321L13.6283 14.7984C13.8385 14.5747 14.1836 14.57 14.399 14.7882C14.6144 15.0064 14.6188 15.3647 14.4086 15.5884C14.4056 15.5918 14.4023 15.5952 14.399 15.5986L11.9587 18.1322Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  {t('modals.PostJobSuccessModal.copyButton.copyLink')}
                </button>
              </Tooltip> */}
            </div>
          </footer>
        </div>
      </Modal>
      <JobPostPreviewModal
        show={isJobPostPreviewModalVisible}
        handleClose={() => setIsJobPostPreviewModalVisible(false)}
        jobPostId={createdJobPost?.id}
      />
    </>
  );
}
