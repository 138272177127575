import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import FormButton from '../../FormButton';

import classes from './styles.module.scss';
import arrowIcon from '../../../assets/images/auth/arrow.svg';

export default function InnerHeader({
  label,
  title,
  onButtonClick,
  noButton,
  withBackButton,
}) {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.InnerHeader}>
      <div className={classes.titleContainer}>
        {withBackButton && (
          <button
            onClick={() => navigate(-1)}
            className={classes.backButton}
            type="button"
          >
            <img src={arrowIcon} alt="Back" />
          </button>
        )}
        <h2 className={classes.signin}>{title}</h2>
      </div>
      {!noButton && (
        <div className={classes.buttonContainer}>
          <FormButton
            onClick={onButtonClick}
            variant="reset"
            style={{
              width: isTabletOrMobile ? 130 : 175,
              fontSize: 14,
              fontFamily: 'Gilroy-SemiBold',
            }}
          >
            {label}
          </FormButton>
        </div>
      )}
    </div>
  );
}
