import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import SearchDropdown from './SearchDropdown';
import TopMenu from './TopMenu';
import UserMenu from './UserMenu';

import getResizedImageUrl from '../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';
import UserService from '../../../services/UserService';
import AdminService from '../../../services/AdminService';
import logo from '../../../assets/images/logo-small.png';
import searchIcon from '../../../assets/images/dashboard/header/search.svg';
import burgerIcon from '../../../assets/images/dashboard/header/burger.svg';
import { useUiStore } from '../../../store';

let isClicked = false;

export default function Header() {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [language, setLanguage] = useState('English');
  const [online, setOnline] = useState('Online');
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);

  const toggleMobileSidebar = useUiStore((state) => state.toggleMobileSidebar);

  const { t } = useTranslation();

  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({
    query: location.pathname.includes('discover')
      ? '(max-width: 1224px)'
      : '(max-width: 1024px)',
  });

  const { data } = useQuery({ queryKey: ['me'], queryFn: UserService.getMe });

  const { data: tenantSettings } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const userPicRef = useRef();

  // Hide mobile search on route change
  useEffect(() => {
    setIsMobileSearchVisible(false);
  }, [location.pathname]);

  const logoUrl = tenantSettings?.brand?.logo
    ? getResizedImageUrl({
        url: `brand/${tenantSettings.brand.logo.split('/').pop()}`,
        width: 300,
      })
    : logo;

  return (
    <header
      className={classNames(classes.Header, {
        [classes.mobile]: isTabletOrMobile,
      })}
    >
      <img src={logoUrl} alt="Logo" className={classes.logo} />
      <div className={classes.organization}>
        <span>{t('dashboardComponents.Header.Your Organization')}</span>
        <div className={classes.dropdown}>
          {t('dashboardComponents.Header.Isthanbul')}
        </div>
      </div>
      <SearchDropdown
        setIsUserMenuVisible={setIsUserMenuVisible}
        isTabletOrMobile={isTabletOrMobile}
        isVisibleOnMobile={isMobileSearchVisible}
      />
      <div className={classes.topMenuContainer}>
        <TopMenu />
      </div>
      <div
        className={classNames(classes.userBlock, {
          [classes.openMenu]: isUserMenuVisible,
        })}
      >
        <button
          draggable="false"
          className={classes.searchButton}
          type="button"
          onClick={() => {
            if (!isClicked) {
              setIsMobileSearchVisible((prevState) => !prevState);
              isClicked = true;
              setTimeout(() => {
                isClicked = false;
              }, 200);
            }
          }}
        >
          <img src={searchIcon} alt="Search" />
        </button>
        <div className={classes.info}>
          <span className={classes.name}>
            {t('dashboardComponents.Header.Hi')},{' '}
            <span>
              {data?.userProfile?.firstName} {data?.userProfile?.lastName}
            </span>
          </span>
          <span className={classes.role}>{data?.roles?.[0]?.name}</span>
        </div>
        <div
          className={classes.userpic}
          onClick={() => setIsUserMenuVisible((prevState) => !prevState)}
          ref={userPicRef}
        >
          {data?.userProfile?.avatar ? (
            <img
              src={getResizedImageUrl({
                url: `avatar/${data?.userProfile?.avatar}`,
                width: 110,
              })}
              alt="User"
            />
          ) : (
            <div className={classes.noUserpic}>
              {`${data?.userProfile?.firstName[0] || ''}${
                data?.userProfile?.lastName[0] || ''
              }`}
            </div>
          )}
        </div>
        <button
          className={classes.burgerButton}
          type="button"
          onClick={toggleMobileSidebar}
        >
          <img src={burgerIcon} alt="Burger" />
        </button>
        {isUserMenuVisible && (
          <UserMenu
            language={language}
            setLanguage={setLanguage}
            online={online}
            setOnline={setOnline}
            hideUserMenu={() => setIsUserMenuVisible(false)}
            toggleRef={userPicRef}
          />
        )}
      </div>
    </header>
  );
}
