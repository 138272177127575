/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import Calendar from '../components/Calendar';

import useOnClickOutside from './useOnClickOutside';

export default function useDropdown({
  currentOption,
  setCurrentOption,
  options,
  classes,
  placeholder,
  calendar,
  useValuesAsLabels,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();
  const optionsRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const toggleOptionsList = () => {
    setIsOptionsListVisible((prevState) => !prevState);
  };

  const selectOption = (option) => {
    setIsOptionsListVisible(false);
    setCurrentOption?.(option);
  };

  const listItems = options?.map((option) => (
    <li
      className={classNames(classes.option, {
        [classes.active]: option.value === currentOption?.value,
      })}
      onClick={() => selectOption(option)}
      key={option.value}
    >
      <span>{useValuesAsLabels ? option.value : option.label}</span>
    </li>
  ));

  const optionsContainer = calendar ? (
    <div className={classNames(classes.optionsContainer, classes.calendar)}>
      <ul className={classes.options} ref={optionsRef}>
        <Calendar
          onlyPastDatesWithCurrentDate
          selectRange
          value={currentOption}
          setValue={setCurrentOption}
        />
      </ul>
    </div>
  ) : (
    <div className={classes.optionsContainer}>
      <ul className={classes.options} ref={optionsRef}>
        {listItems}
      </ul>
    </div>
  );

  let label = currentOption?.label;

  if (calendar) {
    label = `${moment(currentOption?.[0]).format('DD MMM')} - ${moment(
      currentOption?.[1]
    ).format('DD MMM')}`;
  }

  const selector = (
    <div className={classes.selector} onClick={toggleOptionsList}>
      {currentOption ? (
        <span className={classes.value}>{label}</span>
      ) : (
        <span className={classes.placeholder}>{placeholder}</span>
      )}
    </div>
  );

  const dropdown = (
    <div
      className={classNames(classes.Dropdown, {
        [classes.open]: isOptionsListVisible,
      })}
      ref={dropdownRef}
    >
      {selector}
      {isOptionsListVisible && optionsContainer}
    </div>
  );

  useEffect(() => {
    if (optionsRef.current && isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `${optionsRef.current.clientHeight}px`
      );
    }
  }, [isOptionsListVisible]);

  return dropdown;
}
