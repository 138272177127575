import React, { useState } from 'react';

import classes from './styles.module.scss';
import passwordIcon from '../../../../../assets/images/dashboard/profile-settings/password.svg';

export default function PasswordInput({
  label,
  name,
  value,
  onChange,
  readOnly,
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <div className={classes.PasswordInput}>
      <label htmlFor={name} className={classes.label}>
        {label}
      </label>
      <input
        name={name}
        id={name}
        type={isPasswordVisible ? 'text' : 'password'}
        className={classes.input}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      <button
        type="button"
        className={classes.passwordButton}
        onClick={() => setIsPasswordVisible((prevState) => !prevState)}
      >
        <img src={passwordIcon} alt="Password icon" draggable="false" />
      </button>
    </div>
  );
}
