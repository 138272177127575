import React, { useState, useContext, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import RICIBs from 'react-individual-character-input-boxes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';
import InnerHeader from '../../components/Auth/InnerHeader';

import { saveAuthData } from '../../helpers/authStorage';
import { AuthContext } from '../../context/AuthContext';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import classes from './styles.module.scss';

const inputProps = {
  className: classes.input,
  placeholder: 'x',
};

export default function OtpSignInPage() {
  const [otp, setOtp] = useState('');

  const { email } = useContext(AuthContext);
  const { showUnknownErrorModal, showModal } = useContext(UiContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleOutput = (string) => {
    setOtp(string);
  };

  const requestNewOtp = async () => {
    try {
      setOtp('');
      await UserService.requestOtp({ email });
      showModal({
        title: t('pages.OtpSignInPage.codeSentTitle'),
        text: t('pages.OtpSignInPage.codeSentText'),
        dismissButtonVariant: 'modal',
      });
    } catch (error) {
      showUnknownErrorModal();
    }
  };

  const signIn = async () => {
    try {
      const response = await UserService.loginByOtp({ email, code: otp });
      saveAuthData({
        accessToken: response.token,
        userName: response.userName,
        userId: response.id,
      });
      navigate('/tools/discover');
    } catch (error) {
      if (error.response.status === 401) {
        showModal({
          title: t('pages.OtpSignInPage.incorrectOtpTitle'),
          text: t('pages.OtpSignInPage.incorrectOtpText'),
          dismissButtonLabel: t('pages.OtpSignInPage.resendOTP'),
        });
      } else if (
        error.response.status === 400 &&
        error.response.data.message === 'Expireded'
      ) {
        showModal({
          title: t('pages.OtpSignInPage.expiredOtpTitle'),
          text: t('pages.OtpSignInPage.expiredOtpText'),
          dismissButtonLabel: t('pages.OtpSignInPage.resendOTP'),
          dismissButtonVariant: 'modal',
          onCancel: requestNewOtp,
        });
      } else {
        showUnknownErrorModal();
      }
    }
  };

  useEffect(() => {
    const requestOtp = async () => {
      try {
        const response = await UserService.requestOtp({ email });
        console.log(response);
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    };

    requestOtp();
  }, [email, showUnknownErrorModal]);

  // Reset inputs when otp is empyt
  useEffect(() => {
    if (!otp) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => {
        // eslint-disable-next-line no-param-reassign
        input.value = '';
      });
    }
  }, [otp]);

  return (
    <div className={classes.OtpSignInPage}>
      <Helmet>
        <title>{t('pages.OtpSignInPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        {/* <h1>{t('pages.OtpSignInPage.enterOtpCode')}</h1> */}
        <InnerHeader
          withBackButton
          title={t('pages.OtpSignInPage.pageTitle')}
          noButton
        />
        <div className={classes.inputs}>
          <span className={classes.label}>
            {t('pages.OtpSignInPage.enterOtpCode')}
          </span>
          <RICIBs
            amount={6}
            handleOutputString={handleOutput}
            inputProps={Array.from(Array(10).keys()).map(() => inputProps)}
            // inputRegExp={/^[a-zA-Z0-9_]+$/}
          />
        </div>
        <div className={classes.helper}>
          <p>
            {t('pages.OtpSignInPage.didNotReceiveCode')}{' '}
            <span onClick={requestNewOtp}>
              {t('pages.OtpSignInPage.resendCode')}
            </span>
          </p>
          <p>{t('pages.OtpSignInPage.checkSpamFolder')}</p>
        </div>
        <ButtonWithHelperText
          onClick={signIn}
          noHelper
          buttonLabel={t('common.verify')}
          isDisabled={otp.length !== 6}
        />
      </div>
    </div>
  );
}
