import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function ContactInfo({ event }) {
  const { t } = useTranslation();

  if (!event?.users?.length && !event?.event?.note?.length) {
    return null;
  }

  return (
    <div className={classes.ContactInfo}>
      <div className={classes.contacts}>
        <span className={classes.title}>
          {t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Point of contact'
          )}
        </span>
        <div className={classes.users}>
          {event?.users?.map((user) => {
            const email = user?.userName || user?.userProfile?.email || 'n/a';
            const { firstName, lastName } = user?.userProfile;

            return (
              <div className={classes.user} key={user}>
                <div
                  className={classes.photo}
                >{`${firstName[0]}${lastName[0]}`}</div>
                <span className={classes.userStatus} />
                <span
                  className={classes.email}
                >{`${firstName} ${lastName} (${email})`}</span>
                <span className={classes.contStatus}>
                  {
                    event?.invites?.find(({ userId }) => user?.id === userId)
                      ?.status
                  }
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.messageToCandidate}>
        <span className={classes.title}>
          {t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.Message to candidate'
          )}
        </span>
        <div className={classes.input}>
          {event?.event?.note || (
            <span className={classes.placeholder}>
              Enter any notes you want to share
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
