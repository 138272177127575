import React from 'react';

import classes from './styles.module.scss';

export default function Slide({ title, text, image, width, height }) {
  return (
    <div
      className={classes.Slide}
      style={{ backgroundImage: `url(${image})`, width, height }}
    >
      <div className={classes.content}>
        <h1>{title}</h1>
        <p className={classes.text}>{text}</p>
      </div>
    </div>
  );
}
