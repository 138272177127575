import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function NumberInput({
  label,
  value,
  name,
  setFieldValue,
  labelHeight,
  mode,
  touched,
  error,
  warning,
  note,
  height,
  minValue,
  labelStyle,
  withBackground,
  readOnly,
}) {
  const changeInputValue = (event) => {
    let val = event.target.value.replace(/\D/g, '');
    val = parseInt(val, 10) || 0;
    if (mode === 'percents') {
      if (val > 100) {
        val = 100;
      }
    }

    if (mode === 'limit' || name === 'duration') {
      if (val > 99) {
        val = 99;
      }

      if (val < 1) {
        val = minValue;
      }
    }

    setFieldValue(name, val, '');
  };

  const increaseValue = () => {
    const prevValue = parseInt(value, 10);
    let newValue = prevValue === 9999 ? 9999 : prevValue + 1;
    if (mode === 'percents') {
      if (newValue > 100) {
        newValue = 100;
      }
    }

    if (mode === 'limit' || name === 'duration') {
      if (newValue > 99) {
        newValue = 99;
      }
    }
    setFieldValue(name, newValue);
  };

  const decreaseValue = () => {
    const prevValue = parseInt(value, 10);
    let newValue = prevValue === 0 ? 0 : prevValue - 1;

    if (mode === 'limit') {
      if (newValue < 1) {
        newValue = 1;
      }
    }

    if (mode === 'duration') {
      if (newValue < 30) {
        newValue = 30;
      }
    }

    if (newValue < minValue) {
      newValue = minValue;
    }

    setFieldValue(name, newValue);
  };

  const checkInputValue = () => {
    if (mode === 'duration') {
      if (value < 30) {
        setFieldValue(name, 30);
      }
    }
  };

  return (
    <div
      className={classNames(classes.NumberInput, {
        [classes.withBackground]: withBackground,
        [classes.readOnly]: readOnly,
      })}
    >
      <span
        style={{ height: labelHeight, ...labelStyle }}
        className={classes.label}
      >
        {label} {note && <span className={classes.note}>({note})</span>}
      </span>
      <div className={classes.inputContainer} style={{ height }}>
        <i className={classes.minusButton} onClick={decreaseValue}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 12L6 12"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </i>
        {touched && error && !warning && (
          <div className={classes.error}>{error}</div>
        )}
        {warning && (
          <div className={classNames(classes.error, classes.warning)}>
            {warning}
          </div>
        )}
        <input
          type="text"
          name={name}
          className={classNames(
            {
              [classes.amber]:
                (mode === 'percents' && value >= 50 && value < 80) ||
                (mode === 'duration' && (value > 90 || value <= 120)),
            },
            {
              [classes.red]:
                (mode === 'percents' && value < 50) ||
                (mode === 'limit' && (value >= 10 || value <= 2)) ||
                (mode === 'duration' && value > 120),
            }
          )}
          value={mode === 'percents' ? `${parseInt(value, 10)}%` : value}
          onChange={changeInputValue}
          maxLength={4}
          onBlur={checkInputValue}
        />
        <i className={classes.plusButton} onClick={increaseValue}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6L12 18"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M18 12L6 12"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      </div>
    </div>
  );
}
