import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';

import Badge from './Badge';
import Switch from '../../../components/Switch';

import { UiContext } from '../../../context/UiContext';
import AdminService from '../../../services/AdminService';
import classes from './styles.module.scss';

export default function Badges({ job, readOnly, isDiscoveryPage }) {
  const [areBadgesEnabled, setAreBadgesEnabled] = useState(false);
  const [enabledBadges, setEnabledBadges] = useState([]);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: () =>
      AdminService.getTags({
        pageSize: 50,
      }),
  });

  const addTagToJobPost = async (badges) => {
    try {
      setIsFetching(true);
      setEnabledBadges(badges);

      await AdminService.addTagToJobPost({
        id: job?.id,
        jobPostTagIds: badges.map((tag) => tag.id),
      });

      if (isDiscoveryPage) {
        queryClient.invalidateQueries(['jobs']);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (job?.jobPostTags?.length) {
      setAreBadgesEnabled(true);
    }

    setEnabledBadges(job?.jobPostTags || []);
  }, [job]);

  return (
    <div className={classes.badgesContainer}>
      <Switch
        labelStyle={{
          fontFamily: 'Gilroy-SemiBold',
        }}
        label={t('modals.PostJobSuccessModal.Badges.enableBadgesLabel')}
        isEnabled={areBadgesEnabled}
        onClick={() => {
          if (readOnly) {
            return;
          }

          if (areBadgesEnabled) {
            addTagToJobPost([]);
          }

          setAreBadgesEnabled((prevState) => !prevState);
        }}
      />
      <div
        className={classNames(classes.badges, {
          [classes.active]: areBadgesEnabled,
        })}
      >
        {tags?.data?.map?.((tag) => (
          <Badge
            key={tag.id}
            badge={tag}
            areBadgesEnabled={areBadgesEnabled}
            enabledBadges={enabledBadges}
            addTagToJobPost={addTagToJobPost}
          />
        ))}
      </div>
    </div>
  );
}
