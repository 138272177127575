import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import MultiDropdown from '../../components/Form/MultiDropdown';
import TextInput from '../../components/Form/TextInput';
import InnerHeader from '../../components/Auth/InnerHeader';
import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';

import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import emailIcon from '../../assets/images/auth/email.svg';
import userIcon from '../../assets/images/auth/user.svg';
import cityIcon from '../../assets/images/auth/city.svg';
import restaurantIcon from '../../assets/images/auth/restaurant.svg';

export default function RequestAccessPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const navigate = useNavigate();

  const { showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .matches(
            /^[\p{L}\p{M}'\-\s]+$/u,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          )
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        lastName: yup
          .string()
          .trim()
          .matches(
            /^[\p{L}\p{M}'\-\s]+$/u,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          )
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        email: yup
          .string()
          .trim()
          .email(t('pages.RequestAccessPage.validationErrors.invalidEmail'))
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        city: yup.array(),
        restaurant: yup.array(),
      }),
    [t]
  );

  const { data } = useQuery({
    queryKey: ['references'],
    queryFn: UserService.getReferences,
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      restaurant: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        const requestData = {
          email: values.email,
          firstName: values.firstName.replace(/\s/g, ''),
          lastName: values.lastName.replace(/\s/g, ''),
        };

        if (values.city?.length > 0) {
          requestData.cities = values.city.map((city) => city.value);
        }

        if (values.restaurant?.length > 0) {
          requestData.locations = values.restaurant.map((rest) => rest.value);
        }

        await UserService.requestAccess(requestData);
        navigate('/request-access/success');
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message === 'Already have access') {
          showModal({
            title: t('pages.RequestAccessPage.duplicityErrorTitle'),
            text: t('pages.RequestAccessPage.duplicityErrorMessage'),
            dismissButtonVariant: 'modal',
          });

          return;
        }

        if (error?.response?.data?.message === 'Waiting') {
          showModal({
            title: t('pages.RequestAccessPage.pendingApprovalTitle'),
            text: t('pages.RequestAccessPage.pendingApprovalMessage'),
            onCancel: () => {
              UserService.requestAccessSendReminder(values.email);
            },
            dismissButtonLabel: t('pages.RequestAccessPage.sendReminder'),
            dismissButtonVariant: 'modal',
          });
          return;
        }
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    },
  });

  const isSubmitButtonDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.email ||
    formik.isSubmitting;

  return (
    <div className={classes.RequestAccessPage}>
      <Helmet>
        <title>{t('pages.RequestAccessPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          label={t('common.signIn')}
          title={t('pages.RequestAccessPage.header')}
          onButtonClick={() => navigate('/login')}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            icon={userIcon}
            error={formik.errors.firstName}
            touched={isTriedToSubmit}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t('pages.RequestAccessPage.firstNameLabel')}
            name="firstName"
            placeholder={t('pages.RequestAccessPage.firstNamePlaceholder')}
            height={55}
          />
          <TextInput
            icon={userIcon}
            error={formik.errors.lastName}
            touched={isTriedToSubmit}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t('pages.RequestAccessPage.lastNameLabel')}
            name="lastName"
            placeholder={t('pages.RequestAccessPage.lastNamePlaceholder')}
            height={55}
          />
          <TextInput
            icon={emailIcon}
            error={formik.errors.email}
            touched={isTriedToSubmit}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t('pages.RequestAccessPage.emailLabel')}
            name="email"
            placeholder={t('pages.RequestAccessPage.emailPlaceholder')}
            height={55}
          />
          <MultiDropdown
            error={formik.errors.city}
            touched={isTriedToSubmit}
            value={formik.values.city}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            label={t('pages.RequestAccessPage.cityLabel')}
            name="city"
            placeholder={t('pages.RequestAccessPage.cityPlaceholder')}
            height={55}
            width="100%"
            options={data?.cities.map((city) => ({
              label: city.name,
              value: city.id,
            }))}
            setFieldTouched={formik.setFieldTouched}
            icon={cityIcon}
          />
          <MultiDropdown
            icon={restaurantIcon}
            error={formik.errors.restaurant}
            touched={isTriedToSubmit}
            value={formik.values.restaurant}
            setFieldValue={formik.setFieldValue}
            onBlur={formik.handleBlur}
            label={t('pages.RequestAccessPage.restaurantLabel')}
            name="restaurant"
            placeholder={t('pages.RequestAccessPage.restaurantPlaceholder')}
            height={55}
            width="100%"
            options={data?.locations.map((location) => ({
              label: location.name,
              value: location.id,
            }))}
            setFieldTouched={formik.setFieldTouched}
          />
        </form>
        <ButtonWithHelperText
          onClick={() => {
            setIsTriedToSubmit(true);
            formik.handleSubmit();
          }}
          isDisabled={isSubmitButtonDisabled}
          buttonLabel={t('pages.RequestAccessPage.requestButton')}
          noHelper
        />
      </div>
    </div>
  );
}
