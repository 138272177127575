/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useRef, useContext } from 'react';

import classNames from 'classnames';

import useOptions from './useOptions';

import { JobsContext } from '../../../../../../../../../context/JobsContext';
import useOnClickOutside from '../../../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function CandidateMenu({
  candidate,
  changeJobApplicationStatus,
  refetch,
  jobStatus,
  hasAssessment,
  isCandidatePopup,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const hideOptionsOnClick = (event, action) => {
    event.nativeEvent?.stopImmediatePropagation();
    event.stopPropagation();
    if (action) {
      action();
    }
    setIsOptionsListVisible(false);
  };

  const selectorRef = useRef();

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, hideOptionsOnClick);

  const { showAssessmentModal } = useContext(JobsContext);

  const { options, isOnlyStandardOptions, standardOptions } = useOptions({
    isCandidatePopup,
    candidate,
    refetch,
    setIsOptionsListVisible,
    showAssessmentModal,
    changeJobApplicationStatus,
    hasAssessment,
    jobStatus,
  });

  let optionsToShow = options[candidate?.status]?.[candidate?.subStatus]
    ?.flat()
    ?.filter((option) => !option.hidden && !Array.isArray(option));

  if (candidate?.subStatus === 'Rejected') {
    optionsToShow = optionsToShow.filter((option) => option.title !== 'Reject');
  }

  if (
    ((jobStatus === 'UnPublished' || jobStatus === 'Closed') &&
      candidate?.status !== 'Onboard') ||
    candidate?.subStatus === 'JobClosed'
  ) {
    optionsToShow = standardOptions;
  }

  if (!optionsToShow?.length) {
    optionsToShow = standardOptions;
  }

  const hasOnlyStandardOptions = isOnlyStandardOptions(optionsToShow);

  return (
    <div
      className={classNames({
        [classes.CandidateMenu]: !isCandidatePopup,
        [classes.candidatePopup]: isCandidatePopup,
      })}
      ref={dropdownRef}
    >
      <div
        ref={selectorRef}
        className={classes.selector}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {!isCandidatePopup && (
          <svg
            width="16"
            height="5"
            viewBox="0 0 16 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2.57812" r="2" fill="#C1C5D6" />
            <circle cx="8" cy="2.57812" r="2" fill="#C1C5D6" />
            <circle cx="14" cy="2.57812" r="2" fill="#C1C5D6" />
          </svg>
        )}
      </div>
      {isOptionsListVisible && optionsToShow?.length ? (
        <div className={classes.optionsContainer}>
          <ul
            className={classNames(classes.options, {
              [classes.onlyStandardOptions]: hasOnlyStandardOptions,
            })}
          >
            {optionsToShow?.map((option) => {
              const hasUpperLine =
                option.title === 'Assign Assessment' ||
                option.title === 'Send Reminder' ||
                option.title === 'Re-take Assessment';

              return (
                <li
                  key={option.title}
                  onClick={option.onClick}
                  className={classNames({
                    [classes.disabled]: option.disabled,
                    [classes.lastItemLine]: hasUpperLine,
                  })}
                >
                  {option.title}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
