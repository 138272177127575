/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../Tooltip';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import usePaginatedDropdownOptions from '../../../hooks/usePaginatedDropdownOptions';

export default function FormDropdown({
  options,
  value,
  placeholder,
  width,
  dangerOption,
  height,
  label,
  error,
  touched,
  searchInfo,
  searchInfoWidth,
  name,
  setFieldValue,
  setFieldTouched,
  maxOptionsHeight,
  info,
  note,
  infoWidth,
  fetchOptions,
  readOnly,
  labelStyle,
  style,
  readonly,
  noPortal,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isEnoughHeight, setIsEnoughHeight] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [optionsCoords, setOptionsCoords] = useState(null);

  const selectorRef = useRef();
  const dropdownRef = useRef();
  const optionsRef = useRef();
  const isOpened = useRef();

  const { t } = useTranslation();

  useOnClickOutside(
    optionsRef,
    () => setIsOptionsListVisible(false),
    selectorRef
  );

  const setOption = (option) => {
    setFieldValue(name, option);
    setIsOptionsListVisible(false);
  };

  useEffect(() => {
    if (isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `-${optionsRef.current.clientHeight}px`
      );

      const { bottom } = optionsRef.current.getBoundingClientRect();

      if (window.innerHeight - bottom < dropdownRef.current.clientHeight) {
        setIsEnoughHeight(false);
      } else {
        setIsEnoughHeight(true);
      }
    } else {
      setIsEnoughHeight(true);
      if (isOpened.current) {
        setFieldTouched?.(name, true, true);
      }
    }
  }, [isOptionsListVisible, name, setFieldTouched]);

  useEffect(() => {
    if (!isOptionsListVisible) {
      setSearchTerm('');
      setOptionsCoords(null);
    }
  }, [isOptionsListVisible]);

  // Hide options list when user scrolls outside of it
  useEffect(() => {
    const hideOptionsOnScroll = (event) => {
      if (!optionsRef.current?.contains(event.target)) {
        setIsOptionsListVisible(false);
      }
    };

    const preventArrowKeysScroll = (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
      }
    };

    if (isOptionsListVisible) {
      window.addEventListener('wheel', hideOptionsOnScroll);
      window.addEventListener('keydown', preventArrowKeysScroll);
    } else {
      window.removeEventListener('wheel', hideOptionsOnScroll);
      window.removeEventListener('keydown', preventArrowKeysScroll);
    }
  }, [isOptionsListVisible]);

  const { options: fetchedOptions, lastListElementRef } =
    usePaginatedDropdownOptions({
      dataName: fetchOptions?.dataName,
      getDataHandler: fetchOptions?.getDataHandler,
      searchTerm,
      labelName: fetchOptions?.labelName,
      isUserList: fetchOptions?.isUserList,
    });

  const optionsToDisplay = fetchOptions
    ? fetchedOptions
    : options?.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

  // Make sure that options list is visible when user clicks on selector (by setting position to fixed)
  useEffect(() => {
    /*  if (!fixed) {
      return;
    } */

    if (isOptionsListVisible) {
      const {
        top,
        bottom,
        left,
        width: selectorWidth,
      } = selectorRef.current.getBoundingClientRect();

      setTimeout(() => {
        if (dropdownRef.current.classList.contains(classes.optionsTop)) {
          setOptionsCoords({
            // top: 'unset',
            top: top - optionsRef.current.clientHeight - 5,
            left,
            width: selectorWidth,
          });
        } else {
          setOptionsCoords({ top: bottom + 10, left, width: selectorWidth });
        }
      }, 0);
    }
  }, [isOptionsListVisible, searchTerm]);

  const searchAndOptions = (
    <div
      className={classes.searchAndOptions}
      ref={optionsRef}
      style={{
        top: optionsCoords?.top,
        left: optionsCoords?.left,
        position: optionsCoords ? 'fixed' : null,
        width: optionsCoords?.width,
        bottom: optionsCoords?.bottom,
      }}
    >
      <div className={classes.search}>
        <input
          type="text"
          placeholder={t('common.search')}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        {searchInfo && (
          <Tooltip text={searchInfo} width={searchInfoWidth}>
            <i>Info</i>
          </Tooltip>
        )}
      </div>
      {optionsToDisplay?.length ? (
        <div className={classes.options}>
          <ul style={{ maxHeight: maxOptionsHeight }}>
            {optionsToDisplay?.map((option, index) => {
              return (
                <li
                  ref={
                    index === optionsToDisplay.length - 1
                      ? lastListElementRef
                      : null
                  }
                  key={option.value}
                  onClick={() => {
                    setOption(option);
                  }}
                  className={classNames({
                    [classes.active]: value?.value === option.value,
                    [classes.danger]: dangerOption === option.value,
                  })}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );

  return (
    <div
      style={{ width }}
      tabIndex={0}
      role="listbox"
      ref={dropdownRef}
      className={classNames(classes.FormDropdown, {
        [classes.open]: isOptionsListVisible,
        [classes.error]: error && touched,
        [classes.optionsTop]: !isEnoughHeight,
        [classes.readonly]: readonly,
      })}
      onKeyDown={
        readOnly
          ? null
          : (e) => {
              if (e.key === 'Enter') {
                setIsOptionsListVisible(!isOptionsListVisible);
              }
            }
      }
    >
      <span className={classes.label} style={labelStyle}>
        {label}{' '}
        {info && (
          <Tooltip text={info} width={infoWidth} direction="top-right">
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && <span className={classes.note}>({note})</span>}
      </span>
      <div
        style={{ ...style, width, height }}
        ref={selectorRef}
        className={classNames(classes.selector, {
          [classes.selectorReadOnly]: readOnly,
        })}
        onClick={() => {
          if (readOnly) {
            return;
          }
          isOpened.current = true;
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {value ? (
          <span className={classes.hasOption}>{value.label}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      <div className={classes.error}>{error}</div>
      {isOptionsListVisible && (
        <>
          {optionsCoords && !noPortal
            ? createPortal(searchAndOptions, document.body)
            : searchAndOptions}
        </>
      )}
    </div>
  );
}
