/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';
import getResizedImageUrl from '../../../../../../../../helpers/getResizedImageUrl';

function MediaUploader({ type, file, setFile, isEdit, error, touched }) {
  const [isWrongFileFormat, setIsWrongFileFormat] = useState(false);

  const fileInputRef = useRef();

  const { t } = useTranslation();

  let text;
  let allowedFiles;

  if (type === 'image') {
    text = (
      <div className={classes.text}>
        <span>
          {t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.MediaUploader.CoverPicture'
          )}
        </span>
        <span className={classes.allowedFormats}>(jpg, png, jpeg, webp)</span>
      </div>
    );
    allowedFiles = '.jpg, .png, .jpeg, .webp';
  } else if (type === 'video') {
    text = (
      <div className={classes.text}>
        <span>
          {t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.MediaUploader.JobSpecs'
          )}
          {'  '}
          {t('common.video')}
        </span>
        <span className={classes.allowedFormats}>
          (mp4, mov, avi, m4a, webm)
        </span>
        {!file?.name && <span className={classes.videoLength}>00:10</span>}
      </div>
    );
    allowedFiles = '.mp4, .mov, .avi, .m4a, .webm';
  }

  let filePreview;

  let videoPreviewSrc = '';

  if (isEdit && file?.url) {
    videoPreviewSrc = file.url;
  } else {
    try {
      videoPreviewSrc = URL.createObjectURL(file);
    } catch (err) {
      //
    }
  }

  if (type === 'image' && file) {
    filePreview = (
      <img
        alt="cover"
        src={
          !file.name
            ? getResizedImageUrl({
                url: `jobpostcover/${file}`,
                width: 400,
              })
            : URL.createObjectURL(file)
        }
      />
    );
  } else if (type === 'video' && file) {
    filePreview = <video alt="cover" src={videoPreviewSrc} />;
  }

  return (
    <div
      className={classNames(classes.MediaUploader, {
        [classes.withFile]: !!file,
      })}
      onClick={() => fileInputRef.current.click()}
    >
      {file ? (
        <i
          className={classes.deleteIcon}
          onClick={(event) => {
            event.stopPropagation();
            setFile('');
            fileInputRef.current.value = null;
          }}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99975 3.99582L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00299 4.99906L9.79233 8.7884C10.0694 9.06544 10.0694 9.5146 9.79233 9.79164C9.51529 10.0687 9.06612 10.0687 8.78908 9.79164L4.99975 6.00231L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99651 4.99906L0.209068 1.21162C-0.0679701 0.934586 -0.0679701 0.485419 0.209068 0.208381C0.486105 -0.0686568 0.935272 -0.0686568 1.21231 0.208381L4.99975 3.99582Z"
              fill="white"
            />
          </svg>
        </i>
      ) : (
        <i className={classes.addIcon}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99805 1.16797V12.8346"
              stroke="white"
              strokeWidth="1.64103"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.16406 7H12.8307"
              stroke="white"
              strokeWidth="1.64103"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      )}
      {text}
      {filePreview}
      <input
        type="file"
        ref={fileInputRef}
        accept={allowedFiles}
        onChange={(event) => {
          const userFile = event.target.files[0];
          if (userFile) {
            // Convert allowedFiles to an array of extensions
            const allowedExtensions = allowedFiles
              .split(', ')
              .map((ext) => ext.trim());
            // Extract file extension
            const fileExtension = `.${userFile.name
              .split('.')
              .pop()
              .toLowerCase()}`;
            // Check if the file's extension is in the allowedExtensions array
            if (allowedExtensions.includes(fileExtension)) {
              setFile(userFile);
              setIsWrongFileFormat(false);
            } else {
              fileInputRef.current.value = null;
              setIsWrongFileFormat(true);
            }
          }
        }}
      />
      {error && touched && <div className={classes.error}>{error}</div>}
      {isWrongFileFormat && (
        <div className={classes.warning}>
          {t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.MediaUploader.WrongFileFormat'
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(MediaUploader, (prevProps, nextProps) => {
  if (
    prevProps.type === nextProps.type &&
    prevProps.setFile === nextProps.setFile &&
    prevProps.file?.name === nextProps.file?.name
  ) {
    return true;
  }
  return false;
});
