import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import TableHeader from './TableHeader';
import Thead from './Thead';
import Tbody from './Tbody';

import classes from './styles.module.scss';

export default function Table({
  activeReport,
  reports,
  rowsPerPage,
  setRowsPerPage,
  activeSortHeader,
  setActiveSortHeader,
  sortOrder,
  setSortOrder,
  isLeftPanelOpened,
  tableRef,
  isFetching,
}) {
  const [visibleData, setVisibleData] = useState(reports?.data);
  const [currentPage, setCurrentPage] = useState(0);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const pageCount = Math.ceil(reports?.data?.length / rowsPerPage);

  useEffect(() => {
    if (pageCount === 0) {
      setCurrentPage(0);

      return;
    }
    if (currentPage >= pageCount) {
      setCurrentPage(pageCount - 1);
    }
  }, [currentPage, pageCount, rowsPerPage]);

  const getCurrentPageData = useCallback(() => {
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return reports?.data?.slice(startIndex, endIndex);
  }, [currentPage, rowsPerPage, reports]);

  useEffect(() => {
    setVisibleData(getCurrentPageData);
  }, [currentPage, getCurrentPageData, rowsPerPage]);

  let noData = (
    <div className={classes.noData}>
      <h4>
        {t('dashboardComponents.Reports.RightPanel.Table.noRecordsFound')}
      </h4>
      <br />
      {t('dashboardComponents.Reports.RightPanel.Table.noEntriesInReport')}{' '}
      {t(
        'dashboardComponents.Reports.RightPanel.Table.checkBackOrRefineSearch'
      )}
    </div>
  );

  if (isFetching) {
    noData = (
      <div className={classes.noData}>
        <div className={classes.fetching}>
          <p>
            {t(
              'dashboardComponents.Reports.RightPanel.Table.fetchingMessage.0'
            )}
          </p>
          <p>
            {t(
              'dashboardComponents.Reports.RightPanel.Table.fetchingMessage.1'
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(classes.Table, {
          [classes.hasNoData]: !visibleData?.length || isFetching,
        })}
        style={{
          width:
            !isLeftPanelOpened &&
            !isTabletOrMobile &&
            'calc(100vw - 30px - 100px - 24px)',
        }}
      >
        {!!reports?.data?.length && (
          <TableHeader
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            data={reports?.data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          />
        )}
        <div className={classes.tableContainer} style={{ width: '100%' }}>
          {visibleData?.length ? (
            <table className={classes.reportTable}>
              <Thead
                reports={reports?.selectedFields}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                activeSortHeader={activeSortHeader}
                setActiveSortHeader={setActiveSortHeader}
              />
              <Tbody data={visibleData} />
            </table>
          ) : (
            noData
          )}
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div className={classes.toPrint} ref={tableRef}>
          <h1 className={classes.title}>
            {activeReport?.title || activeReport}
          </h1>
          <div className={classes.tableContainer}>
            {visibleData?.length ? (
              <table className={classes.reportTable}>
                <Thead
                  reports={reports?.selectedFields}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  activeSortHeader={activeSortHeader}
                  setActiveSortHeader={setActiveSortHeader}
                  print
                />
                <Tbody data={visibleData} />
              </table>
            ) : (
              <div className={classes.noData}>
                {t(
                  'dashboardComponents.Reports.RightPanel.Table.noRecordsFound'
                )}{' '}
                <br /> <br />{' '}
                {t(
                  'dashboardComponents.Reports.RightPanel.Table.noEntriesInReport'
                )}{' '}
                <br /> <br />{' '}
                {t(
                  'dashboardComponents.Reports.RightPanel.Table.checkBackOrRefineSearch'
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
