import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { JobsContext } from '../../../../../../../../../context/JobsContext';
import {
  sendReminder,
  moveCandidateToPreviousStage,
  rejectCandidate,
  changeJoiningStatusToNoShow,
  sendOfferReminder,
} from '../../../../../../../../../helpers/candidates';
import { useUiContext } from '../../../../../../../../../context/UiContext';
import AdminService from '../../../../../../../../../services/AdminService';

const isSendReminderOptionDisabled = (candidate) => {
  if (candidate?.status === 'Onboard' && candidate?.subStatus === 'Pending') {
    return true;
  }

  if (candidate?.status === 'Screen' && candidate?.subStatus === 'Completed') {
    return true;
  }

  if (candidate?.status === 'Interview' && candidate?.subStatus === 'Pending') {
    return true;
  }

  return false;
};

export default function useOptions({
  isCandidatePopup,
  candidate,
  refetch,
  setIsOptionsListVisible,
  showAssessmentModal,
  changeJobApplicationStatus,
  hasAssessment,
  jobStatus,
}) {
  const { t } = useTranslation();

  const {
    showReportCandidateModal,
    showShareModal,
    showSetOnboardingModal,
    showScheduleInterviewModal,
    showSetJoiningModal,
    showOfferModal,
    showRejectCandidateWithReasonModal,
    showChangeStageWithExceptionModal,
    showNextStepsModal,
  } = useContext(JobsContext);

  const { showModal, showNotification, setIsFetching } = useUiContext();

  const hasOnboarding = candidate?.jobApplicationOnboardings?.length;
  const hasJoining = candidate?.jobApplicationJoinings?.length;
  const jobApplicationHasAssessment = !!candidate?.assignedAssessments?.length;

  const hideOptionsOnClick = (event, action) => {
    event.nativeEvent?.stopImmediatePropagation();
    event.stopPropagation();
    if (action) {
      action();
    }
    setIsOptionsListVisible(false);
  };

  const requestNoShowReject = () => {
    showModal({
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalTitle'
      ),
      text: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalText'
      ),
      onConfirm: () =>
        rejectCandidate(
          candidate,
          refetch,
          showNotification,
          true,
          setIsFetching
        ),
      dismissButtonLabel: t('common.no'),
    });
  };

  const requestNoShowJoinginReject = () => {
    showModal({
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalTitle'
      ),
      text: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalText'
      ),
      onConfirm: () =>
        changeJoiningStatusToNoShow(candidate, refetch, setIsFetching),
      dismissButtonLabel: t('common.no'),
    });
  };

  const setJobApplicationStatus = async (jobApplicationId, status) => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId,
        status: status.status,
        subStatus: status.subStatus,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const cancelInterview = async () => {
    try {
      setIsFetching(true);

      await AdminService.cancelJobApplicationInterview({
        jobApplicationId: candidate.id,
        interviewId: candidate.jobApplicationInterviews[0].id,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const rejectOption = {
    title: t(
      'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectTitle'
    ),
    onClick: (event) =>
      hideOptionsOnClick(event, () => {
        showRejectCandidateWithReasonModal({ candidate, refetch });
      }),
  };

  const standardOptions = isCandidatePopup
    ? []
    : [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.chatComingSoonTitle'
          ),
          disabled: true,
          onClick: (event) => hideOptionsOnClick(event, () => {}),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.emailCandidateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              window.location.href = `mailto:${candidate.user?.userName}`;
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.shareTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => showShareModal({ candidate })),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.blockAndReportTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showReportCandidateModal({ candidate, refetch })
            ),
        },
      ];

  const assignAssessmentOption = {
    title: t(
      'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.assignAssessmentTitle'
    ),
    onClick: (event) =>
      hideOptionsOnClick(event, () =>
        showAssessmentModal({ candidate, refetch })
      ),
  };

  let sendReminderOption = jobApplicationHasAssessment
    ? [
        {
          hidden: isSendReminderOptionDisabled(candidate),
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              sendReminder(candidate, refetch, showNotification, setIsFetching)
            ),
        },
      ]
    : [assignAssessmentOption];

  if (
    candidate?.status === 'Offer' &&
    (candidate?.subStatus === 'Offered' || candidate?.subStatus === 'Countered')
  ) {
    sendReminderOption = [
      {
        title: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
        ),
        onClick: (event) =>
          hideOptionsOnClick(event, () =>
            sendOfferReminder(
              candidate,
              refetch,
              showNotification,
              setIsFetching
            )
          ),
      },
    ];
  }

  const newOptions = [
    {
      title: hasAssessment
        ? t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToScreenTitle'
          )
        : t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToInterviewTitle'
          ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          if (jobStatus === 'UnPublished') {
            return;
          }
          changeJobApplicationStatus(
            candidate.id,
            hasAssessment
              ? { status: 'Screen', subStatus: 'Requested' }
              : { status: 'Interview', subStatus: 'Pending' },
            () =>
              moveCandidateToPreviousStage(
                candidate?.status,
                candidate?.subStatus,
                candidate,
                refetch,
                setIsFetching
              )
          );
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showModal({
            title: t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateWithTitle'
            ),
            text: t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateText'
            ),
            onConfirm: () =>
              rejectCandidate(
                candidate,
                refetch,
                showNotification,
                false,
                setIsFetching
              ),
            dismissButtonLabel: t('common.no'),
          });
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.assignAssessmentTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () =>
          showAssessmentModal({ candidate, refetch })
        ),
    },
    ...standardOptions,
  ];

  const interviewAdvancedStagesOptions = [
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.editInterviewTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showScheduleInterviewModal({ candidate, refetch });
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          if (jobStatus === 'UnPublished') {
            return;
          }
          showChangeStageWithExceptionModal({
            candidate,
            refetch,
            stage: 'Offer',
          });
        }),
    },
    rejectOption,
    ...sendReminderOption,
    ...standardOptions,
  ];

  const pendingOfferOptions = [
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendOfferTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showOfferModal({ candidate, refetch });
        }),
    },
    rejectOption,
    assignAssessmentOption,
    ...standardOptions,
  ];

  const viewOfferOption = {
    hidden: !candidate?.jobApplicationOffers?.length,
    title:
      candidate?.subStatus === 'Countered'
        ? t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.counterOfferTitle'
          )
        : t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.viewOfferTitle'
          ),
    onClick: (event) =>
      hideOptionsOnClick(event, () => {
        const readOnly =
          candidate?.subStatus !== 'Offered' &&
          candidate?.subStatus !== 'Countered';
        showOfferModal({ candidate, refetch, readOnly });
      }),
  };

  const options = {
    Invited: {
      Pending: [rejectOption, ...standardOptions],
      Rejected: standardOptions,
      Withdrawn: standardOptions,
    },
    New: {
      Pending: newOptions,
      Rejected: standardOptions,
      Accepted: newOptions,
      Withdrawn: standardOptions,
    },
    Screen: {
      Requested: [rejectOption, ...sendReminderOption, ...standardOptions],
      Passed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Interview', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate?.status,
                    candidate?.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        ...sendReminderOption,
        ...standardOptions,
      ],
      Completed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Interview', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate?.status,
                    candidate?.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        ...sendReminderOption,
        ...standardOptions,
      ],
      Rejected: [
        // {
        //   title: 'Re-take Assessment',
        //   onClick: (event) =>
        //     hideOptionsOnClick(event, () => {
        //       showModal({
        //         title: 'Re-take Assessment',
        //         text: 'Are you sure you want the candidate to take the assessment again?',
        //         onConfirm: () =>
        //           retakeAssessment(
        //             candidate,
        //             refetch,
        //             showNotification,
        //             setIsFetching
        //           ),
        //         dismissButtonLabel: 'No',
        //       });
        //     }),
        // },
        ...standardOptions,
      ],
      Incomplete: [
        {
          title: 'Re-take Assessment',
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch, retake: true })
            ),
        },
        ...standardOptions,
      ],
      Failed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.reTakeAssessmentTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch, retake: true })
            ),
        },
        ...standardOptions,
      ],
      Retake: [...sendReminderOption, ...standardOptions],
      Withdrawn: standardOptions,
    },
    Interview: {
      JobClosed: standardOptions,
      Pending: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.scheduleInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showScheduleInterviewModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showChangeStageWithExceptionModal({
                candidate,
                refetch,
                stage: 'Offer',
              });
            }),
        },
        rejectOption,
        ...sendReminderOption,
        ...standardOptions,
      ],
      Scheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      Rescheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      ChangeRequested: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      Accepted: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      Declined: standardOptions,
      Fail: interviewAdvancedStagesOptions,
      Rejected: standardOptions,
      Canceled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.scheduleInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showScheduleInterviewModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Offer', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate.status,
                    candidate.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        ...sendReminderOption,
        ...standardOptions,
      ],
      Passed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Offer', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate.status,
                    candidate.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        assignAssessmentOption,
        ...standardOptions,
      ],
      Withdrawn: standardOptions,
    },
    Offer: {
      Pending: pendingOfferOptions,
      Rejected: [viewOfferOption, standardOptions],
      Declined: [viewOfferOption, standardOptions],
      Offered: [
        viewOfferOption,
        {
          title: 'Reject',
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showRejectCandidateWithReasonModal({ candidate, refetch });
            }),
        },
        ...sendReminderOption,
        ...standardOptions,
      ],
      Countered: [
        viewOfferOption,
        rejectOption,
        ...sendReminderOption,
        ...standardOptions,
      ],
      CounterAccepted: [
        viewOfferOption,
        rejectOption,
        ...sendReminderOption,
        ...standardOptions,
      ],
      Accepted: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOnboardTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Onboard', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate?.status,
                    candidate?.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      Withdrawn: standardOptions,
    },
    Onboard: {
      Pending: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetOnboardingModal({ candidate, refetch });
            }),
        },
        rejectOption,
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      Scheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.changeOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetOnboardingModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Onboarded',
              });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        /*  {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelJobApplicationOnboarding({
                  jobApplicationId: candidate.id,
                  onboardingId: candidate.jobApplicationOnboardings[0].id,
                });
              } catch (error) {
                console.log(error);
              } finally {
                await refetch();
                setIsFetching(false);
              }
            }),
        }, */
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      Rescheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.changeOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetOnboardingModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Onboarded',
              });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        /*   {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelJobApplicationOnboarding({
                  jobApplicationId: candidate.id,
                  onboardingId: candidate.jobApplicationOnboardings[0].id,
                });
              } catch (error) {
                console.log(error);
              } finally {
                await refetch();
                setIsFetching(false);
              }
            }),
        }, */
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      ChangeRequested: hasJoining
        ? [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.changeStartTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetJoiningModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...sendReminderOption,
            viewOfferOption,
            ...standardOptions,
          ]
        : [
            {
              title: isCandidatePopup
                ? t(
                    'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rescheduleTitle'
                  )
                : t(
                    'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.changeOnboardingTitle'
                  ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetOnboardingModal({ candidate, refetch });
                }),
            },
            rejectOption,
            viewOfferOption,
            ...sendReminderOption,
            ...standardOptions,
          ],

      StartConfirmed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.joinedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showNextStepsModal({
                candidate,
                refetch,
              });
              /*  setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Joined',
              }); */
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowJoinginReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelStartDate({
                  jobApplicationId: candidate.id,
                  joiningId: candidate.jobApplicationJoinings[0].id,
                });
                await refetch();
              } catch (error) {
                console.log(error);
              } finally {
                setIsFetching(false);
              }
            }),
        },
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      Accepted: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Onboarded',
              });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rescheduleTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetOnboardingModal({ candidate, refetch });
            }),
        },
        //  ...sendReminderOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.assignAssessmentTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch })
            ),
        },
        {
          // hidden: isSendReminderOptionDisabled(candidate),
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              sendReminder(candidate, refetch, showNotification, setIsFetching)
            ),
        },
        viewOfferOption,
        ...standardOptions,
      ],
      Onboarded: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowJoinginReject();
            }),
        },
        rejectOption,
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      StartDateSet: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.joinedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showNextStepsModal({
                candidate,
                refetch,
              });
              /*  setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Joined',
              }); */
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowJoinginReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelStartDate({
                  jobApplicationId: candidate.id,
                  joiningId: candidate.jobApplicationJoinings[0].id,
                });
                await refetch();
              } catch (error) {
                console.log(error);
              } finally {
                setIsFetching(false);
              }
            }),
        },
        ...sendReminderOption,
        viewOfferOption,
        ...standardOptions,
      ],
      NoShow: [viewOfferOption, standardOptions],
      Rejected: standardOptions,
      Declined: [viewOfferOption, standardOptions],
      StartDeclined: [viewOfferOption, standardOptions],
      Canceled: hasOnboarding
        ? [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetJoiningModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...sendReminderOption,
            viewOfferOption,
            ...standardOptions,
          ]
        : [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setOnboardingTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetOnboardingModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...sendReminderOption,
            viewOfferOption,
            ...standardOptions,
          ],
      Joined: [viewOfferOption, standardOptions],
      Withdrawn: [viewOfferOption, standardOptions],
    },
  };

  return {
    standardOptions,
    options,
    isOnlyStandardOptions: (optionsToShow) =>
      optionsToShow?.every((option) =>
        standardOptions.some((sOpt) => option.title === sOpt.title)
      ),
  };
}
