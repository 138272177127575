import React from 'react';

import { useQuery } from '@tanstack/react-query';

import AuthCarousel from '../../components/Auth/AuthCarousel';
import AuthHeader from '../../components/Auth/AuthHeader';

import classes from './styles.module.scss';
import TenantSettingService from '../../services/TenantSettingService';

export default function AuthLayout({ children, noButton, previousRoute }) {
  const { data } = useQuery({
    queryKey: 'tenantSettings',
    queryFn: TenantSettingService.getTenantSettings,
  });

  return (
    <main className={classes.AuthLayout}>
      <div className={classes.mainContainer}>
        <div className={classes.leftColumn}>
          <AuthHeader
            noButton={noButton}
            previousRoute={previousRoute}
            logoUrl={data?.brand?.logo}
          />
          <div className={classes.container}>{children}</div>
        </div>
        <div className={classes.rightColumn}>
          <AuthCarousel />
        </div>
      </div>
    </main>
  );
}
