import React from 'react';

import { useTranslation } from 'react-i18next';

import Logo from './Logo';
import Color from './Color';
import Font from './Font';
import Contacts from './Contacts';

import classes from './styles.module.scss';

export default function SettingsContainer({
  tenantSettings,
  refetchTenantSettings,
  activeTab,
  brandName,
  setBrandName,
  logo,
  setLogo,
  favicon,
  setFavicon,
  brandColors,
  setBrandColors,
  titleFont,
  setTitleFont,
  subTitleFont,
  setSubTitleFont,
  bodyTextFont,
  setBodyTextFont,
  bodySubTextFont,
  setBodySubTextFont,
  savedLogoSettings,
  setSavedLogoSettings,
  savedColorSettings,
  setSavedColorSettings,
  savedFontSettings,
  setSavedFontSettings,
  savedContactsSettings,
  setSavedContactsSettings,
  supportEmail,
  setSupportEmail,
  contactEmail,
  setContactEmail,
  appName,
  setAppName,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.SettingsContainer}>
      {activeTab ===
        t('dashboardComponents.ProfileSettings.BrandKit.tabs.logo') && (
        <Logo
          brandName={brandName}
          setBrandName={setBrandName}
          logo={logo}
          setLogo={setLogo}
          favicon={favicon}
          setFavicon={setFavicon}
          savedLogoSettings={savedLogoSettings}
          setSavedLogoSettings={setSavedLogoSettings}
        />
      )}
      {activeTab ===
        t('dashboardComponents.ProfileSettings.BrandKit.tabs.color') && (
        <Color
          brandColors={brandColors}
          setBrandColors={setBrandColors}
          savedColorSettings={savedColorSettings}
          setSavedColorSettings={setSavedColorSettings}
        />
      )}
      {activeTab ===
        t('dashboardComponents.ProfileSettings.BrandKit.tabs.font') && (
        <Font
          titleFont={titleFont}
          setTitleFont={setTitleFont}
          subTitleFont={subTitleFont}
          setSubTitleFont={setSubTitleFont}
          bodyTextFont={bodyTextFont}
          setBodyTextFont={setBodyTextFont}
          bodySubTextFont={bodySubTextFont}
          setBodySubTextFont={setBodySubTextFont}
          tenantSettings={tenantSettings}
          refetchTenantSettings={refetchTenantSettings}
          savedFontSettings={savedFontSettings}
          setSavedFontSettings={setSavedFontSettings}
        />
      )}
      {activeTab ===
        t('dashboardComponents.ProfileSettings.BrandKit.tabs.contacts') && (
        <Contacts
          tenantSettings={tenantSettings}
          refetchTenantSettings={refetchTenantSettings}
          savedContactsSettings={savedContactsSettings}
          setSavedContactsSettings={setSavedContactsSettings}
          supportEmail={supportEmail}
          setSupportEmail={setSupportEmail}
          contactEmail={contactEmail}
          setContactEmail={setContactEmail}
          appName={appName}
          setAppName={setAppName}
        />
      )}
    </div>
  );
}
