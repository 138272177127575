/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Chat({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createFeedbackNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToChat = () => {
      navigate(
        `/tools/chat?jobApplicationId=${notification.jobApplication.id}`
      );
      hideNotifications();
    };

    const feedbackRating =
      notification.jobApplication?.jobApplicationUserFeedbacks?.[0]
        ?.feedbackRating;

    return feedbackRating === 1
      ? {
          title: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback1.title',
            {
              feedbackRating,
              candidate:
                notification.jobApplication?.user?.userProfile?.fullName,
              title: notification.jobApplication?.jobPost?.jobTitle,
              jobId: notification.jobApplication?.jobPost?.id,
            }
          ),
          text: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback1.text',
            {
              feedbackRating,
            }
          ),
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback.buttonLabel'
          ),
          onClick: goToChat,
        }
      : {
          title: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback.title',
            {
              feedbackRating,
              candidate:
                notification.jobApplication?.user?.userProfile?.fullName,
              title: notification.jobApplication?.jobPost?.jobTitle,
              jobId: notification.jobApplication?.jobPost?.id,
            }
          ),
          text: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback.text',
            {
              feedbackRating,
            }
          ),
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Chat.newFeedback.buttonLabel'
          ),
          onClick: goToChat,
        };
  };

  const createMessageNotification = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToChat = () => {
      navigate(
        `/tools/chat?jobApplicationId=${notification.jobApplication.id}`
      );
      hideNotifications();
    };

    return {
      title: t(
        'dashboardComponents.Notifications.Notification.Chat.newMessage.title',
        {
          name: notification.messageInfo,
          title: notification.jobPost?.jobTitle,
          jobId: notification.jobPost?.id,
        }
      ),
      text: t(
        'dashboardComponents.Notifications.Notification.Chat.newMessage.text'
      ),
      buttonLabel: t(
        'dashboardComponents.Notifications.Notification.Chat.newMessage.buttonLabel'
      ),
      onClick: goToChat,
    };
  };

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const content = {
      NewFeedback: createFeedbackNotificationContent(
        notification,
        navigate,
        hideNotifications
      ),
      NewMessage: createMessageNotification(
        notification,
        navigate,
        hideNotifications
      ),
    };

    return (
      content[notification.eventNote] ||
      content[notification.name] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
