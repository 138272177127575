import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import CandidatesService from '../../../services/CandidatesService';

let timer;

export default function UserValidation({
  isEmailValid,
  email,
  isUserValid,
  setIsUserValid,
}) {
  const { t } = useTranslation();

  // Validate email on input (when email is valid and user stopped typing)
  useEffect(() => {
    setIsUserValid(null);

    if (isEmailValid && email) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          await CandidatesService.checkCandidateEmail(email);
          setIsUserValid(false);
        } catch (error) {
          setIsUserValid(true);
        }
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isEmailValid, email, setIsUserValid]);

  return (
    <div className={classes.UserValidation}>
      <h3 className={classes.title}>
        {t('modals.ChangePasswordModal.User validation')}
      </h3>
      {isUserValid === false && (
        <p className={classes.error}>
          ✓ {t('modals.ChangePasswordModal.User does not exist')}
        </p>
      )}
      {isUserValid === true && (
        <p className={classes.success}>
          ✓ {t('modals.ChangePasswordModal.Valid user')}
        </p>
      )}
    </div>
  );
}
