/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
import React, { useState, useContext } from 'react';

import classNames from 'classnames';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { PreventNavigation } from '../../../../context/PreventNavigationContext';

import SubMenu from '../SubMenu';
import classes from './styles.module.scss';

export default function NavButton({
  path,
  icon,
  label,
  iconHover,
  iconActive,
  visibleSubmenu,
  setVisibleSubmenu,
  subMenu,
  resetPath,
  hasScroll,
}) {
  const [isActive, setIsActive] = useState(false);

  const { isNavigationAllowed, setShowModal, setNavigationLink } =
    useContext(PreventNavigation);

  const location = useLocation();
  const navigate = useNavigate();

  let currentIcon = icon;

  if (isActive) {
    currentIcon = iconActive;
  }

  return (
    <li
      className={classNames(classes.NavButton, {
        [classes.active]: isActive,
        [classes.hasScroll]: hasScroll,
      })}
      onClick={() => setVisibleSubmenu(label)}
    >
      <NavLink
        draggable="false"
        to={path}
        className={({ isActive }) => setIsActive(isActive)}
        onClick={(event) => {
          if (location.pathname.includes(path)) {
            event.preventDefault();
            navigate(resetPath || path);
          }

          if (!isNavigationAllowed) {
            event.preventDefault();
            setShowModal(true);
            setNavigationLink(path);
          }
        }}
      >
        <div className={classes.iconContainer}>
          <div className={classes.iconContainerBackground} />
          <img
            className={classes.mainImg}
            src={currentIcon}
            alt={label}
            draggable="false"
          />
          <img className={classes.hoverImg} src={iconHover} alt="" />
        </div>
        <span className={classes.iconTitle}>{label}</span>
      </NavLink>
      {subMenu && (
        <SubMenu
          isVisible={visibleSubmenu === label}
          options={subMenu}
          setVisibleSubmenu={setVisibleSubmenu}
        />
      )}
    </li>
  );
}
