import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import Button from '../../../../../Button';

import { useUiContext } from '../../../../../../context/UiContext';
import AdminService from '../../../../../../services/AdminService';

// This function updates the local storage with the time when the refresh button
// for the given jobPostId was clicked
const updateLocalStorageButtonData = (jobPostId) => {
  const buttonData =
    JSON.parse(localStorage.getItem('refreshButtonData')) || {};
  buttonData[jobPostId] = Date.now();
  localStorage.setItem('refreshButtonData', JSON.stringify(buttonData));
};

// This function checks if the refresh button for the give jobPostId
// has been clicked in the last 5 minutes
const hasButtonBeenClickedRecently = (jobPostId) => {
  const buttonData =
    JSON.parse(localStorage.getItem('refreshButtonData')) || {};
  const lastClicked = buttonData[jobPostId];
  return lastClicked && Date.now() - lastClicked < 300000; // 5 minutes
};

export default function RefreshButton({ jobPostId }) {
  const [isAssessmentRefreshing, setIsAssessmentRefreshing] = useState(false);

  const { showNotification, setIsFetching } = useUiContext();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const checkFlowqAssessment = async () => {
    try {
      setIsFetching(true);
      setIsAssessmentRefreshing(true);
      await AdminService.checkFlowqAssessment(jobPostId);
      console.log('jobPostId', jobPostId);
      await queryClient.invalidateQueries({
        queryKey: ['myJobCandidates'],
        exact: false, // This allows for invalidating queries that match the prefix of the provided queryKey
      });
      showNotification({
        text: t(
          'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.Assessment result refreshed.'
        ),
      });
      updateLocalStorageButtonData(jobPostId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsAssessmentRefreshing(false);
    }
  };

  // If the assessment is refreshing or the button has been clicked less than 5 minutes ago
  // the button is disabled
  if (isAssessmentRefreshing || hasButtonBeenClickedRecently(jobPostId)) {
    return (
      <Button
        disabled
        style={{
          fontSize: 14,
          border: 'none',
          color: 'white',
          backgroundColor: '#C1C5D6',
        }}
        width={143}
        height={40}
      >
        {t(
          'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.Refresh Results'
        )}
      </Button>
    );
  }

  return (
    <Button
      style={{
        fontSize: 14,
      }}
      width={143}
      height={40}
      onClick={(event) => {
        event.stopPropagation();
        checkFlowqAssessment();
      }}
    >
      {t(
        'dashboardComponents.Candidate.AssessmentAndInterview.Assessment.Refresh Results'
      )}
    </Button>
  );
}
