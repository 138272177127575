/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

import { addOneHour } from '../../../../../helpers/time';
import {
  getTimezoneDate,
  getTimezoneTime,
} from '../../../../../helpers/timezones';

export default function Interview({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToCalendar = (date) => {
      navigate(
        `/tools/calendar?date=${date}&event=Interview&id=${notification.jobApplication?.jobApplicationInterviews[0]?.id}`
      );
      hideNotifications();
    };

    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      ApplicationPendingInterview: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      InterviewChangeRequested: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewChangeRequested.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.suggestedInterviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.suggestedInterviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.suggestedInterviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewChangeRequested.text',
          {
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.review'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.suggestedInterviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.suggestedStartTime
            )[0]
          ),
      },
      InterviewAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedCanditate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedCanditate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedCanditate.buttonLabel'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate
            )[0]
          ),
      },
      InterviewAcceptedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAcceptedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate
            )[0]
          ),
      },
      InterviewDeclined: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedCanditate.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedCanditate.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedCanditate.buttonLabel'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate
            )[0]
          ),
      },
      InterviewDeclinedRecruiter: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedRecruiter.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedRecruiter.text',
          {
            name: notification.messageInfo,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclinedRecruiter.buttonLabel'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate
            )[0]
          ),
      },
      InterviewInvite: {
        title: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewInvite.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            date: moment(
              getTimezoneDate(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone,
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate
              )[0]
            ).format('Do MMM YYYY'),
            time: addOneHour(
              getTimezoneTime(
                notification.jobApplication?.jobApplicationInterviews?.[0]
                  ?.interviewDate,
                userTimezone
              )
            ),
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewInvite.text',
          {
            name: notification.jobPost?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.interviewInvite.buttonLabel'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.interviewDate,
              userTimezone,
              '05:00'
            )[0]
          ),
      },
    };

    return (
      content[notification.name] || {
        title: notification.eventNote,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
