import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import Button from './Button';

import classes from './styles.module.scss';
/* import chevron from '../../../../../../assets/images/dashboard/candidate/chevron.svg';
import chevronGreen from '../../../../../../assets/images/dashboard/candidate/chevron-green.svg';
import chevronWhite from '../../../../../../assets/images/dashboard/candidate/chevron-white.svg'; */

export default function Pagination({ pageCount, currentPage, setPage }) {
  const [isHoveredOutLeftButton, setIsHoveredOutLeftButton] = useState(false);
  const [isHoveredOutRightButton, setIsHoveredOutRightButton] = useState(false);
  const [isLeftButtonActive, setIsLeftButtonActive] = useState(false);
  const [isRightButtonActive, setIsRightButtonActive] = useState(false);

  useEffect(() => {
    if (isHoveredOutLeftButton) {
      setTimeout(() => {
        setIsHoveredOutLeftButton(false);
      }, 400);
    }

    if (isHoveredOutRightButton) {
      setTimeout(() => {
        setIsHoveredOutRightButton(false);
      }, 400);
    }
  }, [isHoveredOutLeftButton, isHoveredOutRightButton]);

  // Reset active state
  useEffect(() => {
    if (isLeftButtonActive) {
      setTimeout(() => {
        setIsLeftButtonActive(false);
      }, 400);
    }

    if (isRightButtonActive) {
      setTimeout(() => {
        setIsRightButtonActive(false);
      }, 400);
    }
  }, [isLeftButtonActive, isRightButtonActive]);

  return (
    <div
      className={classes.Pagination}
      style={{
        visibility: !pageCount || pageCount === 1 ? 'hidden' : 'visible',
      }}
    >
      <button
        type="button"
        className={classNames(classes.switch, {
          [classes.hoveredOut]: isHoveredOutLeftButton,
          [classes.green]: currentPage !== 1,
        })}
        onClick={() => {
          setPage(currentPage - 1);
          setIsLeftButtonActive(true);
        }}
        disabled={currentPage === 1}
        onMouseLeave={() => {
          setIsHoveredOutLeftButton(true);
        }}
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.35186 1.28033C6.64476 0.987436 6.64476 0.512564 6.35186 0.21967C6.05897 -0.0732231 5.5841 -0.0732231 5.2912 0.21967L0.291203 5.21967C-0.00169039 5.51256 -0.00169039 5.98744 0.291203 6.28033L5.2912 11.2803C5.5841 11.5732 6.05897 11.5732 6.35186 11.2803C6.64476 10.9874 6.64476 10.5126 6.35186 10.2197L1.88219 5.75L6.35186 1.28033Z"
            fill="#B1B6CA"
          />
        </svg>
      </button>
      <div className={classes.pages}>
        {Array.from({ length: pageCount }, (_, i) => i + 1).map((page) => (
          <Button
            key={page}
            page={page}
            isActive={page === currentPage}
            onClick={() => setPage(page)}
          />
        ))}
      </div>
      <button
        type="button"
        className={classNames(classes.switch, {
          [classes.hoveredOut]: isHoveredOutRightButton,
          [classes.green]: currentPage !== pageCount,
        })}
        onClick={() => {
          setPage(currentPage + 1);
          setIsRightButtonActive(true);
        }}
        disabled={currentPage === pageCount}
        onMouseLeave={() => {
          setIsHoveredOutRightButton(true);
        }}
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.35186 1.28033C6.64476 0.987436 6.64476 0.512564 6.35186 0.21967C6.05897 -0.0732231 5.5841 -0.0732231 5.2912 0.21967L0.291203 5.21967C-0.00169039 5.51256 -0.00169039 5.98744 0.291203 6.28033L5.2912 11.2803C5.5841 11.5732 6.05897 11.5732 6.35186 11.2803C6.64476 10.9874 6.64476 10.5126 6.35186 10.2197L1.88219 5.75L6.35186 1.28033Z"
            fill="#B1B6CA"
          />
        </svg>
      </button>
    </div>
  );
}
