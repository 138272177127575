/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Group from './Group';
import ReportList from './ReportList';
import classes from './styles.module.scss';

import candidatesReportIcon from '../../../../assets/images/dashboard/reports/filter/candidates.svg';
import jobsReportIcon from '../../../../assets/images/dashboard/reports/filter/jobs.svg';

import newApplicationsIcon from '../../../../assets/images/dashboard/reports/filter/new-applications.svg';
// import assessmentIcon from '../../../../assets/images/dashboard/reports/filter/assessment.svg';
import interviewIcon from '../../../../assets/images/dashboard/reports/filter/interview.svg';
import offerIcon from '../../../../assets/images/dashboard/reports/filter/offer.svg';
import onboardingIcon from '../../../../assets/images/dashboard/reports/filter/onboarding.svg';
import joiningIcon from '../../../../assets/images/dashboard/reports/filter/joining.svg';

// import hideIcon from '../../../../assets/images/dashboard/reports/hide.svg';
import Tooltip from '../../../Tooltip';

export default function LeftPanel({
  savedReports,
  refetch,
  activeReport,
  setActiveReport,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  downloadReport,
  printReport,
  reportTypes,
}) {
  const { t } = useTranslation();

  const groups = [
    {
      listTitle: t(
        'dashboardComponents.Reports.LeftPanel.groups.byCategory.listTitle'
      ),
      list: [
        {
          icon: candidatesReportIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.candidateReport.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.candidateReport.reportInfo'
          ),
          type: 'Candidates',
        },
        {
          icon: jobsReportIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.jobsReport.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.jobsReport.reportInfo'
          ),
          type: 'Jobs',
        },
      ],
      listInfo: t(
        'dashboardComponents.Reports.LeftPanel.groups.byCategory.listInfo'
      ),
    },
    {
      listTitle: t(
        'dashboardComponents.Reports.LeftPanel.groups.byEvent.listTitle'
      ),
      list: [
        {
          icon: newApplicationsIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.newApplicants.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.newApplicants.reportInfo'
          ),
          type: 'Applications',
        },
        /*   {
          icon: assessmentIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.assessment.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.assessment.reportInfo'
          ),
          // type: 'Assessments',
        }, */
        {
          icon: interviewIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.interview.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.interview.reportInfo'
          ),
          type: 'Interviews',
        },
        {
          icon: offerIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.offer.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.offer.reportInfo'
          ),
          type: 'Offers',
        },
        {
          icon: onboardingIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.onboarding.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.onboarding.reportInfo'
          ),
          type: 'Onboardings',
        },
        {
          icon: joiningIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.joining.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.joining.reportInfo'
          ),
          type: 'Joinings',
        },
      ],
      listInfo: t(
        'dashboardComponents.Reports.LeftPanel.groups.byEvent.listInfo'
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setActiveReport(groups[0].list[0]);
    }, 300);
  }, []);

  return (
    <div
      className={classNames(classes.LeftPanel, {
        [classes.hide]: !isLeftPanelOpened,
      })}
    >
      <div className={classes.header}>
        <span className={classes.title}>
          {t('dashboardComponents.Reports.LeftPanel.reports')}
        </span>
        <Tooltip
          text={t('dashboardComponents.Reports.LeftPanel.hideSidebar')}
          direction="bottom-left"
          width={120}
        >
          <button
            className={classes.hideButton}
            type="button"
            onClick={() => setIsLeftPanelOpened(false)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.39978 1.45357C4.74567 1.58252 3.41383 2.03853 2.46446 2.9879C1.31749 4.13487 0.890625 5.84009 0.890625 7.9974V12.9974C0.890625 15.1547 1.31749 16.8599 2.46446 18.0069C3.41383 18.9563 4.74568 19.4123 6.39978 19.5412C6.47538 19.5668 6.55638 19.5807 6.64062 19.5807C6.69244 19.5807 6.74303 19.5755 6.7919 19.5655C7.0139 19.5758 7.2413 19.5807 7.47396 19.5807H12.474C14.6313 19.5807 16.3365 19.1539 17.4835 18.0069C18.6304 16.8599 19.0573 15.1547 19.0573 12.9974V7.9974C19.0573 5.84009 18.6304 4.13487 17.4835 2.9879C16.3365 1.84093 14.6313 1.41406 12.474 1.41406H7.47396C7.2413 1.41406 7.0139 1.41903 6.7919 1.42932C6.74303 1.41932 6.69244 1.41406 6.64062 1.41406C6.55638 1.41406 6.47537 1.42795 6.39978 1.45357ZM5.89062 3.01879C4.78951 3.18299 4.03756 3.53612 3.52512 4.04856C2.79709 4.77659 2.39062 5.98804 2.39062 7.9974V12.9974C2.39062 15.0068 2.79709 16.2182 3.52512 16.9462C4.03756 17.4587 4.78951 17.8118 5.89062 17.976V3.01879ZM7.39062 18.0805C7.41825 18.0807 7.44603 18.0807 7.47396 18.0807H12.474C14.4833 18.0807 15.6948 17.6743 16.4228 16.9462C17.1508 16.2182 17.5573 15.0068 17.5573 12.9974V7.9974C17.5573 5.98804 17.1508 4.77659 16.4228 4.04856C15.6948 3.32053 14.4833 2.91406 12.474 2.91406H7.47396C7.44603 2.91406 7.41825 2.91414 7.39062 2.9143V18.0805Z"
                fill="url(#paint0_linear_15263_125666)"
              />
              <path
                d="M12.4732 8.36719L10.3398 10.5005L12.4732 12.6339"
                stroke="url(#paint1_linear_15263_125666)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_15263_125666"
                  x1="9.97396"
                  y1="-8.81942"
                  x2="-10.493"
                  y2="9.34725"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00E4AC" />
                  <stop offset="0.504968" stopColor="#10C5E9" />
                  <stop offset="1" stopColor="#9240DC" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_15263_125666"
                  x1="11.4065"
                  y1="5.96373"
                  x2="7.81657"
                  y2="7.55696"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#00E4AC" />
                  <stop offset="0.504968" stopColor="#10C5E9" />
                  <stop offset="1" stopColor="#9240DC" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </Tooltip>
      </div>
      <div className={classes.container}>
        {groups.map((group) => (
          <Group
            group={group}
            activeReport={activeReport}
            setActiveReport={setActiveReport}
            key={group.listTitle}
            downloadReport={downloadReport}
            printReport={printReport}
          />
        ))}
        <ReportList
          listTitle={t('dashboardComponents.Reports.LeftPanel.savedReport')}
          listInfo={t(
            'dashboardComponents.Reports.LeftPanel.seeYourSavedReportsHere'
          )}
          reports={savedReports?.filter(
            (report) =>
              !reportTypes
                ?.map((rep) => rep.split('.').pop())
                .includes(report.title)
          )}
          refetch={refetch}
          activeReport={activeReport}
          setActiveReport={setActiveReport}
          editable
          downloadReport={downloadReport}
          printReport={printReport}
        />
      </div>
    </div>
  );
}
