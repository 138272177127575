import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function getCreatedDate(date, noWeekday) {
  const tempDate = new Date(date);

  let formattedDate = `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()} ${tempDate.toLocaleString(
    'en-us',
    {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
  )}
  `;

  if (noWeekday) {
    formattedDate = formattedDate.split(',').slice(1).join(',').trim();
  }

  return formattedDate;
}

export default function Details({
  event,
  setIsCandidateModalVisible,
  setCurrentCandidate,
  noBottomMargin,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.Details}>
      <div className={classes.recruiter}>
        <div>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Recruiter'
            )}
          </span>
          <div
            className={classes.description}
          >{`${event?.event?.organizer?.userProfile?.firstName} ${event?.event?.organizer?.userProfile?.lastName}`}</div>
        </div>
        <div>
          <span className={classes.title}>
            {t('dashboardComponents.Calendar.EventDetails.Onboarding.Sent')}
          </span>
          <div className={classes.description}>
            {getCreatedDate(event?.event?.createdAt)}
          </div>
        </div>
      </div>

      <div
        className={classes.eventInfo}
        style={{ marginBottom: noBottomMargin ? 0 : '' }}
      >
        <div className={classes.headerCol}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Candidate'
            )}
          </span>
          <span
            className={classes.name}
          >{`${event?.userFirstName} ${event?.userLastName}`}</span>
          <div
            className={classes.view}
            onClick={() => {
              setIsCandidateModalVisible(true);
              setCurrentCandidate(event?.candidate?.user);
            }}
          >
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.View Profile'
            )}
          </div>
        </div>
        <div className={classes.headerCol}>
          <span className={classes.title}>
            {t('dashboardComponents.Calendar.EventDetails.Onboarding.Position')}
          </span>
          <span className={classes.name}>{event?.jobPost?.jobTitle}</span>
          <div className={classes.jobId}>
            {t('dashboardComponents.Calendar.EventDetails.Onboarding.Job ID')}:{' '}
            <span>{event?.jobPost?.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
