import React from 'react';

import classNames from 'classnames';

import AverageTimeToFill from './AverageTimeToFill';
import MainStats from './MainStats';
import DashboardHeader from './DashboardHeader';

import classes from './styles.module.scss';
import StatsList from './StatsList';
import RecruitmentFunnel from './RecruitmentFunnel';
import RedAlertJobs from './RedAlertJobs';

export default function LeftPanel({
  data,
  location,
  setLocation,
  dateRange,
  setDateRange,
  city,
  setCity,
  defaultCityOption,
  defaultLocationOption,
  dashboardMode,
  setDashboardMode,
  dashboardModeOptions,
}) {
  return (
    <div className={classes.LeftPanel}>
      <div className={classNames(classes.row, classes.first)}>
        <div className={classNames(classes.col, classes.flexOne)}>
          <DashboardHeader
            location={location}
            setLocation={setLocation}
            dateRange={dateRange}
            setDateRange={setDateRange}
            city={city}
            setCity={setCity}
            defaultCityOption={defaultCityOption}
            defaultLocationOption={defaultLocationOption}
            dashboardMode={dashboardMode}
            setDashboardMode={setDashboardMode}
            dashboardModeOptions={dashboardModeOptions}
          />
          <MainStats data={data} />
        </div>
        <div className={classes.col}>
          <AverageTimeToFill data={data?.timeToHireByRole} />
        </div>
      </div>
      <div className={classNames(classes.row, classes.second)}>
        <StatsList data={data} />
      </div>
      <div className={classNames(classes.row, classes.third)}>
        <div className={classes.col}>
          <RecruitmentFunnel data={data} />
        </div>
        <div className={classNames(classes.col, classes.flexOne)}>
          <RedAlertJobs />
        </div>
      </div>
    </div>
  );
}
