import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import BrandName from '../Logo/BrandName';
import SaveResetButtons from '../SaveResetButtons';
import TextInput from '../../../../../../Form/TextInput';

import { BrandKitContext } from '../../../../../../../context/BrandKitContext';
import { hasContactsSettingsChanged } from '../../../../../../../helpers/compareBrandKitSettings';
import classes from './styles.module.scss';
import AdminService from '../../../../../../../services/AdminService';
import useUiContext from '../../../../../../../hooks/useUiContext';

export default function Contacts({
  supportEmail,
  setSupportEmail,
  contactEmail,
  setContactEmail,
  appName,
  setAppName,
  savedContactsSettings,
  // setSavedContactsSettings,
  tenantSettings,
  refetchTenantSettings,
}) {
  const [isTouched, setIsTouched] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const { saveButtonValidation, setSaveButtonValidation } =
    useContext(BrandKitContext);
  const { showNotification, setIsFetching, showUnknownErrorModal } =
    useUiContext();

  const { t } = useTranslation();

  const saveContactsSettings = async (values) => {
    try {
      setIsPending(true);
      setIsFetching(true);
      await AdminService.updateTenantSettings({
        ...tenantSettings,
        contacts: {
          supportEmail: values.supportEmail,
          contactEmail: values.contactEmail,
          appName,
        },
      });

      await refetchTenantSettings();
      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.published'
        ),
      });
    } catch (error) {
      console.error(error);
      showUnknownErrorModal();
    } finally {
      setTimeout(() => {
        setIsPending(false);
      }, 500);
      setIsFetching(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      supportEmail,
      contactEmail,
      appName,
    },
    validationSchema: Yup.object({
      supportEmail: Yup.string()
        .email(t('common.invalidEmail'))
        .required(t('common.requiredField')),
      contactEmail: Yup.string()
        .email(t('common.invalidEmail'))
        .required(t('common.requiredField')),
    }),
    onSubmit: saveContactsSettings,
  });

  // Sync local state with formik values
  useEffect(() => {
    setSupportEmail(formik.values.supportEmail);
    setContactEmail(formik.values.contactEmail);
  }, [
    contactEmail,
    supportEmail,
    formik.values.supportEmail,
    formik.values.contactEmail,
    setSupportEmail,
    setContactEmail,
  ]);

  useEffect(() => {
    const isDisabled =
      !hasContactsSettingsChanged(
        {
          supportEmail,
          contactEmail,
          appName,
        },
        savedContactsSettings
      ) ||
      !supportEmail ||
      !contactEmail ||
      !appName;

    setSaveButtonValidation((prevState) => ({
      ...prevState,
      isContactsSaveButtonDisabled: isDisabled,
    }));
  }, [
    appName,
    contactEmail,
    savedContactsSettings,
    setSaveButtonValidation,
    supportEmail,
  ]);

  const resetContactsSettings = () => {
    setAppName(savedContactsSettings.appName);
    formik.resetForm();
  };

  return (
    <div className={classes.Contacts}>
      <div className={classes.header}>
        <BrandName
          brandName={appName}
          setBrandName={setAppName}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.internalAppNameLabel'
          )}
        />
        <div className={classes.saveButtonsContainer}>
          <SaveResetButtons
            onSave={() => {
              setIsTouched(true);
              formik.handleSubmit();
            }}
            onReset={resetContactsSettings}
            isSaveDisabled={
              saveButtonValidation.isContactsSaveButtonDisabled || isPending
            }
            isResetDisabled={
              saveButtonValidation.isContactsSaveButtonDisabled || isPending
            }
          />
        </div>
      </div>
      <div className={classes.form}>
        <TextInput
          error={formik.errors.supportEmail}
          touched={isTouched}
          value={formik.values.supportEmail}
          name="supportEmail"
          onChange={formik.handleChange}
          noteStyle={{
            color: '#59c6c1',
            fontFamily: 'Gilroy-Bold',
          }}
          note={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.supportEmailInfo'
          )}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.supportEmailLabel'
          )}
          placeholder={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.supportEmailPlaceholder'
          )}
        />
        <TextInput
          name="contactEmail"
          error={formik.errors.contactEmail}
          value={formik.values.contactEmail}
          touched={isTouched}
          onChange={formik.handleChange}
          noteStyle={{
            color: '#D52D34',
            fontFamily: 'Gilroy-Bold',
          }}
          note={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.contactEmailInfo'
          )}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.contactEmailLabel'
          )}
          placeholder={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Contacts.contactEmailPlaceholder'
          )}
        />
      </div>
    </div>
  );
}
