import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// import { useQuery } from '@tanstack/react-query';

import TimeDropdown from './TimeDropdown';

import classes from './styles.module.scss';
// import UserService from '../../../services/UserService';
// import { getTimezoneDate } from '../../../helpers/timezones';

const isStartValueBeforeEndValue = (startValue, endValue) => {
  const [startHours, startMinutes] = startValue.split(':');
  const [endHours, endMinutes] = endValue.split(':');

  const startTime = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
  const endTime = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

  return startTime < endTime;
};

export default function TimeRange({
  label,
  fromValue,
  toValue,
  setFieldValue,
  fromName,
  toName,
  error,
  touched,
  showDuration,
  height,
  labelStyle,
  style,
  readOnly,
}) {
  const { t } = useTranslation();

  /*   const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });
 */
  let hoursDuration = 0;
  let minutesDuration = 0;
  let duration = 0;
  if (fromValue && toValue) {
    const [startHours, startMinutes] = fromValue.split(':');
    const [endHours, endMinutes] = toValue.split(':');
    const startTime =
      parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
    const endTime = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

    duration = endTime - startTime;

    if (duration < 60) {
      hoursDuration = 0;
    } else {
      hoursDuration = Math.floor(duration / 60);
    }

    minutesDuration = Math.max(duration % 60, 0);
  }

  // Set fromValue to current time if it's empty
  /*   useEffect(() => {
    if (!fromValue) {
      const currentDate = getTimezoneDate(
        new Date(),
        user?.userProfile?.timezone
      )[0];
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      const adjustedHours = hours % 24; // This wraps around the hour to 0 if it's 24.
      const formattedHours =
        adjustedHours < 10 ? `0${adjustedHours}` : `${adjustedHours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

      const startValue = `${formattedHours}:${formattedMinutes}`;

      setFieldValue(fromName, startValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromValue, fromName, user?.userProfile?.timezone, toValue]); */

  // On change of fromValue set toValue to 30 minutes later
  useEffect(() => {
    if (fromValue) {
      const [startHours, startMinutes] = fromValue.split(':');
      let endHours = +startHours;
      let endMinutes = +startMinutes + 30;

      if (endMinutes >= 60) {
        endHours += 1;
        endMinutes -= 60;
      }

      const formattedEndHours = endHours < 10 ? `0${endHours}` : `${endHours}`;
      const formattedEndMinutes =
        endMinutes < 10 ? `0${endMinutes}` : `${endMinutes}`;

      const endValue = `${formattedEndHours}:${formattedEndMinutes}`;

      setFieldValue(toName, endValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromValue, toName]);

  /*   // On change of toValue set fromValue to 30 minutes earlier
  useEffect(() => {
    if (toValue && !areValuesIn30MinutesInterval(fromValue, toValue)) {
      const [endHours, endMinutes] = toValue.split(':');
      let startHours = +endHours;
      let startMinutes = +endMinutes - 30;

      if (startMinutes < 0) {
        startHours -= 1;
        startMinutes += 60;
      }

      const formattedStartHours =
        startHours < 10 ? `0${startHours}` : `${startHours}`;
      const formattedStartMinutes =
        startMinutes < 10 ? `0${startMinutes}` : `${startMinutes}`;

      const startValue = `${formattedStartHours}:${formattedStartMinutes}`;

      console.log('START VALUE', startValue);
      console.log(areValuesIn30MinutesInterval(startValue, toValue));
      //  setFieldValue(fromName, startValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toValue, fromName]);
  */

  return (
    <div
      className={classNames(classes.TimeRange, {
        [classes.readOnly]: readOnly,
      })}
    >
      <div className={classes.title}>
        <h2 style={labelStyle}>
          {label || t('formComponents.TimeRange.title')}
        </h2>
        {showDuration ? (
          <div
            className={classNames(classes.duration, {
              [classes.gray]: duration < 30,
              [classes.gradient]: duration > 0 && duration <= 60,
              [classes.green]: duration === 90,
              [classes.orange]: duration === 120,
              [classes.red]: duration > 120,
            })}
          >
            <span className={classes.bold}>{hoursDuration}</span>{' '}
            {t('formComponents.TimeRange.hr')}
            <span className={classes.bold}>{minutesDuration}</span>
            {t('formComponents.TimeRange.mins')}
          </div>
        ) : (
          <span>(Your local time)</span>
        )}
      </div>
      <div className={classes.dropdowns}>
        <TimeDropdown
          name={fromName}
          setFieldValue={setFieldValue}
          value={fromValue}
          height={height}
          style={style}
          error={
            toValue &&
            fromValue &&
            !isStartValueBeforeEndValue(fromValue, toValue)
          }
        />
        <TimeDropdown
          name={toName}
          setFieldValue={setFieldValue}
          value={toValue}
          error={touched && error}
          // fromValue={fromValue}
          height={height}
          style={style}
        />
      </div>
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
}
