/* eslint-disable no-plusplus */
import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function checkConsecutiveChars(str, number) {
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    let counter = 1;

    for (let j = i + 1; j < str.length; j++) {
      if (str[j] !== char) {
        break;
      }
      if (str[j] === char) {
        counter++;
      }
      if (counter === number) {
        return true;
      }
    }
  }

  for (let i = 0; i < str.length; i++) {
    let counter = 1;

    for (let j = i + 1; j < str.length; j++) {
      if (str[j].charCodeAt() - str[j - 1].charCodeAt() !== 1) {
        break;
      }
      if (str[j].charCodeAt() - str[j - 1].charCodeAt() === 1) {
        counter++;
      }

      if (counter === number) {
        return true;
      }
    }
  }

  return false;
}

export default function PasswordValidation({
  newPassword,
  confirmNewPassword,
  setIsValid,
  noConfirm,
  style,
}) {
  const { t } = useTranslation();

  const isPasswordHasEnoughLength = newPassword.length >= 8;
  const isPasswordIncludesLetter = /[a-zA-Z]/.test(newPassword);
  const isPasswordIncludesNumber = /[0-9]/.test(newPassword);
  const isPasswordsMatch =
    noConfirm ||
    (newPassword === confirmNewPassword && newPassword?.length !== 0);

  const isPasswordHasntConsecutiveChars =
    !checkConsecutiveChars(newPassword, 4) && newPassword.length !== 0;

  useEffect(() => {
    if (
      isPasswordHasEnoughLength &&
      isPasswordIncludesLetter &&
      isPasswordIncludesNumber &&
      isPasswordsMatch &&
      isPasswordHasntConsecutiveChars
    ) {
      setIsValid?.(true);
    } else {
      setIsValid?.(false);
    }
  }, [
    isPasswordHasEnoughLength,
    isPasswordHasntConsecutiveChars,
    isPasswordIncludesLetter,
    isPasswordIncludesNumber,
    isPasswordsMatch,
    setIsValid,
  ]);

  return (
    <div className={classes.PasswordValidation} style={style}>
      <div className={classes.row}>
        <span className={classes.text}>
          {t(
            'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordsMust'
          )}
        </span>
        <ul className={classes.list}>
          <li
            className={classNames(classes.rule, {
              [classes.activeRule]: isPasswordHasEnoughLength,
            })}
          >
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.length'
            )}
          </li>
          <li
            className={classNames(classes.rule, {
              [classes.activeRule]: isPasswordIncludesLetter,
            })}
          >
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.letters'
            )}
          </li>
          <li
            className={classNames(classes.rule, {
              [classes.activeRule]: isPasswordIncludesNumber,
            })}
          >
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.numbers'
            )}
          </li>
          {!noConfirm && (
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordsMatch,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.match'
              )}
            </li>
          )}
        </ul>
      </div>
      <div className={classes.row}>
        <span className={classes.text}>
          {t(
            'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordsMustNot'
          )}
        </span>
        <ul className={classes.list}>
          <li
            className={classNames(classes.rule, {
              [classes.activeRule]: isPasswordHasntConsecutiveChars,
            })}
          >
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.consecutiveChars'
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
