import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TextInput from '../../components/Form/TextInput';
import InnerHeader from '../../components/Auth/InnerHeader';
import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';

import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import emailIcon from '../../assets/images/auth/email.svg';

export default function RequestNewPasswordPage() {
  const { showUnknownErrorModal } = useContext(UiContext);
  const navigate = useNavigate();
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .trim()
          .email(t('pages.RequestNewPasswordPage.emailValidationError'))
          .required(t('pages.RequestNewPasswordPage.requiredFieldError')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await UserService.resetPassword({ email: values.email });
        navigate('/request-password/success');
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    },
  });

  return (
    <div className={classes.RequestNewPasswordPage}>
      <Helmet>
        <title>{t('pages.RequestNewPasswordPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          withBackButton
          noButton
          title={t('pages.RequestNewPasswordPage.pageHeader')}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.inputContainer}>
            <TextInput
              icon={emailIcon}
              error={formik.errors.email}
              touched={isTriedToSubmit}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t('pages.RequestNewPasswordPage.emailLabel')}
              name="email"
              placeholder={t('pages.RequestNewPasswordPage.emailPlaceholder')}
              height={55}
            />
          </div>
        </form>
        <ButtonWithHelperText
          onClick={() => {
            setIsTriedToSubmit(true);
            formik.handleSubmit();
          }}
          isDisabled={!formik.values.email}
          helperText={t('pages.RequestNewPasswordPage.successMessage')}
          buttonLabel={t('pages.RequestNewPasswordPage.submitButton')}
        />
      </div>
    </div>
  );
}
