import React from 'react';

import { useMediaQuery } from 'react-responsive';

import Tooltip from '../../../../../../../Tooltip';

// import infoIcon from '../../../../../../../../assets/images/dashboard/brand-kit/info.svg';
import classes from './styles.module.scss';

export default function TitleWithTooltip({ title, text, tooltipWidth }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.TitleWithTooltip}>
      <h2>{title}</h2>
      <Tooltip
        text={text}
        fixed
        direction="bottom"
        width={isTabletOrMobile ? '200px' : tooltipWidth}
      >
        <div className={classes.icon}>
          <svg
            width="3"
            height="10"
            viewBox="0 0 3 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.25H1.03125"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 8.75V4.75"
              stroke="#242833"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Tooltip>
    </div>
  );
}
