import { useContext } from 'react';

import i18n from '../i18n';
import { UiContext } from '../context/UiContext';

const t = i18n.t.bind(i18n);

export default function useCreateData(
  createHandler,
  addFormVisibiltySetter,
  refetch,
  dataName,
  onError
) {
  const {
    showModal,
    showUnknownErrorModal,
    showDuplicateDataModal,
    setIsFetching,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  return {
    createData: async (values) => {
      try {
        setIsCreatingOrUpdating(true);
        setIsFetching(true);
        await createHandler(values);
        await refetch();
        addFormVisibiltySetter(false);
        showModal({
          title: t('hooks.useCreateData.successTitle'),
          text: `${dataName} ${t('hooks.useCreateData.successMessage')}`,
          dismissButtonLabel: t('hooks.useCreateData.addMoreButton'),
          confirmButtonLabel: t('common.gotIt'),
          confirmButtonVariant: '',
          onConfirm: () => {},
          onCancel: () => addFormVisibiltySetter(true),
        });
      } catch (error) {
        console.log(error);

        // This allows to add custom error handling to the component that uses this hook
        if (onError) {
          const shouldStop = onError(error);
          if (shouldStop) {
            return;
          }
        }

        if (error?.response?.data?.message === 'Duplicity') {
          showDuplicateDataModal(dataName);
        } else {
          showUnknownErrorModal();
        }
      } finally {
        setIsFetching(false);
        setIsCreatingOrUpdating(false);
      }
    },
  };
}
