import React, { useEffect, lazy, Suspense } from 'react';

import { Helmet } from 'react-helmet';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import NotFound from '../../components/NotFound';
import UnderMaintenance from '../../components/UnderMaintenance';
import Discover from '../../components/Dashboard/Discover';
import Chat from '../../components/Dashboard/Chat';
import Dashboard from '../../components/Dashboard/Dashboard';
import Candidates from '../../components/Dashboard/Candidates';
import Jobs from '../../components/Dashboard/Jobs';
import Console from '../../components/Dashboard/Console';
import Header from '../../components/Dashboard/Header';
import SideMenu from '../../components/Dashboard/SideMenu';
import Calendar from '../../components/Dashboard/Calendar';
import Search from '../../components/Dashboard/Search';
import ProfileSettings from '../../components/Dashboard/ProfileSettings';
import Reports from '../../components/Dashboard/Reports';

import { useUserStore } from '../../store';
import classes from './styles.module.scss';

const RestaurantsMap = lazy(() =>
  import('../../components/Dashboard/RestaurantsMap')
);

export default function DashboardPage() {
  const { page, '*': thirdLevelPage } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const isTabletOrMobile = useMediaQuery({
    query: pathname.includes('discover')
      ? '(max-width: 1224px)'
      : '(max-width: 1024px)',
  });

  const { firstAvailableLink } = useUserStore();

  const { t } = useTranslation();

  useEffect(() => {
    if (firstAvailableLink && pathname === '/tools') {
      navigate(firstAvailableLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstAvailableLink, pathname]);

  const renderPage = () => {
    if (
      thirdLevelPage === '' &&
      page !== 'discover' &&
      page !== 'jobs' &&
      page !== 'admin' &&
      page !== 'settings'
    ) {
      return <NotFound />;
    }

    if (thirdLevelPage && thirdLevelPage.includes('/')) {
      return <NotFound />;
    }

    switch (page) {
      case 'discover':
        return <Discover />;
      case 'dashboard':
        return <Dashboard />;
      case 'jobs':
        return <Jobs />;
      case 'candidates':
        return <Candidates />;
      case 'calendar':
        return <Calendar />;
      case 'reports':
        return <Reports />;
      case 'admin':
        return <Console />;
      case 'settings':
        return <ProfileSettings />;
      case 'search':
        return <Search />;
      case 'chat':
        return <Chat />;
      case 'map':
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <RestaurantsMap />
          </Suspense>
        );
      case '502':
        return <UnderMaintenance />;
      case '404':
      default:
        return <NotFound />;
    }
  };

  return (
    <div
      className={classNames(classes.DashboardPage, {
        [classes.mobile]: isTabletOrMobile,
      })}
    >
      <Helmet>
        <title>{t(`common.dashboardSections.${page}`)}</title>
      </Helmet>
      <SideMenu />
      <div className={classes.container} id="dashboardPageContainer">
        <Header />
        {renderPage()}
      </div>
    </div>
  );
}
