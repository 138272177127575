import React from 'react';

import { useTranslation } from 'react-i18next';

import SidePopup from '../../../SidePopup';
import TextInput from '../../../../../Form/TextInput';

import getResizedImageUrl from '../../../../../../helpers/getResizedImageUrl';
import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import classes from './styles.module.scss';

export default function ViewCity({
  isVisible,
  handleClose,
  cities,
  currentCityId,
  deleteCities,
  showEditCity,
  isOverlayed,
}) {
  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(cities, currentCityId);

  return (
    <SidePopup
      mode="view"
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={t(
        'dashboardComponents.Console.RestaurantManagement.Cities.ViewCity.title'
      )}
      leftButtonLabel={t('common.delete')}
      rightButtonLabel={t('common.edit')}
      switchNext={cities?.length > 1 && switchToNextDataElement}
      switchPrev={cities?.length > 1 && switchToPrevDataElement}
      onLeftButtonClick={async () => {
        deleteCities([cities[currentDataIndex].id], handleClose);
      }}
      onRightButtonClick={() => showEditCity(cities[currentDataIndex].id)}
      isOverlayed={isOverlayed}
    >
      <div className={classes.ViewCity}>
        <div className={classes.col}>
          <TextInput
            height={50}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.ViewCity.labels.City Name'
            )}
            value={cities?.[currentDataIndex]?.name}
            readonly
          />
          <TextInput
            height={50}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.ViewCity.labels.Country'
            )}
            value={cities?.[currentDataIndex]?.country}
            readonly
          />
          <TextInput
            height={50}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Cities.ViewCity.labels.Zipcode'
            )}
            value={cities?.[currentDataIndex]?.postCode}
            readonly
          />
        </div>
        <div className={classes.col}>
          <div className={classes.coverImage}>
            <h1>Picture</h1>
            <div className={classes.imageContainer}>
              <img
                src={getResizedImageUrl({
                  url: `city/${cities?.[currentDataIndex]?.coverImageName}`,
                  width: 450,
                })}
                alt={cities?.[currentDataIndex]?.name}
              />
            </div>
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
