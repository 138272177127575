import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import InfoIcon from '../../../../components/InfoIcon';
import Test from './Test';

export default function Assessment({
  assessment,
  isSelected,
  searchTerm,
  setFields,
}) {
  const [isListVisible, setIsListVisible] = useState(false);

  // Show the list of assessments if the search term is not empty
  useEffect(() => {
    if (searchTerm) {
      setIsListVisible(true);
    }
  }, [searchTerm]);

  const toggleSelection = (test) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.assessmentProviderKey === test.assessmentProviderKey) {
          if (field.tests[0].type === 'radio') {
            return {
              ...field,
              tests: field.tests.map((testField) => {
                if (testField.name === test.name) {
                  return {
                    ...testField,
                    isSelected: !testField.isSelected,
                  };
                }
                return {
                  ...testField,
                  isSelected: false,
                };
              }),
            };
          }
          return {
            ...field,
            tests: field.tests.map((testField) => {
              if (testField.name === test.name) {
                return {
                  ...testField,
                  isSelected: !testField.isSelected,
                };
              }
              return testField;
            }),
          };
        }
        return field;
      });
    });
  };

  const handleClickOnProvider = () => {
    toggleSelection({ name: assessment.name, tests: [] });
  };

  return (
    <div
      className={classNames(classes.Assessment, {
        [classes.active]: isSelected,
      })}
    >
      <div
        className={classes.container}
        onClick={(event) => {
          event.stopPropagation();
          setIsListVisible(!isListVisible);
        }}
      >
        <div className={classes.checkbox} onClick={handleClickOnProvider} />
        <span onClick={handleClickOnProvider}>{assessment?.name}</span>
        <InfoIcon
          infoWidth={120}
          info={assessment?.info}
          tooltipDirection="bottom-right"
          fixed
        />
        <span
          className={classNames(classes.arrow, {
            [classes.arrowUp]: isListVisible,
          })}
        />
      </div>
      {!!isListVisible &&
        assessment?.tests?.map((test) => {
          if (!test.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return null;
          }
          return (
            <Test
              key={test.assessmentId}
              test={test}
              isSelected={test.isSelected}
              toggleTestSelection={toggleSelection}
            />
          );
        })}
    </div>
  );
}
