import React from 'react';

// import { useQuery } from '@tanstack/react-query';

// import AdminService from '../../../services/AdminService';
import classes from './styles.module.scss';
import viewedIcon from '../../../assets/images/dashboard/candidates/view-icon.svg';
import notViewedIcon from '../../../assets/images/dashboard/candidate/header/closeEye.svg';

export default function JobAppViewNotificationIcon({ style, isSeen }) {
  /* const { data: jobApplicationNotifications } = useQuery({
    queryKey: ['jobApplicationNotifications', jobApplication?.id],
    queryFn: () =>
      AdminService.getJobApplicationNotifications(jobApplication?.id),
    enabled: !!jobApplication?.id,
  });

 */

  return (
    <img
      style={style}
      src={isSeen ? viewedIcon : notViewedIcon}
      alt="Viewed icon"
      className={classes.JobAppViewNotificationIcon}
    />
  );
}
