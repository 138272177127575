import React from 'react';

import classes from './styles.module.scss';

export default function ResumeUploadProgress({ progress }) {
  return (
    <div className={classes.ResumeUploadProgress}>
      <div className={classes.progress}>
        <span>{progress}%</span>
        <div
          className={classes.progressBar}
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
    </div>
  );
}
