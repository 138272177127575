import React, { useContext, useState, useRef, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import DropDown from './Dropdown';
import TypeSwitch from './TypeSwitch';
// import Button from '../../../Button';
import Search from '../../Header/Search';
import RecruiterInterview from '../EventDetails/Interview/RecruiterInterview';

import { SearchContext } from '../../../../context/SearchContext';
import classes from './styles.module.scss';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
// import sortIcon from '../../../../assets/images/dashboard/sort-icon.svg';
import { getAllTimezonesNames } from '../../../../helpers/timezones';
// import plusIcon from '../../../../assets/images/dashboard/calendar/plus.svg';

export default function CalendarMenu({
  activeType,
  setActiveType,
  setCurrentDate,
  events,
  user,
  timezone,
  setTimezone,
  dateToday,
  // isReadOnly,
}) {
  const [searchPopupIsOpened, setSearchPopupIsOpened] = useState(true);
  const [isScheduleInterviewVisible, setIsScheduleInterviewVisible] =
    useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isTimezonesDropdownOpened, setIsTimezonesDropdownOpened] =
    useState(false);
  const [searchResultCount, setSearchResultCount] = useState(0);

  const timezonesRef = useRef();

  const { searchTerm, setSearchTerm } = useContext(SearchContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'calendarSearchHistory'
  );

  const setToday = () => {
    setCurrentDate(new Date(dateToday));
    setActiveType('Day');
  };

  /*  const openScheduleInterviewWindow = () => {
    setIsScheduleInterviewVisible(true);
  };
 */

  const timezones = useMemo(() => getAllTimezonesNames(), []);

  return (
    <div className={classes.CalendarMenu}>
      <div className={classes.container}>
        <h1 className={classes.title}>Calendar</h1>
        <div
          className={classes.today}
          onClick={setToday}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setToday();
            }
          }}
        >
          {t('dashboardComponents.Calendar.CalendarMenu.today')}
        </div>
      </div>

      <div className={classes.flexContainer}>
        {/* <div className={classes.viewContainer}>
          {viewTypes.map((type) => (
            <div
              className={classNames(classes.view, {
                [classes.active]: activeType === type.text,
              })}
              onClick={() => setActiveType(type.text)}
              key={type.text}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setActiveType(type.text);
                }
              }}
            >
              <span className={classes.text}>
                {t(
                  `dashboardComponents.Calendar.CalendarMenu.viewTypes.${type.text}`
                )}
              </span>
              <span className={classes.counter}>{type.amount}</span>
            </div>
          ))}
          <div
            className={classes.zone}
            ref={timezonesRef}
            onClick={() =>
              setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened)
            }
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened);
              }
            }}
          >
            {timezone}
            {isTimezonesDropdownOpened && (
              <DropDown
                options={timezones}
                value={timezone}
                setValue={setTimezone}
                width="400px"
                handleClose={() => setIsTimezonesDropdownOpened(false)}
                labelRef={timezonesRef}
              />
            )}
          </div>
        </div> */}

        <div className={classes.viewContainer}>
          <div
            className={classes.zone}
            ref={timezonesRef}
            onClick={() =>
              setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened)
            }
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened);
              }
            }}
          >
            <div className={classes.content}>
              {timezone}
              <div className={classes.arrow} />
            </div>
            {isTimezonesDropdownOpened && (
              <DropDown
                options={timezones}
                value={timezone}
                setValue={setTimezone}
                width="400px"
                handleClose={() => setIsTimezonesDropdownOpened(false)}
                labelRef={timezonesRef}
              />
            )}
          </div>
        </div>
        {!isTabletOrMobile && (
          <TypeSwitch
            events={events}
            activeType={activeType}
            setActiveType={setActiveType}
            timezone={timezone}
            setTimezone={setTimezone}
          />
        )}
        {searchTerm.length > 0 && searchPopupIsOpened ? (
          <div className={classes.searchResultCount}>
            {searchResultCount}{' '}
            {searchResultCount === 1
              ? t('dashboardComponents.Console.TableMenu.recordFound')
              : t('dashboardComponents.Console.TableMenu.recordsFound')}
          </div>
        ) : null}
        <div className={classes.form}>
          <div className={classes.search}>
            <input
              className={classes.searchInput}
              type="text"
              placeholder={t(
                'dashboardComponents.Calendar.CalendarMenu.searchPlaceholder'
              )}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              onFocus={() => {
                setIsFocused(true);
                setSearchPopupIsOpened(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchPopupIsOpened(true);
                }
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsFocused(false);
                }, 150);
              }}
            />
            {isFocused && !searchTerm.length && searchHistory.length ? (
              <ul className={classes.searchHistory}>
                {searchHistory.map((record) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li key={record} onClick={() => setSearchTerm(record)}>
                    {record}
                  </li>
                ))}
              </ul>
            ) : null}
            {searchTerm && (
              <button
                type="button"
                onClick={() => {
                  setSearchTerm('');
                  setIsFocused(false);
                }}
                className={classes.button}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#F2F4F8" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9142 15.0252L19.9194 11.02C20.2123 10.7271 20.6872 10.7271 20.9801 11.02C21.273 11.3129 21.273 11.7877 20.9801 12.0806L16.9749 16.0858L20.9801 20.091C21.273 20.3839 21.273 20.8588 20.9801 21.1517C20.6872 21.4446 20.2123 21.4446 19.9194 21.1517L15.9142 17.1465L11.909 21.1517C11.6161 21.4446 11.1412 21.4446 10.8483 21.1517C10.5554 20.8588 10.5554 20.3839 10.8483 20.091L14.8535 16.0858L10.8483 12.0806C10.5554 11.7877 10.5554 11.3129 10.8483 11.02C11.1412 10.7271 11.6161 10.7271 11.909 11.02L15.9142 15.0252Z"
                    fill="#242833"
                  />
                </svg>
              </button>
            )}

            {searchTerm.length > 0 && searchPopupIsOpened && (
              <Search
                searchTerm={searchTerm}
                currentTab="Calendar"
                handleClose={() => setSearchPopupIsOpened(false)}
                timezone={timezone}
                long
                isVisible
                onSearchFinish={(data) =>
                  setSearchResultCount(data?.calendarEvents?.length)
                }
              />
            )}
          </div>

          {/* <Button
            variant="secondary"
            width={45}
            height={45}
            style={{ backgroundColor: 'white' }}
            isAnimation
          >
            <img src={sortIcon} alt="Sort" />
          </Button> */}

          {/* {!isReadOnly && (
            <button
              type="button"
              className={classes.schedule}
              onClick={openScheduleInterviewWindow}
            >
              <img src={plusIcon} alt="Plus" />
              <span>
                {t('dashboardComponents.Calendar.CalendarMenu.schedule')}
              </span>
            </button>
          )} */}
        </div>
      </div>

      {isScheduleInterviewVisible && (
        <RecruiterInterview
          isScheduleInterviewVisible={isScheduleInterviewVisible}
          setIsScheduleInterviewVisible={setIsScheduleInterviewVisible}
          currentUser={user}
        />
      )}
    </div>
  );
}
