import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import EventDetails from '../../../EventDetails';
import classes from './styles.module.scss';

export default function Group({ group, events, refetch, user }) {
  const [isOpened, setIsOpened] = useState(false);
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { t } = useTranslation();

  const toggleGroupShowing = () => {
    if (isOpened) {
      setIsOpened(false);

      return;
    }

    setIsOpened(true);
  };

  useEffect(() => {
    if (group.group === 'Today') {
      setIsOpened(true);
    }
  }, [group.group]);

  useEffect(() => {
    setSelectedEvent(events.find((event) => event?.id === selectedEvent?.id));
  }, [events, selectedEvent?.id]);

  return (
    <div className={classes.eventGroup}>
      <div
        className={classNames(classes.header, {
          [classes.isOpened]: isOpened,
        })}
      >
        <span className={classes.group}>
          {t(
            `dashboardComponents.Calendar.SideCalendar.EventGroups.Group.${group.group}`
          )}
        </span>
        <div className={classes.container}>
          <span className={classes.counter}>{group.amount}</span>
          <div
            className={classNames(classes.show, { [classes.hide]: isOpened })}
            onClick={toggleGroupShowing}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                toggleGroupShowing();
              }
            }}
          />
        </div>
      </div>

      {isOpened && (
        <div
          className={classNames(classes.events, {
            [classes.noEvents]: events.length === 0,
          })}
        >
          {events.length === 0 ? (
            <p>
              {t(
                'dashboardComponents.Calendar.SideCalendar.EventGroups.Group.No events scheduled'
              )}
            </p>
          ) : (
            <>
              {events
                .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
                .map((event) => {
                  const {
                    id,
                    type,
                    userFirstName,
                    userLastName,
                    time,
                    eventDate,
                  } = event;
                  const text = `${type} with ${userFirstName} ${userLastName}`;
                  let visibleTime;

                  if (group.group === 'Today') {
                    visibleTime = time;
                  } else if (time.includes('Invalid')) {
                    visibleTime = `${moment(eventDate).format('Do MMM')}`;
                  } else {
                    visibleTime = `${moment(eventDate).format(
                      'Do MMM'
                    )} at ${time}`;
                  }

                  return (
                    <div
                      className={classes.event}
                      key={id}
                      onClick={() => {
                        setSelectedEvent(event);
                        setIsEventDetailsVisible(true);
                      }}
                    >
                      <span className={classes.text}>{text}</span>
                      <span className={classes.date}>{visibleTime}</span>
                    </div>
                  );
                })}
              {isEventDetailsVisible && (
                <EventDetails
                  isEventDetailsVisible={isEventDetailsVisible}
                  handleClose={() => {
                    setIsEventDetailsVisible(false);
                    setSelectedEvent(null);
                  }}
                  event={selectedEvent}
                  refetch={refetch}
                  currentUser={user}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
