import { useEffect, useState, useRef } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import flattenPaginatedData from '../helpers/flattenPaginatedData';
import useIntersectionObserverPagination from './useIntersectionObserverPagination';

export default function usePaginatedDropdownOptions({
  dataName,
  getDataHandler,
  searchTerm,
  labelName = 'name',
  isUserList,
  fetchHanlderOptions,
}) {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const timer = useRef();

  const { data, fetchNextPage, isFetching, refetch } = useInfiniteQuery({
    queryKey: [dataName, debouncedSearchTerm, fetchHanlderOptions],
    queryFn: ({ pageParam = 1 }) => {
      return getDataHandler?.({
        // orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        pageNumber: pageParam,
        ...(fetchHanlderOptions || {}),
      });
    },
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage?.nextPage;
    },
    enabled: !!getDataHandler,
  });

  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
  }, [searchTerm]);

  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, refetch]);

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  const options = flattenPaginatedData(data)?.map?.((value) => ({
    label: isUserList
      ? `${value?.userProfile?.firstName} ${value?.userProfile?.lastName} (${value?.userName})`
      : value?.[labelName],
    value: value?.id,
  }));

  return {
    options,
    lastListElementRef,
  };
}
