/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Screen({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      ApplicationPendingScreen: {
        title: t(
          'dashboardComponents.Notifications.Notification.Screen.screenPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Screen.screenPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Screen.screenPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      AssessmentCompleted: {
        title: t(
          'dashboardComponents.Notifications.Notification.Screen.screenCompleted.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Screen.screenCompleted.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Screen.screenCompleted.buttonLabel'
        ),
        onClick: goToJob,
      },
    };

    return (
      content[notification.name] ||
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
