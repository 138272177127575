import axios from 'axios';

import { PUBLIC_CMS_API_URL } from '../constants/main';

const TenantSettingService = {
  async getTenantSettings() {
    const response = await axios.get(`${PUBLIC_CMS_API_URL}/tenantsettings`);
    return response.data;
  },
};

export default TenantSettingService;
