/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import Tabs from './Tabs';
import MessageEditor from './MessageEditor';
import PollEditor from './PollEditor';
import AppreciationEditor from './AppreciationEditor';

import processEditingPostContent, {
  addQuotesToQuoteContainers,
} from '../../../../helpers/processEditingPostContent';
import processPostContent from '../../../../helpers/processPostContent';
import { useFeedStore, usePersistedFeedStore } from '../../../../store';
import { UiContext } from '../../../../context/UiContext';
import FeedService from '../../../../services/FeedService';
import classes from './styles.module.scss';

function createAttachmentComparisonArrays(attachments) {
  if (!attachments) {
    return [];
  }

  return attachments.map((attach, index) => {
    const currentAttach = {
      ...attach,
      sortOrder: index,
      url: attach.uri || attach.url,
    };
    if (attach.uri) {
      delete currentAttach.uri;
    }

    return currentAttach;
  });
}

function hasEditingPostChanged(currentFeedPost, savedPost) {
  if (!currentFeedPost || !savedPost) {
    return true;
  }

  const currentFeedPostComparisionObject = {
    title: currentFeedPost.title,
    content: currentFeedPost.content,
    tags: currentFeedPost?.tags || [],
    recipientIds: currentFeedPost.recipientIds || [],
    mentionIds:
      currentFeedPost.mentionIds
        ?.filter((addressee) => addressee !== 'To all the employee')
        .map((addressee) => addressee) || [],
    attachments:
      createAttachmentComparisonArrays(currentFeedPost.attachments) || [],
    pollQuestions:
      currentFeedPost.pollQuestions?.filter((qstn) => !!qstn.name) || [],
  };

  const pollQuestions = [];

  if (savedPost.polls.length) {
    savedPost.polls[0].questions.forEach((qstn) => {
      const question = {
        id: qstn.id,
        limit: qstn.limit,
        name: qstn.title,
        allowsMultipleAnswers: qstn.allowsMultipleAnswers,
        answers: qstn.options.map((opt) => {
          return {
            id: opt.id,
            name: opt.title,
          };
        }),
      };
      pollQuestions.push(question);
    });
  }

  const savedPostComparisionObject = {
    title: savedPost.title,
    content: savedPost.content,
    tags: savedPost.tags || [],
    recipientIds:
      savedPost.recipients.map((recipient) => recipient.user?.id) || [],
    mentionIds: savedPost.mentions.map((addressee) => addressee.id) || [],
    attachments: createAttachmentComparisonArrays(savedPost.attachments) || [],
    pollQuestions,
  };

  return !isEqual(currentFeedPostComparisionObject, savedPostComparisionObject);
}

export default function AddContent({
  setIsEditorVisible,
  isEditorVisible,
  sharedClasses,
  editingPost,
  setEditingPost,
}) {
  const [activeTab, setActiveTab] = useState('Message');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [savedDraft, setSavedDraft] = useState(null);
  const [isDraftButtonDisabled, setIsDraftButtonDisabled] = useState(false);
  const [currentFeedPost, setCurrentFeedPost] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pollQuestions, setPollQuestions] = useState([
    {
      id: Math.random(),
      name: '',
      limit: 2,
      answers: [
        {
          name: '',
          id: Math.random(),
        },
        {
          name: '',
          id: Math.random(),
        },
      ],
      allowsMultipleAnswers: false,
    },
  ]);

  const [recipients, setRecipients] = useState([]);

  const textBoxRef = useRef();
  const addContentRef = useRef();

  const { showModal, setIsFetching, showNotification, isFetching } =
    useContext(UiContext);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [mentions, setMentions] = useState([
    {
      label: t('dashboardComponents.Discover.AddContent.toAllMembers'),
      value: 'To all the employee',
    },
  ]);

  const tabs = [
    t('dashboardComponents.Discover.AddContent.tabs.message'),
    t('dashboardComponents.Discover.AddContent.tabs.announcement'),
    t('dashboardComponents.Discover.AddContent.tabs.appreciation'),
    t('dashboardComponents.Discover.AddContent.tabs.poll'),
  ];

  const setEditingPostRef = useFeedStore((state) => state.setEditingPostRef);
  const editingPostRef = useFeedStore((state) => state.editingPostRef);
  const setQuoteContainers = useFeedStore((state) => state.setQuoteContainers);
  const quoteContainers = useFeedStore((state) => state.quoteContainers);
  const setLastVisibleFeedPostId = usePersistedFeedStore(
    (state) => state.setLastVisibleFeedPostId
  );

  useEffect(() => {
    if (!isEditorVisible) {
      setQuoteContainers([]);
    }
  }, [isEditorVisible, setQuoteContainers]);

  const cleanUp = useCallback(() => {
    setFileNames([]);
    setIsEditorVisible(false);
    setTitle('');
    setMessage('');
    setRecipients([]);
    setTags([]);
    setFiles([]);
    setUploadedFiles([]);
    setEditingPost(null);
    setSavedDraft(null);

    setMentions([
      {
        label: t('dashboardComponents.Discover.AddContent.toAllMembers'),
        value: 'To all the employee',
      },
    ]);
    setPollQuestions([
      {
        id: Math.random(),
        name: '',
        limit: 2,
        answers: [
          {
            name: '',
            id: Math.random(),
          },
          {
            name: '',
            id: Math.random(),
          },
        ],
        allowsMultipleAnswers: false,
      },
    ]);
  }, [setEditingPost, setIsEditorVisible, t]);

  const formPost = (status) => {
    return {
      type: activeTab,
      title,
      content: processPostContent(textBoxRef, sharedClasses),
      status,
      tags,
      recipientIds: recipients.map((recipient) => recipient.value) || [],
      mentionIds:
        mentions
          .filter((addressee) => addressee.value !== 'To all the employee')
          .map((addressee) => addressee.value) || [],
      attachments:
        uploadedFiles?.map((file, index) => ({
          fileName: file.fileName,
          displayName: fileNames[index],
          url: file.uri,
          mimeType: file.mimeType,
          sortOrder: index,
          fileSize: file.fileSize,
        })) || [],
    };
  };

  const refetchFeedPosts = async () => {
    return queryClient.invalidateQueries({ queryKey: ['feedPosts'] });
  };

  const createFeedPost = async (status = 'Published', noCleanUp) => {
    const feedPost = formPost(status);

    try {
      setIsFetching(true);
      const createdPost = await FeedService.createFeedPost(feedPost);
      setLastVisibleFeedPostId(createdPost.id);

      if (noCleanUp) {
        return createdPost;
      }

      if (status !== 'Published') {
        delete feedPost.status;
        delete feedPost.attachments;
        delete feedPost.recipientIds;
        delete feedPost.type;

        const { content, quotes } = processEditingPostContent(
          createdPost.content,
          sharedClasses
        );

        const editingPst = {
          ...createdPost,
          content: textBoxRef.current.innerHTML,
        };

        setQuoteContainers(quotes);
        setSavedDraft({ ...feedPost, content });
        setEditingPost(editingPst);

        showNotification({
          text: t('dashboardComponents.Discover.AddContent.postSavedAsDraft'),
        });
      }

      await refetchFeedPosts();

      if (status === 'Published') {
        cleanUp();
        showNotification({
          text: t('dashboardComponents.Discover.AddContent.postPublished'),
        });
      }
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    } finally {
      if (!noCleanUp) {
        setIsFetching(false);
      }
    }
  };

  const formPoll = () =>
    pollQuestions.map((question, index) => ({
      title: question.name,
      limit: question.limit,
      sortOrder: index,
      allowsMultipleAnswers: question.allowsMultipleAnswers,
      options: question.answers.map((answer, idx) => ({
        title: answer.name,
        sortOrder: idx,
      })),
    }));

  const createPoll = async (status) => {
    try {
      const createdPost = await createFeedPost(status, true);

      const pollQuestionToSave = formPoll();

      const createdPoll = await FeedService.createPoll({
        feedPostId: createdPost.id,
        questions: pollQuestionToSave,
      });

      setIsFetching(true);

      if (status === 'Draft') {
        await refetchFeedPosts();
        setEditingPost({ ...createdPost, polls: [createdPoll] });
        //  setSavedDraftPoll(pollQuestions);
        setSavedDraft({ ...savedDraft, polls: [createdPoll] });
        showNotification({
          text: t('dashboardComponents.Discover.AddContent.postPublished'),
        });
      } else {
        await FeedService.startPoll({
          feedPostId: createdPost.id,
          pollId: createdPoll.id,
        });
        await refetchFeedPosts();
        cleanUp();
        showNotification({
          text: t('dashboardComponents.Discover.AddContent.postPublished'),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const updateFeedPost = async (noCleanUp, status) => {
    const feedPost = formPost(status || editingPost.status);

    try {
      setIsFetching(true);
      const updatedPost = await FeedService.updateFeedPost({
        ...feedPost,
        feedPostId: editingPost.id,
        isPinned: editingPost.isPinned,
      });

      if (noCleanUp) {
        return updatedPost;
      }

      if (updatedPost.status === 'Draft') {
        const { quotes } = processEditingPostContent(
          updatedPost.content,
          sharedClasses
        );

        const editingPst = {
          ...updatedPost,
          content: textBoxRef.current.innerHTML,
        };

        setQuoteContainers(quotes);
        setEditingPost(editingPst);

        await refetchFeedPosts();
        return updatedPost;
      }

      await refetchFeedPosts();
      cleanUp();
      showNotification({
        text: t('dashboardComponents.Discover.AddContent.changesSaved'),
      });
      if (editingPostRef?.current) {
        editingPostRef?.current?.scrollIntoView?.();
        setEditingPostRef(null);
      } else {
        navigate(`/tools/discover/posts/${updatedPost.id}`);
      }
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    } finally {
      if (!noCleanUp) {
        setIsFetching(false);
      }
    }
  };

  const updatePoll = async (status) => {
    try {
      setIsFetching(true);
      const updatedPost = await updateFeedPost(true, status);

      await FeedService.deletePoll({
        feedPostId: updatedPost.id,
        pollId: updatedPost.polls[0].id,
      });

      const pollQuestionToSave = formPoll();

      const poll = await FeedService.createPoll({
        feedPostId: updatedPost.id,
        questions: pollQuestionToSave,
      });

      await refetchFeedPosts();

      if (updatedPost?.status === 'Draft') {
        setEditingPost({ ...updatedPost, polls: [poll] });
        return;
      }

      cleanUp();

      if (editingPostRef?.current) {
        editingPostRef?.current?.scrollIntoView?.();
        setEditingPostRef(null);
      } else {
        navigate(`/tools/discover/posts/${updatedPost.id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const cancel = () => {
    const post = formPost();

    const container = document.createElement('div');
    container.innerHTML = editingPost?.content;

    const editingPostWithQuotes = editingPost
      ? {
          ...editingPost,
          content: addQuotesToQuoteContainers(
            container,
            sharedClasses,
            quoteContainers
          ),
        }
      : null;

    const hasChanged = editingPost
      ? hasEditingPostChanged(
          {
            ...post,
            attachments: uploadedFiles,
            pollQuestions,
          },
          editingPostWithQuotes
        )
      : false;

    if ((message && !editingPost) || hasChanged) {
      showModal({
        title: t('dashboardComponents.Discover.AddContent.exitForm.title'),
        text: t('dashboardComponents.Discover.AddContent.exitForm.text'),
        onCancel: cleanUp,
        onConfirm: () => {},
        dismissButtonLabel: t('common.yes'),
        confirmButtonLabel: t('common.no'),
      });
    } else {
      cleanUp();
    }
  };

  const createAppreciation = async () => {
    try {
      if (!recipients.length) {
        showModal({
          title: t(
            'dashboardComponents.Discover.AddContent.recipientsNeeded.title'
          ),
          text: t(
            'dashboardComponents.Discover.AddContent.recipientsNeeded.text'
          ),
          dismissButtonLabel: t(
            'dashboardComponents.Discover.AddContent.recipientsNeeded.dismissButtonLabel'
          ),
          dismissButtonVariant: 'modal',
        });
      } else {
        createFeedPost('Published');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Check if save as draft button should be disabled
  useEffect(() => {
    if (activeTab === 'Poll') {
      const noQuestions = !pollQuestions.filter(
        (qstn) => !!qstn.name && qstn.answers?.every((answ) => !!answ.name)
      ).length;
      const hasChanged =
        !isEqual(pollQuestions, pollQuestions) ||
        hasEditingPostChanged(
          {
            ...currentFeedPost,
            attachments: uploadedFiles,
            pollQuestions,
          },
          editingPost
        );

      setIsDraftButtonDisabled(!hasChanged || !mentions.length || noQuestions);
    } else {
      const noText = !textBoxRef.current?.innerText.trim().length;
      const hasChanged = hasEditingPostChanged(
        { ...currentFeedPost, attachments: uploadedFiles },
        editingPost
      );

      setIsDraftButtonDisabled(noText || !hasChanged || !mentions.length);
    }
  }, [
    currentFeedPost,
    message,
    savedDraft,
    setIsDraftButtonDisabled,
    activeTab,
    pollQuestions,
    mentions.length,
    uploadedFiles,
    editingPost,
  ]);

  useEffect(() => {
    setCurrentFeedPost({
      title,
      content: message,
      mentionIds: mentions
        .map((addressee) => addressee.value)
        .filter((addressee) => addressee !== 'allMembers'),
      tags,
    });
  }, [mentions, message, setCurrentFeedPost, tags, title]);

  // Enable text post edit mode
  useEffect(() => {
    if (editingPost) {
      if (editingPost.polls.length) {
        setActiveTab(t('dashboardComponents.Discover.AddContent.tabs.poll'));
      } else if (
        editingPost.type ===
        t('dashboardComponents.Discover.AddContent.tabs.appreciation')
      ) {
        setActiveTab(
          t('dashboardComponents.Discover.AddContent.tabs.appreciation')
        );
      } else if (
        editingPost.type ===
        t('dashboardComponents.Discover.AddContent.tabs.announcement')
      ) {
        setActiveTab(
          t('dashboardComponents.Discover.AddContent.tabs.announcement')
        );
      } else {
        setActiveTab(t('dashboardComponents.Discover.AddContent.tabs.message'));
      }

      setIsEditorVisible(true);

      setTimeout(() => {
        setTitle(editingPost.title);
        setMessage(editingPost.content);
        textBoxRef.current.innerHTML = editingPost.content;
        setMentions(
          editingPost.mentions.length
            ? editingPost.mentions.map((recipient) => ({
                value: recipient.id,
                label: `${recipient.fullName}`,
              }))
            : [
                {
                  label: t(
                    'dashboardComponents.Discover.AddContent.toAllMembers'
                  ),
                  value: 'To all the employee',
                },
              ]
        );
        setTags(editingPost?.tags?.map((tag) => tag.value));
        setUploadedFiles(
          editingPost?.attachments?.map((attachment) => ({
            fileName: attachment.fileName,
            displayName: attachment.displayName,
            uri: attachment.url,
            mimeType: attachment.mimeType,
            fileSize: attachment.fileSize,
          }))
        );

        setFiles(
          editingPost?.attachments?.map((attachment) => ({
            name: attachment.displayName,
            type: attachment.mimeType,
            size: attachment.fileSize,
          }))
        );
        setFileNames(
          editingPost?.attachments?.map((attachment) => attachment.displayName)
        );

        if (!savedDraft) {
          addContentRef.current.scrollIntoView();
        }
      }, 50);
    }
  }, [
    editingPost,
    savedDraft,
    setFiles,
    setIsEditorVisible,
    setMessage,
    setTags,
    setTitle,
    setUploadedFiles,
    t,
  ]);

  // Enable poll edit mode
  useEffect(() => {
    if (editingPost && editingPost.polls.length) {
      const questions = editingPost.polls[0].questions
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((question) => {
          return {
            id: question.id,
            name: question.title,
            allowsMultipleAnswers: question.allowsMultipleAnswers,
            limit: question.limit,
            answers: question.options
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((option) => ({
                name: option.title,
                id: option.id,
              })),
          };
        });
      setPollQuestions(questions);
    }
  }, [editingPost]);

  // Enable appreciation edit mode
  useEffect(() => {
    if (editingPost && activeTab === 'Appreciation') {
      setRecipients(
        editingPost.recipients.map((recipient) => ({
          value: recipient.id,
          label: `${recipient.fullName}`,
        }))
      );
    }
  }, [activeTab, editingPost]);

  return (
    <div className={classes.AddContent} ref={addContentRef}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        cleanUp={cleanUp}
      />
      <div className={classes.editorContainer}>
        {activeTab ===
          t('dashboardComponents.Discover.AddContent.tabs.message') && (
          <MessageEditor
            editingPost={editingPost}
            setIsEditorVisible={setIsEditorVisible}
            isEditorVisible={isEditorVisible}
            sharedClasses={sharedClasses}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={mentions}
            setAddressees={setMentions}
            tags={tags}
            setTags={setTags}
            isDraftButtonDisabled={isDraftButtonDisabled}
            files={files}
            setFiles={setFiles}
            fileNames={fileNames}
            setFileNames={setFileNames}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            textBoxRef={textBoxRef}
            createFeedPost={createFeedPost}
            updateFeedPost={updateFeedPost}
            cancel={cancel}
            isFetching={isFetching}
            savedDraft={savedDraft}
            setEditingPost={setEditingPost}
          />
        )}
        {activeTab ===
          t('dashboardComponents.Discover.AddContent.tabs.poll') && (
          <PollEditor
            fileNames={fileNames}
            setFileNames={setFileNames}
            editingPost={editingPost}
            setIsEditorVisible={setIsEditorVisible}
            isEditorVisible={isEditorVisible}
            sharedClasses={sharedClasses}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={mentions}
            setAddressees={setMentions}
            tags={tags}
            setTags={setTags}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            textBoxRef={textBoxRef}
            createPoll={createPoll}
            updatePoll={updatePoll}
            cancel={cancel}
            questions={pollQuestions}
            setQuestions={setPollQuestions}
            isDraftButtonDisabled={isDraftButtonDisabled}
            isFetching={isFetching}
            savedDraft={savedDraft}
          />
        )}
        {activeTab ===
          t('dashboardComponents.Discover.AddContent.tabs.appreciation') && (
          <AppreciationEditor
            fileNames={fileNames}
            setFileNames={setFileNames}
            editingPost={editingPost}
            setIsEditorVisible={setIsEditorVisible}
            isEditorVisible={isEditorVisible}
            sharedClasses={sharedClasses}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={mentions}
            setAddressees={setMentions}
            tags={tags}
            setTags={setTags}
            isDraftButtonDisabled={isDraftButtonDisabled}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            textBoxRef={textBoxRef}
            cancel={cancel}
            recipients={recipients}
            setRecipients={setRecipients}
            createAppreciation={createAppreciation}
            updateAppreciation={updateFeedPost}
            isFetching={isFetching}
          />
        )}
        {activeTab ===
          t('dashboardComponents.Discover.AddContent.tabs.announcement') && (
          <MessageEditor
            fileNames={fileNames}
            setFileNames={setFileNames}
            editingPost={editingPost}
            setIsEditorVisible={setIsEditorVisible}
            isEditorVisible={isEditorVisible}
            sharedClasses={sharedClasses}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={mentions}
            setAddressees={setMentions}
            tags={tags}
            setTags={setTags}
            isDraftButtonDisabled={isDraftButtonDisabled}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            textBoxRef={textBoxRef}
            createFeedPost={createFeedPost}
            updateFeedPost={updateFeedPost}
            cancel={cancel}
            contentType="announcement"
            noDraft
            isFetching={isFetching}
          />
        )}
      </div>
    </div>
  );
}
