import { useEffect } from 'react';

import useHubConnection from '../../../../hooks/useHubConnection';
import { FEED_HUB_URL } from '../../../../constants/main';
import { useFeedStore } from '../../../../store';

export default function FeedWatcher() {
  const increaseUnreadPostCount = useFeedStore(
    (state) => state.increaseUnreadPostCount
  );

  const { connection, startConnection, endConnection } =
    useHubConnection(FEED_HUB_URL);

  useEffect(() => {
    startConnection?.();

    return () => {
      endConnection?.();
    };
  }, [connection, endConnection, startConnection]);

  useEffect(() => {
    if (connection) {
      connection.on('NewFeedPost', () => {
        console.log('new post');
        increaseUnreadPostCount();
      });
    }
  }, [connection, increaseUnreadPostCount]);

  return null;
}
