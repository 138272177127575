import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';

import classes from './styles.module.scss';
import { JOBS_PORTAL_URL } from '../../../../constants/main';
import instagramIcon from '../../../../assets/images/dashboard/instagram.svg';
import twitterIcon from '../../../../assets/images/socialMedia/twitter.svg';

export default function SocialButtons({ jobPostId, jobTitle, noPadding }) {
  const { t } = useTranslation();

  return (
    <div
      className={classes.SocialButtons}
      style={{
        padding: noPadding ? '0' : '',
      }}
    >
      <h4>{t('modals.PostJobSuccessModal.shareOnSocial')}</h4>
      <div className={classes.buttons}>
        <LinkedinShareButton
          title={jobTitle}
          url={`${JOBS_PORTAL_URL}/jobs/${jobPostId}`}
        >
          <i>
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 16.5C0 7.38869 7.38869 0 16.5 0C25.6113 0 33 7.38869 33 16.5C33 25.6113 25.6113 33 16.5 33C7.38869 33 0 25.6113 0 16.5ZM7.68679 24.9434H11.7053V12.8536H7.68679V24.9434ZM9.69617 11.2028H9.66998C8.3215 11.2028 7.44937 10.2745 7.44937 9.11433C7.44937 7.92799 8.34819 7.02539 9.72285 7.02539C11.0975 7.02539 11.9435 7.92799 11.9697 9.11433C11.9697 10.2745 11.0975 11.2028 9.69617 11.2028ZM22.1772 24.9434H26.1952V18.0111C26.1952 14.2978 24.2127 12.5699 21.5689 12.5699C19.4369 12.5699 18.4814 13.7416 17.9474 14.5654V12.8536H13.9292C13.9818 13.9878 13.9292 24.9434 13.9292 24.9434H17.9474V18.1919C17.9474 17.8306 17.9734 17.4696 18.0796 17.2113C18.3701 16.4894 19.0313 15.7417 20.1413 15.7417C21.5953 15.7417 22.1772 16.8502 22.1772 18.4756V24.9434Z"
                fill="#006FAC"
              />
            </svg>
          </i>
        </LinkedinShareButton>
        <i
          onClick={() => {
            // Open instagram in new tab
            window.open('https://www.instagram.com/', '_blank');
          }}
        >
          <img src={instagramIcon} alt="Instagram" />
        </i>
        <FacebookShareButton
          url={`${JOBS_PORTAL_URL}/jobs/${jobPostId}`}
          quote={jobTitle}
        >
          <i>
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33 16.5C33 7.38633 25.6137 0 16.5 0C7.38633 0 0 7.38633 0 16.5C0 25.6137 7.38633 33 16.5 33C16.5967 33 16.6934 33 16.79 32.9936V20.1545H13.2451V16.023H16.79V12.9809C16.79 9.45527 18.9428 7.53457 22.0881 7.53457C23.5963 7.53457 24.8918 7.64414 25.2656 7.6957V11.3824H23.1C21.392 11.3824 21.0568 12.1945 21.0568 13.3869V16.0166H25.1496L24.6146 20.148H21.0568V32.3619C27.9533 30.3832 33 24.0346 33 16.5Z"
                fill="#0071FE"
              />
            </svg>
          </i>
        </FacebookShareButton>
        <TwitterShareButton
          title={jobTitle}
          url={`${JOBS_PORTAL_URL}/jobs/${jobPostId}`}
        >
          <i>
            <img src={twitterIcon} alt="Twitter" />
          </i>
        </TwitterShareButton>
      </div>
    </div>
  );
}
