import React from 'react';

import { useTranslation } from 'react-i18next';

import PopupButton from '../../../components/PopupButton';
import Button from '../../../components/Button';

import classes from './styles.module.scss';

export default function Buttons({
  isSaveButtonDisabled,
  isResetButtonDisabled,
  resetForm,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.Buttons}>
      <PopupButton
        disabled={isResetButtonDisabled}
        width={250}
        height={45}
        variant="dark"
        onClick={resetForm}
      >
        {t('common.reset')}
      </PopupButton>
      <Button
        width={250}
        height={45}
        onClick={onSubmit}
        disabled={isSaveButtonDisabled}
        style={{
          fontSize: 16,
        }}
      >
        {t('common.save')}
      </Button>
    </div>
  );
}
