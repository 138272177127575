import axios from 'axios';

import { JOBS_API_URL } from '../constants/main';

const CalendarService = {
  async getEvents() {
    const response = await axios.get(`${JOBS_API_URL}/calendar`);
    return response.data;
  },
};

export default CalendarService;
