import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { hasAuthData } from '../../../helpers/authStorage';
import UserService from '../../../services/UserService';

const isNumeric = (value) => {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(value) && !isNaN(parseFloat(value));
};

export default function useOfferStatus({
  candidate,
  counterOfferMode,
  formik,
}) {
  const hasAuth = hasAuthData();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: !!hasAuth,
  });

  const rawOffer = candidate?.jobApplicationOffers?.at(-1);
  const isCountered = rawOffer?.status === 'Countered';
  const prevOffer = rawOffer?.statusHistory?.at(-2);

  // Add proper amount and counterAmout to offer object for convenience
  const offer = useMemo(
    () =>
      isCountered
        ? {
            ...rawOffer,
            amount: rawOffer?.statusHistory?.at(-2)?.amount,
            counterAmount: rawOffer.amount,
          }
        : rawOffer,
    [isCountered, rawOffer]
  );

  // Offer modal should be in view mode if the following is true
  const readOnly =
    (candidate?.status === 'Offer' && candidate?.subStatus === 'Offered') ||
    offer?.status === 'Accepted' ||
    offer?.status === 'Declined' ||
    offer?.statusHistory?.at(-1)?.performedBy === user?.id;

  // Inputs should be disabled if the following is true
  const allowedToEdit =
    !readOnly && (!isCountered || counterOfferMode === 'update');

  // Candidate has accepted or declined the offer
  const isAccepted =
    offer?.status === 'Accepted' || offer?.status === 'Declined';

  // Amount validation for accepting counter offer
  const floatValue = isNumeric(formik?.values?.amount)
    ? formik.values.amount
    : parseFloat(formik?.values?.amount?.replace?.(/,/g, ''));
  let amountStatus = '';
  if (floatValue < offer?.counterAmount) {
    amountStatus = 'lessThanCounter';
  }

  if (floatValue < offer?.amount) {
    amountStatus = 'lessThanPrev';
  }

  if (readOnly || counterOfferMode !== 'update') {
    amountStatus = '';
  }

  // Recruiter offers
  const recruiterOffers = offer?.statusHistory?.filter(
    (item) => item.performedBy === user?.id
  );

  const status = {
    readOnly,
    allowedToEdit,
    isAccepted,
    counterOfferMode,
    amountStatus,
    floatValue,
    offer,
    isCountered,
    prevOffer,
    recruiterOffers,
  };

  return status;
}
