import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import Country from './Country';

export default function CountriesDropdown({
  isDropdownOpened,
  top,
  left,
  countries,
  preferedCountries,
  activeCountry,
  setActiveCountry,
  handleClose,
  containerRef,
  hasSearchTerm,
}) {
  const [dropdowndExceedsBounds, setDropdownExceedsBounds] = useState(false);

  const dropdownRef = useRef(null);
  const countriesRef = useRef(null);

  useOnClickOutside(dropdownRef, () => handleClose(), containerRef);

  useEffect(() => {
    const hideOnScroll = (event) => {
      if (
        dropdownRef?.current?.contains(event.target) &&
        countriesRef?.current?.scrollHeight + 40 >
          dropdownRef?.current?.scrollHeight
      ) {
        return;
      }
      handleClose();
    };

    window.addEventListener('wheel', hideOnScroll);

    return () => {
      window.removeEventListener('wheel', hideOnScroll);
    };
  }, [handleClose]);

  const handleCheckDropdownBounds = useCallback(() => {
    const dropdown = dropdownRef?.current;
    const dropdownHeight = dropdown?.offsetHeight;
    const windowHeight = window.innerHeight;

    if (windowHeight < top + dropdownHeight) {
      setDropdownExceedsBounds(true);
    } else {
      setDropdownExceedsBounds(false);
    }
  }, [top]);

  useEffect(() => {
    handleCheckDropdownBounds();
  }, [handleCheckDropdownBounds]);

  return (
    <div
      className={classes.CountriesDropdown}
      ref={dropdownRef}
      style={{
        top: dropdowndExceedsBounds
          ? `calc(${top - dropdownRef.current?.offsetHeight - 69}px)`
          : top,
        left,
        visibility: isDropdownOpened ? 'visible' : 'hidden',
      }}
    >
      <div className={classes.content}>
        {countries.length ? (
          <ul className={classes.countries} ref={countriesRef}>
            {preferedCountries && !hasSearchTerm && (
              <>
                {preferedCountries.map((preferedCountry) => (
                  <Country
                    country={preferedCountry}
                    activeCountry={activeCountry}
                    setActiveCountry={setActiveCountry}
                    handleClose={handleClose}
                    key={preferedCountry?.name}
                  />
                ))}
                <span className={classes.all}>All Countries</span>
              </>
            )}
            {countries.map((country) => (
              <Country
                country={country}
                activeCountry={activeCountry}
                setActiveCountry={setActiveCountry}
                handleClose={handleClose}
                key={country?.name}
              />
            ))}
          </ul>
        ) : (
          <div className={classes.noData}>No matches</div>
        )}
      </div>
    </div>
  );
}
