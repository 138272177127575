import React, { useState, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import JobPostPreviewModal from '../../../../../modals/JobPostPreviewModal';
import Details from './Details';
import Candidate from '../../../Candidate';
import GridView from './GridView';
import Table from '../../../../Table';
import Tabs from './Tabs';
import TableMenu from '../../../Console/TableMenu';
import JobHeader from './JobHeader';
import Insights from './Insights';
import JobSettings from './JobSettings';

import useDebouncedSearchWithHistory from '../../../../../hooks/useDebouncedSearchWithHistory';
import tableColsToSortOptions from '../../../../../helpers/tableColsToSortOptions';
import AdminService from '../../../../../services/AdminService';

import classes from './styles.module.scss';
import backIcon from '../../../../../assets/images/dashboard/jobs/back.svg';
import longBackIcon from '../../../../../assets/images/dashboard/jobs/back-with-arrow.svg';

const groupCandidates = (candidates) => {
  if (!candidates) {
    return { qualifiedCandidates: [], disqualifiedCandidates: [] };
  }

  const disqualifiedCandidates = candidates?.filter(
    (candidate) =>
      candidate.subStatus === 'NoShow' ||
      candidate.subStatus === 'Rejected' ||
      candidate.subStatus === 'StartDeclined' ||
      candidate.subStatus === 'Declined' ||
      candidate.subStatus === 'Withdrawn' ||
      candidate.subStatus === 'Failed' ||
      candidate.subStatus === 'Incomplete'
  );

  const qualifiedCandidates = candidates?.filter(
    (candidate) => disqualifiedCandidates.indexOf(candidate) === -1
  );

  return { qualifiedCandidates, disqualifiedCandidates };
};

export default function JobDetails() {
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('grid');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCandidatePopupVisible, setIsCandidatePopupVisible] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [isJobPostPreviewModalVisible, setIsJobPostPreviewModalVisible] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { '*': jobId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const [candidatesFilter, setCandidatesFilter] = useState(
    t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
  );

  const [currentTab, setCurrentTab] = useState(
    t('dashboardComponents.Jobs.MyJobs.JobDetails.candidates')
  );
  const [activeSortOption, setActiveSortOption] = useState({
    label: t('dashboardComponents.Jobs.MyJobs.JobDetails.appliedOnColumn'),
    value: 'createdAt',
  });

  // If view mode is grid and sort option is resume, change the sort order so applications with video resume were on top
  useEffect(() => {
    if (view === 'grid' && activeSortOption.value === 'resumeType') {
      setSortOrder('asc');
    }
  }, [view, activeSortOption]);

  const columns = [
    {
      field: 'isFavorite',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.favoriteColumn'),
      type: 'candidateBooleanFavorite',
      isJobApplication: true,
      sortable: true,
    },
    {
      field: 'jobApplication.user.userProfile',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.candidateColumn'),
      type: 'user',
      sortable: true,
      sortField: 'candidateFullName',
    },
    {
      field: 'jobApplicationVideos',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.resumeColumn'),
      type: 'resume',
      sortable: true,
      sortField: 'resumeType',
    },
    {
      field: 'statusInt',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.stageColumn'),
      type: 'hiringStages',
      sortable: true,
    },
    {
      field: 'subStatusInt',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.statusColumn'),
      type: 'hiringSubStatus',
      sortable: true,
    },
    {
      field: 'assignedAssessmentStatus',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.assessmentColumn'),
      sortable: true,
      // assignedAssessmentCount: 'assignedAssessmentCount',
    },
    {
      field: 'jobApplication.jobApplicationInterviews[0]',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.interviewColumn'),
      type: 'interviewFeedback',
      sortable: true,
      sortField: 'interviewStatus',
    },
    {
      field: 'createdAt',
      name: t('dashboardComponents.Jobs.MyJobs.JobDetails.appliedOnColumn'),
      type: 'date',
      sortable: true,
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'myJobCandidates'
  );

  const { data: job, refetch: refetchJob } = useQuery({
    queryKey: ['myJob', jobId],
    queryFn: () => AdminService.getJobPost({ id: jobId }),
  });

  const { data: candidatesData, refetch: refetchCandidates } = useQuery({
    queryKey: [
      'myJobCandidates',
      jobId,
      activeSortOption,
      debouncedSearchTerm,
      sortOrder,
      currentPage,
    ],
    queryFn: () =>
      AdminService.getMyJobApplications({
        term: debouncedSearchTerm,
        orderBy: activeSortOption.value,
        sortOrder,
        jobId,
        pageNumber: currentPage,
        pageSize: 20,
      }),
    keepPreviousData: true,
    onError: () => {
      navigate('/404');
    },
  });

  const candidates = candidatesData?.data || [];

  const { qualifiedCandidates, disqualifiedCandidates } =
    groupCandidates(candidates);

  const actions = [];

  return (
    <div className={classes.JobDetails}>
      <button
        type="button"
        className={classes.backButton}
        onClick={() => navigate(-1)}
      >
        <img src={isTabletOrMobile ? backIcon : longBackIcon} alt="back" />

        {t('dashboardComponents.Jobs.MyJobs.JobDetails.backToJobList')}
      </button>
      <JobHeader
        job={job}
        jobTitle={job?.jobTitle}
        jobId={job?.id}
        createdAt={job?.createdAt}
        status={job?.status}
        refetchJob={refetchJob}
        showJobPostPreviewModal={() => setIsJobPostPreviewModalVisible(true)}
      />
      <div className={classes.tableContainer}>
        <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab ===
          t('dashboardComponents.Jobs.MyJobs.JobDetails.candidates') && (
          <>
            <TableMenu
              style={{
                height: isTabletOrMobile ? '' : 95,
                backgroundColor: 'transparent',
              }}
              actions={actions}
              activeAction=""
              setActiveAction={() => {}}
              sortOptions={sortOptions}
              activeSortOption={activeSortOption}
              setActiveSortOption={setActiveSortOption}
              selectedCount={selectedRows.length}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchHistory={searchHistory}
              view={view}
              setView={setView}
              isQualifiedSwitchVisible
              candidatesFilter={candidatesFilter}
              setCandidatesFilter={setCandidatesFilter}
              qualifiedCount={qualifiedCandidates?.length}
              disqualifiedCount={disqualifiedCandidates?.length}
              reserveSpaceForSelectionInfo
              setCurrentPage={setCurrentPage}
              totalPages={candidates?.totalPages}
            />
            <div className={classes.innerTableContainer}>
              {view === 'list' ? (
                <Table
                  noSort
                  height="calc(100vh - 80px - 37px - 154px - 20px - 53px  - 95px - 46px)"
                  name="myJobCandidates"
                  isFetching
                  fetchNextPage={() => {}}
                  onRowClick={(id) => {
                    const candidate = candidates?.find((cnd) => cnd.id === id);
                    setCurrentCandidate(candidate);
                    setIsCandidatePopupVisible(true);
                  }}
                  rows={
                    candidatesFilter ===
                    t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
                      ? qualifiedCandidates
                      : disqualifiedCandidates
                  }
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  sortOptions={sortOptions}
                  setActiveSortOption={setActiveSortOption}
                  activeSortOption={activeSortOption}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                  columns={columns}
                  refetch={refetchCandidates}
                />
              ) : (
                <GridView
                  setIsCandidatePopupVisible={setIsCandidatePopupVisible}
                  setCurrentCandidate={setCurrentCandidate}
                  candidates={
                    candidatesFilter ===
                    t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
                      ? qualifiedCandidates
                      : disqualifiedCandidates
                  }
                  refetch={refetchCandidates}
                  job={job}
                  candidatesFilter={candidatesFilter}
                />
              )}
            </div>
          </>
        )}
        {currentTab ===
          t('dashboardComponents.Jobs.MyJobs.JobDetails.jobDetails') && (
          <Details refetch={refetchJob} job={job} />
        )}
        {currentTab ===
          t('dashboardComponents.Jobs.MyJobs.JobDetails.insights') && (
          <Insights job={job} />
        )}
        {currentTab ===
          t('dashboardComponents.Jobs.MyJobs.JobDetails.settings') && (
          <JobSettings job={job} refetch={refetchJob} />
        )}
      </div>
      <Candidate
        isVisible={isCandidatePopupVisible}
        setIsVisible={setIsCandidatePopupVisible}
        refetch={refetchCandidates}
        candidate={currentCandidate}
        setCurrentCandidate={setCurrentCandidate}
        candidates={candidates?.map((candidate) => candidate.jobApplication)}
        notificationsData={candidates?.map((candidate) => ({
          id: candidate.jobApplication?.id,
          onboardingSeenAt: candidate.onboardingSeenAt,
          interviewSeenAt: candidate.interviewSeenAt,
          joiningSeenAt: candidate.joiningSeenAt,
        }))}
        job={job}
        isApplication
      />
      <JobPostPreviewModal
        show={isJobPostPreviewModalVisible}
        handleClose={() => setIsJobPostPreviewModalVisible(false)}
        jobPostId={jobId}
      />
    </div>
  );
}
