/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { JobsContext } from '../../../../../context/JobsContext';
import classes from './styles.module.scss';
import { UiContext } from '../../../../../context/UiContext';

export default function DotMenu({ width, candidate, job, event }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const { showShareModal } = useContext(JobsContext);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  const { showNotification } = useContext(UiContext);

  useOnClickOutside(dropdownRef, () => {
    setIsOptionsListVisible(false);
  });

  const getLink = () => {
    if (candidate) {
      return `/tools/candidates?candidate=${candidate?.userId}`;
    }

    if (job) {
      return `/tools/jobs/my-jobs/${job?.jobPostId}`;
    }

    if (event) {
      return `/tools/calendar?event=${event?.type}&id=${event?.id}`;
    }

    return '';
  };

  const copyLink = () => {
    let text;

    const url = `${window.location.protocol}//${window.location.host}`;
    const link = `${url}${getLink()}`;

    if (candidate) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Candidate link copied to clipboard'
      );
    } else if (job) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Job post link copied to clipboard'
      );
    } else if (event) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Calendar event link copied to clipboard'
      );
    }

    navigator.clipboard.writeText(link);
    showNotification({
      text,
    });
    setIsOptionsListVisible(false);
  };

  const openInNewTab = () => {
    window.open(getLink(), '_blank');
    setIsOptionsListVisible(false);
  };

  const options = [
    {
      label: t('dashboardComponents.Header.Search.DotMenu.View in new tab'),
      onClick: openInNewTab,
    },
    {
      label: t('dashboardComponents.Header.Search.DotMenu.Copy link'),
      onClick: copyLink,
    },
    {
      label: t('dashboardComponents.Header.Search.DotMenu.Share'),
      onClick: () => {
        showShareModal({ candidate, job, event });
      },
    },
  ];

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.dotMenu, {
        [classes.active]: isOptionsListVisible,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={classes.selector}
        onClick={() => {
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        ...
      </div>
      {isOptionsListVisible && (
        <>
          <div className={classes.options} style={{ width }}>
            <ul>
              {options.map((option) => {
                return (
                  <li
                    key={option.label}
                    onClick={() => {
                      setIsOptionsListVisible(false);
                      option.onClick();
                    }}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
