import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function SearchResultCount({ searchResultCount }) {
  const { t } = useTranslation();

  if (searchResultCount === null || searchResultCount === undefined) {
    return null;
  }

  return (
    <div className={classes.searchResultCount}>
      {searchResultCount}{' '}
      {searchResultCount === 1
        ? t('dashboardComponents.Console.TableMenu.recordFound')
        : t('dashboardComponents.Console.TableMenu.recordsFound')}
    </div>
  );
}
