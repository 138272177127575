/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Offer({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      ApplicationPendingOffer: {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.offerPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Offer.offerPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.offerPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      ApplicationPendingCounterOffer: {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.counterOfferPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Offer.counterOfferPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.counterOfferPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      OfferCounteredJS: {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.offerCountered.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Offer.offerCountered.text',
          {
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.offerCountered.buttonLabel'
        ),
        onClick: goToJob,
      },
      OfferAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.offerAccepted.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.offerAccepted.buttonLabel'
        ),
        onClick: goToJob,
      },
      OfferDeclined: {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.offerDeclined.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.Offer.offerDeclined.text'
        ),
        buttonLabel: '',
        onClick: () => {},
      },
    };

    return (
      content[notification.name] ||
      content[notification.eventNote] || {
        title: notification.eventNote,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
