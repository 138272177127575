/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function New({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      ApplicationPendingReview: {
        title: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReview.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReview.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReview.buttonLabel'
        ),
        onClick: goToJob,
      },
      ApplicationPendingReviewAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReviewAccepted.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReviewAccepted.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.newPendingReviewAccepted.buttonLabel'
        ),
        onClick: goToJob,
      },
      InvitationAccepted: {
        title: t(
          'dashboardComponents.Notifications.Notification.New.newInviteAccepted.title',
          {
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
            name: notification.jobApplication?.user?.userProfile?.fullName,
          }
        ),
        text: t(
          'dashboardComponents.Notifications.Notification.New.newInviteAccepted.text'
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.newInviteAccepted.buttonLabel'
        ),
        onClick: goToJob,
      },
      NewCandidate: {
        title: t(
          'dashboardComponents.Notifications.Notification.New.newCandidate.title',
          {
            name: notification.jobApplication?.user?.userProfile?.fullName,
            title: notification.jobPost?.jobTitle,
            jobId: notification.jobPost?.id,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.newCandidate.buttonLabel'
        ),
        onClick: goToJob,
      },
      'notitification.applications.pendingreview': {
        title: t(
          'dashboardComponents.Notifications.Notification.New.notificationApplicationsPendingReview'
        ),
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.review'
        ),
        onClick: goToJob,
      },
      'notitification.application.interview_canceled_recruiter': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.New.interviewCanceled'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        }) - ${notification.jobApplication?.user?.userProfile?.fullName}`,
        onClick: goToJob,
      },
    };

    return (
      content[notification.name] ||
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
