import React from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InnerHeader from '../../components/Auth/InnerHeader';
import ButtonWithHelperText from '../../components/Auth/ButtonWithHelperText';

import classes from './styles.module.scss';

export default function RequestNewPasswordSuccessPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={classes.RequestNewPasswordSuccessPage}>
      <Helmet>
        <title>{t('pages.RequestNewPasswordSuccessPage.pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <InnerHeader
          noButton
          title={t('pages.RequestNewPasswordSuccessPage.pageHeader')}
        />
        <p>
          {t('pages.RequestNewPasswordSuccessPage.notificationText1')} <br />
          <br />
          {t('pages.RequestNewPasswordSuccessPage.notificationText2')}
        </p>
        <ButtonWithHelperText
          noHelper
          onClick={() => navigate('/', { replace: true })}
          buttonLabel={t('pages.RequestNewPasswordSuccessPage.closeButton')}
        />
      </div>
    </div>
  );
}
