import React from 'react';

import classes from './styles.module.scss';

export default function AnimatedText({ children, style }) {
  return (
    <span className={classes.AnimatedText} style={style}>
      {children}
    </span>
  );
}
