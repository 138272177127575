import React from 'react';

import SortIcon from '../SortIcon';

export default function HiringPipelineSubHeadings({
  col,
  index,
  classes,
  changeSortSettings,
  sortOrder,
  activeSortOption,
}) {
  return (
    <>
      <th
        // eslint-disable-next-line react/no-array-index-key
        key={`${col.field}${index}`}
        className={classes.thHiringPipelineHeading}
        onClick={() => changeSortSettings('summary.new')}
      >
        <div className={classes.thContainer}>
          New{' '}
          <SortIcon
            sortOrder={sortOrder}
            activeSortOption={{ label: activeSortOption.value }}
            name="summary.new"
          />
        </div>
      </th>
      <th
        key={`${col.field}13`}
        className={classes.thHiringPipelineHeading}
        onClick={() => changeSortSettings('summary.screen')}
      >
        <div className={classes.thContainer}>
          Screen{' '}
          <SortIcon
            style={{ right: 0 }}
            sortOrder={sortOrder}
            activeSortOption={{ label: activeSortOption.value }}
            name="summary.screen"
          />
        </div>
      </th>
      <th
        key={`${col.field}14`}
        className={classes.thHiringPipelineHeading}
        onClick={() => changeSortSettings('summary.interview')}
      >
        <div className={classes.thContainer}>
          Interview{' '}
          <SortIcon
            style={{ right: 0 }}
            sortOrder={sortOrder}
            activeSortOption={{ label: activeSortOption.value }}
            name="summary.interview"
          />
        </div>
      </th>
      <th
        key={`${col.field}15`}
        className={classes.thHiringPipelineHeading}
        onClick={() => changeSortSettings('summary.offer')}
      >
        <div className={classes.thContainer}>
          Offer{' '}
          <SortIcon
            sortOrder={sortOrder}
            activeSortOption={{ label: activeSortOption.value }}
            name="summary.offer"
          />
        </div>
      </th>
      <th
        key={`${col.field}16`}
        className={classes.thHiringPipelineHeading}
        onClick={() => {
          changeSortSettings('summary.onboard');
        }}
      >
        <div className={classes.thContainer}>
          Onboard{' '}
          <SortIcon
            style={{ right: 0 }}
            sortOrder={sortOrder}
            activeSortOption={{ label: activeSortOption.value }}
            name="summary.onboard"
          />
        </div>
      </th>
    </>
  );
}
