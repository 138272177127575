import React from 'react';

import classNames from 'classnames';

import InactiveOverlay from '../InactiveOverlay';

import getResizedImageUrl from '../../../../../../../helpers/getResizedImageUrl';
import classes from './styles.module.scss';

export default function BrowserPreview({
  backgroundColor,
  name,
  favicon,
  isActive,
}) {
  const imageUrl =
    favicon instanceof File
      ? URL.createObjectURL(favicon)
      : getResizedImageUrl({
          url: `brand/${favicon?.split('/')?.pop()}`,
          width: 64,
        });

  return (
    <div
      className={classNames(classes.BrowserPreview, {
        [classes.active]: isActive,
      })}
    >
      <InactiveOverlay isActive={isActive} />
      <div
        className={classes.container}
        style={{
          backgroundColor: backgroundColor || '#fbf2e5',
        }}
      >
        <h4>{name}</h4>
        <img
          src={imageUrl}
          alt="Favicon"
          style={{
            display: !favicon ? 'none' : 'block',
          }}
        />
      </div>
    </div>
  );
}
