import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import AssessmentInfo from '../../AvailableAssessments/Assessment/Test/AssessmentInfo';
import AssessmentInput from './AssessmentInput';

import classes from './styles.module.scss';

export default function Assessment({
  assessment,
  deleteAssessment,
  setThreshold,
  isForm,
  touched,
  jobTemplate,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Assessment, {
        [classes.isForm]: isForm,
        [classes.jobTemplate]: jobTemplate,
      })}
    >
      <div
        className={classes.drag}
        onClick={(event) => event.stopPropagation()}
      />
      <div className={classes.titleContainer}>
        <span
          className={classes.title}
        >{`${assessment?.assessment}: ${assessment?.name}`}</span>{' '}
        {jobTemplate && assessment.assessment === 'FlowQ' && (
          <AssessmentInfo test={assessment.content?.[0]} />
        )}
      </div>
      <div className={classes.inputs}>
        {false &&
          assessment.englishThreshold !== null &&
          assessment.assessment === 'Zaphire' && (
            <AssessmentInput
              label={t(
                'modals.AssessmentModal.selectedAssessments.assessment.englishThreshold'
              )}
              type="percents"
              name="englishThreshold"
              value={assessment.englishThreshold}
              assessment={assessment}
              setThreshold={setThreshold}
              isForm={isForm}
              touched={touched}
              jobTemplate={jobTemplate}
            />
          )}
        {false &&
          assessment.mentalThreshold !== null &&
          assessment.assessment === 'Zaphire' && (
            <AssessmentInput
              label={t(
                'modals.AssessmentModal.selectedAssessments.assessment.mentalThreshold'
              )}
              type="percents"
              name="mentalThreshold"
              value={assessment.mentalThreshold}
              assessment={assessment}
              setThreshold={setThreshold}
              isForm={isForm}
              touched={touched}
              jobTemplate={jobTemplate}
            />
          )}
        {assessment.threshold !== null &&
          assessment.assessment === 'Zaphire' && (
            <AssessmentInput
              label={t(
                'modals.AssessmentModal.selectedAssessments.assessment.threshold'
              )}
              type="percents"
              name="threshold"
              value={assessment.threshold}
              assessment={assessment}
              setThreshold={setThreshold}
              isForm={isForm}
              touched={touched}
              jobTemplate={jobTemplate}
            />
          )}
        <AssessmentInput
          label={t(
            'modals.AssessmentModal.selectedAssessments.assessment.timeLimitDays'
          )}
          name="limit"
          type="limit"
          value={assessment.limit}
          assessment={assessment}
          setThreshold={setThreshold}
          isForm={isForm}
          touched={touched}
          jobTemplate={jobTemplate}
        />
      </div>
      <span
        className={classes.delete}
        onClick={(event) => {
          event.stopPropagation();
          deleteAssessment();
        }}
      />
    </div>
  );
}
