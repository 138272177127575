import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import useUpdateData from '../../../../hooks/useUpdateData';
import useCreateData from '../../../../hooks/useCreateData';
import { UiContext } from '../../../../context/UiContext';
import AddOrEditData from '../AddOrEditData';
import ViewData from '../ViewData';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import Table from '../../../Table';
import TableMenu from '../TableMenu';
import tableColsToSortOptions from '../../../../helpers/tableColsToSortOptions';
import useDeleteData from '../../../../hooks/useDeleteData';

export default function TableView({
  setItemCount,
  dataName,
  getDataHandler,
  deleteDataHandler,
  createDataHandler,
  updateDataHandler,
  viewLeftColumnFields,
  viewRightColumnFields,
  formLeftColumnFields,
  formRightColumnFields,
  columns,
  viewComponent,
  addOrEditComponent,
  isFetching,
  availableActions,
  cellHeight,
  bottomButton,
  onCreateError,
  onUpdateError,
}) {
  const [activeAction, setActiveAction] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isViewDataVisible, setIsViewDataVisible] = useState(false);
  const [currentDataId, setCurrentDataId] = useState(null);
  const [isAddDataVisible, setIsAddDataVisible] = useState(false);
  const [isEditDataVisible, setIsEditDataVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const [activeSortOption, setActiveSortOption] = useState({
    label: t('common.createdOn'),
    value: 'createdAt',
  });

  const sortOptions = tableColsToSortOptions(columns);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    `${dataName.replace(/\s/g, '')}SearchHistory`
  );

  const { showSelectionNeededModal } = useContext(UiContext);

  const { data, refetch } = useQuery({
    queryKey: [dataName, currentPage, debouncedSearchTerm, sortOrder],
    queryFn: () => {
      return getDataHandler({
        orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        sortOrder,
        pageNumber: currentPage,
      });
    },
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setItemCount(data.totalCount);
    }
  }, [data, setItemCount]);

  useEffect(() => {
    refetch();
  }, [activeSortOption, debouncedSearchTerm, refetch, sortOrder]);

  const rows = data?.data || [];

  const showViewData = (id) => {
    setCurrentDataId(id);
    setIsViewDataVisible(true);
  };

  const showEditData = () => {
    setIsEditDataVisible(true);
  };

  const showAddData = () => {
    setIsAddDataVisible(true);
  };

  const showEditDataSingle = (id, callback) => {
    setSelectedRows([id]);
    setIsEditDataVisible(true);

    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  const { createData } = useCreateData(
    createDataHandler,
    setIsAddDataVisible,
    refetch,
    dataName,
    onCreateError
  );

  const { updateData } = useUpdateData({
    updateHandler: updateDataHandler,
    refetch,
    onError: onUpdateError,
    dataName,
  });

  const { deleteData } = useDeleteData(
    selectedRows,
    () => setIsViewDataVisible(false),
    refetch,
    setSelectedRows,
    deleteDataHandler,
    dataName
  );

  let actions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: showAddData,
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          showEditData();
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteData();
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  if (availableActions) {
    actions = actions.filter((action) =>
      availableActions.includes(action.label)
    );
  }

  const isAddDataMode = isAddDataVisible;
  const ViewComponent = viewComponent;
  const AddOrEditComponent = addOrEditComponent;

  const viewData =
    viewLeftColumnFields || viewRightColumnFields ? (
      <ViewData
        isOverlayed={isEditDataVisible}
        isVisible={isViewDataVisible}
        handleClose={() => setIsViewDataVisible(false)}
        currentDataId={currentDataId}
        data={rows}
        title={`View ${dataName}`}
        leftButtonLabel={t('common.delete')}
        rightButtonLabel={t('common.edit')}
        onRightButtonClick={showEditDataSingle}
        onLeftButtonClick={deleteData}
        leftColumnFields={viewLeftColumnFields}
        rightColumnFields={viewRightColumnFields}
      />
    ) : null;

  const addOrEditData =
    formLeftColumnFields || formRightColumnFields ? (
      <AddOrEditData
        hasNoAnimation={isViewDataVisible}
        data={rows}
        selectedRows={selectedRows}
        isEdit={isEditDataVisible}
        isAdd={isAddDataMode}
        isVisible={isAddDataVisible || isEditDataVisible}
        handleClose={() => {
          setIsEditDataVisible(false);
          setIsAddDataVisible(false);
        }}
        title={
          isAddDataMode
            ? `${t('common.add')} ${dataName}`
            : `${t('common.edit')} ${dataName}`
        }
        leftButtonLabel={isAddDataMode ? t('common.clear') : t('common.reset')}
        rightButtonLabel={isAddDataMode ? t('common.add') : t('common.save')}
        leftColumnFields={formLeftColumnFields}
        rightColumnFields={formRightColumnFields}
        submitHandler={isAddDataMode ? createData : updateData}
        bottomButton={bottomButton}
      />
    ) : null;

  return (
    <div>
      <TableMenu
        actions={actions}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
        dangerAction="Delete"
        sortOptions={sortOptions}
        activeSortOption={activeSortOption}
        setActiveSortOption={setActiveSortOption}
        selectedCount={selectedRows.length}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
        setSortOrder={setSortOrder}
        currentPage={currentPage}
        totalPages={data?.totalPages || 1}
        setCurrentPage={setCurrentPage}
        searchResultCount={
          debouncedSearchTerm && !isFetching ? data?.totalCount : null
        }
      />
      <Table
        name={dataName}
        rows={rows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortOptions={sortOptions}
        setActiveSortOption={setActiveSortOption}
        activeSortOption={activeSortOption}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        columns={columns}
        onRowClick={showViewData}
        isFetching={isFetching}
        cellHeight={cellHeight}
        refetch={refetch}
      />

      {viewComponent ? (
        <ViewComponent
          dataName={dataName}
          isVisible={isViewDataVisible}
          handleClose={() => setIsViewDataVisible(false)}
          data={rows}
          currentDataId={currentDataId}
          showEditDataSingle={showEditDataSingle}
          isOverlayed={isEditDataVisible}
          deleteData={deleteData}
        />
      ) : (
        viewData
      )}

      {addOrEditComponent ? (
        <AddOrEditComponent
          isVisible={isAddDataVisible || isEditDataVisible}
          handleClose={() => {
            setIsEditDataVisible(false);
            setIsAddDataVisible(false);
          }}
          createDataHandler={createDataHandler}
          updateDataHandler={updateDataHandler}
          refetch={refetch}
          setIsAddDataVisible={setIsAddDataVisible}
          selectedRows={selectedRows}
          isEdit={isEditDataVisible}
          isAdd={isAddDataMode}
          data={rows}
          hasNoAnimation={isViewDataVisible}
        />
      ) : (
        addOrEditData
      )}
    </div>
  );
}
